import posthog from 'posthog-js';
export default function sendClientSidePosthogEvent(event) {
    if (process.env.NEXT_PUBLIC_ENVIRONMENT !== 'production') {
        return;
    }
    if (typeof posthog !== 'undefined') {
        posthog.capture(event.key, {
            ...(event?.properties ??
                {}),
        });
    }
}
