import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: DateTime;
};

export type Account = {
  __typename?: 'Account';
  Name: Scalars['String'];
};

export type AccountPlan = {
  __typename?: 'AccountPlan';
  activePopupCount: Scalars['Int'];
  description?: Maybe<Scalars['String']>;
  displayCount: Scalars['Int'];
  domainCount: Scalars['Int'];
  id: Scalars['Int'];
  impressionCount: Scalars['Int'];
  interactionCount: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  popupCount: Scalars['Int'];
  price: Scalars['Int'];
  stripeId?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type AccountStats = {
  __typename?: 'AccountStats';
  autoExtendEmailStatus?: Maybe<Scalars['Boolean']>;
  autoExtendPageViewStatus?: Maybe<Scalars['Boolean']>;
  autoExtendStatus?: Maybe<Scalars['Boolean']>;
  email: Scalars['Int'];
  emailLimitExtendPrice: Scalars['Int'];
  emailLimitExtendTotal: Scalars['Int'];
  isBlocked: Scalars['Boolean'];
  pageView: Scalars['Int'];
  pageViewLimitExtendPrice: Scalars['Int'];
  pageViewLimitExtendTotal: Scalars['Int'];
  planEmail: Scalars['Int'];
  planPageView: Scalars['Int'];
  respondentEmailCount: Scalars['Int'];
  selfEmailCount: Scalars['Int'];
  usedEmail: Scalars['Int'];
  usedPageView: Scalars['Int'];
};

export type AccountUsersResponse = {
  __typename?: 'AccountUsersResponse';
  canCreateCampaign: Scalars['Boolean'];
  email?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  role?: Maybe<AccountUsersRoleResponse>;
  status?: Maybe<Scalars['Boolean']>;
};

export type AccountUsersRoleResponse = {
  __typename?: 'AccountUsersRoleResponse';
  campaigns: Array<Scalars['Int']>;
  roleTypeId: Scalars['Int'];
};

export type Accounts = {
  __typename?: 'Accounts';
  id: Scalars['Int'];
  isAgency: Scalars['Boolean'];
  isOwner: Scalars['Boolean'];
  name: Scalars['String'];
  parentId?: Maybe<Scalars['Int']>;
};

export type ActiveCampaignCampaign = {
  __typename?: 'ActiveCampaignCampaign';
  accountId: Scalars['Int'];
  apiKey: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
  status: Scalars['Boolean'];
};

export type ActiveCampaignInput = {
  account: Scalars['String'];
  apiKey: Scalars['String'];
  id: Scalars['String'];
  integrationCampaigns: Array<ActiveCampaignIntegrationCampaignInput>;
  name: Scalars['String'];
  status: Scalars['Boolean'];
};

export type ActiveCampaignIntegrationCampaignInput = {
  campaignId: Scalars['Int'];
  id: Scalars['Int'];
  integrationFields: Array<ActiveCampaignIntegrationFieldsInput>;
  integrationId: Scalars['String'];
  listId: Scalars['String'];
  name: Scalars['String'];
  status: Scalars['Boolean'];
};

export type ActiveCampaignIntegrationCampaignResponse = {
  __typename?: 'ActiveCampaignIntegrationCampaignResponse';
  campaignId: Scalars['Int'];
  id: Scalars['Int'];
  integration?: Maybe<ActiveCampaignCampaign>;
  integrationFields: Array<ActiveCampaignIntegrationFields>;
  integrationId: Scalars['Int'];
  listId?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  status: Scalars['Boolean'];
};

export type ActiveCampaignIntegrationFields = {
  __typename?: 'ActiveCampaignIntegrationFields';
  fieldType: Scalars['Int'];
  id: Scalars['Int'];
  integrationCampaignId?: Maybe<Scalars['Int']>;
  integratorFieldId?: Maybe<Scalars['String']>;
  integratorFieldName?: Maybe<Scalars['String']>;
  integratorTag?: Maybe<Scalars['String']>;
  isCustomerEmail: Scalars['Boolean'];
  popupsmartFieldName?: Maybe<Scalars['String']>;
};

export type ActiveCampaignIntegrationFieldsInput = {
  fieldType?: InputMaybe<Scalars['Int']>;
  id: Scalars['Int'];
  integrationCampaignId?: InputMaybe<Scalars['Int']>;
  integratorFieldId?: InputMaybe<Scalars['String']>;
  integratorFieldName?: InputMaybe<Scalars['String']>;
  integratorTag?: InputMaybe<Scalars['String']>;
  isCustomerEmail?: InputMaybe<Scalars['Boolean']>;
  nodeName?: InputMaybe<Scalars['String']>;
  popupsmartFieldName?: InputMaybe<Scalars['String']>;
};

export type ActiveCampaignListResponseItem = {
  __typename?: 'ActiveCampaignListResponseItem';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export type ActiveCampaignResponse = {
  __typename?: 'ActiveCampaignResponse';
  account: Scalars['String'];
  accountId: Scalars['Int'];
  apiKey: Scalars['String'];
  id: Scalars['Int'];
  integrationCampaigns: Array<ActiveCampaignIntegrationCampaignResponse>;
  name: Scalars['String'];
  status: Scalars['Boolean'];
};

export type AdminCustomerLoginTokenResponse = {
  __typename?: 'AdminCustomerLoginTokenResponse';
  accessToken: Scalars['String'];
  refreshToken: Scalars['String'];
};

export type AnalyticsBounceData = {
  __typename?: 'AnalyticsBounceData';
  changeToLastPeriod: Scalars['Float'];
  count: Scalars['Int'];
  invalid: AnalyticsData;
  rate?: Maybe<Scalars['Float']>;
};

export type AnalyticsData = {
  __typename?: 'AnalyticsData';
  changeToLastPeriod: Scalars['Float'];
  count: Scalars['Int'];
  rate?: Maybe<Scalars['Float']>;
};

export type AnalyticsFilterInput = {
  campaignId?: InputMaybe<Scalars['Int']>;
  endDate: Scalars['DateTime'];
  startDate?: InputMaybe<Scalars['DateTime']>;
};

export type AutomationsFilter = {
  domainIds?: InputMaybe<Array<Scalars['Int']>>;
  existingCampaignId?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<Scalars['String']>;
};

export type BrevoInput = {
  apiKey: Scalars['String'];
  id: Scalars['String'];
  integrationCampaigns: Array<BrevoIntegrationCampaignInput>;
  name: Scalars['String'];
  status: Scalars['Boolean'];
};

export type BrevoIntegrationCampaign = {
  __typename?: 'BrevoIntegrationCampaign';
  accountId: Scalars['Int'];
  apiKey: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
  status: Scalars['Boolean'];
};

export type BrevoIntegrationCampaignInput = {
  campaignId: Scalars['Int'];
  id: Scalars['Int'];
  integrationFields: Array<BrevoIntegrationFieldsInput>;
  integrationId: Scalars['String'];
  listId: Scalars['String'];
  name: Scalars['String'];
  status: Scalars['Boolean'];
};

export type BrevoIntegrationCampaignResponse = {
  __typename?: 'BrevoIntegrationCampaignResponse';
  campaignId: Scalars['Int'];
  id: Scalars['Int'];
  integration?: Maybe<BrevoIntegrationCampaign>;
  integrationFields: Array<BrevoIntegrationFields>;
  integrationId: Scalars['Int'];
  listId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  status: Scalars['Boolean'];
};

export type BrevoIntegrationFields = {
  __typename?: 'BrevoIntegrationFields';
  fieldType: Scalars['Int'];
  id: Scalars['Int'];
  integrationCampaignId?: Maybe<Scalars['Int']>;
  integratorFieldId?: Maybe<Scalars['String']>;
  integratorFieldName?: Maybe<Scalars['String']>;
  integratorTag?: Maybe<Scalars['String']>;
  isCustomerEmail: Scalars['Boolean'];
  popupsmartFieldName?: Maybe<Scalars['String']>;
};

export type BrevoIntegrationFieldsInput = {
  fieldType?: InputMaybe<Scalars['Int']>;
  id: Scalars['Int'];
  integrationCampaignId?: InputMaybe<Scalars['Int']>;
  integratorFieldId?: InputMaybe<Scalars['String']>;
  integratorFieldName?: InputMaybe<Scalars['String']>;
  integratorTag?: InputMaybe<Scalars['String']>;
  isCustomerEmail?: InputMaybe<Scalars['Boolean']>;
  nodeName?: InputMaybe<Scalars['String']>;
  popupsmartFieldName?: InputMaybe<Scalars['String']>;
};

export type BrevoListResponseItem = {
  __typename?: 'BrevoListResponseItem';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type BrevoResponse = {
  __typename?: 'BrevoResponse';
  accountId: Scalars['Int'];
  apiKey: Scalars['String'];
  id: Scalars['Int'];
  integrationCampaigns: Array<BrevoIntegrationCampaignResponse>;
  name: Scalars['String'];
  status: Scalars['Boolean'];
};

export type BrowserAnalytics = {
  __typename?: 'BrowserAnalytics';
  browser: Scalars['String'];
  display: Scalars['Int'];
  interaction: Scalars['Int'];
};

export type Campaign = {
  __typename?: 'Campaign';
  conversionRate: Scalars['Float'];
  createdAt: Scalars['String'];
  domains: Array<Domain>;
  folder?: Maybe<Folder>;
  folderId?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  isActive: Scalars['Boolean'];
  isTemplateSelected: Scalars['Boolean'];
  name: Scalars['String'];
  publishVersion: Scalars['Int'];
  variantInfo?: Maybe<VariantInfo>;
};

export type CampaignAnalytics = {
  __typename?: 'CampaignAnalytics';
  details: CampaignAnalyticsDetail;
  id: Scalars['Int'];
};

export type CampaignAnalyticsDetail = {
  __typename?: 'CampaignAnalyticsDetail';
  clickedRate: Scalars['Float'];
  delivered: Scalars['Int'];
  openedRate: Scalars['Float'];
  sent: Scalars['Int'];
};

export type CampaignCreateRequest = {
  campaignName: Scalars['String'];
  domains: Array<Scalars['Int']>;
  folderId?: InputMaybe<Scalars['Int']>;
  layout?: InputMaybe<Scalars['String']>;
  settings?: InputMaybe<Scalars['String']>;
  targets?: InputMaybe<Scalars['String']>;
};

export type CampaignCreateWithTemplateIdRequest = {
  campaignName: Scalars['String'];
  domainId: Scalars['Int'];
  templateId: Scalars['Int'];
  themeElement?: InputMaybe<Scalars['String']>;
};

export type CampaignDailyAnalytics = {
  __typename?: 'CampaignDailyAnalytics';
  abTestingId?: Maybe<Scalars['Int']>;
  campaignId: Scalars['Int'];
  display: Scalars['Int'];
  interaction: Scalars['Int'];
  lead: Scalars['Int'];
};

export type CampaignFilterInput = {
  folderId?: InputMaybe<Scalars['Float']>;
};

export type CampaignForSegment = {
  __typename?: 'CampaignForSegment';
  id: Scalars['Float'];
  name: Scalars['String'];
};

export type CampaignGetWithIntegrations = {
  __typename?: 'CampaignGetWithIntegrations';
  autoResponderMailBody?: Maybe<Scalars['String']>;
  autoResponderReplyTo?: Maybe<Scalars['String']>;
  autoResponderSenderName?: Maybe<Scalars['String']>;
  autoResponderStatus: Scalars['Boolean'];
  autoResponderSubject?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  domains: Array<DomainResponse>;
  id: Scalars['Int'];
  integrationActiveCampaignCampaigns: Scalars['String'];
  integrationCustomerIoCampaigns: Scalars['String'];
  integrationDripCampaigns: Scalars['String'];
  integrationEmailOctopusCampaigns: Scalars['String'];
  integrationHubSpotCampaigns: Scalars['String'];
  integrationKlaviyoCampaigns: Scalars['String'];
  integrationMailchimpCampaigns: Scalars['String'];
  integrationMailerliteCampaigns: Scalars['String'];
  integrationSendLoopCampaigns: Scalars['String'];
  integrationSendgridCampaigns: Scalars['String'];
  integrationSendinBlueCampaigns: Scalars['String'];
  integrationWebhooks: Scalars['String'];
  layout: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  publishVersion?: Maybe<Scalars['Int']>;
  settings: Scalars['String'];
  status?: Maybe<Scalars['Boolean']>;
  targets: Scalars['String'];
  variantInfo?: Maybe<Scalars['String']>;
};

export type CampaignType = {
  __typename?: 'CampaignType';
  Domain?: Maybe<Domain>;
  Id: Scalars['Int'];
  Name: Scalars['String'];
};

export type CampaignUpdateRequest = {
  domains?: InputMaybe<Array<Scalars['Int']>>;
  isTemplateSelected?: InputMaybe<Scalars['Boolean']>;
  layout?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  popupType?: InputMaybe<Scalars['String']>;
  settings?: InputMaybe<Scalars['String']>;
  targets?: InputMaybe<Scalars['String']>;
  templateId?: InputMaybe<Scalars['Int']>;
};

export type CampaignVariantInput = {
  campaignId: Scalars['Int'];
  percentage: Scalars['Int'];
};

export type CampaignWithDomainsResponse = {
  __typename?: 'CampaignWithDomainsResponse';
  domains: Array<Scalars['String']>;
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  variantInfo?: Maybe<CampaignWithDomainsResponseVariantInfo>;
};

export type CampaignWithDomainsResponseVariant = {
  __typename?: 'CampaignWithDomainsResponseVariant';
  name: Scalars['String'];
  percentage: Scalars['Int'];
};

export type CampaignWithDomainsResponseVariantInfo = {
  __typename?: 'CampaignWithDomainsResponseVariantInfo';
  abTestingId: Scalars['Int'];
  variant: CampaignWithDomainsResponseVariant;
};

export type CampaignsWithoutEmailAutomation = {
  __typename?: 'CampaignsWithoutEmailAutomation';
  domains: Array<Domain>;
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type ChangeAccountPlanResponse = {
  __typename?: 'ChangeAccountPlanResponse';
  id: Scalars['Float'];
  name: Scalars['String'];
  planId: Scalars['Float'];
};

export type ChangeStatusResponse = {
  __typename?: 'ChangeStatusResponse';
  id: Scalars['Int'];
  status: Scalars['Boolean'];
};

export type ChangeStatusShopifyIntegrationResponse = {
  __typename?: 'ChangeStatusShopifyIntegrationResponse';
  id: Scalars['Int'];
  status: Scalars['Boolean'];
};

export type ChangeUserEmailResponse = {
  __typename?: 'ChangeUserEmailResponse';
  email: Scalars['String'];
  id: Scalars['Float'];
};

export type CreateAbTestingResponse = {
  __typename?: 'CreateAbTestingResponse';
  id: Scalars['Int'];
};

export type CreateCampaignResponse = {
  __typename?: 'CreateCampaignResponse';
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type CreateCampaignVariantResponse = {
  __typename?: 'CreateCampaignVariantResponse';
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type CreateCustomerType = {
  __typename?: 'CreateCustomerType';
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Scalars['String']>>;
};

export type CreateEmailAutomationInput = {
  accountId?: InputMaybe<Scalars['Int']>;
  campaignId?: InputMaybe<Scalars['Int']>;
  domain?: InputMaybe<Scalars['String']>;
  flowId?: InputMaybe<Scalars['Int']>;
  name: Scalars['String'];
  triggerId?: InputMaybe<Scalars['Int']>;
};

export type CreateFirstEmailAutomationInput = {
  campaignId?: InputMaybe<Scalars['Int']>;
};

export type CreateFolderInput = {
  color?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};

export type CreatePortalLinkOutput = {
  __typename?: 'CreatePortalLinkOutput';
  url: Scalars['String'];
};

export type CreateSegment = {
  Description?: InputMaybe<Scalars['String']>;
  Key?: InputMaybe<Scalars['String']>;
  Name?: InputMaybe<Scalars['String']>;
  Targets?: InputMaybe<Scalars['String']>;
};

export type CreateSessionIdOutput = {
  __typename?: 'CreateSessionIdOutput';
  url: Scalars['String'];
};

export type CreateWorkspaceInput = {
  name: Scalars['String'];
};

export type CreateWorkspaceResponse = {
  __typename?: 'CreateWorkspaceResponse';
  createdAt: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
  parentAccount?: Maybe<ParentAccount>;
  updatedAt: Scalars['String'];
};

export type CustomFont = {
  __typename?: 'CustomFont';
  campaignId: Scalars['Int'];
  id: Scalars['Int'];
  name: Scalars['String'];
  url: Scalars['String'];
  weightUrls: Scalars['String'];
};

export type CustomFontsResponse = {
  __typename?: 'CustomFontsResponse';
  fonts: Array<CustomFont>;
};

export type CustomerCreateResponse = {
  __typename?: 'CustomerCreateResponse';
  customer?: Maybe<CreateCustomerType>;
  userErrors?: Maybe<Array<UserErrorsType>>;
};

export type CustomerIoInput = {
  apiKey: Scalars['String'];
  id: Scalars['String'];
  integrationCampaigns: Array<CustomerIoIntegrationCampaignInput>;
  name: Scalars['String'];
  siteId: Scalars['String'];
  status: Scalars['Boolean'];
};

export type CustomerIoIntegrationCampaign = {
  __typename?: 'CustomerIoIntegrationCampaign';
  accountId: Scalars['Int'];
  apiKey: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
  status: Scalars['Boolean'];
};

export type CustomerIoIntegrationCampaignInput = {
  campaignId: Scalars['Int'];
  id: Scalars['Int'];
  integrationFields: Array<CustomerIoIntegrationFieldsInput>;
  integrationId: Scalars['String'];
  listId?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  status: Scalars['Boolean'];
};

export type CustomerIoIntegrationCampaignResponse = {
  __typename?: 'CustomerIoIntegrationCampaignResponse';
  campaignId: Scalars['Int'];
  id: Scalars['Int'];
  integration?: Maybe<CustomerIoIntegrationCampaign>;
  integrationFields: Array<CustomerIoIntegrationFields>;
  integrationId: Scalars['Int'];
  listId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  status: Scalars['Boolean'];
};

export type CustomerIoIntegrationFields = {
  __typename?: 'CustomerIoIntegrationFields';
  fieldType: Scalars['Int'];
  id: Scalars['Int'];
  integrationCampaignId?: Maybe<Scalars['Int']>;
  integratorFieldId?: Maybe<Scalars['String']>;
  integratorFieldName?: Maybe<Scalars['String']>;
  integratorTag?: Maybe<Scalars['String']>;
  isCustomerEmail: Scalars['Boolean'];
  popupsmartFieldName?: Maybe<Scalars['String']>;
};

export type CustomerIoIntegrationFieldsInput = {
  fieldType?: InputMaybe<Scalars['Int']>;
  id: Scalars['Int'];
  integrationCampaignId?: InputMaybe<Scalars['Int']>;
  integratorFieldId?: InputMaybe<Scalars['String']>;
  integratorFieldName?: InputMaybe<Scalars['String']>;
  integratorTag?: InputMaybe<Scalars['String']>;
  isCustomerEmail?: InputMaybe<Scalars['Boolean']>;
  nodeName?: InputMaybe<Scalars['String']>;
  popupsmartFieldName?: InputMaybe<Scalars['String']>;
};

export type CustomerIoResponse = {
  __typename?: 'CustomerIoResponse';
  accountId: Scalars['Int'];
  apiKey: Scalars['String'];
  id: Scalars['Int'];
  integrationCampaigns: Array<CustomerIoIntegrationCampaignResponse>;
  name: Scalars['String'];
  siteId: Scalars['String'];
  status: Scalars['Boolean'];
};

export type DeleteEmailAutomationLeadResponse = {
  __typename?: 'DeleteEmailAutomationLeadResponse';
  status: Scalars['Boolean'];
};

export type DeleteResponse = {
  __typename?: 'DeleteResponse';
  status: Scalars['Boolean'];
};

export type DeleteShopifyIntegrationResponse = {
  __typename?: 'DeleteShopifyIntegrationResponse';
  id: Scalars['Int'];
};

export type DeviceAnalytics = {
  __typename?: 'DeviceAnalytics';
  device: Scalars['String'];
  display: Scalars['Int'];
  interaction: Scalars['Int'];
};

export type Domain = {
  __typename?: 'Domain';
  cms?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  isShopify: Scalars['Boolean'];
  url: Scalars['String'];
  verified: Scalars['Boolean'];
};

export type DomainResponse = {
  __typename?: 'DomainResponse';
  cMS?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  isShopify: Scalars['Boolean'];
  url?: Maybe<Scalars['String']>;
  verified: Scalars['Boolean'];
};

export type DripInput = {
  account: Scalars['String'];
  apiKey: Scalars['String'];
  id: Scalars['String'];
  integrationCampaigns: Array<DripIntegrationCampaignInput>;
  name: Scalars['String'];
  status: Scalars['Boolean'];
};

export type DripIntegrationCampaign = {
  __typename?: 'DripIntegrationCampaign';
  accountId: Scalars['Int'];
  apiKey: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
  status: Scalars['Boolean'];
};

export type DripIntegrationCampaignInput = {
  campaignId: Scalars['Int'];
  id: Scalars['Int'];
  integrationFields: Array<DripIntegrationFieldsInput>;
  integrationId: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  status: Scalars['Boolean'];
};

export type DripIntegrationCampaignResponse = {
  __typename?: 'DripIntegrationCampaignResponse';
  campaignId: Scalars['Int'];
  id: Scalars['Int'];
  integration?: Maybe<DripIntegrationCampaign>;
  integrationFields: Array<DripIntegrationFields>;
  integrationId: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  status: Scalars['Boolean'];
};

export type DripIntegrationFields = {
  __typename?: 'DripIntegrationFields';
  fieldType: Scalars['Int'];
  id: Scalars['Int'];
  integrationCampaignId?: Maybe<Scalars['Int']>;
  integratorFieldId?: Maybe<Scalars['String']>;
  integratorFieldName?: Maybe<Scalars['String']>;
  integratorTag?: Maybe<Scalars['String']>;
  isCustomerEmail: Scalars['Boolean'];
  popupsmartFieldName?: Maybe<Scalars['String']>;
};

export type DripIntegrationFieldsInput = {
  fieldType?: InputMaybe<Scalars['Int']>;
  id: Scalars['Int'];
  integrationCampaignId?: InputMaybe<Scalars['Int']>;
  integratorFieldId?: InputMaybe<Scalars['String']>;
  integratorFieldName?: InputMaybe<Scalars['String']>;
  integratorTag?: InputMaybe<Scalars['String']>;
  isCustomerEmail?: InputMaybe<Scalars['Boolean']>;
  nodeName?: InputMaybe<Scalars['String']>;
  popupsmartFieldName?: InputMaybe<Scalars['String']>;
};

export type DripResponse = {
  __typename?: 'DripResponse';
  account: Scalars['String'];
  accountId: Scalars['Int'];
  apiKey: Scalars['String'];
  id: Scalars['Int'];
  integrationCampaigns: Array<DripIntegrationCampaignResponse>;
  name: Scalars['String'];
  status: Scalars['Boolean'];
};

export type EmailAutomation = {
  __typename?: 'EmailAutomation';
  Account?: Maybe<Account>;
  Campaign?: Maybe<CampaignType>;
  Flow?: Maybe<Flow>;
  accountId: Scalars['Int'];
  campaignId?: Maybe<Scalars['Int']>;
  flowId: Scalars['Int'];
  id: Scalars['Int'];
  name: Scalars['String'];
  status: Scalars['Boolean'];
  store?: Maybe<ShopifyStore>;
};

export type EmailAutomationAnalyticsByIdsResponse = {
  __typename?: 'EmailAutomationAnalyticsByIdsResponse';
  items: Array<CampaignAnalytics>;
};

export type EmailAutomationAnalyticsInput = {
  campaignId?: InputMaybe<Scalars['Int']>;
  endDate: Scalars['DateTime'];
  startDate: Scalars['DateTime'];
};

export type EmailAutomationAnalyticsResponse = {
  __typename?: 'EmailAutomationAnalyticsResponse';
  bounced: AnalyticsBounceData;
  clicked: AnalyticsData;
  delivered: AnalyticsData;
  opened: AnalyticsData;
  sent: AnalyticsData;
  unsubscribed: AnalyticsData;
};

export type EmailAutomationByCampaignResponse = {
  __typename?: 'EmailAutomationByCampaignResponse';
  emailAutomation?: Maybe<EmailAutomation>;
  message?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type EmailAutomationLead = {
  __typename?: 'EmailAutomationLead';
  email: Scalars['String'];
  id: Scalars['String'];
  isClicked: Scalars['Boolean'];
  isOpened: Scalars['Boolean'];
  sendingDate?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  subscribe: Scalars['Boolean'];
};

export type EmailAutomationLeadResponse = {
  __typename?: 'EmailAutomationLeadResponse';
  columns: Array<Scalars['String']>;
  items: Array<EmailAutomationLead>;
  total: Scalars['Int'];
};

export type EmailAutomationLeadsInput = {
  campaignId?: InputMaybe<Scalars['Int']>;
  endDate: Scalars['DateTime'];
  searchTerm?: InputMaybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
};

export type EmailAutomationLineChartAnalytics = {
  __typename?: 'EmailAutomationLineChartAnalytics';
  clicked: Array<EmailAutomationLineData>;
  delivered: Array<EmailAutomationLineData>;
  opened: Array<EmailAutomationLineData>;
  sent: Array<EmailAutomationLineData>;
};

export enum EmailAutomationLineChartRange {
  DAY = 'DAY',
  MONTH = 'MONTH',
  WEEK = 'WEEK'
}

export type EmailAutomationLineData = {
  __typename?: 'EmailAutomationLineData';
  date: Scalars['String'];
  value: Scalars['Float'];
};

export type EmailAutomationResponse = {
  __typename?: 'EmailAutomationResponse';
  items: Array<EmailAutomation>;
  total: Scalars['Float'];
};

export type EmailOctopusCampaign = {
  __typename?: 'EmailOctopusCampaign';
  accountId: Scalars['Int'];
  apiKey: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
  status: Scalars['Boolean'];
};

export type EmailOctopusInput = {
  apiKey: Scalars['String'];
  id: Scalars['String'];
  integrationCampaigns: Array<EmailOctopusIntegrationCampaignInput>;
  name: Scalars['String'];
  status: Scalars['Boolean'];
};

export type EmailOctopusIntegrationCampaignInput = {
  campaignId: Scalars['Int'];
  id: Scalars['Int'];
  integrationFields: Array<EmailOctopusIntegrationFieldsInput>;
  integrationId: Scalars['String'];
  listId: Scalars['String'];
  name: Scalars['String'];
  status: Scalars['Boolean'];
};

export type EmailOctopusIntegrationCampaignResponse = {
  __typename?: 'EmailOctopusIntegrationCampaignResponse';
  campaignId: Scalars['Int'];
  id: Scalars['Int'];
  integration?: Maybe<EmailOctopusCampaign>;
  integrationFields: Array<EmailOctopusIntegrationFields>;
  integrationId: Scalars['Int'];
  listId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  status: Scalars['Boolean'];
};

export type EmailOctopusIntegrationFields = {
  __typename?: 'EmailOctopusIntegrationFields';
  fieldType: Scalars['Int'];
  id: Scalars['Int'];
  integrationCampaignId?: Maybe<Scalars['Int']>;
  integratorFieldId?: Maybe<Scalars['String']>;
  integratorFieldName?: Maybe<Scalars['String']>;
  integratorTag?: Maybe<Scalars['String']>;
  isCustomerEmail: Scalars['Boolean'];
  popupsmartFieldName?: Maybe<Scalars['String']>;
};

export type EmailOctopusIntegrationFieldsInput = {
  fieldType?: InputMaybe<Scalars['Int']>;
  id: Scalars['Int'];
  integrationCampaignId?: InputMaybe<Scalars['Int']>;
  integratorFieldId?: InputMaybe<Scalars['String']>;
  integratorFieldName?: InputMaybe<Scalars['String']>;
  integratorTag?: InputMaybe<Scalars['String']>;
  isCustomerEmail?: InputMaybe<Scalars['Boolean']>;
  nodeName?: InputMaybe<Scalars['String']>;
  popupsmartFieldName?: InputMaybe<Scalars['String']>;
};

export type EmailOctopusListResponseItem = {
  __typename?: 'EmailOctopusListResponseItem';
  id?: Maybe<Scalars['String']>;
  list_type?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type EmailOctopusResponse = {
  __typename?: 'EmailOctopusResponse';
  accountId: Scalars['Int'];
  apiKey: Scalars['String'];
  id: Scalars['Int'];
  integrationCampaigns: Array<EmailOctopusIntegrationCampaignResponse>;
  name: Scalars['String'];
  status: Scalars['Boolean'];
};

export type EmailTemplate = {
  __typename?: 'EmailTemplate';
  document?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  imageUrl?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  url?: Maybe<Scalars['String']>;
};

export type FieldsResponse = {
  __typename?: 'FieldsResponse';
  integratorFieldId: Scalars['String'];
  integratorFieldName: Scalars['String'];
  integratorTag?: Maybe<Scalars['String']>;
};

export type Flow = {
  __typename?: 'Flow';
  EmailTemplate?: Maybe<EmailTemplate>;
  Trigger?: Maybe<Trigger>;
  accountId: Scalars['Int'];
  color?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  emailTemplateId?: Maybe<Scalars['Int']>;
  frequencyStatus: Scalars['Boolean'];
  icon?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  isPlainText?: Maybe<Scalars['Boolean']>;
  isTemplate: Scalars['Boolean'];
  name: Scalars['String'];
  plainTextBody?: Maybe<Scalars['String']>;
  steps?: Maybe<Scalars['String']>;
  triggerId?: Maybe<Scalars['Int']>;
  type: FlowType;
};

export enum FlowType {
  FORM_SUBMISSION = 'FORM_SUBMISSION',
  SHOPIFY = 'SHOPIFY'
}

export type Folder = {
  __typename?: 'Folder';
  campaigns: Array<Campaign>;
  color?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type FormInput = {
  __typename?: 'FormInput';
  label: Scalars['String'];
  nodeName: Scalars['String'];
  value: Scalars['String'];
};

export type GenerateAiImageResponse = {
  __typename?: 'GenerateAIImageResponse';
  data?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type GenerateAiTextSuggestionsInput = {
  currentText: Scalars['String'];
  nodeType: Scalars['String'];
};

export type GenerateAiTextSuggestionsResponse = {
  __typename?: 'GenerateAITextSuggestionsResponse';
  error?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
  suggestions?: Maybe<Array<TextSuggestion>>;
};

export type GenerateAiPopupInput = {
  domains?: InputMaybe<Array<Scalars['Int']>>;
  folderId?: InputMaybe<Scalars['Int']>;
  prompt: Scalars['String'];
};

export type GenerateAiPopupResponse = {
  __typename?: 'GenerateAiPopupResponse';
  error?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  prompt?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type GetAccountRevenueAnalyticDataInput = {
  endDate: Scalars['DateTime'];
  startDate: Scalars['DateTime'];
};

export type GetCampaignCountsResponse = {
  __typename?: 'GetCampaignCountsResponse';
  active: Scalars['Int'];
  passive: Scalars['Int'];
  total: Scalars['Int'];
};

export type GetEmailLeadFromRef = {
  __typename?: 'GetEmailLeadFromRef';
  email: Scalars['String'];
  emailAutomationId: Scalars['Float'];
  subscribe: Scalars['Boolean'];
  website: Scalars['String'];
};

export type GetEmailLeadFromRefResponse = {
  __typename?: 'GetEmailLeadFromRefResponse';
  data: GetEmailLeadFromRef;
  message: Scalars['String'];
  status: Scalars['String'];
};

export type GetOverviewAnalyticsDataInput = {
  endDate: Scalars['DateTime'];
  startDate: Scalars['DateTime'];
};

export type GetUrlByDomainResponse = {
  __typename?: 'GetUrlByDomainResponse';
  url?: Maybe<Scalars['String']>;
};

export type GetUsersResponse = {
  __typename?: 'GetUsersResponse';
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Float']>;
  image?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  organizationName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  planId?: Maybe<Scalars['Float']>;
  status: Scalars['Boolean'];
  stripeId?: Maybe<Scalars['String']>;
  surname?: Maybe<Scalars['String']>;
};

export type GoogleFont = {
  __typename?: 'GoogleFont';
  category: Scalars['String'];
  family: Scalars['String'];
  lastModified: Scalars['String'];
  subsets: Array<Scalars['String']>;
  variants: Array<Scalars['String']>;
  version: Scalars['String'];
};

export type GoogleFontsResponse = {
  __typename?: 'GoogleFontsResponse';
  fonts?: Maybe<Array<GoogleFont>>;
};

export type GoogleLoginOutput = {
  __typename?: 'GoogleLoginOutput';
  isRegisteredUser: Scalars['Boolean'];
  refreshToken: Scalars['String'];
  token: Scalars['String'];
  tokenExpireDate: Scalars['String'];
};

export type HubSpotInput = {
  apiKey: Scalars['String'];
  id: Scalars['String'];
  integrationCampaigns: Array<HubSpotIntegrationCampaignInput>;
  name: Scalars['String'];
  status: Scalars['Boolean'];
};

export type HubSpotIntegrationCampaign = {
  __typename?: 'HubSpotIntegrationCampaign';
  accountId: Scalars['Int'];
  apiKey?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  name: Scalars['String'];
  status: Scalars['Boolean'];
};

export type HubSpotIntegrationCampaignInput = {
  campaignId: Scalars['Int'];
  id: Scalars['Int'];
  integrationFields: Array<HubSpotIntegrationFieldsInput>;
  integrationId: Scalars['String'];
  listId: Scalars['String'];
  name: Scalars['String'];
  status: Scalars['Boolean'];
};

export type HubSpotIntegrationCampaignResponse = {
  __typename?: 'HubSpotIntegrationCampaignResponse';
  campaignId: Scalars['Int'];
  id: Scalars['Int'];
  integration?: Maybe<HubSpotIntegrationCampaign>;
  integrationFields: Array<HubSpotIntegrationFields>;
  integrationId: Scalars['Int'];
  listId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  status: Scalars['Boolean'];
};

export type HubSpotIntegrationFields = {
  __typename?: 'HubSpotIntegrationFields';
  fieldType: Scalars['Int'];
  id: Scalars['Int'];
  integrationCampaignId?: Maybe<Scalars['Int']>;
  integratorFieldId?: Maybe<Scalars['String']>;
  integratorFieldName?: Maybe<Scalars['String']>;
  integratorTag?: Maybe<Scalars['String']>;
  isCustomerEmail: Scalars['Boolean'];
  popupsmartFieldName?: Maybe<Scalars['String']>;
};

export type HubSpotIntegrationFieldsInput = {
  fieldType?: InputMaybe<Scalars['Int']>;
  id: Scalars['Int'];
  integrationCampaignId?: InputMaybe<Scalars['Int']>;
  integratorFieldId?: InputMaybe<Scalars['String']>;
  integratorFieldName?: InputMaybe<Scalars['String']>;
  integratorTag?: InputMaybe<Scalars['String']>;
  isCustomerEmail?: InputMaybe<Scalars['Boolean']>;
  nodeName?: InputMaybe<Scalars['String']>;
  popupsmartFieldName?: InputMaybe<Scalars['String']>;
};

export type HubSpotListResponseItem = {
  __typename?: 'HubSpotListResponseItem';
  id?: Maybe<Scalars['String']>;
  listId?: Maybe<Scalars['String']>;
  listType?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type HubSpotResponse = {
  __typename?: 'HubSpotResponse';
  accountId: Scalars['Int'];
  apiKey?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  integrationCampaigns: Array<HubSpotIntegrationCampaignResponse>;
  name: Scalars['String'];
  status: Scalars['Boolean'];
  token?: Maybe<Scalars['String']>;
};

export type HubSpotSaveConnectionResponse = {
  __typename?: 'HubSpotSaveConnectionResponse';
  accountId: Scalars['Float'];
  apiKey?: Maybe<Scalars['String']>;
  id: Scalars['Float'];
  integrationCampaigns: Array<HubSpotIntegrationCampaignResponse>;
  name: Scalars['String'];
  refreshToken: Scalars['String'];
  status: Scalars['Boolean'];
  token: Scalars['String'];
  tokenExpireDate: Scalars['String'];
};

export type ImageResponse = {
  __typename?: 'ImageResponse';
  url: Scalars['String'];
};

export type IntegrationFieldShopify = {
  __typename?: 'IntegrationFieldShopify';
  fieldType: Scalars['Float'];
  id: Scalars['Int'];
  integratorFieldName?: Maybe<Scalars['String']>;
  popupsmartFieldName?: Maybe<Scalars['String']>;
};

export type IntegrationShopifyCampaign = {
  __typename?: 'IntegrationShopifyCampaign';
  acceptEmailMarketing: Scalars['Boolean'];
  acceptSmsMarketing: Scalars['Boolean'];
  campaignId: Scalars['Int'];
  createdAt?: Maybe<Scalars['DateTime']>;
  customTags?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  integrationFieldsShopify: Array<IntegrationFieldShopify>;
  integrationId: Scalars['Int'];
  isConnectedTestCompleted?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Scalars['Boolean']>;
  storeDomain?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type IntegrationShopifyFieldInput = {
  fieldType: Scalars['Float'];
  id?: InputMaybe<Scalars['Int']>;
  integratorFieldName: Scalars['String'];
  popupsmartFieldName: Scalars['String'];
};

export type InteractionAnalytics = {
  __typename?: 'InteractionAnalytics';
  count: Scalars['Int'];
  text: Scalars['String'];
  type: Scalars['String'];
};

export type Invitation = {
  __typename?: 'Invitation';
  accountId?: Maybe<Scalars['Int']>;
  canCreateCampaign: Scalars['Boolean'];
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  role: InvitationRole;
  status?: Maybe<Scalars['String']>;
  userId: Scalars['Int'];
};

export type InvitationRole = {
  __typename?: 'InvitationRole';
  campaigns: Array<Scalars['Int']>;
  roleTypeId: Scalars['Int'];
};

export type InviteUserResponse = {
  __typename?: 'InviteUserResponse';
  canCreateCampaign: Scalars['Boolean'];
  email?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  role?: Maybe<InvitationRole>;
  status?: Maybe<Scalars['String']>;
};

export type InvitedUserRegisterOutput = {
  __typename?: 'InvitedUserRegisterOutput';
  message: Scalars['String'];
  refreshToken: Scalars['String'];
  token: Scalars['String'];
  tokenExpireDate: Scalars['String'];
  userId: Scalars['String'];
};

export type KlaviyoInput = {
  apiKey: Scalars['String'];
  id: Scalars['String'];
  integrationCampaigns: Array<KlaviyoIntegrationCampaignInput>;
  name: Scalars['String'];
  status: Scalars['Boolean'];
};

export type KlaviyoIntegrationCampaign = {
  __typename?: 'KlaviyoIntegrationCampaign';
  accountId: Scalars['Int'];
  apiKey: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
  status: Scalars['Boolean'];
};

export type KlaviyoIntegrationCampaignInput = {
  campaignId: Scalars['Int'];
  id: Scalars['Int'];
  integrationFields: Array<KlaviyoIntegrationFieldsInput>;
  integrationId: Scalars['String'];
  listId: Scalars['String'];
  name: Scalars['String'];
  status: Scalars['Boolean'];
};

export type KlaviyoIntegrationCampaignResponse = {
  __typename?: 'KlaviyoIntegrationCampaignResponse';
  campaignId: Scalars['Int'];
  id: Scalars['Int'];
  integration?: Maybe<KlaviyoIntegrationCampaign>;
  integrationFields: Array<KlaviyoIntegrationFields>;
  integrationId: Scalars['Int'];
  listId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  status: Scalars['Boolean'];
};

export type KlaviyoIntegrationFields = {
  __typename?: 'KlaviyoIntegrationFields';
  fieldType: Scalars['Int'];
  id: Scalars['Int'];
  integrationCampaignId?: Maybe<Scalars['Int']>;
  integratorFieldId?: Maybe<Scalars['String']>;
  integratorFieldName?: Maybe<Scalars['String']>;
  integratorTag?: Maybe<Scalars['String']>;
  isCustomerEmail: Scalars['Boolean'];
  popupsmartFieldName?: Maybe<Scalars['String']>;
};

export type KlaviyoIntegrationFieldsInput = {
  fieldType?: InputMaybe<Scalars['Int']>;
  id: Scalars['Int'];
  integrationCampaignId?: InputMaybe<Scalars['Int']>;
  integratorFieldId?: InputMaybe<Scalars['String']>;
  integratorFieldName?: InputMaybe<Scalars['String']>;
  integratorTag?: InputMaybe<Scalars['String']>;
  isCustomerEmail?: InputMaybe<Scalars['Boolean']>;
  nodeName?: InputMaybe<Scalars['String']>;
  popupsmartFieldName?: InputMaybe<Scalars['String']>;
};

export type KlaviyoListResponseItem = {
  __typename?: 'KlaviyoListResponseItem';
  id?: Maybe<Scalars['String']>;
  list_type?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type KlaviyoResponse = {
  __typename?: 'KlaviyoResponse';
  accountId: Scalars['Int'];
  apiKey: Scalars['String'];
  id: Scalars['Int'];
  integrationCampaigns: Array<KlaviyoIntegrationCampaignResponse>;
  name: Scalars['String'];
  status: Scalars['Boolean'];
};

export type Lead = {
  __typename?: 'Lead';
  browser: Scalars['String'];
  campaignId: Scalars['Int'];
  campaignName: Scalars['String'];
  city: Scalars['String'];
  country: Scalars['String'];
  countryCode: Scalars['String'];
  date: Scalars['String'];
  device: Scalars['String'];
  formData: Scalars['String'];
  host: Scalars['String'];
  id: Scalars['String'];
  ip: Scalars['String'];
  language: Scalars['String'];
  latitude: Scalars['String'];
  longitude: Scalars['String'];
  meta: Scalars['String'];
  os: Scalars['String'];
  page: Scalars['String'];
  postalCode: Scalars['String'];
  referrer: Scalars['String'];
  region: Scalars['String'];
  regionCode: Scalars['String'];
  resolution: Scalars['String'];
  timezone: Scalars['String'];
  unsubscribed?: Maybe<Scalars['Boolean']>;
  url: Scalars['String'];
  userAgent: Scalars['String'];
};

export type LeadResponse = {
  __typename?: 'LeadResponse';
  columns: Array<Scalars['String']>;
  items: Array<Lead>;
  total: Scalars['Int'];
};

export type LeadsInput = {
  campaignId: Scalars['Int'];
  endDate: Scalars['DateTime'];
  searchTerm?: InputMaybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
};

export type LimitExtend = {
  __typename?: 'LimitExtend';
  autoExtendEmailStatus: Scalars['Boolean'];
  autoExtendPageViewStatus: Scalars['Boolean'];
  autoExtendStatus: Scalars['Boolean'];
  emailTotal: Scalars['Float'];
  id: Scalars['Int'];
  pageViewTotal: Scalars['Float'];
  usedEmail: Scalars['Float'];
  usedPageView: Scalars['Float'];
};

export type LineChartAnalytics = {
  __typename?: 'LineChartAnalytics';
  conversionRate: Array<LineData>;
  display: Array<LineData>;
  interaction: Array<LineData>;
  lead: Array<LineData>;
  pageView: Array<LineData>;
};

export enum LineChartRange {
  DAY = 'DAY',
  HOUR = 'HOUR',
  MONTH = 'MONTH',
  WEEK = 'WEEK'
}

export type LineData = {
  __typename?: 'LineData';
  date: Scalars['DateTime'];
  value: Scalars['Float'];
};

export type LoginWithShopifyResponse = {
  __typename?: 'LoginWithShopifyResponse';
  accessToken: Scalars['String'];
  refreshToken: Scalars['String'];
};

export type MailChimpInput = {
  apiKey: Scalars['String'];
  id: Scalars['String'];
  integrationCampaigns: Array<MailChimpIntegrationCampaignInput>;
  name: Scalars['String'];
  status: Scalars['Boolean'];
};

export type MailChimpIntegrationCampaign = {
  __typename?: 'MailChimpIntegrationCampaign';
  accountId: Scalars['Int'];
  apiKey: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
  status: Scalars['Boolean'];
};

export type MailChimpIntegrationCampaignInput = {
  campaignId: Scalars['Int'];
  id: Scalars['Int'];
  integrationFields: Array<MailChimpIntegrationFieldsInput>;
  integrationId: Scalars['String'];
  listId: Scalars['String'];
  name: Scalars['String'];
  status: Scalars['Boolean'];
};

export type MailChimpIntegrationCampaignResponse = {
  __typename?: 'MailChimpIntegrationCampaignResponse';
  campaignId: Scalars['Int'];
  id: Scalars['Int'];
  integration?: Maybe<MailChimpIntegrationCampaign>;
  integrationFields: Array<MailChimpIntegrationFields>;
  integrationId: Scalars['Int'];
  listId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  status: Scalars['Boolean'];
};

export type MailChimpIntegrationFields = {
  __typename?: 'MailChimpIntegrationFields';
  fieldType: Scalars['Int'];
  id: Scalars['Int'];
  integrationCampaignId?: Maybe<Scalars['Int']>;
  integratorFieldId?: Maybe<Scalars['String']>;
  integratorFieldName?: Maybe<Scalars['String']>;
  integratorTag?: Maybe<Scalars['String']>;
  isCustomerEmail: Scalars['Boolean'];
  popupsmartFieldName?: Maybe<Scalars['String']>;
};

export type MailChimpIntegrationFieldsInput = {
  fieldType?: InputMaybe<Scalars['Int']>;
  id: Scalars['Int'];
  integrationCampaignId?: InputMaybe<Scalars['Int']>;
  integratorFieldId?: InputMaybe<Scalars['String']>;
  integratorFieldName?: InputMaybe<Scalars['String']>;
  integratorTag?: InputMaybe<Scalars['String']>;
  isCustomerEmail?: InputMaybe<Scalars['Boolean']>;
  nodeName?: InputMaybe<Scalars['String']>;
  popupsmartFieldName?: InputMaybe<Scalars['String']>;
};

export type MailChimpListResponseItem = {
  __typename?: 'MailChimpListResponseItem';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type MailChimpResponse = {
  __typename?: 'MailChimpResponse';
  accountId: Scalars['Int'];
  apiKey: Scalars['String'];
  id: Scalars['Int'];
  integrationCampaigns: Array<MailChimpIntegrationCampaignResponse>;
  name: Scalars['String'];
  status: Scalars['Boolean'];
};

export type MailerLiteInput = {
  apiKey: Scalars['String'];
  id: Scalars['String'];
  integrationCampaigns: Array<MailerLiteIntegrationCampaignInput>;
  name: Scalars['String'];
  status: Scalars['Boolean'];
};

export type MailerLiteIntegrationCampaign = {
  __typename?: 'MailerLiteIntegrationCampaign';
  accountId: Scalars['Int'];
  apiKey: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
  status: Scalars['Boolean'];
};

export type MailerLiteIntegrationCampaignInput = {
  campaignId: Scalars['Int'];
  id: Scalars['Int'];
  integrationFields: Array<MailerLiteIntegrationFieldsInput>;
  integrationId: Scalars['String'];
  listId: Scalars['String'];
  name: Scalars['String'];
  status: Scalars['Boolean'];
};

export type MailerLiteIntegrationCampaignResponse = {
  __typename?: 'MailerLiteIntegrationCampaignResponse';
  campaignId: Scalars['Int'];
  id: Scalars['Int'];
  integration?: Maybe<MailerLiteIntegrationCampaign>;
  integrationFields: Array<MailerLiteIntegrationFields>;
  integrationId: Scalars['Int'];
  listId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  status: Scalars['Boolean'];
};

export type MailerLiteIntegrationFields = {
  __typename?: 'MailerLiteIntegrationFields';
  fieldType: Scalars['Int'];
  id: Scalars['Int'];
  integrationCampaignId?: Maybe<Scalars['Int']>;
  integratorFieldId?: Maybe<Scalars['String']>;
  integratorFieldName?: Maybe<Scalars['String']>;
  integratorTag?: Maybe<Scalars['String']>;
  isCustomerEmail: Scalars['Boolean'];
  popupsmartFieldName?: Maybe<Scalars['String']>;
};

export type MailerLiteIntegrationFieldsInput = {
  fieldType?: InputMaybe<Scalars['Int']>;
  id: Scalars['Int'];
  integrationCampaignId?: InputMaybe<Scalars['Int']>;
  integratorFieldId?: InputMaybe<Scalars['String']>;
  integratorFieldName?: InputMaybe<Scalars['String']>;
  integratorTag?: InputMaybe<Scalars['String']>;
  isCustomerEmail?: InputMaybe<Scalars['Boolean']>;
  nodeName?: InputMaybe<Scalars['String']>;
  popupsmartFieldName?: InputMaybe<Scalars['String']>;
};

export type MailerLiteListResponseItem = {
  __typename?: 'MailerLiteListResponseItem';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type MailerLiteResponse = {
  __typename?: 'MailerLiteResponse';
  accountId: Scalars['Int'];
  apiKey: Scalars['String'];
  id: Scalars['Int'];
  integrationCampaigns: Array<MailerLiteIntegrationCampaignResponse>;
  name: Scalars['String'];
  status: Scalars['Boolean'];
};

export type Mutation = {
  __typename?: 'Mutation';
  activeCampaignChangeStatus: ChangeStatusResponse;
  activeCampaignTestConnection: TestConnectionResponse;
  addDomainRelation: Domain;
  addLeadEmailAddress: PostLeadEmailAddressOutput;
  addShopifyIntegrationInCampaign: IntegrationShopifyCampaign;
  adminLogin: TokenResponse;
  brevoChangeStatus: ChangeStatusResponse;
  brevoTestConnection: TestConnectionResponse;
  campaignChangeStatus: Campaign;
  campaignCreate: CreateCampaignResponse;
  campaignCreateWithTemplateId: CreateCampaignResponse;
  campaignPublish: PublishResponse;
  changeAccountPlan: ChangeAccountPlanResponse;
  changeAccountUserRoles: Scalars['Boolean'];
  changeAccountUserStatus: Scalars['Boolean'];
  changeCampaignName: CreateCampaignResponse;
  changeCampaignVariantName: CreateCampaignVariantResponse;
  changeEmail: TokenResponse;
  changePassword: Scalars['Boolean'];
  changeSegmentName: SegmentResponse;
  changeShopifyIntegrationInCampaignIsConnectedTestCompleted: Scalars['Boolean'];
  changeShopifyIntegrationInCampaignStatus: ChangeStatusShopifyIntegrationResponse;
  changeUserEmail: ChangeUserEmailResponse;
  changeWeeklyStatsSubscriptionStatus: Scalars['Boolean'];
  connectCampaign: EmailAutomation;
  createActiveCampaignIntegration: Scalars['Boolean'];
  createActiveCampaignIntegrationCampaign: Scalars['Boolean'];
  createBrevoIntegration: Scalars['Boolean'];
  createBrevoIntegrationCampaign: Scalars['Boolean'];
  createCustomer: CustomerCreateResponse;
  createCustomerIoIntegration: Scalars['Boolean'];
  createCustomerIoIntegrationCampaign: Scalars['Boolean'];
  createDripIntegration: Scalars['Boolean'];
  createDripIntegrationCampaign: Scalars['Boolean'];
  createEmailAutomation: EmailAutomation;
  createEmailOctopusIntegration: Scalars['Boolean'];
  createEmailOctopusIntegrationCampaign: Scalars['Boolean'];
  createFirstEmailAutomation: EmailAutomation;
  createFolder: Folder;
  createHubSpotIntegration: Scalars['Boolean'];
  createHubSpotIntegrationCampaign: Scalars['Boolean'];
  createKlaviyoIntegration: Scalars['Boolean'];
  createKlaviyoIntegrationCampaign: Scalars['Boolean'];
  createMailChimpIntegration: Scalars['Boolean'];
  createMailChimpIntegrationCampaign: Scalars['Boolean'];
  createMailerLiteIntegration: Scalars['Boolean'];
  createMailerLiteIntegrationCampaign: Scalars['Boolean'];
  createOneTimePurchase: Scalars['String'];
  createSegment: SegmentResponse;
  createSendGridIntegration: Scalars['Boolean'];
  createSendGridIntegrationCampaign: Scalars['Boolean'];
  createShopifyIntegration: IntegrationShopifyCampaign;
  createShopifySubscription: Scalars['String'];
  createWebhookIntegration: Scalars['Boolean'];
  createWebsite: WebsiteItem;
  createWorkspace: CreateWorkspaceResponse;
  customerGoogleLogin: GoogleLoginOutput;
  customerIoChangeStatus: ChangeStatusResponse;
  customerIoTestConnection: TestConnectionResponse;
  deleteAccount: Scalars['Boolean'];
  deleteActiveCampaignIntegration: Scalars['Boolean'];
  deleteActiveCampaignIntegrationCampaign: Scalars['Boolean'];
  deleteAllIntegrationsInCampaign: Scalars['Boolean'];
  deleteBrevoIntegration: Scalars['Boolean'];
  deleteBrevoIntegrationCampaign: Scalars['Boolean'];
  deleteCampaign: Scalars['Boolean'];
  deleteCampaignVariant: Scalars['Boolean'];
  deleteCustomFont: Scalars['Boolean'];
  deleteCustomerIoIntegration: Scalars['Boolean'];
  deleteCustomerIoIntegrationCampaign: Scalars['Boolean'];
  deleteDomainRelation: Scalars['Boolean'];
  deleteDripIntegration: Scalars['Boolean'];
  deleteDripIntegrationCampaign: Scalars['Boolean'];
  deleteEmailAutomation: EmailAutomation;
  deleteEmailAutomationLeads: DeleteEmailAutomationLeadResponse;
  deleteEmailOctopusIntegration: Scalars['Boolean'];
  deleteEmailOctopusIntegrationCampaign: Scalars['Boolean'];
  deleteFolder: Scalars['Boolean'];
  deleteHubSpotIntegration: Scalars['Boolean'];
  deleteHubSpotIntegrationCampaign: Scalars['Boolean'];
  deleteInvitation: Scalars['Boolean'];
  deleteKlaviyoIntegration: Scalars['Boolean'];
  deleteKlaviyoIntegrationCampaign: Scalars['Boolean'];
  deleteLead: DeleteResponse;
  deleteLeadEmailAddress: Scalars['Boolean'];
  deleteMailChimpIntegration: Scalars['Boolean'];
  deleteMailChimpIntegrationCampaign: Scalars['Boolean'];
  deleteMailerLiteIntegration: Scalars['Boolean'];
  deleteMailerLiteIntegrationCampaign: Scalars['Boolean'];
  deleteSegment: Scalars['Boolean'];
  deleteSendGridIntegration: Scalars['Boolean'];
  deleteSendGridIntegrationCampaign: Scalars['Boolean'];
  deleteShopifyIntegration: Scalars['Boolean'];
  deleteShopifyIntegrationInCampaign: DeleteShopifyIntegrationResponse;
  deleteTeammate: Scalars['Boolean'];
  deleteWebhookIntegration: Scalars['Boolean'];
  deleteWebsite: Scalars['Boolean'];
  disableAllIntegrationsInCampaign: Scalars['Boolean'];
  dripChangeStatus: ChangeStatusResponse;
  dripTestConnection: TestConnectionResponse;
  duplicateCampaign: CreateCampaignResponse;
  duplicateNewVariant: SaveAsNewCampaigntResponse;
  emailAutomationLeadsExport: Scalars['Boolean'];
  emailOctopusChangeStatus: ChangeStatusResponse;
  emailOctopusTestConnection: TestConnectionResponse;
  exportLeads: Scalars['Boolean'];
  forgotPassword: Scalars['Boolean'];
  generateAIImage: GenerateAiImageResponse;
  generateAITextSuggestions: GenerateAiTextSuggestionsResponse;
  generateAiPopup: GenerateAiPopupResponse;
  getEmailLeadFromRef: GetEmailLeadFromRefResponse;
  getUsers: Array<GetUsersResponse>;
  hubSpotChangeStatus: ChangeStatusResponse;
  hubSpotTestConnection: TestConnectionResponse;
  invitationAddRole: Scalars['Boolean'];
  inviteUser: InviteUserResponse;
  invitedUserRegister: InvitedUserRegisterOutput;
  klaviyoChangeStatus: ChangeStatusResponse;
  klaviyoTestConnection: TestConnectionResponse;
  login: TokenResponse;
  loginWithShopify: LoginWithShopifyResponse;
  mailChimpChangeStatus: ChangeStatusResponse;
  mailChimpTestConnection: TestConnectionResponse;
  mailerLiteChangeStatus: ChangeStatusResponse;
  mailerliteTestConnection: TestConnectionResponse;
  publishEmailAutomation: EmailAutomation;
  refreshToken: TokenResponse;
  register: RegisterOutput;
  resetPassword: Scalars['Boolean'];
  resubscribeToWebsite: ResubscribeToWebsiteResponse;
  saveAsNewCampaign: SaveAsNewCampaigntResponse;
  saveHubSpotConnection: HubSpotSaveConnectionResponse;
  sendGridChangeStatus: ChangeStatusResponse;
  sendGridTestConnection: TestConnectionResponse;
  sendTestEmail: SendTestEmailResponse;
  sendinBlueChangeStatus: ChangeStatusResponse;
  shopifyAppExtensionStatus: ShopifyAppExtensionStatusResponse;
  shopifyLinkAccount: ShopifyLinkAccountResponse;
  shopifyLoginOrRegister: ShopifyLoginOrRegisterResponse;
  unsubscribeToWebsite: UnsubscribeToWebsiteResponse;
  updateActiveCampaignIntegrationCampaign: Scalars['Boolean'];
  updateAutoLimitExtend: UpdateAutoLimitExtendResponse;
  updateBrevoIntegrationCampaign: Scalars['Boolean'];
  updateCampaign: Scalars['Boolean'];
  updateCampaignFolder: Scalars['Boolean'];
  updateCampaignTemplateWithAi: GenerateAiPopupResponse;
  updateCustomerIoIntegrationCampaign: Scalars['Boolean'];
  updateDomain: EmailAutomation;
  updateDripIntegrationCampaign: Scalars['Boolean'];
  updateEmailAutomationName: EmailAutomation;
  updateEmailOctopusIntegrationCampaign: Scalars['Boolean'];
  updateFlowEmailTemplate: Flow;
  updateFlowTrigger: Flow;
  updateFolder: Folder;
  updateHubSpotIntegrationCampaign: Scalars['Boolean'];
  updateKlaviyoIntegrationCampaign: Scalars['Boolean'];
  updateMailChimpIntegrationCampaign: Scalars['Boolean'];
  updateMailerLiteIntegrationCampaign: Scalars['Boolean'];
  updateSegment: SegmentResponse;
  updateSendGridIntegrationCampaign: Scalars['Boolean'];
  updateShopifyIntegrationInCampaign: IntegrationShopifyCampaign;
  updateUserProfile: Scalars['Boolean'];
  updateVariantPercentages: Scalars['Boolean'];
  updateWebhookIntegration: Scalars['Boolean'];
  updateWebsite: WebsiteItem;
  uploadCustomFont: CustomFont;
  uploadImage: ImageResponse;
  verifyLeadEmailAddress: Scalars['Boolean'];
  weeklyStatsDetailsFromRef: WeeklyStatsDetailsFromRef;
};


export type MutationActiveCampaignChangeStatusArgs = {
  id: Scalars['Int'];
  status: Scalars['Boolean'];
};


export type MutationActiveCampaignTestConnectionArgs = {
  input: TestConnectionInput;
};


export type MutationAddDomainRelationArgs = {
  campaignId: Scalars['Int'];
  domainId: Scalars['Int'];
};


export type MutationAddLeadEmailAddressArgs = {
  campaignId: Scalars['Int'];
  email: Scalars['String'];
};


export type MutationAddShopifyIntegrationInCampaignArgs = {
  acceptEmailMarketing: Scalars['Boolean'];
  acceptSmsMarketing: Scalars['Boolean'];
  campaignId: Scalars['Int'];
  customTags?: InputMaybe<Scalars['String']>;
  integrationFieldsShopify: Array<IntegrationShopifyFieldInput>;
  integrationId: Scalars['Int'];
  storeDomain: Scalars['String'];
};


export type MutationAdminLoginArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type MutationBrevoChangeStatusArgs = {
  id: Scalars['Int'];
  status: Scalars['Boolean'];
};


export type MutationBrevoTestConnectionArgs = {
  input: TestConnectionInput;
};


export type MutationCampaignChangeStatusArgs = {
  campaignId: Scalars['Int'];
  status: Scalars['Boolean'];
};


export type MutationCampaignCreateArgs = {
  input: CampaignCreateRequest;
};


export type MutationCampaignCreateWithTemplateIdArgs = {
  input: CampaignCreateWithTemplateIdRequest;
};


export type MutationCampaignPublishArgs = {
  campaignId: Scalars['Int'];
};


export type MutationChangeAccountPlanArgs = {
  id: Scalars['Int'];
  planId: Scalars['Int'];
};


export type MutationChangeAccountUserRolesArgs = {
  accountUserId: Scalars['Float'];
  campaignIds: Array<Scalars['Float']>;
  canCreateCampaign: Scalars['Boolean'];
  roleId: Scalars['Float'];
};


export type MutationChangeAccountUserStatusArgs = {
  accountUserId: Scalars['Float'];
};


export type MutationChangeCampaignNameArgs = {
  campaignId: Scalars['Int'];
  name: Scalars['String'];
};


export type MutationChangeCampaignVariantNameArgs = {
  campaignId: Scalars['Int'];
  name: Scalars['String'];
};


export type MutationChangeEmailArgs = {
  captcha: Scalars['String'];
  email: Scalars['String'];
};


export type MutationChangePasswordArgs = {
  newPassword: Scalars['String'];
  oldPassword: Scalars['String'];
};


export type MutationChangeSegmentNameArgs = {
  description: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
};


export type MutationChangeShopifyIntegrationInCampaignIsConnectedTestCompletedArgs = {
  isConnectedTestCompleted: Scalars['Boolean'];
  shopifyIntegrationCampaignId: Scalars['Int'];
};


export type MutationChangeShopifyIntegrationInCampaignStatusArgs = {
  shopifyIntegrationCampaignId: Scalars['Int'];
  status: Scalars['Boolean'];
};


export type MutationChangeUserEmailArgs = {
  email: Scalars['String'];
  id: Scalars['Int'];
};


export type MutationChangeWeeklyStatsSubscriptionStatusArgs = {
  ref: Scalars['String'];
};


export type MutationConnectCampaignArgs = {
  campaignId?: InputMaybe<Scalars['Int']>;
  id: Scalars['Float'];
};


export type MutationCreateActiveCampaignIntegrationArgs = {
  input: ActiveCampaignInput;
};


export type MutationCreateActiveCampaignIntegrationCampaignArgs = {
  input: ActiveCampaignIntegrationCampaignInput;
};


export type MutationCreateBrevoIntegrationArgs = {
  input: BrevoInput;
};


export type MutationCreateBrevoIntegrationCampaignArgs = {
  input: BrevoIntegrationCampaignInput;
};


export type MutationCreateCustomerArgs = {
  acceptEmailMarketing: Scalars['Boolean'];
  acceptSmsMarketing: Scalars['Boolean'];
  accountId: Scalars['Int'];
  customTags: Scalars['String'];
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  storeDomain: Scalars['String'];
  tags?: InputMaybe<Scalars['String']>;
};


export type MutationCreateCustomerIoIntegrationArgs = {
  input: CustomerIoInput;
};


export type MutationCreateCustomerIoIntegrationCampaignArgs = {
  input: CustomerIoIntegrationCampaignInput;
};


export type MutationCreateDripIntegrationArgs = {
  input: DripInput;
};


export type MutationCreateDripIntegrationCampaignArgs = {
  input: DripIntegrationCampaignInput;
};


export type MutationCreateEmailAutomationArgs = {
  input: CreateEmailAutomationInput;
};


export type MutationCreateEmailOctopusIntegrationArgs = {
  input: EmailOctopusInput;
};


export type MutationCreateEmailOctopusIntegrationCampaignArgs = {
  input: EmailOctopusIntegrationCampaignInput;
};


export type MutationCreateFirstEmailAutomationArgs = {
  input: CreateFirstEmailAutomationInput;
};


export type MutationCreateFolderArgs = {
  input: CreateFolderInput;
};


export type MutationCreateHubSpotIntegrationArgs = {
  input: HubSpotInput;
};


export type MutationCreateHubSpotIntegrationCampaignArgs = {
  input: HubSpotIntegrationCampaignInput;
};


export type MutationCreateKlaviyoIntegrationArgs = {
  input: KlaviyoInput;
};


export type MutationCreateKlaviyoIntegrationCampaignArgs = {
  input: KlaviyoIntegrationCampaignInput;
};


export type MutationCreateMailChimpIntegrationArgs = {
  input: MailChimpInput;
};


export type MutationCreateMailChimpIntegrationCampaignArgs = {
  input: MailChimpIntegrationCampaignInput;
};


export type MutationCreateMailerLiteIntegrationArgs = {
  input: MailerLiteInput;
};


export type MutationCreateMailerLiteIntegrationCampaignArgs = {
  input: MailerLiteIntegrationCampaignInput;
};


export type MutationCreateOneTimePurchaseArgs = {
  planId: Scalars['Int'];
};


export type MutationCreateSegmentArgs = {
  segment: CreateSegment;
};


export type MutationCreateSendGridIntegrationArgs = {
  input: SendGridInput;
};


export type MutationCreateSendGridIntegrationCampaignArgs = {
  input: SendGridIntegrationCampaignInput;
};


export type MutationCreateShopifyIntegrationArgs = {
  acceptEmailMarketing: Scalars['Boolean'];
  acceptSmsMarketing: Scalars['Boolean'];
  accountId: Scalars['Int'];
  campaignId: Scalars['Int'];
  customTags?: InputMaybe<Scalars['String']>;
  integrationFieldsShopify: Array<IntegrationShopifyFieldInput>;
  storeDomain: Scalars['String'];
};


export type MutationCreateShopifySubscriptionArgs = {
  planId: Scalars['Int'];
};


export type MutationCreateWebhookIntegrationArgs = {
  input: WebhookInput;
};


export type MutationCreateWebsiteArgs = {
  website: Scalars['String'];
};


export type MutationCreateWorkspaceArgs = {
  input: CreateWorkspaceInput;
};


export type MutationCustomerGoogleLoginArgs = {
  accessToken: Scalars['String'];
};


export type MutationCustomerIoChangeStatusArgs = {
  id: Scalars['Int'];
  status: Scalars['Boolean'];
};


export type MutationCustomerIoTestConnectionArgs = {
  input: TestConnectionInput;
};


export type MutationDeleteAccountArgs = {
  email: Scalars['String'];
  survey: Scalars['String'];
};


export type MutationDeleteActiveCampaignIntegrationArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteActiveCampaignIntegrationCampaignArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteAllIntegrationsInCampaignArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteBrevoIntegrationArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteBrevoIntegrationCampaignArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteCampaignArgs = {
  campaignId: Scalars['Int'];
};


export type MutationDeleteCampaignVariantArgs = {
  campaignId: Scalars['Int'];
};


export type MutationDeleteCustomFontArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteCustomerIoIntegrationArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteCustomerIoIntegrationCampaignArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteDomainRelationArgs = {
  campaignId: Scalars['Int'];
  domainId: Scalars['Int'];
};


export type MutationDeleteDripIntegrationArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteDripIntegrationCampaignArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteEmailAutomationArgs = {
  id: Scalars['Float'];
};


export type MutationDeleteEmailAutomationLeadsArgs = {
  ids: Array<Scalars['String']>;
};


export type MutationDeleteEmailOctopusIntegrationArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteEmailOctopusIntegrationCampaignArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteFolderArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteHubSpotIntegrationArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteHubSpotIntegrationCampaignArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteInvitationArgs = {
  invitationId: Scalars['Int'];
};


export type MutationDeleteKlaviyoIntegrationArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteKlaviyoIntegrationCampaignArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteLeadArgs = {
  ids: Array<Scalars['String']>;
};


export type MutationDeleteLeadEmailAddressArgs = {
  campaignId: Scalars['Int'];
  emailId: Scalars['Int'];
};


export type MutationDeleteMailChimpIntegrationArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteMailChimpIntegrationCampaignArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteMailerLiteIntegrationArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteMailerLiteIntegrationCampaignArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteSegmentArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteSendGridIntegrationArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteSendGridIntegrationCampaignArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteShopifyIntegrationArgs = {
  shopifyIntegrationId: Scalars['Int'];
};


export type MutationDeleteShopifyIntegrationInCampaignArgs = {
  shopifyIntegrationCampaignId: Scalars['Int'];
};


export type MutationDeleteTeammateArgs = {
  accountUserId: Scalars['Float'];
};


export type MutationDeleteWebhookIntegrationArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteWebsiteArgs = {
  websiteId: Scalars['Float'];
};


export type MutationDisableAllIntegrationsInCampaignArgs = {
  id: Scalars['Int'];
};


export type MutationDripChangeStatusArgs = {
  id: Scalars['Int'];
  status: Scalars['Boolean'];
};


export type MutationDripTestConnectionArgs = {
  input: TestConnectionInput;
};


export type MutationDuplicateCampaignArgs = {
  campaignId: Scalars['Int'];
};


export type MutationDuplicateNewVariantArgs = {
  variantId: Scalars['Int'];
};


export type MutationEmailAutomationLeadsExportArgs = {
  filter: EmailAutomationLeadsInput;
};


export type MutationEmailOctopusChangeStatusArgs = {
  id: Scalars['Int'];
  status: Scalars['Boolean'];
};


export type MutationEmailOctopusTestConnectionArgs = {
  input: TestConnectionInput;
};


export type MutationExportLeadsArgs = {
  filter: LeadsInput;
};


export type MutationForgotPasswordArgs = {
  captcha: Scalars['String'];
  email: Scalars['String'];
};


export type MutationGenerateAiImageArgs = {
  prompt: Scalars['String'];
  size?: Scalars['String'];
  templateId?: InputMaybe<Scalars['Int']>;
};


export type MutationGenerateAiTextSuggestionsArgs = {
  input: GenerateAiTextSuggestionsInput;
};


export type MutationGenerateAiPopupArgs = {
  input: GenerateAiPopupInput;
};


export type MutationGetEmailLeadFromRefArgs = {
  ref: Scalars['String'];
};


export type MutationGetUsersArgs = {
  filter: Scalars['String'];
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
};


export type MutationHubSpotChangeStatusArgs = {
  id: Scalars['Int'];
  status: Scalars['Boolean'];
};


export type MutationHubSpotTestConnectionArgs = {
  input: TestConnectionInput;
};


export type MutationInvitationAddRoleArgs = {
  campaignIds: Array<Scalars['String']>;
  canCreateCampaign: Scalars['Boolean'];
  invitationId: Scalars['Int'];
  roleId: Scalars['Int'];
};


export type MutationInviteUserArgs = {
  campaignIds: Array<Scalars['Int']>;
  canCreateCampaign: Scalars['Boolean'];
  email: Scalars['String'];
  roleId: Scalars['Int'];
};


export type MutationInvitedUserRegisterArgs = {
  code: Scalars['String'];
  domain?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  password: Scalars['String'];
};


export type MutationKlaviyoChangeStatusArgs = {
  id: Scalars['Int'];
  status: Scalars['Boolean'];
};


export type MutationKlaviyoTestConnectionArgs = {
  input: TestConnectionInput;
};


export type MutationLoginArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type MutationLoginWithShopifyArgs = {
  code: Scalars['String'];
  hmac: Scalars['String'];
  host: Scalars['String'];
  shop: Scalars['String'];
  timestamp: Scalars['String'];
};


export type MutationMailChimpChangeStatusArgs = {
  id: Scalars['Int'];
  status: Scalars['Boolean'];
};


export type MutationMailChimpTestConnectionArgs = {
  input: TestConnectionInput;
};


export type MutationMailerLiteChangeStatusArgs = {
  id: Scalars['Int'];
  status: Scalars['Boolean'];
};


export type MutationMailerliteTestConnectionArgs = {
  input: TestConnectionInput;
};


export type MutationPublishEmailAutomationArgs = {
  id: Scalars['Float'];
  status: Scalars['Boolean'];
};


export type MutationRefreshTokenArgs = {
  accessToken: Scalars['String'];
  refreshToken: Scalars['String'];
};


export type MutationRegisterArgs = {
  data: RegisterInput;
};


export type MutationResetPasswordArgs = {
  password: Scalars['String'];
  token: Scalars['String'];
};


export type MutationResubscribeToWebsiteArgs = {
  email: Scalars['String'];
  emailAutomationId: Scalars['Float'];
  ref: Scalars['String'];
};


export type MutationSaveAsNewCampaignArgs = {
  variantId: Scalars['Int'];
};


export type MutationSaveHubSpotConnectionArgs = {
  input: TokenInput;
};


export type MutationSendGridChangeStatusArgs = {
  id: Scalars['Int'];
  status: Scalars['Boolean'];
};


export type MutationSendGridTestConnectionArgs = {
  input: TestConnectionInput;
};


export type MutationSendTestEmailArgs = {
  emails: Array<Scalars['String']>;
  id: Scalars['Float'];
  sender?: InputMaybe<Scalars['String']>;
  subject?: InputMaybe<Scalars['String']>;
};


export type MutationSendinBlueChangeStatusArgs = {
  id: Scalars['Int'];
  status: Scalars['Boolean'];
};


export type MutationShopifyAppExtensionStatusArgs = {
  domain: Scalars['String'];
};


export type MutationShopifyLinkAccountArgs = {
  code: Scalars['String'];
  hmac: Scalars['String'];
  host: Scalars['String'];
  isRegisterPage?: InputMaybe<Scalars['Boolean']>;
  shop: Scalars['String'];
  timestamp: Scalars['String'];
};


export type MutationShopifyLoginOrRegisterArgs = {
  code: Scalars['String'];
  hmac: Scalars['String'];
  host: Scalars['String'];
  shop: Scalars['String'];
  timestamp: Scalars['String'];
};


export type MutationUnsubscribeToWebsiteArgs = {
  email: Scalars['String'];
  emailAutomationId: Scalars['Float'];
  ref: Scalars['String'];
};


export type MutationUpdateActiveCampaignIntegrationCampaignArgs = {
  id: Scalars['Int'];
  input: ActiveCampaignIntegrationCampaignInput;
};


export type MutationUpdateAutoLimitExtendArgs = {
  emailExtend: Scalars['Boolean'];
  limitExtend: Scalars['Boolean'];
  pageViewExtend: Scalars['Boolean'];
};


export type MutationUpdateBrevoIntegrationCampaignArgs = {
  id: Scalars['Int'];
  input: BrevoIntegrationCampaignInput;
};


export type MutationUpdateCampaignArgs = {
  campaignId: Scalars['Int'];
  data: CampaignUpdateRequest;
};


export type MutationUpdateCampaignFolderArgs = {
  input: UpdateCampaignFolderInput;
};


export type MutationUpdateCampaignTemplateWithAiArgs = {
  input: UpdateCampaignWithAiInput;
};


export type MutationUpdateCustomerIoIntegrationCampaignArgs = {
  id: Scalars['Int'];
  input: CustomerIoIntegrationCampaignInput;
};


export type MutationUpdateDomainArgs = {
  domain: Scalars['String'];
  id: Scalars['Float'];
};


export type MutationUpdateDripIntegrationCampaignArgs = {
  id: Scalars['Int'];
  input: DripIntegrationCampaignInput;
};


export type MutationUpdateEmailAutomationNameArgs = {
  id: Scalars['Float'];
  name: Scalars['String'];
};


export type MutationUpdateEmailOctopusIntegrationCampaignArgs = {
  id: Scalars['Int'];
  input: EmailOctopusIntegrationCampaignInput;
};


export type MutationUpdateFlowEmailTemplateArgs = {
  emailTemplateId: Scalars['Float'];
  id: Scalars['Float'];
  isPlainText: Scalars['Boolean'];
};


export type MutationUpdateFlowTriggerArgs = {
  frequencyStatus: Scalars['Boolean'];
  id: Scalars['Float'];
  isPlainText: Scalars['Boolean'];
  plainTextBody: Scalars['String'];
  steps: Scalars['String'];
  triggerId: Scalars['Float'];
};


export type MutationUpdateFolderArgs = {
  id: Scalars['Int'];
  name: Scalars['String'];
};


export type MutationUpdateHubSpotIntegrationCampaignArgs = {
  id: Scalars['Int'];
  input: HubSpotIntegrationCampaignInput;
};


export type MutationUpdateKlaviyoIntegrationCampaignArgs = {
  id: Scalars['Int'];
  input: KlaviyoIntegrationCampaignInput;
};


export type MutationUpdateMailChimpIntegrationCampaignArgs = {
  id: Scalars['Int'];
  input: MailChimpIntegrationCampaignInput;
};


export type MutationUpdateMailerLiteIntegrationCampaignArgs = {
  id: Scalars['Int'];
  input: MailerLiteIntegrationCampaignInput;
};


export type MutationUpdateSegmentArgs = {
  id: Scalars['Int'];
  segment: CreateSegment;
};


export type MutationUpdateSendGridIntegrationCampaignArgs = {
  id: Scalars['Int'];
  input: SendGridIntegrationCampaignInput;
};


export type MutationUpdateShopifyIntegrationInCampaignArgs = {
  acceptEmailMarketing: Scalars['Boolean'];
  acceptSmsMarketing: Scalars['Boolean'];
  campaignId: Scalars['Int'];
  customTags?: InputMaybe<Scalars['String']>;
  integrationFieldsShopify: Array<IntegrationShopifyFieldInput>;
  integrationId: Scalars['Int'];
  storeDomain?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateUserProfileArgs = {
  name?: InputMaybe<Scalars['String']>;
  phone_number?: InputMaybe<Scalars['String']>;
  surname?: InputMaybe<Scalars['String']>;
  systemLanguage?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateVariantPercentagesArgs = {
  input: Array<CampaignVariantInput>;
};


export type MutationUpdateWebhookIntegrationArgs = {
  id: Scalars['Int'];
  input: WebhookInput;
};


export type MutationUpdateWebsiteArgs = {
  website: Scalars['String'];
  websiteId: Scalars['Float'];
};


export type MutationUploadCustomFontArgs = {
  campaignId: Scalars['Int'];
  file: Scalars['String'];
  filename: Scalars['String'];
};


export type MutationUploadImageArgs = {
  imageBase64: Scalars['String'];
};


export type MutationVerifyLeadEmailAddressArgs = {
  code: Scalars['String'];
};


export type MutationWeeklyStatsDetailsFromRefArgs = {
  ref: Scalars['String'];
};

export type OverviewAnalyticsData = {
  __typename?: 'OverviewAnalyticsData';
  chartData: OverviewChartData;
  currencySymbol?: Maybe<Scalars['String']>;
  endDate: Scalars['DateTime'];
  id: Scalars['String'];
  increasePercentage: Scalars['String'];
  startDate: Scalars['DateTime'];
  total: Scalars['String'];
};

export type OverviewChartData = {
  __typename?: 'OverviewChartData';
  dates: Array<Scalars['DateTime']>;
  increaseValues: Array<Scalars['String']>;
  labels: Array<Scalars['String']>;
  values: Array<Scalars['Float']>;
};

export type PageAnalytics = {
  __typename?: 'PageAnalytics';
  display?: Maybe<Scalars['Int']>;
  interaction?: Maybe<Scalars['Int']>;
  lead?: Maybe<Scalars['Int']>;
  page: Scalars['String'];
};

export type PaginatedCampaignResponse = {
  __typename?: 'PaginatedCampaignResponse';
  canCreateCampaign: Scalars['Boolean'];
  hasFullAccess: Scalars['Boolean'];
  items: Array<Campaign>;
  total: Scalars['Int'];
};

export type ParentAccount = {
  __typename?: 'ParentAccount';
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type PostLeadEmailAddressOutput = {
  __typename?: 'PostLeadEmailAddressOutput';
  campaignId: Scalars['Int'];
  code: Scalars['String'];
  email: Scalars['String'];
  id: Scalars['Int'];
  status: Scalars['String'];
};

export type PublishResponse = {
  __typename?: 'PublishResponse';
  code: Scalars['String'];
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

export type Query = {
  __typename?: 'Query';
  accountStats: AccountStats;
  accountToken: TokenResponse;
  adminCustomerLogin: AdminCustomerLoginTokenResponse;
  browserAnalytics: Array<BrowserAnalytics>;
  campaign: CampaignGetWithIntegrations;
  campaignDailyAnalytics: Array<CampaignDailyAnalytics>;
  campaigns: PaginatedCampaignResponse;
  campaignsWithDomainNames: Array<CampaignWithDomainsResponse>;
  campaignsWithoutEmailAutomation: Array<CampaignsWithoutEmailAutomation>;
  createAbTesting: CreateAbTestingResponse;
  createPortalLink: CreatePortalLinkOutput;
  createSessionId: CreateSessionIdOutput;
  customFonts: CustomFontsResponse;
  defaultEmailTemplateByTrigger: EmailTemplate;
  deviceAnalytics: Array<DeviceAnalytics>;
  emailAutomation: EmailAutomation;
  emailAutomationAnalyticsByIds: EmailAutomationAnalyticsByIdsResponse;
  emailAutomationLeads: EmailAutomationLeadResponse;
  emailAutomations: EmailAutomationResponse;
  emailAutomationsWithoutCampaign: Array<EmailAutomation>;
  emailFlowTemplates: Array<Flow>;
  emailTemplates: Array<EmailTemplate>;
  findEmailAutomationByCampaignId: EmailAutomationByCampaignResponse;
  folders: Array<Folder>;
  getAccountRevenueAnalyticData: RevenueAnalyticData;
  getAccountsAccountUser: Array<AccountUsersResponse>;
  getActiveCampaignFields: Array<FieldsResponse>;
  getActiveCampaignIntegrationsByAccountId: Array<ActiveCampaignResponse>;
  getActiveCampaignIntegrationsByCampaignId: Array<ActiveCampaignIntegrationCampaignResponse>;
  getActiveCampaignLists: Array<ActiveCampaignListResponseItem>;
  getAllShopifyIntegrationInCampaign: Array<IntegrationShopifyCampaign>;
  getBrevoFields: Array<FieldsResponse>;
  getBrevoIntegrationsByAccountId: Array<BrevoResponse>;
  getBrevoIntegrationsByCampaignId: Array<BrevoIntegrationCampaignResponse>;
  getBrevoLists: Array<BrevoListResponseItem>;
  getCampaignCounts: GetCampaignCountsResponse;
  getCustomerIoIntegrationsByAccountId: Array<CustomerIoResponse>;
  getCustomerIoIntegrationsByCampaignId: Array<CustomerIoIntegrationCampaignResponse>;
  getDripFields: Array<FieldsResponse>;
  getDripIntegrationsByAccountId: Array<DripResponse>;
  getDripIntegrationsByCampaignId: Array<DripIntegrationCampaignResponse>;
  getEmailAutomationAnalytics: EmailAutomationAnalyticsResponse;
  getEmailAutomationLineChartAnalytics: EmailAutomationLineChartAnalytics;
  getEmailOctopusFields: Array<FieldsResponse>;
  getEmailOctopusIntegrationsByAccountId: Array<EmailOctopusResponse>;
  getEmailOctopusIntegrationsByCampaignId: Array<EmailOctopusIntegrationCampaignResponse>;
  getEmailOctopusLists: Array<EmailOctopusListResponseItem>;
  getHubSpotFields: Array<FieldsResponse>;
  getHubSpotIntegrationsByAccountId: Array<HubSpotResponse>;
  getHubSpotIntegrationsByCampaignId: Array<HubSpotIntegrationCampaignResponse>;
  getHubSpotLists: Array<HubSpotListResponseItem>;
  getKlaviyoFields: Array<FieldsResponse>;
  getKlaviyoIntegrationsByAccountId: Array<KlaviyoResponse>;
  getKlaviyoIntegrationsByCampaignId: Array<KlaviyoIntegrationCampaignResponse>;
  getKlaviyoLists: Array<KlaviyoListResponseItem>;
  getLeadEmailAddresses: Array<PostLeadEmailAddressOutput>;
  getLimitExtend: LimitExtend;
  getLimitExtendUnpaidInvoice: UpdateAutoLimitExtendResponse;
  getMailChimpFields: Array<FieldsResponse>;
  getMailChimpIntegrationsByAccountId: Array<MailChimpResponse>;
  getMailChimpIntegrationsByCampaignId: Array<MailChimpIntegrationCampaignResponse>;
  getMailChimpLists: Array<MailChimpListResponseItem>;
  getMailerLiteFields: Array<FieldsResponse>;
  getMailerLiteIntegrationsByAccountId: Array<MailerLiteResponse>;
  getMailerLiteIntegrationsByCampaignId: Array<MailerLiteIntegrationCampaignResponse>;
  getMailerLiteLists: Array<MailerLiteListResponseItem>;
  getSegment: SegmentResponse;
  getSegments: Array<SegmentResponse>;
  getSendGridFields: Array<FieldsResponse>;
  getSendGridIntegrationsByAccountId: Array<SendGridResponse>;
  getSendGridIntegrationsByCampaignId: Array<SendGridIntegrationCampaignResponse>;
  getSendGridLists: Array<SendGridListResponseItem>;
  getUrlByDomain: GetUrlByDomainResponse;
  getWebhookIntegrationsByAccountId: Array<WebhookIntegration>;
  getWebhookIntegrationsByCampaignId: Array<WebhookIntegration>;
  googleFonts: GoogleFontsResponse;
  interactionAnalytics: Array<InteractionAnalytics>;
  invitations: Array<Invitation>;
  leads: LeadResponse;
  lineChartAnalytics: LineChartAnalytics;
  me: User;
  overviewPageAnalyticsData: Array<OverviewAnalyticsData>;
  pageAnalytics: Array<PageAnalytics>;
  referrerAnalytics: Array<ReferrerAnalytics>;
  regionAnalytics: Array<RegionAnalytics>;
  relatedCampaign?: Maybe<RelatedCampaign>;
  resolutionAnalytics: Array<ResolutionAnalytics>;
  sendinBlueTest: TestResponse;
  shortAnalytics: ShortAnalytics;
  supportedLanguages: Array<Scalars['String']>;
  templateById: TemplateDetailResponse;
  templateSeasons: Array<SeasonItem>;
  templateTags: Array<TagItem>;
  templates: TemplatesResponse;
  triggers: TriggerResponse;
  userAccounts: Array<Accounts>;
  websites: WebsitesResponse;
};


export type QueryAccountTokenArgs = {
  requestedAccountId: Scalars['Float'];
};


export type QueryAdminCustomerLoginArgs = {
  id: Scalars['Int'];
};


export type QueryBrowserAnalyticsArgs = {
  filter: AnalyticsFilterInput;
};


export type QueryCampaignArgs = {
  campaignId: Scalars['Int'];
};


export type QueryCampaignDailyAnalyticsArgs = {
  campaignIds: Array<Scalars['Int']>;
};


export type QueryCampaignsArgs = {
  filter?: InputMaybe<CampaignFilterInput>;
};


export type QueryCampaignsWithoutEmailAutomationArgs = {
  filter?: InputMaybe<AutomationsFilter>;
};


export type QueryCreateAbTestingArgs = {
  campaignId: Scalars['Int'];
};


export type QueryCreatePortalLinkArgs = {
  paymentMode?: InputMaybe<Scalars['String']>;
};


export type QueryCreateSessionIdArgs = {
  paymentMode?: InputMaybe<Scalars['String']>;
  priceId: Scalars['String'];
};


export type QueryCustomFontsArgs = {
  campaignId: Scalars['Int'];
};


export type QueryDefaultEmailTemplateByTriggerArgs = {
  id: Scalars['Float'];
};


export type QueryDeviceAnalyticsArgs = {
  filter: AnalyticsFilterInput;
};


export type QueryEmailAutomationArgs = {
  id: Scalars['Float'];
};


export type QueryEmailAutomationAnalyticsByIdsArgs = {
  endDate?: InputMaybe<Scalars['DateTime']>;
  ids: Array<Scalars['Float']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
};


export type QueryEmailAutomationLeadsArgs = {
  filter: EmailAutomationLeadsInput;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};


export type QueryEmailAutomationsArgs = {
  filter?: InputMaybe<AutomationsFilter>;
};


export type QueryFindEmailAutomationByCampaignIdArgs = {
  campaignId: Scalars['Float'];
};


export type QueryGetAccountRevenueAnalyticDataArgs = {
  input: GetAccountRevenueAnalyticDataInput;
};


export type QueryGetActiveCampaignFieldsArgs = {
  account: Scalars['String'];
  apiKey: Scalars['String'];
};


export type QueryGetActiveCampaignIntegrationsByCampaignIdArgs = {
  campaignId: Scalars['Int'];
};


export type QueryGetActiveCampaignListsArgs = {
  account: Scalars['String'];
  apiKey: Scalars['String'];
};


export type QueryGetAllShopifyIntegrationInCampaignArgs = {
  campaignId: Scalars['Int'];
};


export type QueryGetBrevoFieldsArgs = {
  apiKey: Scalars['String'];
};


export type QueryGetBrevoIntegrationsByCampaignIdArgs = {
  campaignId: Scalars['Int'];
};


export type QueryGetBrevoListsArgs = {
  apiKey: Scalars['String'];
};


export type QueryGetCustomerIoIntegrationsByCampaignIdArgs = {
  campaignId: Scalars['Int'];
};


export type QueryGetDripFieldsArgs = {
  account: Scalars['String'];
  apiKey: Scalars['String'];
  password: Scalars['String'];
};


export type QueryGetDripIntegrationsByCampaignIdArgs = {
  campaignId: Scalars['Int'];
};


export type QueryGetEmailAutomationAnalyticsArgs = {
  filter: EmailAutomationAnalyticsInput;
};


export type QueryGetEmailAutomationLineChartAnalyticsArgs = {
  filter: EmailAutomationAnalyticsInput;
  range: EmailAutomationLineChartRange;
};


export type QueryGetEmailOctopusFieldsArgs = {
  apiKey: Scalars['String'];
  listId: Scalars['String'];
};


export type QueryGetEmailOctopusIntegrationsByCampaignIdArgs = {
  campaignId: Scalars['Int'];
};


export type QueryGetEmailOctopusListsArgs = {
  apiKey: Scalars['String'];
};


export type QueryGetHubSpotFieldsArgs = {
  id?: InputMaybe<Scalars['Int']>;
};


export type QueryGetHubSpotIntegrationsByCampaignIdArgs = {
  campaignId: Scalars['Int'];
};


export type QueryGetHubSpotListsArgs = {
  apiKey: Scalars['String'];
  id: Scalars['Int'];
};


export type QueryGetKlaviyoIntegrationsByCampaignIdArgs = {
  campaignId: Scalars['Int'];
};


export type QueryGetKlaviyoListsArgs = {
  apiKey: Scalars['String'];
};


export type QueryGetLeadEmailAddressesArgs = {
  campaignId: Scalars['Int'];
};


export type QueryGetMailChimpFieldsArgs = {
  apiKey: Scalars['String'];
  listId: Scalars['String'];
};


export type QueryGetMailChimpIntegrationsByCampaignIdArgs = {
  campaignId: Scalars['Int'];
};


export type QueryGetMailChimpListsArgs = {
  apiKey: Scalars['String'];
};


export type QueryGetMailerLiteFieldsArgs = {
  apiKey: Scalars['String'];
};


export type QueryGetMailerLiteIntegrationsByCampaignIdArgs = {
  campaignId: Scalars['Int'];
};


export type QueryGetMailerLiteListsArgs = {
  apiKey: Scalars['String'];
};


export type QueryGetSegmentArgs = {
  id: Scalars['Int'];
};


export type QueryGetSegmentsArgs = {
  campaignId: Scalars['Int'];
};


export type QueryGetSendGridFieldsArgs = {
  apiKey: Scalars['String'];
};


export type QueryGetSendGridIntegrationsByCampaignIdArgs = {
  campaignId: Scalars['Int'];
};


export type QueryGetSendGridListsArgs = {
  apiKey: Scalars['String'];
};


export type QueryGetUrlByDomainArgs = {
  domain: Scalars['String'];
};


export type QueryGetWebhookIntegrationsByCampaignIdArgs = {
  campaignId: Scalars['Int'];
};


export type QueryInteractionAnalyticsArgs = {
  filter: AnalyticsFilterInput;
};


export type QueryLeadsArgs = {
  filter: LeadsInput;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};


export type QueryLineChartAnalyticsArgs = {
  filter: AnalyticsFilterInput;
  range: LineChartRange;
};


export type QueryOverviewPageAnalyticsDataArgs = {
  input: GetOverviewAnalyticsDataInput;
};


export type QueryPageAnalyticsArgs = {
  filter: AnalyticsFilterInput;
};


export type QueryReferrerAnalyticsArgs = {
  filter: AnalyticsFilterInput;
};


export type QueryRegionAnalyticsArgs = {
  filter: AnalyticsFilterInput;
};


export type QueryRelatedCampaignArgs = {
  id: Scalars['Float'];
};


export type QueryResolutionAnalyticsArgs = {
  filter: AnalyticsFilterInput;
};


export type QuerySendinBlueTestArgs = {
  testInput: TestInput;
};


export type QueryShortAnalyticsArgs = {
  filter: AnalyticsFilterInput;
};


export type QueryTemplateByIdArgs = {
  id: Scalars['Float'];
};


export type QueryTemplatesArgs = {
  templatesInput: TemplatesInput;
};

export type ReferrerAnalytics = {
  __typename?: 'ReferrerAnalytics';
  display?: Maybe<Scalars['Int']>;
  interaction?: Maybe<Scalars['Int']>;
  lead?: Maybe<Scalars['Int']>;
  referrer: Scalars['String'];
};

export type RegionAnalytics = {
  __typename?: 'RegionAnalytics';
  city: Scalars['String'];
  country: Scalars['String'];
  display?: Maybe<Scalars['Int']>;
  interaction?: Maybe<Scalars['Int']>;
  lead?: Maybe<Scalars['Int']>;
  region: Scalars['String'];
};

export type RegisterInput = {
  captcha: Scalars['String'];
  domain: Scalars['String'];
  email: Scalars['String'];
  firstname: Scalars['String'];
  lastname: Scalars['String'];
  password: Scalars['String'];
  phoneNumber?: InputMaybe<Scalars['String']>;
  privacy_policy: Scalars['Boolean'];
};

export type RegisterOutput = {
  __typename?: 'RegisterOutput';
  refreshToken: Scalars['String'];
  token: Scalars['String'];
  tokenExpireDate: Scalars['String'];
};

export type RelatedCampaign = {
  __typename?: 'RelatedCampaign';
  domains: Array<Domain>;
  formInputs: Array<FormInput>;
  haveForm: Scalars['Boolean'];
  id: Scalars['Int'];
  isActive: Scalars['Boolean'];
  isTemplateSelected: Scalars['Boolean'];
  name: Scalars['String'];
  publishVersion: Scalars['Int'];
};

export type ResolutionAnalytics = {
  __typename?: 'ResolutionAnalytics';
  display: Scalars['Int'];
  interaction: Scalars['Int'];
  resolution: Scalars['String'];
};

export type ResubscribeToWebsiteResponse = {
  __typename?: 'ResubscribeToWebsiteResponse';
  data?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
  status: Scalars['String'];
};

export type RevenueAnalyticData = {
  __typename?: 'RevenueAnalyticData';
  chartData: RevenueChartData;
  endDate: Scalars['DateTime'];
  increasePercentage: Scalars['String'];
  startDate: Scalars['DateTime'];
  total: Scalars['String'];
};

export type RevenueChartData = {
  __typename?: 'RevenueChartData';
  dates: Array<Scalars['DateTime']>;
  increaseValues: Array<Scalars['String']>;
  labels: Array<Scalars['String']>;
  values: Array<Scalars['Float']>;
};

export type SaveAsNewCampaigntResponse = {
  __typename?: 'SaveAsNewCampaigntResponse';
  id: Scalars['Int'];
};

export type SeasonCount = {
  __typename?: 'SeasonCount';
  count?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
};

export type SeasonItem = {
  __typename?: 'SeasonItem';
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type SegmentResponse = {
  __typename?: 'SegmentResponse';
  campaigns: Array<CampaignForSegment>;
  color: Scalars['String'];
  createdAt: Scalars['String'];
  description: Scalars['String'];
  icon: Scalars['String'];
  id: Scalars['Float'];
  isDeletable: Scalars['Boolean'];
  isPredefined: Scalars['Boolean'];
  key?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  segmentCategoryId: Scalars['Float'];
  segmentCategoryKey?: Maybe<Scalars['String']>;
  segmentCategoryName: Scalars['String'];
  targets?: Maybe<Scalars['String']>;
};

export type SendGridInput = {
  apiKey: Scalars['String'];
  id: Scalars['String'];
  integrationCampaigns: Array<SendGridIntegrationCampaignInput>;
  name: Scalars['String'];
  status: Scalars['Boolean'];
};

export type SendGridIntegrationCampaign = {
  __typename?: 'SendGridIntegrationCampaign';
  accountId: Scalars['Int'];
  apiKey: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
  status: Scalars['Boolean'];
};

export type SendGridIntegrationCampaignInput = {
  campaignId: Scalars['Int'];
  id: Scalars['Int'];
  integrationFields: Array<SendGridIntegrationFieldsInput>;
  integrationId: Scalars['String'];
  listId: Scalars['String'];
  name: Scalars['String'];
  status: Scalars['Boolean'];
};

export type SendGridIntegrationCampaignResponse = {
  __typename?: 'SendGridIntegrationCampaignResponse';
  campaignId: Scalars['Int'];
  id: Scalars['Int'];
  integration?: Maybe<SendGridIntegrationCampaign>;
  integrationFields: Array<SendGridIntegrationFields>;
  integrationId: Scalars['Int'];
  listId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  status: Scalars['Boolean'];
};

export type SendGridIntegrationFields = {
  __typename?: 'SendGridIntegrationFields';
  fieldType: Scalars['Int'];
  id: Scalars['Int'];
  integrationCampaignId?: Maybe<Scalars['Int']>;
  integratorFieldId?: Maybe<Scalars['String']>;
  integratorFieldName?: Maybe<Scalars['String']>;
  integratorTag?: Maybe<Scalars['String']>;
  isCustomerEmail: Scalars['Boolean'];
  popupsmartFieldName?: Maybe<Scalars['String']>;
};

export type SendGridIntegrationFieldsInput = {
  fieldType?: InputMaybe<Scalars['Int']>;
  id: Scalars['Int'];
  integrationCampaignId?: InputMaybe<Scalars['Int']>;
  integratorFieldId?: InputMaybe<Scalars['String']>;
  integratorFieldName?: InputMaybe<Scalars['String']>;
  integratorFieldType: Scalars['Int'];
  integratorTag?: InputMaybe<Scalars['String']>;
  isCustomerEmail?: InputMaybe<Scalars['Boolean']>;
  nodeName?: InputMaybe<Scalars['String']>;
  popupsmartFieldName?: InputMaybe<Scalars['String']>;
};

export type SendGridListResponseItem = {
  __typename?: 'SendGridListResponseItem';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type SendGridResponse = {
  __typename?: 'SendGridResponse';
  accountId: Scalars['Int'];
  apiKey: Scalars['String'];
  id: Scalars['Int'];
  integrationCampaigns: Array<SendGridIntegrationCampaignResponse>;
  name: Scalars['String'];
  status: Scalars['Boolean'];
};

export type SendTestEmailResponse = {
  __typename?: 'SendTestEmailResponse';
  message?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type ShopifyAppExtensionStatusResponse = {
  __typename?: 'ShopifyAppExtensionStatusResponse';
  redirectUrl: Scalars['String'];
  status: Scalars['Boolean'];
};

export type ShopifyLinkAccountResponse = {
  __typename?: 'ShopifyLinkAccountResponse';
  addedDomain: Scalars['String'];
  addedNewDomain: Scalars['Boolean'];
  status: Scalars['Boolean'];
};

export type ShopifyLoginOrRegisterResponse = {
  __typename?: 'ShopifyLoginOrRegisterResponse';
  accessToken: Scalars['String'];
  addedDomain: Scalars['String'];
  addedNewDomain: Scalars['Boolean'];
  isNewAccount: Scalars['Boolean'];
  refreshToken: Scalars['String'];
};

export type ShopifyStore = {
  __typename?: 'ShopifyStore';
  domain?: Maybe<Scalars['String']>;
};

export type ShortAnalytics = {
  __typename?: 'ShortAnalytics';
  display?: Maybe<Scalars['Int']>;
  interaction?: Maybe<Scalars['Int']>;
  lead?: Maybe<Scalars['Int']>;
  pageView?: Maybe<Scalars['Int']>;
  previousDisplay?: Maybe<Scalars['Int']>;
  previousInteraction?: Maybe<Scalars['Int']>;
  previousLead?: Maybe<Scalars['Int']>;
  previousPageView?: Maybe<Scalars['Int']>;
};

export type SubRelation = {
  __typename?: 'SubRelation';
  subTags?: Maybe<Array<SubTagItem>>;
};

export type SubTagItem = {
  __typename?: 'SubTagItem';
  count?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  seasonsCounts?: Maybe<Array<SeasonCount>>;
};

export type TagItem = {
  __typename?: 'TagItem';
  count: Scalars['Int'];
  icon: Scalars['String'];
  id: Scalars['Int'];
  seasonsCounts: Array<SeasonCount>;
  subRelations?: Maybe<SubRelation>;
  tag: Scalars['String'];
  type: Scalars['String'];
};

export type TemplateBase = {
  __typename?: 'TemplateBase';
  description?: Maybe<Scalars['String']>;
  deviceType?: Maybe<Scalars['Int']>;
  goals: Array<Scalars['Int']>;
  id: Scalars['Int'];
  imageUrl?: Maybe<Scalars['String']>;
  isAnimated?: Maybe<Scalars['Boolean']>;
  isMultipage?: Maybe<Scalars['Boolean']>;
  layoutElements: Array<Scalars['String']>;
  layoutType?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  popupType?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['Int']>;
  subTags: Array<Scalars['String']>;
  tags: Array<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
};

export type TemplateDetailResponse = {
  __typename?: 'TemplateDetailResponse';
  description?: Maybe<Scalars['String']>;
  deviceType?: Maybe<Scalars['Int']>;
  goals: Array<Scalars['Int']>;
  id: Scalars['Int'];
  imageUrl?: Maybe<Scalars['String']>;
  isAnimated?: Maybe<Scalars['Boolean']>;
  isMultipage?: Maybe<Scalars['Boolean']>;
  layout: Scalars['String'];
  layoutElements: Array<Scalars['String']>;
  layoutType?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  popupType?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['Int']>;
  subTags: Array<Scalars['String']>;
  tags: Array<Scalars['Int']>;
  targets: Scalars['String'];
  title?: Maybe<Scalars['String']>;
};

export type TemplatesInput = {
  animatedFilterValue?: InputMaybe<Scalars['String']>;
  deviceType?: InputMaybe<Scalars['Int']>;
  goalId?: InputMaybe<Scalars['Int']>;
  isAnimated?: InputMaybe<Scalars['Boolean']>;
  isMultipage?: InputMaybe<Scalars['Boolean']>;
  layoutType?: InputMaybe<Scalars['Int']>;
  popupType?: InputMaybe<Scalars['Int']>;
  searchTerm?: InputMaybe<Scalars['String']>;
  seasonId?: InputMaybe<Scalars['Int']>;
  tags: Array<Scalars['Int']>;
};

export type TemplatesResponse = {
  __typename?: 'TemplatesResponse';
  data: Array<TemplateBase>;
  layoutElements: Array<Scalars['String']>;
  total: Scalars['Int'];
};

export type TestConnectionConnectionSettings = {
  account?: InputMaybe<Scalars['String']>;
  apiKey: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
  siteId?: InputMaybe<Scalars['String']>;
};

export type TestConnectionFieldList = {
  fieldType: Scalars['Int'];
  id: Scalars['Int'];
  integrationCampaignId: Scalars['Int'];
  integratorFieldId: Scalars['String'];
  integratorFieldName: Scalars['String'];
  integratorFieldType?: InputMaybe<Scalars['String']>;
  integratorTag?: InputMaybe<Scalars['String']>;
  isCustomerEmail: Scalars['Boolean'];
  nodeName?: InputMaybe<Scalars['String']>;
  popupsmartFieldName?: InputMaybe<Scalars['String']>;
  staticValue?: InputMaybe<Scalars['String']>;
};

export type TestConnectionInput = {
  connectionSettings: TestConnectionConnectionSettings;
  fieldList: Array<TestConnectionFieldList>;
  listSettings: TestConnectionListSettings;
};

export type TestConnectionListSettings = {
  listId: Scalars['String'];
};

export type TestConnectionResponse = {
  __typename?: 'TestConnectionResponse';
  errorLogs?: Maybe<Scalars['String']>;
  isSent: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
  messageTitle?: Maybe<Scalars['String']>;
  sentData: Array<TestConnectionSentData>;
};

export type TestConnectionSentData = {
  __typename?: 'TestConnectionSentData';
  integratorFieldId?: Maybe<Scalars['String']>;
  integratorFieldName: Scalars['String'];
  integratorTag?: Maybe<Scalars['String']>;
  nodeName?: Maybe<Scalars['String']>;
  popupsmartFieldName?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type TestField = {
  fieldType: Scalars['Int'];
  id?: InputMaybe<Scalars['Int']>;
  integrationCampaignId?: InputMaybe<Scalars['Int']>;
  integratorFieldId?: InputMaybe<Scalars['String']>;
  integratorFieldName: Scalars['String'];
  integratorTag?: InputMaybe<Scalars['String']>;
  isCustomerEmail: Scalars['Boolean'];
  nodeName?: InputMaybe<Scalars['String']>;
  popupsmartFieldName: Scalars['String'];
};

export type TestInput = {
  account?: InputMaybe<Scalars['String']>;
  apiKey?: InputMaybe<Scalars['String']>;
  fields: Array<TestField>;
  id: Scalars['String'];
  listId: Scalars['String'];
  name: Scalars['String'];
};

export type TestResponse = {
  __typename?: 'TestResponse';
  errorLogs?: Maybe<Scalars['String']>;
  isSent: Scalars['Boolean'];
  message: Scalars['String'];
  messageTitle: Scalars['String'];
  sentData: Array<TestSendData>;
};

export type TestSendData = {
  __typename?: 'TestSendData';
  integratorFieldId: Scalars['String'];
  integratorFieldName: Scalars['String'];
  integratorTag: Scalars['String'];
  nodeName: Scalars['String'];
  popupsmartFieldName: Scalars['String'];
  value: Scalars['String'];
};

export type TextSuggestion = {
  __typename?: 'TextSuggestion';
  text: Scalars['String'];
};

export type TokenInput = {
  code: Scalars['String'];
  grantType: Scalars['Float'];
};

export type TokenResponse = {
  __typename?: 'TokenResponse';
  accessToken: Scalars['String'];
  refreshToken: Scalars['String'];
};

export type Trigger = {
  __typename?: 'Trigger';
  code: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  name: Scalars['String'];
  type: FlowType;
};

export type TriggerResponse = {
  __typename?: 'TriggerResponse';
  items: Array<Trigger>;
  total: Scalars['Float'];
};

export type UnsubscribeToWebsiteResponse = {
  __typename?: 'UnsubscribeToWebsiteResponse';
  data?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
  status: Scalars['String'];
};

export type UpdateAutoLimitExtendResponse = {
  __typename?: 'UpdateAutoLimitExtendResponse';
  error?: Maybe<Scalars['String']>;
  invoiceUrl?: Maybe<Scalars['String']>;
  status: Scalars['Boolean'];
};

export type UpdateCampaignFolderInput = {
  campaignId: Scalars['Int'];
  folderId?: InputMaybe<Scalars['Int']>;
};

export type UpdateCampaignWithAiInput = {
  id: Scalars['Float'];
  prompt: Scalars['String'];
};

export type User = {
  __typename?: 'User';
  accountId?: Maybe<Scalars['String']>;
  accountIsGettingStartedCompleted: Scalars['Boolean'];
  accountName?: Maybe<Scalars['String']>;
  accountPlan: AccountPlan;
  accountUseProxiedFonts: Scalars['Boolean'];
  apiToken?: Maybe<Scalars['String']>;
  baseShopifyStore: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  emailVerify: Scalars['Boolean'];
  id: Scalars['Int'];
  isAgency: Scalars['Boolean'];
  isMigrated: Scalars['Boolean'];
  isOwner: Scalars['Boolean'];
  isPageViewBlocked: Scalars['Boolean'];
  isShopify: Scalars['Boolean'];
  isShopifyUser: Scalars['Boolean'];
  isSubWorkspace: Scalars['Boolean'];
  logoUrl?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  paymentProvider: Scalars['String'];
  phone_number: Scalars['String'];
  surname?: Maybe<Scalars['String']>;
  systemLanguage?: Maybe<Scalars['String']>;
  userHasShopifyWebsite: Scalars['Boolean'];
};

export type UserErrorsType = {
  __typename?: 'UserErrorsType';
  field?: Maybe<Array<Scalars['String']>>;
  message?: Maybe<Scalars['String']>;
};

export type Variant = {
  __typename?: 'Variant';
  name: Scalars['String'];
  percentage: Scalars['Int'];
};

export type VariantInfo = {
  __typename?: 'VariantInfo';
  abTestingId: Scalars['Int'];
  variant: Variant;
  variantId?: Maybe<Scalars['Int']>;
};

export type WebhookInput = {
  campaignId?: InputMaybe<Scalars['Int']>;
  name: Scalars['String'];
  url: Scalars['String'];
};

export type WebhookIntegration = {
  __typename?: 'WebhookIntegration';
  campaignId: Scalars['Int'];
  id: Scalars['Int'];
  name: Scalars['String'];
  url: Scalars['String'];
};

export type WebsiteItem = {
  __typename?: 'WebsiteItem';
  campaignCount: Scalars['Int'];
  cms?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  isShopify: Scalars['Boolean'];
  url?: Maybe<Scalars['String']>;
  verified?: Maybe<Scalars['Boolean']>;
};

export type WebsitesResponse = {
  __typename?: 'WebsitesResponse';
  data: Array<WebsiteItem>;
  total: Scalars['Int'];
};

export type WeeklyStatsDetailsFromRef = {
  __typename?: 'WeeklyStatsDetailsFromRef';
  email: Scalars['String'];
  status: Scalars['Boolean'];
};

export type GenerateAiCampaignImageMutationVariables = Exact<{
  prompt: Scalars['String'];
  size: Scalars['String'];
  templateId?: InputMaybe<Scalars['Int']>;
}>;


export type GenerateAiCampaignImageMutation = { __typename?: 'Mutation', generateAIImage: { __typename?: 'GenerateAIImageResponse', success: boolean, data?: string | null, error?: string | null } };

export type CreateWorkspaceMutationVariables = Exact<{
  input: CreateWorkspaceInput;
}>;


export type CreateWorkspaceMutation = { __typename?: 'Mutation', createWorkspace: { __typename?: 'CreateWorkspaceResponse', id: number, name: string, createdAt: string, updatedAt: string } };

export type CreateFolderMutationVariables = Exact<{
  input: CreateFolderInput;
}>;


export type CreateFolderMutation = { __typename?: 'Mutation', createFolder: { __typename?: 'Folder', id: number, name: string, color?: string | null, createdAt: string, campaigns: Array<{ __typename?: 'Campaign', id: number, name: string }> } };

export type DeleteFolderMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteFolderMutation = { __typename?: 'Mutation', deleteFolder: boolean };

export type UpdateCampaignFolderMutationVariables = Exact<{
  input: UpdateCampaignFolderInput;
}>;


export type UpdateCampaignFolderMutation = { __typename?: 'Mutation', updateCampaignFolder: boolean };

export type UpdateFolderMutationVariables = Exact<{
  id: Scalars['Int'];
  name: Scalars['String'];
}>;


export type UpdateFolderMutation = { __typename?: 'Mutation', updateFolder: { __typename?: 'Folder', id: number, name: string, color?: string | null, createdAt: string } };

export type CreateAbTestingQueryVariables = Exact<{
  campaignId: Scalars['Int'];
}>;


export type CreateAbTestingQuery = { __typename?: 'Query', createAbTesting: { __typename?: 'CreateAbTestingResponse', id: number } };

export type AdminCustomerLoginQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type AdminCustomerLoginQuery = { __typename?: 'Query', adminCustomerLogin: { __typename?: 'AdminCustomerLoginTokenResponse', accessToken: string, refreshToken: string } };

export type ChangeUserEmailMutationVariables = Exact<{
  id: Scalars['Int'];
  email: Scalars['String'];
}>;


export type ChangeUserEmailMutation = { __typename?: 'Mutation', changeUserEmail: { __typename?: 'ChangeUserEmailResponse', id: number, email: string } };

export type ChangeAccountPlanMutationVariables = Exact<{
  id: Scalars['Int'];
  planId: Scalars['Int'];
}>;


export type ChangeAccountPlanMutation = { __typename?: 'Mutation', changeAccountPlan: { __typename?: 'ChangeAccountPlanResponse', id: number, name: string, planId: number } };

export type GetUsersMutationVariables = Exact<{
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  filter: Scalars['String'];
}>;


export type GetUsersMutation = { __typename?: 'Mutation', getUsers: Array<{ __typename?: 'GetUsersResponse', id?: number | null, name?: string | null, surname?: string | null, email?: string | null, phone?: string | null, image?: string | null, status: boolean, stripeId?: string | null, planId?: number | null, organizationName?: string | null }> };

export type UsedByAnalyticsQueryVariables = Exact<{
  filter: AnalyticsFilterInput;
}>;


export type UsedByAnalyticsQuery = { __typename?: 'Query', browserAnalytics: Array<{ __typename?: 'BrowserAnalytics', browser: string, display: number }>, deviceAnalytics: Array<{ __typename?: 'DeviceAnalytics', device: string, display: number }>, resolutionAnalytics: Array<{ __typename?: 'ResolutionAnalytics', resolution: string, display: number }> };

export type LoginMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type LoginMutation = { __typename?: 'Mutation', login: { __typename?: 'TokenResponse', accessToken: string, refreshToken: string } };

export type RefreshTokenMutationVariables = Exact<{
  accessToken: Scalars['String'];
  refreshToken: Scalars['String'];
}>;


export type RefreshTokenMutation = { __typename?: 'Mutation', refreshToken: { __typename?: 'TokenResponse', accessToken: string, refreshToken: string } };

export type AdminLoginMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type AdminLoginMutation = { __typename?: 'Mutation', adminLogin: { __typename?: 'TokenResponse', accessToken: string, refreshToken: string } };

export type ForgotPasswordMutationVariables = Exact<{
  captcha: Scalars['String'];
  email: Scalars['String'];
}>;


export type ForgotPasswordMutation = { __typename?: 'Mutation', forgotPassword: boolean };

export type ResetPasswordMutationVariables = Exact<{
  password: Scalars['String'];
  token: Scalars['String'];
}>;


export type ResetPasswordMutation = { __typename?: 'Mutation', resetPassword: boolean };

export type RegisterMutationVariables = Exact<{
  data: RegisterInput;
}>;


export type RegisterMutation = { __typename?: 'Mutation', register: { __typename?: 'RegisterOutput', token: string, refreshToken: string, tokenExpireDate: string } };

export type CustomerGoogleLoginMutationVariables = Exact<{
  accessToken: Scalars['String'];
}>;


export type CustomerGoogleLoginMutation = { __typename?: 'Mutation', customerGoogleLogin: { __typename?: 'GoogleLoginOutput', isRegisteredUser: boolean, token: string, refreshToken: string, tokenExpireDate: string } };

export type AccountTokenQueryVariables = Exact<{
  requestedAccountId: Scalars['Float'];
}>;


export type AccountTokenQuery = { __typename?: 'Query', accountToken: { __typename?: 'TokenResponse', accessToken: string, refreshToken: string } };

export type DeleteCampaignVariantMutationVariables = Exact<{
  campaignId: Scalars['Int'];
}>;


export type DeleteCampaignVariantMutation = { __typename?: 'Mutation', deleteCampaignVariant: boolean };

export type ChangeCampaignVariantNameMutationVariables = Exact<{
  name: Scalars['String'];
  campaignId: Scalars['Int'];
}>;


export type ChangeCampaignVariantNameMutation = { __typename?: 'Mutation', changeCampaignVariantName: { __typename?: 'CreateCampaignVariantResponse', id: number, name: string } };

export type SaveAsNewCampaignMutationVariables = Exact<{
  variantId: Scalars['Int'];
}>;


export type SaveAsNewCampaignMutation = { __typename?: 'Mutation', saveAsNewCampaign: { __typename?: 'SaveAsNewCampaigntResponse', id: number } };

export type DuplicateNewVariantMutationVariables = Exact<{
  variantId: Scalars['Int'];
}>;


export type DuplicateNewVariantMutation = { __typename?: 'Mutation', duplicateNewVariant: { __typename?: 'SaveAsNewCampaigntResponse', id: number } };

export type UpdateVariantPercentagesMutationVariables = Exact<{
  input: Array<CampaignVariantInput> | CampaignVariantInput;
}>;


export type UpdateVariantPercentagesMutation = { __typename?: 'Mutation', updateVariantPercentages: boolean };

export type CustomFontsQueryVariables = Exact<{
  campaignId: Scalars['Int'];
}>;


export type CustomFontsQuery = { __typename?: 'Query', customFonts: { __typename?: 'CustomFontsResponse', fonts: Array<{ __typename?: 'CustomFont', id: number, name: string, url: string, weightUrls: string, campaignId: number }> } };

export type UploadCustomFontMutationVariables = Exact<{
  file: Scalars['String'];
  filename: Scalars['String'];
  campaignId: Scalars['Int'];
}>;


export type UploadCustomFontMutation = { __typename?: 'Mutation', uploadCustomFont: { __typename?: 'CustomFont', id: number, name: string, url: string, weightUrls: string, campaignId: number } };

export type DeleteCustomFontMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteCustomFontMutation = { __typename?: 'Mutation', deleteCustomFont: boolean };

export type UploadImageMutationVariables = Exact<{
  imageBase64: Scalars['String'];
}>;


export type UploadImageMutation = { __typename?: 'Mutation', uploadImage: { __typename?: 'ImageResponse', url: string } };

export type InteractionAnalyticsQueryVariables = Exact<{
  filter: AnalyticsFilterInput;
}>;


export type InteractionAnalyticsQuery = { __typename?: 'Query', interactionAnalytics: Array<{ __typename?: 'InteractionAnalytics', type: string, text: string, count: number }> };

export type InvitationAddRoleMutationVariables = Exact<{
  campaignIds: Array<Scalars['String']> | Scalars['String'];
  roleId: Scalars['Int'];
  invitationId: Scalars['Int'];
  canCreateCampaign: Scalars['Boolean'];
}>;


export type InvitationAddRoleMutation = { __typename?: 'Mutation', invitationAddRole: boolean };

export type InvitationsQueryVariables = Exact<{ [key: string]: never; }>;


export type InvitationsQuery = { __typename?: 'Query', invitations: Array<{ __typename?: 'Invitation', id: number, email?: string | null, status?: string | null, canCreateCampaign: boolean, role: { __typename?: 'InvitationRole', roleTypeId: number, campaigns: Array<number> } }> };

export type DeleteInvitationMutationVariables = Exact<{
  invitationId: Scalars['Int'];
}>;


export type DeleteInvitationMutation = { __typename?: 'Mutation', deleteInvitation: boolean };

export type InviteUserMutationVariables = Exact<{
  campaignIds: Array<Scalars['Int']> | Scalars['Int'];
  roleId: Scalars['Int'];
  email: Scalars['String'];
  canCreateCampaign: Scalars['Boolean'];
}>;


export type InviteUserMutation = { __typename?: 'Mutation', inviteUser: { __typename?: 'InviteUserResponse', id: number, email?: string | null, status?: string | null, canCreateCampaign: boolean, role?: { __typename?: 'InvitationRole', roleTypeId: number, campaigns: Array<number> } | null } };

export type AddLeadEmailAddressMutationVariables = Exact<{
  campaignId: Scalars['Int'];
  email: Scalars['String'];
}>;


export type AddLeadEmailAddressMutation = { __typename?: 'Mutation', addLeadEmailAddress: { __typename?: 'PostLeadEmailAddressOutput', id: number, email: string, campaignId: number, status: string, code: string } };

export type GetLeadEmailAddressesQueryVariables = Exact<{
  campaignId: Scalars['Int'];
}>;


export type GetLeadEmailAddressesQuery = { __typename?: 'Query', getLeadEmailAddresses: Array<{ __typename?: 'PostLeadEmailAddressOutput', id: number, email: string, campaignId: number, status: string, code: string }> };

export type DeleteLeadEmailAddressMutationVariables = Exact<{
  campaignId: Scalars['Int'];
  emailId: Scalars['Int'];
}>;


export type DeleteLeadEmailAddressMutation = { __typename?: 'Mutation', deleteLeadEmailAddress: boolean };

export type VerifyLeadEmailAddressMutationVariables = Exact<{
  code: Scalars['String'];
}>;


export type VerifyLeadEmailAddressMutation = { __typename?: 'Mutation', verifyLeadEmailAddress: boolean };

export type LeadsQueryVariables = Exact<{
  filter: LeadsInput;
  take?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
}>;


export type LeadsQuery = { __typename?: 'Query', leads: { __typename?: 'LeadResponse', total: number, columns: Array<string>, items: Array<{ __typename?: 'Lead', id: string, campaignId: number, campaignName: string, date: string, country: string, countryCode: string, region: string, regionCode: string, city: string, ip: string, latitude: string, longitude: string, timezone: string, postalCode: string, url: string, page: string, host: string, userAgent: string, device: string, resolution: string, os: string, browser: string, language: string, referrer: string, meta: string, formData: string, unsubscribed?: boolean | null }> } };

export type DeleteLeadMutationVariables = Exact<{
  ids: Array<Scalars['String']> | Scalars['String'];
}>;


export type DeleteLeadMutation = { __typename?: 'Mutation', deleteLead: { __typename?: 'DeleteResponse', status: boolean } };

export type ExportLeadsMutationVariables = Exact<{
  filter: LeadsInput;
}>;


export type ExportLeadsMutation = { __typename?: 'Mutation', exportLeads: boolean };

export type LeadsPageQueryVariables = Exact<{ [key: string]: never; }>;


export type LeadsPageQuery = { __typename?: 'Query', campaigns: { __typename?: 'PaginatedCampaignResponse', items: Array<{ __typename?: 'Campaign', id: number, name: string, variantInfo?: { __typename?: 'VariantInfo', abTestingId: number, variant: { __typename?: 'Variant', name: string } } | null }> } };

export type LineChartAnalyticsQueryVariables = Exact<{
  filter: AnalyticsFilterInput;
  range: LineChartRange;
}>;


export type LineChartAnalyticsQuery = { __typename?: 'Query', lineChartAnalytics: { __typename?: 'LineChartAnalytics', display: Array<{ __typename?: 'LineData', date: DateTime, value: number }>, lead: Array<{ __typename?: 'LineData', date: DateTime, value: number }>, interaction: Array<{ __typename?: 'LineData', date: DateTime, value: number }>, conversionRate: Array<{ __typename?: 'LineData', date: DateTime, value: number }>, pageView: Array<{ __typename?: 'LineData', date: DateTime, value: number }> } };

export type SupportedLanguagesQueryVariables = Exact<{ [key: string]: never; }>;


export type SupportedLanguagesQuery = { __typename?: 'Query', supportedLanguages: Array<string> };

export type GetOverviewPageAnalyticsDataQueryVariables = Exact<{
  input: GetOverviewAnalyticsDataInput;
}>;


export type GetOverviewPageAnalyticsDataQuery = { __typename?: 'Query', overviewPageAnalyticsData: Array<{ __typename?: 'OverviewAnalyticsData', id: string, increasePercentage: string, total: string, startDate: DateTime, endDate: DateTime, chartData: { __typename?: 'OverviewChartData', values: Array<number>, increaseValues: Array<string>, labels: Array<string>, dates: Array<DateTime> } }> };

export type PageAnalyticsQueryVariables = Exact<{
  filter: AnalyticsFilterInput;
}>;


export type PageAnalyticsQuery = { __typename?: 'Query', pageAnalytics: Array<{ __typename?: 'PageAnalytics', page: string, display?: number | null, interaction?: number | null, lead?: number | null }> };

export type ReferrerAnalyticsQueryVariables = Exact<{
  filter: AnalyticsFilterInput;
}>;


export type ReferrerAnalyticsQuery = { __typename?: 'Query', referrerAnalytics: Array<{ __typename?: 'ReferrerAnalytics', referrer: string, display?: number | null, interaction?: number | null, lead?: number | null }> };

export type RegionAnalyticsQueryVariables = Exact<{
  filter: AnalyticsFilterInput;
}>;


export type RegionAnalyticsQuery = { __typename?: 'Query', regionAnalytics: Array<{ __typename?: 'RegionAnalytics', country: string, region: string, city: string, display?: number | null, interaction?: number | null, lead?: number | null }> };

export type GetAccountRevenueAnalyticDataQueryVariables = Exact<{
  input: GetAccountRevenueAnalyticDataInput;
}>;


export type GetAccountRevenueAnalyticDataQuery = { __typename?: 'Query', getAccountRevenueAnalyticData: { __typename?: 'RevenueAnalyticData', increasePercentage: string, total: string, startDate: DateTime, endDate: DateTime, chartData: { __typename?: 'RevenueChartData', values: Array<number>, increaseValues: Array<string>, labels: Array<string>, dates: Array<DateTime> } } };

export type GetSegmentQueryVariables = Exact<{
  getSegmentId: Scalars['Int'];
}>;


export type GetSegmentQuery = { __typename?: 'Query', getSegment: { __typename?: 'SegmentResponse', id: number, name: string, description: string, icon: string, color: string, isPredefined: boolean, segmentCategoryId: number, segmentCategoryName: string, segmentCategoryKey?: string | null, createdAt: string, targets?: string | null, isDeletable: boolean, key?: string | null, campaigns: Array<{ __typename?: 'CampaignForSegment', id: number, name: string }> } };

export type GetSegmentsQueryVariables = Exact<{
  campaignId: Scalars['Int'];
}>;


export type GetSegmentsQuery = { __typename?: 'Query', getSegments: Array<{ __typename?: 'SegmentResponse', id: number, name: string, description: string, icon: string, color: string, isPredefined: boolean, segmentCategoryId: number, segmentCategoryName: string, segmentCategoryKey?: string | null, createdAt: string, targets?: string | null, isDeletable: boolean, key?: string | null, campaigns: Array<{ __typename?: 'CampaignForSegment', id: number, name: string }> }> };

export type CreateSegmentMutationVariables = Exact<{
  segment: CreateSegment;
}>;


export type CreateSegmentMutation = { __typename?: 'Mutation', createSegment: { __typename?: 'SegmentResponse', id: number, name: string, description: string, icon: string, color: string, isPredefined: boolean, segmentCategoryId: number, segmentCategoryName: string, segmentCategoryKey?: string | null, createdAt: string, targets?: string | null, isDeletable: boolean, key?: string | null, campaigns: Array<{ __typename?: 'CampaignForSegment', id: number, name: string }> } };

export type UpdateSegmentMutationVariables = Exact<{
  segment: CreateSegment;
  updateSegmentId: Scalars['Int'];
}>;


export type UpdateSegmentMutation = { __typename?: 'Mutation', updateSegment: { __typename?: 'SegmentResponse', id: number, name: string, description: string, icon: string, color: string, isPredefined: boolean, segmentCategoryId: number, segmentCategoryName: string, segmentCategoryKey?: string | null, createdAt: string, targets?: string | null, isDeletable: boolean, key?: string | null, campaigns: Array<{ __typename?: 'CampaignForSegment', id: number, name: string }> } };

export type ChangeSegmentNameMutationVariables = Exact<{
  description: Scalars['String'];
  name: Scalars['String'];
  changeSegmentNameId: Scalars['Int'];
}>;


export type ChangeSegmentNameMutation = { __typename?: 'Mutation', changeSegmentName: { __typename?: 'SegmentResponse', id: number, name: string, description: string, icon: string, color: string, isPredefined: boolean, segmentCategoryId: number, segmentCategoryName: string, segmentCategoryKey?: string | null, createdAt: string, targets?: string | null, isDeletable: boolean, key?: string | null, campaigns: Array<{ __typename?: 'CampaignForSegment', id: number, name: string }> } };

export type DeleteSegmentMutationVariables = Exact<{
  deleteSegmentId: Scalars['Int'];
}>;


export type DeleteSegmentMutation = { __typename?: 'Mutation', deleteSegment: boolean };

export type ShopifyLoginOrRegisterMutationVariables = Exact<{
  code: Scalars['String'];
  shop: Scalars['String'];
  hmac: Scalars['String'];
  host: Scalars['String'];
  timestamp: Scalars['String'];
}>;


export type ShopifyLoginOrRegisterMutation = { __typename?: 'Mutation', shopifyLoginOrRegister: { __typename?: 'ShopifyLoginOrRegisterResponse', accessToken: string, refreshToken: string, isNewAccount: boolean, addedNewDomain: boolean, addedDomain: string } };

export type LoginWithShopifyMutationVariables = Exact<{
  code: Scalars['String'];
  shop: Scalars['String'];
  hmac: Scalars['String'];
  host: Scalars['String'];
  timestamp: Scalars['String'];
}>;


export type LoginWithShopifyMutation = { __typename?: 'Mutation', loginWithShopify: { __typename?: 'LoginWithShopifyResponse', accessToken: string, refreshToken: string } };

export type ShopifyLinkAccountMutationVariables = Exact<{
  code: Scalars['String'];
  shop: Scalars['String'];
  hmac: Scalars['String'];
  host: Scalars['String'];
  timestamp: Scalars['String'];
  isRegisterPage?: InputMaybe<Scalars['Boolean']>;
}>;


export type ShopifyLinkAccountMutation = { __typename?: 'Mutation', shopifyLinkAccount: { __typename?: 'ShopifyLinkAccountResponse', status: boolean, addedNewDomain: boolean, addedDomain: string } };

export type CreateShopifySubscriptionMutationVariables = Exact<{
  planId: Scalars['Int'];
}>;


export type CreateShopifySubscriptionMutation = { __typename?: 'Mutation', createShopifySubscription: string };

export type CreateOneTimePurchaseMutationVariables = Exact<{
  planId: Scalars['Int'];
}>;


export type CreateOneTimePurchaseMutation = { __typename?: 'Mutation', createOneTimePurchase: string };

export type ShopifyAppExtensionStatusMutationVariables = Exact<{
  domain: Scalars['String'];
}>;


export type ShopifyAppExtensionStatusMutation = { __typename?: 'Mutation', shopifyAppExtensionStatus: { __typename?: 'ShopifyAppExtensionStatusResponse', status: boolean, redirectUrl: string } };

export type CreateCustomerMutationVariables = Exact<{
  accountId: Scalars['Int'];
  storeDomain: Scalars['String'];
  customTags: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phone: Scalars['String'];
  tags: Scalars['String'];
  acceptEmailMarketing: Scalars['Boolean'];
  acceptSmsMarketing: Scalars['Boolean'];
}>;


export type CreateCustomerMutation = { __typename?: 'Mutation', createCustomer: { __typename?: 'CustomerCreateResponse', customer?: { __typename?: 'CreateCustomerType', email?: string | null, firstName?: string | null, lastName?: string | null, phone?: string | null, tags?: Array<string> | null } | null, userErrors?: Array<{ __typename?: 'UserErrorsType', field?: Array<string> | null, message?: string | null }> | null } };

export type GetUrlByShopifyDomainQueryVariables = Exact<{
  domain: Scalars['String'];
}>;


export type GetUrlByShopifyDomainQuery = { __typename?: 'Query', getUrlByDomain: { __typename?: 'GetUrlByDomainResponse', url?: string | null } };

export type ShortAnalyticsQueryVariables = Exact<{
  filter: AnalyticsFilterInput;
}>;


export type ShortAnalyticsQuery = { __typename?: 'Query', shortAnalytics: { __typename?: 'ShortAnalytics', display?: number | null, previousDisplay?: number | null, interaction?: number | null, previousInteraction?: number | null, lead?: number | null, previousLead?: number | null, pageView?: number | null, previousPageView?: number | null } };

export type CreatePortalLinkQueryVariables = Exact<{
  paymentMode?: InputMaybe<Scalars['String']>;
}>;


export type CreatePortalLinkQuery = { __typename?: 'Query', createPortalLink: { __typename?: 'CreatePortalLinkOutput', url: string } };

export type CreateSessionIdQueryVariables = Exact<{
  priceId: Scalars['String'];
  paymentMode?: InputMaybe<Scalars['String']>;
}>;


export type CreateSessionIdQuery = { __typename?: 'Query', createSessionId: { __typename?: 'CreateSessionIdOutput', url: string } };

export type TemplatesQueryVariables = Exact<{
  templatesInput: TemplatesInput;
}>;


export type TemplatesQuery = { __typename?: 'Query', templates: { __typename?: 'TemplatesResponse', total: number, layoutElements: Array<string>, data: Array<{ __typename?: 'TemplateBase', id: number, description?: string | null, deviceType?: number | null, imageUrl?: string | null, isAnimated?: boolean | null, isMultipage?: boolean | null, sort?: number | null, layoutType?: number | null, popupType?: number | null, name?: string | null, title?: string | null, goals: Array<number>, tags: Array<number>, subTags: Array<string>, layoutElements: Array<string> }> } };

export type TemplateByIdQueryVariables = Exact<{
  id: Scalars['Float'];
}>;


export type TemplateByIdQuery = { __typename?: 'Query', templateById: { __typename?: 'TemplateDetailResponse', id: number, name?: string | null, imageUrl?: string | null, title?: string | null, description?: string | null, layoutType?: number | null, popupType?: number | null, goals: Array<number>, sort?: number | null, deviceType?: number | null, isMultipage?: boolean | null, isAnimated?: boolean | null, tags: Array<number>, subTags: Array<string>, targets: string, layout: string, layoutElements: Array<string> } };

export type TemplateTagsQueryVariables = Exact<{ [key: string]: never; }>;


export type TemplateTagsQuery = { __typename?: 'Query', templateTags: Array<{ __typename?: 'TagItem', id: number, tag: string, icon: string, type: string, count: number, seasonsCounts: Array<{ __typename?: 'SeasonCount', count?: number | null, name?: string | null }>, subRelations?: { __typename?: 'SubRelation', subTags?: Array<{ __typename?: 'SubTagItem', name?: string | null, count?: number | null, seasonsCounts?: Array<{ __typename?: 'SeasonCount', count?: number | null, name?: string | null }> | null }> | null } | null }> };

export type TemplateSeasonsQueryVariables = Exact<{ [key: string]: never; }>;


export type TemplateSeasonsQuery = { __typename?: 'Query', templateSeasons: Array<{ __typename?: 'SeasonItem', id: number, name: string }> };

export type GetEmailLeadFromRefMutationVariables = Exact<{
  ref: Scalars['String'];
}>;


export type GetEmailLeadFromRefMutation = { __typename?: 'Mutation', getEmailLeadFromRef: { __typename?: 'GetEmailLeadFromRefResponse', status: string, message: string, data: { __typename?: 'GetEmailLeadFromRef', website: string, email: string, subscribe: boolean, emailAutomationId: number } } };

export type UnsubscribeToWebsiteMutationVariables = Exact<{
  ref: Scalars['String'];
  email: Scalars['String'];
  emailAutomationId: Scalars['Float'];
}>;


export type UnsubscribeToWebsiteMutation = { __typename?: 'Mutation', unsubscribeToWebsite: { __typename?: 'UnsubscribeToWebsiteResponse', status: string, message: string, data?: boolean | null } };

export type ResubscribeToWebsiteMutationVariables = Exact<{
  ref: Scalars['String'];
  email: Scalars['String'];
  emailAutomationId: Scalars['Float'];
}>;


export type ResubscribeToWebsiteMutation = { __typename?: 'Mutation', resubscribeToWebsite: { __typename?: 'ResubscribeToWebsiteResponse', status: string, message: string, data?: boolean | null } };

export type WeeklyStatsDetailsFromRefMutationVariables = Exact<{
  ref: Scalars['String'];
}>;


export type WeeklyStatsDetailsFromRefMutation = { __typename?: 'Mutation', weeklyStatsDetailsFromRef: { __typename?: 'WeeklyStatsDetailsFromRef', status: boolean, email: string } };

export type ChangeWeeklyStatsSubscriptionStatusMutationVariables = Exact<{
  ref: Scalars['String'];
}>;


export type ChangeWeeklyStatsSubscriptionStatusMutation = { __typename?: 'Mutation', changeWeeklyStatsSubscriptionStatus: boolean };

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = { __typename?: 'Query', me: { __typename?: 'User', id: number, name?: string | null, surname?: string | null, accountId?: string | null, accountIsGettingStartedCompleted: boolean, accountUseProxiedFonts: boolean, apiToken?: string | null, email?: string | null, emailVerify: boolean, phone_number: string, isOwner: boolean, isShopifyUser: boolean, isPageViewBlocked: boolean, isMigrated: boolean, paymentProvider: string, baseShopifyStore: string, isShopify: boolean, userHasShopifyWebsite: boolean, systemLanguage?: string | null, isAgency: boolean, isSubWorkspace: boolean, accountName?: string | null, logoUrl?: string | null, accountPlan: { __typename?: 'AccountPlan', description?: string | null, displayCount: number, domainCount: number, id: number, impressionCount: number, interactionCount: number, name?: string | null, popupCount: number, price: number, stripeId?: string | null, type?: string | null, activePopupCount: number } } };

export type ChangeEmailMutationVariables = Exact<{
  email: Scalars['String'];
  captcha: Scalars['String'];
}>;


export type ChangeEmailMutation = { __typename?: 'Mutation', changeEmail: { __typename?: 'TokenResponse', accessToken: string, refreshToken: string } };

export type InvitedUserRegisterMutationVariables = Exact<{
  code: Scalars['String'];
  password: Scalars['String'];
  domain?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
}>;


export type InvitedUserRegisterMutation = { __typename?: 'Mutation', invitedUserRegister: { __typename?: 'InvitedUserRegisterOutput', refreshToken: string, token: string, tokenExpireDate: string } };

export type UpdateUserProfileMutationVariables = Exact<{
  name: Scalars['String'];
  surname: Scalars['String'];
  phone_number: Scalars['String'];
  systemLanguage: Scalars['String'];
}>;


export type UpdateUserProfileMutation = { __typename?: 'Mutation', updateUserProfile: boolean };

export type ChangePasswordMutationVariables = Exact<{
  oldPassword: Scalars['String'];
  newPassword: Scalars['String'];
}>;


export type ChangePasswordMutation = { __typename?: 'Mutation', changePassword: boolean };

export type AccountStatsQueryVariables = Exact<{ [key: string]: never; }>;


export type AccountStatsQuery = { __typename?: 'Query', accountStats: { __typename?: 'AccountStats', pageView: number, planPageView: number, email: number, planEmail: number, autoExtendStatus?: boolean | null, autoExtendPageViewStatus?: boolean | null, autoExtendEmailStatus?: boolean | null, usedPageView: number, pageViewLimitExtendTotal: number, usedEmail: number, emailLimitExtendTotal: number, pageViewLimitExtendPrice: number, emailLimitExtendPrice: number, respondentEmailCount: number, selfEmailCount: number, isBlocked: boolean } };

export type ChangeAccountUserRolesMutationVariables = Exact<{
  campaignIds: Array<Scalars['Float']> | Scalars['Float'];
  roleId: Scalars['Float'];
  accountUserId: Scalars['Float'];
  canCreateCampaign: Scalars['Boolean'];
}>;


export type ChangeAccountUserRolesMutation = { __typename?: 'Mutation', changeAccountUserRoles: boolean };

export type DeleteAccountMutationVariables = Exact<{
  email: Scalars['String'];
  survey: Scalars['String'];
}>;


export type DeleteAccountMutation = { __typename?: 'Mutation', deleteAccount: boolean };

export type AccountsQueryVariables = Exact<{ [key: string]: never; }>;


export type AccountsQuery = { __typename?: 'Query', userAccounts: Array<{ __typename?: 'Accounts', id: number, isOwner: boolean, name: string, parentId?: number | null, isAgency: boolean }> };

export type UpdateAutoLimitExtendMutationVariables = Exact<{
  emailExtend: Scalars['Boolean'];
  pageViewExtend: Scalars['Boolean'];
  limitExtend: Scalars['Boolean'];
}>;


export type UpdateAutoLimitExtendMutation = { __typename?: 'Mutation', updateAutoLimitExtend: { __typename?: 'UpdateAutoLimitExtendResponse', status: boolean, invoiceUrl?: string | null, error?: string | null } };

export type GetLimitExtendUnpaidInvoiceQueryVariables = Exact<{ [key: string]: never; }>;


export type GetLimitExtendUnpaidInvoiceQuery = { __typename?: 'Query', getLimitExtendUnpaidInvoice: { __typename?: 'UpdateAutoLimitExtendResponse', status: boolean, invoiceUrl?: string | null, error?: string | null } };

export type GetAccountsAccountUserQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAccountsAccountUserQuery = { __typename?: 'Query', getAccountsAccountUser: Array<{ __typename?: 'AccountUsersResponse', id: number, email?: string | null, status?: boolean | null, canCreateCampaign: boolean, role?: { __typename?: 'AccountUsersRoleResponse', roleTypeId: number, campaigns: Array<number> } | null }> };

export type ChangeAccountUserStatusMutationVariables = Exact<{
  accountUserId: Scalars['Float'];
}>;


export type ChangeAccountUserStatusMutation = { __typename?: 'Mutation', changeAccountUserStatus: boolean };

export type DeleteTeammateMutationVariables = Exact<{
  accountUserId: Scalars['Float'];
}>;


export type DeleteTeammateMutation = { __typename?: 'Mutation', deleteTeammate: boolean };

export type CampaignListAnalyticsQueryVariables = Exact<{
  campaignIds: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type CampaignListAnalyticsQuery = { __typename?: 'Query', campaignDailyAnalytics: Array<{ __typename?: 'CampaignDailyAnalytics', campaignId: number, display: number, interaction: number, lead: number, abTestingId?: number | null }> };

export type CampaignNameListQueryVariables = Exact<{ [key: string]: never; }>;


export type CampaignNameListQuery = { __typename?: 'Query', campaigns: { __typename?: 'PaginatedCampaignResponse', total: number, items: Array<{ __typename?: 'Campaign', id: number, name: string, isActive: boolean, publishVersion: number, isTemplateSelected: boolean, createdAt: string, variantInfo?: { __typename?: 'VariantInfo', abTestingId: number } | null, domains: Array<{ __typename?: 'Domain', id: number, url: string, verified: boolean, cms?: string | null }> }> } };

export type CampaignsQueryVariables = Exact<{
  filter?: InputMaybe<CampaignFilterInput>;
}>;


export type CampaignsQuery = { __typename?: 'Query', me: { __typename?: 'User', isPageViewBlocked: boolean }, campaigns: { __typename?: 'PaginatedCampaignResponse', hasFullAccess: boolean, canCreateCampaign: boolean, total: number, items: Array<{ __typename?: 'Campaign', id: number, name: string, isActive: boolean, publishVersion: number, isTemplateSelected: boolean, createdAt: string, conversionRate: number, folderId?: number | null, domains: Array<{ __typename?: 'Domain', id: number, url: string, verified: boolean, cms?: string | null }>, variantInfo?: { __typename?: 'VariantInfo', variantId?: number | null, abTestingId: number, variant: { __typename?: 'Variant', name: string, percentage: number } } | null }> } };

export type CampaignCreateWithTemplateIdMutationVariables = Exact<{
  input: CampaignCreateWithTemplateIdRequest;
}>;


export type CampaignCreateWithTemplateIdMutation = { __typename?: 'Mutation', campaignCreateWithTemplateId: { __typename?: 'CreateCampaignResponse', id: number, name: string } };

export type CampaignCreateMutationVariables = Exact<{
  input: CampaignCreateRequest;
}>;


export type CampaignCreateMutation = { __typename?: 'Mutation', campaignCreate: { __typename?: 'CreateCampaignResponse', id: number, name: string } };

export type CampaignChangeStatusMutationVariables = Exact<{
  campaignId: Scalars['Int'];
  status: Scalars['Boolean'];
}>;


export type CampaignChangeStatusMutation = { __typename?: 'Mutation', campaignChangeStatus: { __typename?: 'Campaign', id: number, name: string, isActive: boolean, publishVersion: number, isTemplateSelected: boolean, createdAt: string, variantInfo?: { __typename?: 'VariantInfo', variantId?: number | null, abTestingId: number, variant: { __typename?: 'Variant', name: string, percentage: number } } | null } };

export type CampaignPublishMutationVariables = Exact<{
  campaignId: Scalars['Int'];
}>;


export type CampaignPublishMutation = { __typename?: 'Mutation', campaignPublish: { __typename?: 'PublishResponse', success: boolean, message: string, code: string } };

export type AddDomainRelationMutationVariables = Exact<{
  campaignId: Scalars['Int'];
  domainId: Scalars['Int'];
}>;


export type AddDomainRelationMutation = { __typename?: 'Mutation', addDomainRelation: { __typename?: 'Domain', id: number, url: string, verified: boolean, cms?: string | null, isShopify: boolean } };

export type DeleteDomainRelationMutationVariables = Exact<{
  campaignId: Scalars['Int'];
  domainId: Scalars['Int'];
}>;


export type DeleteDomainRelationMutation = { __typename?: 'Mutation', deleteDomainRelation: boolean };

export type GetCampaignCountsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCampaignCountsQuery = { __typename?: 'Query', getCampaignCounts: { __typename?: 'GetCampaignCountsResponse', total: number, active: number, passive: number } };

export type CampaignsWithDomainNamesQueryVariables = Exact<{ [key: string]: never; }>;


export type CampaignsWithDomainNamesQuery = { __typename?: 'Query', campaignsWithDomainNames: Array<{ __typename?: 'CampaignWithDomainsResponse', id: number, domains: Array<string>, name?: string | null, variantInfo?: { __typename?: 'CampaignWithDomainsResponseVariantInfo', abTestingId: number, variant: { __typename?: 'CampaignWithDomainsResponseVariant', name: string, percentage: number } } | null }> };

export type CampaignQueryVariables = Exact<{
  campaignId: Scalars['Int'];
}>;


export type CampaignQuery = { __typename?: 'Query', campaign: { __typename?: 'CampaignGetWithIntegrations', id: number, name?: string | null, status?: boolean | null, publishVersion?: number | null, autoResponderStatus: boolean, autoResponderReplyTo?: string | null, autoResponderSenderName?: string | null, autoResponderSubject?: string | null, autoResponderMailBody?: string | null, targets: string, layout: string, settings: string, integrationActiveCampaignCampaigns: string, integrationCustomerIoCampaigns: string, integrationDripCampaigns: string, integrationEmailOctopusCampaigns: string, integrationHubSpotCampaigns: string, integrationKlaviyoCampaigns: string, integrationMailchimpCampaigns: string, integrationMailerliteCampaigns: string, integrationSendLoopCampaigns: string, integrationSendgridCampaigns: string, integrationSendinBlueCampaigns: string, integrationWebhooks: string, createdAt?: string | null, variantInfo?: string | null, domains: Array<{ __typename?: 'DomainResponse', id: number, url?: string | null, verified: boolean, cMS?: string | null, isShopify: boolean }> } };

export type ChangeCampaignNameMutationVariables = Exact<{
  name: Scalars['String'];
  campaignId: Scalars['Int'];
}>;


export type ChangeCampaignNameMutation = { __typename?: 'Mutation', changeCampaignName: { __typename?: 'CreateCampaignResponse', id: number, name: string } };

export type DuplicateCampaignMutationVariables = Exact<{
  campaignId: Scalars['Int'];
}>;


export type DuplicateCampaignMutation = { __typename?: 'Mutation', duplicateCampaign: { __typename?: 'CreateCampaignResponse', id: number, name: string } };

export type DeleteCampaignMutationVariables = Exact<{
  campaignId: Scalars['Int'];
}>;


export type DeleteCampaignMutation = { __typename?: 'Mutation', deleteCampaign: boolean };

export type GenerateAiPopupMutationVariables = Exact<{
  input: GenerateAiPopupInput;
}>;


export type GenerateAiPopupMutation = { __typename?: 'Mutation', generateAiPopup: { __typename?: 'GenerateAiPopupResponse', id?: number | null, name?: string | null, success: boolean, error?: string | null } };

export type UpdateCampaignTemplateWithAiMutationVariables = Exact<{
  input: UpdateCampaignWithAiInput;
}>;


export type UpdateCampaignTemplateWithAiMutation = { __typename?: 'Mutation', updateCampaignTemplateWithAi: { __typename?: 'GenerateAiPopupResponse', id?: number | null, name?: string | null, success: boolean, error?: string | null, prompt?: string | null } };

export type GenerateAiTextSuggestionsMutationVariables = Exact<{
  input: GenerateAiTextSuggestionsInput;
}>;


export type GenerateAiTextSuggestionsMutation = { __typename?: 'Mutation', generateAITextSuggestions: { __typename?: 'GenerateAITextSuggestionsResponse', success: boolean, error?: string | null, suggestions?: Array<{ __typename?: 'TextSuggestion', text: string }> | null } };

export type GetEmailAutomationAnalyticsQueryVariables = Exact<{
  filter: EmailAutomationAnalyticsInput;
}>;


export type GetEmailAutomationAnalyticsQuery = { __typename?: 'Query', getEmailAutomationAnalytics: { __typename?: 'EmailAutomationAnalyticsResponse', sent: { __typename?: 'AnalyticsData', count: number, changeToLastPeriod: number }, delivered: { __typename?: 'AnalyticsData', count: number, rate?: number | null, changeToLastPeriod: number }, opened: { __typename?: 'AnalyticsData', count: number, rate?: number | null, changeToLastPeriod: number }, clicked: { __typename?: 'AnalyticsData', count: number, rate?: number | null, changeToLastPeriod: number }, unsubscribed: { __typename?: 'AnalyticsData', count: number, rate?: number | null, changeToLastPeriod: number }, bounced: { __typename?: 'AnalyticsBounceData', count: number, rate?: number | null, changeToLastPeriod: number, invalid: { __typename?: 'AnalyticsData', count: number, changeToLastPeriod: number } } } };

export type GetEmailAutomationLineChartAnalyticsQueryVariables = Exact<{
  filter: EmailAutomationAnalyticsInput;
  range: EmailAutomationLineChartRange;
}>;


export type GetEmailAutomationLineChartAnalyticsQuery = { __typename?: 'Query', getEmailAutomationLineChartAnalytics: { __typename?: 'EmailAutomationLineChartAnalytics', sent: Array<{ __typename?: 'EmailAutomationLineData', date: string, value: number }>, delivered: Array<{ __typename?: 'EmailAutomationLineData', date: string, value: number }>, opened: Array<{ __typename?: 'EmailAutomationLineData', date: string, value: number }>, clicked: Array<{ __typename?: 'EmailAutomationLineData', date: string, value: number }> } };

export type GetEmailAutomationAnalyticsByIdsQueryVariables = Exact<{
  ids: Array<Scalars['Float']> | Scalars['Float'];
  endDate?: InputMaybe<Scalars['DateTime']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
}>;


export type GetEmailAutomationAnalyticsByIdsQuery = { __typename?: 'Query', emailAutomationAnalyticsByIds: { __typename?: 'EmailAutomationAnalyticsByIdsResponse', items: Array<{ __typename?: 'CampaignAnalytics', id: number, details: { __typename?: 'CampaignAnalyticsDetail', sent: number, delivered: number, openedRate: number, clickedRate: number } }> } };

export type GetEmailAutomationsQueryVariables = Exact<{
  filter: AutomationsFilter;
}>;


export type GetEmailAutomationsQuery = { __typename?: 'Query', emailAutomations: { __typename?: 'EmailAutomationResponse', total: number, items: Array<{ __typename?: 'EmailAutomation', id: number, name: string, accountId: number, flowId: number, status: boolean, campaignId?: number | null, store?: { __typename?: 'ShopifyStore', domain?: string | null } | null, Flow?: { __typename?: 'Flow', id: number, name: string, description?: string | null, icon?: string | null, accountId: number, isTemplate: boolean, triggerId?: number | null, type: FlowType, Trigger?: { __typename?: 'Trigger', id: number, name: string, type: FlowType, description?: string | null, code: string } | null } | null }> } };

export type FindEmailAutomationByCampaignIdQueryVariables = Exact<{
  campaignId: Scalars['Float'];
}>;


export type FindEmailAutomationByCampaignIdQuery = { __typename?: 'Query', findEmailAutomationByCampaignId: { __typename?: 'EmailAutomationByCampaignResponse', message?: string | null, success: boolean, emailAutomation?: { __typename?: 'EmailAutomation', id: number, name: string, accountId: number, flowId: number, status: boolean, campaignId?: number | null, store?: { __typename?: 'ShopifyStore', domain?: string | null } | null, Campaign?: { __typename?: 'CampaignType', Id: number, Name: string } | null, Flow?: { __typename?: 'Flow', id: number, name: string, description?: string | null, icon?: string | null, accountId: number, isTemplate: boolean, triggerId?: number | null, type: FlowType, steps?: string | null, Trigger?: { __typename?: 'Trigger', id: number, name: string, type: FlowType, description?: string | null, code: string } | null } | null } | null } };

export type GetEmailAutomationsWithoutCampaignQueryVariables = Exact<{ [key: string]: never; }>;


export type GetEmailAutomationsWithoutCampaignQuery = { __typename?: 'Query', emailAutomationsWithoutCampaign: Array<{ __typename?: 'EmailAutomation', id: number, name: string }> };

export type GetEmailAutomationQueryVariables = Exact<{
  id: Scalars['Float'];
}>;


export type GetEmailAutomationQuery = { __typename?: 'Query', emailAutomation: { __typename?: 'EmailAutomation', id: number, name: string, accountId: number, flowId: number, campaignId?: number | null, status: boolean, store?: { __typename?: 'ShopifyStore', domain?: string | null } | null, Account?: { __typename?: 'Account', Name: string } | null, Flow?: { __typename?: 'Flow', id: number, name: string, description?: string | null, icon?: string | null, accountId: number, steps?: string | null, frequencyStatus: boolean, isTemplate: boolean, type: FlowType, triggerId?: number | null, emailTemplateId?: number | null, isPlainText?: boolean | null, plainTextBody?: string | null, Trigger?: { __typename?: 'Trigger', id: number, name: string, type: FlowType, description?: string | null, icon?: string | null, code: string } | null, EmailTemplate?: { __typename?: 'EmailTemplate', id: number, name: string, url?: string | null, imageUrl?: string | null } | null } | null } };

export type CreateEmailAutomationMutationVariables = Exact<{
  input: CreateEmailAutomationInput;
}>;


export type CreateEmailAutomationMutation = { __typename?: 'Mutation', createEmailAutomation: { __typename?: 'EmailAutomation', id: number, name: string, accountId: number, flowId: number, campaignId?: number | null, store?: { __typename?: 'ShopifyStore', domain?: string | null } | null } };

export type UpdateEmailAutomationNameMutationVariables = Exact<{
  id: Scalars['Float'];
  name: Scalars['String'];
}>;


export type UpdateEmailAutomationNameMutation = { __typename?: 'Mutation', updateEmailAutomationName: { __typename?: 'EmailAutomation', id: number, name: string } };

export type DeleteEmailAutomationMutationVariables = Exact<{
  id: Scalars['Float'];
}>;


export type DeleteEmailAutomationMutation = { __typename?: 'Mutation', deleteEmailAutomation: { __typename?: 'EmailAutomation', id: number } };

export type GetEmailFlowTemplatesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetEmailFlowTemplatesQuery = { __typename?: 'Query', emailFlowTemplates: Array<{ __typename?: 'Flow', id: number, name: string, description?: string | null, icon?: string | null, color?: string | null, frequencyStatus: boolean, accountId: number, type: FlowType, isTemplate: boolean, triggerId?: number | null }> };

export type GetTriggersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTriggersQuery = { __typename?: 'Query', triggers: { __typename?: 'TriggerResponse', total: number, items: Array<{ __typename?: 'Trigger', id: number, name: string, description?: string | null, icon?: string | null, type: FlowType, code: string }> } };

export type RelatedCampaignQueryVariables = Exact<{
  automationId: Scalars['Float'];
}>;


export type RelatedCampaignQuery = { __typename?: 'Query', relatedCampaign?: { __typename?: 'RelatedCampaign', id: number, name: string, isActive: boolean, publishVersion: number, isTemplateSelected: boolean, haveForm: boolean, formInputs: Array<{ __typename?: 'FormInput', label: string, value: string, nodeName: string }>, domains: Array<{ __typename?: 'Domain', verified: boolean, url: string, isShopify: boolean, id: number, cms?: string | null }> } | null };

export type SendTestEmailMutationVariables = Exact<{
  emails: Array<Scalars['String']> | Scalars['String'];
  id: Scalars['Float'];
  subject?: InputMaybe<Scalars['String']>;
  sender?: InputMaybe<Scalars['String']>;
}>;


export type SendTestEmailMutation = { __typename?: 'Mutation', sendTestEmail: { __typename?: 'SendTestEmailResponse', message?: string | null, success?: boolean | null } };

export type CreateFirstEmailAutomationMutationVariables = Exact<{
  input: CreateFirstEmailAutomationInput;
}>;


export type CreateFirstEmailAutomationMutation = { __typename?: 'Mutation', createFirstEmailAutomation: { __typename?: 'EmailAutomation', id: number, name: string, accountId: number, flowId: number, campaignId?: number | null, store?: { __typename?: 'ShopifyStore', domain?: string | null } | null } };

export type UpdateFlowTriggerMutationVariables = Exact<{
  id: Scalars['Float'];
  triggerId: Scalars['Float'];
  frequencyStatus: Scalars['Boolean'];
  steps: Scalars['String'];
  isPlainText: Scalars['Boolean'];
  plainTextBody: Scalars['String'];
}>;


export type UpdateFlowTriggerMutation = { __typename?: 'Mutation', updateFlowTrigger: { __typename?: 'Flow', id: number, name: string, description?: string | null, icon?: string | null, accountId: number, steps?: string | null, isTemplate: boolean, frequencyStatus: boolean, isPlainText?: boolean | null, plainTextBody?: string | null, emailTemplateId?: number | null, type: FlowType, triggerId?: number | null, EmailTemplate?: { __typename?: 'EmailTemplate', id: number, name: string, url?: string | null, imageUrl?: string | null } | null, Trigger?: { __typename?: 'Trigger', id: number, name: string, description?: string | null, icon?: string | null, type: FlowType, code: string } | null } };

export type PublishEmailAutomationMutationVariables = Exact<{
  id: Scalars['Float'];
  status: Scalars['Boolean'];
}>;


export type PublishEmailAutomationMutation = { __typename?: 'Mutation', publishEmailAutomation: { __typename?: 'EmailAutomation', id: number, status: boolean } };

export type ConnectCampaignMutationVariables = Exact<{
  id: Scalars['Float'];
  campaignId?: InputMaybe<Scalars['Int']>;
}>;


export type ConnectCampaignMutation = { __typename?: 'Mutation', connectCampaign: { __typename?: 'EmailAutomation', id: number, campaignId?: number | null } };

export type UpdateDomainMutationVariables = Exact<{
  id: Scalars['Float'];
  domain: Scalars['String'];
}>;


export type UpdateDomainMutation = { __typename?: 'Mutation', updateDomain: { __typename?: 'EmailAutomation', id: number, store?: { __typename?: 'ShopifyStore', domain?: string | null } | null } };

export type CampaignsWithoutEmailAutomationQueryVariables = Exact<{
  filter: AutomationsFilter;
}>;


export type CampaignsWithoutEmailAutomationQuery = { __typename?: 'Query', campaignsWithoutEmailAutomation: Array<{ __typename?: 'CampaignsWithoutEmailAutomation', name: string, id: number, domains: Array<{ __typename?: 'Domain', id: number, url: string, verified: boolean, isShopify: boolean }> }> };

export type EmailTemplatesQueryVariables = Exact<{ [key: string]: never; }>;


export type EmailTemplatesQuery = { __typename?: 'Query', emailTemplates: Array<{ __typename?: 'EmailTemplate', id: number, name: string, url?: string | null, imageUrl?: string | null }> };

export type UpdateFlowEmailTemplateMutationVariables = Exact<{
  id: Scalars['Float'];
  emailTemplateId: Scalars['Float'];
  isPlainText: Scalars['Boolean'];
}>;


export type UpdateFlowEmailTemplateMutation = { __typename?: 'Mutation', updateFlowEmailTemplate: { __typename?: 'Flow', id: number, name: string, description?: string | null, icon?: string | null, accountId: number, steps?: string | null, isTemplate: boolean, frequencyStatus: boolean, isPlainText?: boolean | null, plainTextBody?: string | null, emailTemplateId?: number | null, type: FlowType, triggerId?: number | null, EmailTemplate?: { __typename?: 'EmailTemplate', id: number, name: string, url?: string | null, imageUrl?: string | null } | null, Trigger?: { __typename?: 'Trigger', id: number, name: string, description?: string | null, icon?: string | null, type: FlowType, code: string } | null } };

export type DefaultEmailTemplateByTriggerQueryVariables = Exact<{
  id: Scalars['Float'];
}>;


export type DefaultEmailTemplateByTriggerQuery = { __typename?: 'Query', defaultEmailTemplateByTrigger: { __typename?: 'EmailTemplate', id: number, name: string, url?: string | null, imageUrl?: string | null } };

export type EmailAutomationLeadsQueryVariables = Exact<{
  filter: EmailAutomationLeadsInput;
  take?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
}>;


export type EmailAutomationLeadsQuery = { __typename?: 'Query', emailAutomationLeads: { __typename?: 'EmailAutomationLeadResponse', total: number, columns: Array<string>, items: Array<{ __typename?: 'EmailAutomationLead', id: string, email: string, status?: string | null, sendingDate?: string | null, isOpened: boolean, isClicked: boolean, subscribe: boolean }> } };

export type DeleteEmailAutomationLeadsMutationVariables = Exact<{
  ids: Array<Scalars['String']> | Scalars['String'];
}>;


export type DeleteEmailAutomationLeadsMutation = { __typename?: 'Mutation', deleteEmailAutomationLeads: { __typename?: 'DeleteEmailAutomationLeadResponse', status: boolean } };

export type EmailAutomationLeadsExportMutationVariables = Exact<{
  filter: EmailAutomationLeadsInput;
}>;


export type EmailAutomationLeadsExportMutation = { __typename?: 'Mutation', emailAutomationLeadsExport: boolean };

export type GetFoldersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetFoldersQuery = { __typename?: 'Query', folders: Array<{ __typename?: 'Folder', id: number, name: string, color?: string | null, createdAt: string, campaigns: Array<{ __typename?: 'Campaign', id: number, name: string }> }> };

export type ActiveCampaignChangeStatusMutationVariables = Exact<{
  id: Scalars['Int'];
  status: Scalars['Boolean'];
}>;


export type ActiveCampaignChangeStatusMutation = { __typename?: 'Mutation', activeCampaignChangeStatus: { __typename?: 'ChangeStatusResponse', id: number, status: boolean } };

export type ActiveCampaignTestConnectionMutationVariables = Exact<{
  input: TestConnectionInput;
}>;


export type ActiveCampaignTestConnectionMutation = { __typename?: 'Mutation', activeCampaignTestConnection: { __typename?: 'TestConnectionResponse', isSent: boolean, messageTitle?: string | null, message?: string | null, errorLogs?: string | null, sentData: Array<{ __typename?: 'TestConnectionSentData', integratorFieldName: string, integratorFieldId?: string | null, integratorTag?: string | null, popupsmartFieldName?: string | null, value?: string | null, nodeName?: string | null }> } };

export type CustomerIoChangeStatusMutationVariables = Exact<{
  id: Scalars['Int'];
  status: Scalars['Boolean'];
}>;


export type CustomerIoChangeStatusMutation = { __typename?: 'Mutation', customerIoChangeStatus: { __typename?: 'ChangeStatusResponse', id: number, status: boolean } };

export type CustomerIoTestConnectionMutationVariables = Exact<{
  input: TestConnectionInput;
}>;


export type CustomerIoTestConnectionMutation = { __typename?: 'Mutation', customerIoTestConnection: { __typename?: 'TestConnectionResponse', isSent: boolean, messageTitle?: string | null, message?: string | null, errorLogs?: string | null, sentData: Array<{ __typename?: 'TestConnectionSentData', integratorFieldName: string, integratorFieldId?: string | null, integratorTag?: string | null, popupsmartFieldName?: string | null, value?: string | null, nodeName?: string | null }> } };

export type DripChangeStatusMutationVariables = Exact<{
  id: Scalars['Int'];
  status: Scalars['Boolean'];
}>;


export type DripChangeStatusMutation = { __typename?: 'Mutation', dripChangeStatus: { __typename?: 'ChangeStatusResponse', id: number, status: boolean } };

export type DripTestConnectionMutationVariables = Exact<{
  input: TestConnectionInput;
}>;


export type DripTestConnectionMutation = { __typename?: 'Mutation', dripTestConnection: { __typename?: 'TestConnectionResponse', isSent: boolean, messageTitle?: string | null, message?: string | null, errorLogs?: string | null, sentData: Array<{ __typename?: 'TestConnectionSentData', integratorFieldName: string, integratorFieldId?: string | null, integratorTag?: string | null, popupsmartFieldName?: string | null, value?: string | null, nodeName?: string | null }> } };

export type EmailOctopusChangeStatusMutationVariables = Exact<{
  id: Scalars['Int'];
  status: Scalars['Boolean'];
}>;


export type EmailOctopusChangeStatusMutation = { __typename?: 'Mutation', emailOctopusChangeStatus: { __typename?: 'ChangeStatusResponse', id: number, status: boolean } };

export type EmailOctopusTestConnectionMutationVariables = Exact<{
  input: TestConnectionInput;
}>;


export type EmailOctopusTestConnectionMutation = { __typename?: 'Mutation', emailOctopusTestConnection: { __typename?: 'TestConnectionResponse', isSent: boolean, messageTitle?: string | null, message?: string | null, errorLogs?: string | null, sentData: Array<{ __typename?: 'TestConnectionSentData', integratorFieldName: string, integratorFieldId?: string | null, integratorTag?: string | null, popupsmartFieldName?: string | null, value?: string | null, nodeName?: string | null }> } };

export type HubSpotChangeStatusMutationVariables = Exact<{
  id: Scalars['Int'];
  status: Scalars['Boolean'];
}>;


export type HubSpotChangeStatusMutation = { __typename?: 'Mutation', hubSpotChangeStatus: { __typename?: 'ChangeStatusResponse', id: number, status: boolean } };

export type HubSpotTestConnectionMutationVariables = Exact<{
  input: TestConnectionInput;
}>;


export type HubSpotTestConnectionMutation = { __typename?: 'Mutation', hubSpotTestConnection: { __typename?: 'TestConnectionResponse', isSent: boolean, messageTitle?: string | null, message?: string | null, errorLogs?: string | null, sentData: Array<{ __typename?: 'TestConnectionSentData', integratorFieldName: string, integratorFieldId?: string | null, integratorTag?: string | null, popupsmartFieldName?: string | null, value?: string | null, nodeName?: string | null }> } };

export type DeleteAllIntegrationsInCampaignMutationVariables = Exact<{
  campaignId: Scalars['Int'];
}>;


export type DeleteAllIntegrationsInCampaignMutation = { __typename?: 'Mutation', deleteAllIntegrationsInCampaign: boolean };

export type DisableAllIntegrationsInCampaignMutationVariables = Exact<{
  campaignId: Scalars['Int'];
}>;


export type DisableAllIntegrationsInCampaignMutation = { __typename?: 'Mutation', disableAllIntegrationsInCampaign: boolean };

export type KlaviyoChangeStatusMutationVariables = Exact<{
  id: Scalars['Int'];
  status: Scalars['Boolean'];
}>;


export type KlaviyoChangeStatusMutation = { __typename?: 'Mutation', klaviyoChangeStatus: { __typename?: 'ChangeStatusResponse', id: number, status: boolean } };

export type KlaviyoTestConnectionMutationVariables = Exact<{
  input: TestConnectionInput;
}>;


export type KlaviyoTestConnectionMutation = { __typename?: 'Mutation', klaviyoTestConnection: { __typename?: 'TestConnectionResponse', isSent: boolean, messageTitle?: string | null, message?: string | null, errorLogs?: string | null, sentData: Array<{ __typename?: 'TestConnectionSentData', integratorFieldName: string, integratorFieldId?: string | null, integratorTag?: string | null, popupsmartFieldName?: string | null, value?: string | null, nodeName?: string | null }> } };

export type MailChimpChangeStatusMutationVariables = Exact<{
  id: Scalars['Int'];
  status: Scalars['Boolean'];
}>;


export type MailChimpChangeStatusMutation = { __typename?: 'Mutation', mailChimpChangeStatus: { __typename?: 'ChangeStatusResponse', id: number, status: boolean } };

export type MailChimpTestConnectionMutationVariables = Exact<{
  input: TestConnectionInput;
}>;


export type MailChimpTestConnectionMutation = { __typename?: 'Mutation', mailChimpTestConnection: { __typename?: 'TestConnectionResponse', isSent: boolean, messageTitle?: string | null, message?: string | null, errorLogs?: string | null, sentData: Array<{ __typename?: 'TestConnectionSentData', integratorFieldName: string, integratorFieldId?: string | null, integratorTag?: string | null, popupsmartFieldName?: string | null, value?: string | null, nodeName?: string | null }> } };

export type MailerLiteChangeStatusMutationVariables = Exact<{
  id: Scalars['Int'];
  status: Scalars['Boolean'];
}>;


export type MailerLiteChangeStatusMutation = { __typename?: 'Mutation', mailerLiteChangeStatus: { __typename?: 'ChangeStatusResponse', id: number, status: boolean } };

export type MailerliteTestConnectionMutationVariables = Exact<{
  input: TestConnectionInput;
}>;


export type MailerliteTestConnectionMutation = { __typename?: 'Mutation', mailerliteTestConnection: { __typename?: 'TestConnectionResponse', isSent: boolean, messageTitle?: string | null, message?: string | null, errorLogs?: string | null, sentData: Array<{ __typename?: 'TestConnectionSentData', integratorFieldName: string, integratorFieldId?: string | null, integratorTag?: string | null, popupsmartFieldName?: string | null, value?: string | null, nodeName?: string | null }> } };

export type SendGridChangeStatusMutationVariables = Exact<{
  id: Scalars['Int'];
  status: Scalars['Boolean'];
}>;


export type SendGridChangeStatusMutation = { __typename?: 'Mutation', sendGridChangeStatus: { __typename?: 'ChangeStatusResponse', id: number, status: boolean } };

export type SendGridTestConnectionMutationVariables = Exact<{
  input: TestConnectionInput;
}>;


export type SendGridTestConnectionMutation = { __typename?: 'Mutation', sendGridTestConnection: { __typename?: 'TestConnectionResponse', isSent: boolean, messageTitle?: string | null, message?: string | null, errorLogs?: string | null, sentData: Array<{ __typename?: 'TestConnectionSentData', integratorFieldName: string, integratorFieldId?: string | null, integratorTag?: string | null, popupsmartFieldName?: string | null, value?: string | null, nodeName?: string | null }> } };

export type SendinBlueChangeStatusMutationVariables = Exact<{
  id: Scalars['Int'];
  status: Scalars['Boolean'];
}>;


export type SendinBlueChangeStatusMutation = { __typename?: 'Mutation', sendinBlueChangeStatus: { __typename?: 'ChangeStatusResponse', id: number, status: boolean } };

export type BrevoTestConnectionMutationVariables = Exact<{
  input: TestConnectionInput;
}>;


export type BrevoTestConnectionMutation = { __typename?: 'Mutation', brevoTestConnection: { __typename?: 'TestConnectionResponse', isSent: boolean, messageTitle?: string | null, message?: string | null, errorLogs?: string | null, sentData: Array<{ __typename?: 'TestConnectionSentData', integratorFieldName: string, integratorFieldId?: string | null, integratorTag?: string | null, popupsmartFieldName?: string | null, value?: string | null, nodeName?: string | null }> } };

export type CreateShopifyIntegrationMutationVariables = Exact<{
  accountId: Scalars['Int'];
  campaignId: Scalars['Int'];
  storeDomain: Scalars['String'];
  customTags?: InputMaybe<Scalars['String']>;
  integrationFieldsShopify: Array<IntegrationShopifyFieldInput> | IntegrationShopifyFieldInput;
  acceptEmailMarketing: Scalars['Boolean'];
  acceptSmsMarketing: Scalars['Boolean'];
}>;


export type CreateShopifyIntegrationMutation = { __typename?: 'Mutation', createShopifyIntegration: { __typename?: 'IntegrationShopifyCampaign', id: number, campaignId: number, status?: boolean | null, storeDomain?: string | null, customTags?: string | null, isConnectedTestCompleted?: boolean | null, createdAt?: DateTime | null, updatedAt?: DateTime | null, acceptEmailMarketing: boolean, acceptSmsMarketing: boolean, integrationFieldsShopify: Array<{ __typename?: 'IntegrationFieldShopify', id: number, fieldType: number, popupsmartFieldName?: string | null, integratorFieldName?: string | null }> } };

export type UpdateShopifyIntegrationInCampaignMutationVariables = Exact<{
  integrationId: Scalars['Int'];
  campaignId: Scalars['Int'];
  storeDomain?: InputMaybe<Scalars['String']>;
  customTags?: InputMaybe<Scalars['String']>;
  integrationFieldsShopify: Array<IntegrationShopifyFieldInput> | IntegrationShopifyFieldInput;
  acceptEmailMarketing: Scalars['Boolean'];
  acceptSmsMarketing: Scalars['Boolean'];
}>;


export type UpdateShopifyIntegrationInCampaignMutation = { __typename?: 'Mutation', updateShopifyIntegrationInCampaign: { __typename?: 'IntegrationShopifyCampaign', id: number, campaignId: number, status?: boolean | null, storeDomain?: string | null, customTags?: string | null, isConnectedTestCompleted?: boolean | null, createdAt?: DateTime | null, updatedAt?: DateTime | null, acceptEmailMarketing: boolean, acceptSmsMarketing: boolean, integrationFieldsShopify: Array<{ __typename?: 'IntegrationFieldShopify', id: number, fieldType: number, popupsmartFieldName?: string | null, integratorFieldName?: string | null }> } };

export type AddShopifyIntegrationInCampaignMutationVariables = Exact<{
  integrationId: Scalars['Int'];
  campaignId: Scalars['Int'];
  storeDomain: Scalars['String'];
  customTags?: InputMaybe<Scalars['String']>;
  integrationFieldsShopify: Array<IntegrationShopifyFieldInput> | IntegrationShopifyFieldInput;
  acceptEmailMarketing: Scalars['Boolean'];
  acceptSmsMarketing: Scalars['Boolean'];
}>;


export type AddShopifyIntegrationInCampaignMutation = { __typename?: 'Mutation', addShopifyIntegrationInCampaign: { __typename?: 'IntegrationShopifyCampaign', id: number, campaignId: number, status?: boolean | null, storeDomain?: string | null, customTags?: string | null, isConnectedTestCompleted?: boolean | null, createdAt?: DateTime | null, updatedAt?: DateTime | null, acceptEmailMarketing: boolean, acceptSmsMarketing: boolean, integrationFieldsShopify: Array<{ __typename?: 'IntegrationFieldShopify', id: number, fieldType: number, popupsmartFieldName?: string | null, integratorFieldName?: string | null }> } };

export type ChangeShopifyIntegrationInCampaignStatusMutationVariables = Exact<{
  shopifyIntegrationCampaignId: Scalars['Int'];
  status: Scalars['Boolean'];
}>;


export type ChangeShopifyIntegrationInCampaignStatusMutation = { __typename?: 'Mutation', changeShopifyIntegrationInCampaignStatus: { __typename?: 'ChangeStatusShopifyIntegrationResponse', id: number, status: boolean } };

export type ChangeShopifyIntegrationInCampaignIsConnectedTestCompletedMutationVariables = Exact<{
  shopifyIntegrationCampaignId: Scalars['Int'];
  isConnectedTestCompleted: Scalars['Boolean'];
}>;


export type ChangeShopifyIntegrationInCampaignIsConnectedTestCompletedMutation = { __typename?: 'Mutation', changeShopifyIntegrationInCampaignIsConnectedTestCompleted: boolean };

export type DeleteShopifyIntegrationInCampaignMutationVariables = Exact<{
  shopifyIntegrationCampaignId: Scalars['Int'];
}>;


export type DeleteShopifyIntegrationInCampaignMutation = { __typename?: 'Mutation', deleteShopifyIntegrationInCampaign: { __typename?: 'DeleteShopifyIntegrationResponse', id: number } };

export type DeleteShopifyIntegrationMutationVariables = Exact<{
  shopifyIntegrationId: Scalars['Int'];
}>;


export type DeleteShopifyIntegrationMutation = { __typename?: 'Mutation', deleteShopifyIntegration: boolean };

export type GetAllShopifyIntegrationInCampaignQueryVariables = Exact<{
  campaignId: Scalars['Int'];
}>;


export type GetAllShopifyIntegrationInCampaignQuery = { __typename?: 'Query', getAllShopifyIntegrationInCampaign: Array<{ __typename?: 'IntegrationShopifyCampaign', id: number, campaignId: number, integrationId: number, status?: boolean | null, storeDomain?: string | null, customTags?: string | null, isConnectedTestCompleted?: boolean | null, createdAt?: DateTime | null, updatedAt?: DateTime | null, acceptEmailMarketing: boolean, acceptSmsMarketing: boolean, integrationFieldsShopify: Array<{ __typename?: 'IntegrationFieldShopify', id: number, fieldType: number, popupsmartFieldName?: string | null, integratorFieldName?: string | null }> }> };

export type WebsitesQueryVariables = Exact<{ [key: string]: never; }>;


export type WebsitesQuery = { __typename?: 'Query', websites: { __typename?: 'WebsitesResponse', total: number, data: Array<{ __typename?: 'WebsiteItem', id: number, url?: string | null, verified?: boolean | null, cms?: string | null, campaignCount: number, isShopify: boolean }> } };

export type DeleteWebsiteMutationVariables = Exact<{
  websiteId: Scalars['Float'];
}>;


export type DeleteWebsiteMutation = { __typename?: 'Mutation', deleteWebsite: boolean };

export type CreateWebsiteMutationVariables = Exact<{
  website: Scalars['String'];
}>;


export type CreateWebsiteMutation = { __typename?: 'Mutation', createWebsite: { __typename?: 'WebsiteItem', url?: string | null, verified?: boolean | null, isShopify: boolean, id: number, cms?: string | null, campaignCount: number } };

export type UpdateWebsiteMutationVariables = Exact<{
  website: Scalars['String'];
  websiteId: Scalars['Float'];
}>;


export type UpdateWebsiteMutation = { __typename?: 'Mutation', updateWebsite: { __typename?: 'WebsiteItem', id: number, url?: string | null, cms?: string | null, verified?: boolean | null, campaignCount: number, isShopify: boolean } };


export const GenerateAiCampaignImageDocument = gql`
    mutation GenerateAICampaignImage($prompt: String!, $size: String!, $templateId: Int) {
  generateAIImage(prompt: $prompt, size: $size, templateId: $templateId) {
    success
    data
    error
  }
}
    `;
export type GenerateAiCampaignImageMutationFn = Apollo.MutationFunction<GenerateAiCampaignImageMutation, GenerateAiCampaignImageMutationVariables>;

/**
 * __useGenerateAiCampaignImageMutation__
 *
 * To run a mutation, you first call `useGenerateAiCampaignImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateAiCampaignImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateAiCampaignImageMutation, { data, loading, error }] = useGenerateAiCampaignImageMutation({
 *   variables: {
 *      prompt: // value for 'prompt'
 *      size: // value for 'size'
 *      templateId: // value for 'templateId'
 *   },
 * });
 */
export function useGenerateAiCampaignImageMutation(baseOptions?: Apollo.MutationHookOptions<GenerateAiCampaignImageMutation, GenerateAiCampaignImageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GenerateAiCampaignImageMutation, GenerateAiCampaignImageMutationVariables>(GenerateAiCampaignImageDocument, options);
      }
export type GenerateAiCampaignImageMutationHookResult = ReturnType<typeof useGenerateAiCampaignImageMutation>;
export type GenerateAiCampaignImageMutationResult = Apollo.MutationResult<GenerateAiCampaignImageMutation>;
export type GenerateAiCampaignImageMutationOptions = Apollo.BaseMutationOptions<GenerateAiCampaignImageMutation, GenerateAiCampaignImageMutationVariables>;
export const CreateWorkspaceDocument = gql`
    mutation CreateWorkspace($input: CreateWorkspaceInput!) {
  createWorkspace(input: $input) {
    id
    name
    createdAt
    updatedAt
  }
}
    `;
export type CreateWorkspaceMutationFn = Apollo.MutationFunction<CreateWorkspaceMutation, CreateWorkspaceMutationVariables>;

/**
 * __useCreateWorkspaceMutation__
 *
 * To run a mutation, you first call `useCreateWorkspaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWorkspaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWorkspaceMutation, { data, loading, error }] = useCreateWorkspaceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateWorkspaceMutation(baseOptions?: Apollo.MutationHookOptions<CreateWorkspaceMutation, CreateWorkspaceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateWorkspaceMutation, CreateWorkspaceMutationVariables>(CreateWorkspaceDocument, options);
      }
export type CreateWorkspaceMutationHookResult = ReturnType<typeof useCreateWorkspaceMutation>;
export type CreateWorkspaceMutationResult = Apollo.MutationResult<CreateWorkspaceMutation>;
export type CreateWorkspaceMutationOptions = Apollo.BaseMutationOptions<CreateWorkspaceMutation, CreateWorkspaceMutationVariables>;
export const CreateFolderDocument = gql`
    mutation CreateFolder($input: CreateFolderInput!) {
  createFolder(input: $input) {
    id
    name
    color
    campaigns {
      id
      name
    }
    createdAt
  }
}
    `;
export type CreateFolderMutationFn = Apollo.MutationFunction<CreateFolderMutation, CreateFolderMutationVariables>;

/**
 * __useCreateFolderMutation__
 *
 * To run a mutation, you first call `useCreateFolderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFolderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFolderMutation, { data, loading, error }] = useCreateFolderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateFolderMutation(baseOptions?: Apollo.MutationHookOptions<CreateFolderMutation, CreateFolderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateFolderMutation, CreateFolderMutationVariables>(CreateFolderDocument, options);
      }
export type CreateFolderMutationHookResult = ReturnType<typeof useCreateFolderMutation>;
export type CreateFolderMutationResult = Apollo.MutationResult<CreateFolderMutation>;
export type CreateFolderMutationOptions = Apollo.BaseMutationOptions<CreateFolderMutation, CreateFolderMutationVariables>;
export const DeleteFolderDocument = gql`
    mutation DeleteFolder($id: Int!) {
  deleteFolder(id: $id)
}
    `;
export type DeleteFolderMutationFn = Apollo.MutationFunction<DeleteFolderMutation, DeleteFolderMutationVariables>;

/**
 * __useDeleteFolderMutation__
 *
 * To run a mutation, you first call `useDeleteFolderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFolderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFolderMutation, { data, loading, error }] = useDeleteFolderMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteFolderMutation(baseOptions?: Apollo.MutationHookOptions<DeleteFolderMutation, DeleteFolderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteFolderMutation, DeleteFolderMutationVariables>(DeleteFolderDocument, options);
      }
export type DeleteFolderMutationHookResult = ReturnType<typeof useDeleteFolderMutation>;
export type DeleteFolderMutationResult = Apollo.MutationResult<DeleteFolderMutation>;
export type DeleteFolderMutationOptions = Apollo.BaseMutationOptions<DeleteFolderMutation, DeleteFolderMutationVariables>;
export const UpdateCampaignFolderDocument = gql`
    mutation UpdateCampaignFolder($input: UpdateCampaignFolderInput!) {
  updateCampaignFolder(input: $input)
}
    `;
export type UpdateCampaignFolderMutationFn = Apollo.MutationFunction<UpdateCampaignFolderMutation, UpdateCampaignFolderMutationVariables>;

/**
 * __useUpdateCampaignFolderMutation__
 *
 * To run a mutation, you first call `useUpdateCampaignFolderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCampaignFolderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCampaignFolderMutation, { data, loading, error }] = useUpdateCampaignFolderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCampaignFolderMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCampaignFolderMutation, UpdateCampaignFolderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCampaignFolderMutation, UpdateCampaignFolderMutationVariables>(UpdateCampaignFolderDocument, options);
      }
export type UpdateCampaignFolderMutationHookResult = ReturnType<typeof useUpdateCampaignFolderMutation>;
export type UpdateCampaignFolderMutationResult = Apollo.MutationResult<UpdateCampaignFolderMutation>;
export type UpdateCampaignFolderMutationOptions = Apollo.BaseMutationOptions<UpdateCampaignFolderMutation, UpdateCampaignFolderMutationVariables>;
export const UpdateFolderDocument = gql`
    mutation UpdateFolder($id: Int!, $name: String!) {
  updateFolder(id: $id, name: $name) {
    id
    name
    color
    createdAt
  }
}
    `;
export type UpdateFolderMutationFn = Apollo.MutationFunction<UpdateFolderMutation, UpdateFolderMutationVariables>;

/**
 * __useUpdateFolderMutation__
 *
 * To run a mutation, you first call `useUpdateFolderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFolderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFolderMutation, { data, loading, error }] = useUpdateFolderMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useUpdateFolderMutation(baseOptions?: Apollo.MutationHookOptions<UpdateFolderMutation, UpdateFolderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateFolderMutation, UpdateFolderMutationVariables>(UpdateFolderDocument, options);
      }
export type UpdateFolderMutationHookResult = ReturnType<typeof useUpdateFolderMutation>;
export type UpdateFolderMutationResult = Apollo.MutationResult<UpdateFolderMutation>;
export type UpdateFolderMutationOptions = Apollo.BaseMutationOptions<UpdateFolderMutation, UpdateFolderMutationVariables>;
export const CreateAbTestingDocument = gql`
    query createAbTesting($campaignId: Int!) {
  createAbTesting(campaignId: $campaignId) {
    id
  }
}
    `;

/**
 * __useCreateAbTestingQuery__
 *
 * To run a query within a React component, call `useCreateAbTestingQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreateAbTestingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreateAbTestingQuery({
 *   variables: {
 *      campaignId: // value for 'campaignId'
 *   },
 * });
 */
export function useCreateAbTestingQuery(baseOptions: Apollo.QueryHookOptions<CreateAbTestingQuery, CreateAbTestingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CreateAbTestingQuery, CreateAbTestingQueryVariables>(CreateAbTestingDocument, options);
      }
export function useCreateAbTestingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CreateAbTestingQuery, CreateAbTestingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CreateAbTestingQuery, CreateAbTestingQueryVariables>(CreateAbTestingDocument, options);
        }
export type CreateAbTestingQueryHookResult = ReturnType<typeof useCreateAbTestingQuery>;
export type CreateAbTestingLazyQueryHookResult = ReturnType<typeof useCreateAbTestingLazyQuery>;
export type CreateAbTestingQueryResult = Apollo.QueryResult<CreateAbTestingQuery, CreateAbTestingQueryVariables>;
export const AdminCustomerLoginDocument = gql`
    query adminCustomerLogin($id: Int!) {
  adminCustomerLogin(id: $id) {
    accessToken
    refreshToken
  }
}
    `;

/**
 * __useAdminCustomerLoginQuery__
 *
 * To run a query within a React component, call `useAdminCustomerLoginQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminCustomerLoginQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminCustomerLoginQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdminCustomerLoginQuery(baseOptions: Apollo.QueryHookOptions<AdminCustomerLoginQuery, AdminCustomerLoginQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminCustomerLoginQuery, AdminCustomerLoginQueryVariables>(AdminCustomerLoginDocument, options);
      }
export function useAdminCustomerLoginLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminCustomerLoginQuery, AdminCustomerLoginQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminCustomerLoginQuery, AdminCustomerLoginQueryVariables>(AdminCustomerLoginDocument, options);
        }
export type AdminCustomerLoginQueryHookResult = ReturnType<typeof useAdminCustomerLoginQuery>;
export type AdminCustomerLoginLazyQueryHookResult = ReturnType<typeof useAdminCustomerLoginLazyQuery>;
export type AdminCustomerLoginQueryResult = Apollo.QueryResult<AdminCustomerLoginQuery, AdminCustomerLoginQueryVariables>;
export const ChangeUserEmailDocument = gql`
    mutation changeUserEmail($id: Int!, $email: String!) {
  changeUserEmail(id: $id, email: $email) {
    id
    email
  }
}
    `;
export type ChangeUserEmailMutationFn = Apollo.MutationFunction<ChangeUserEmailMutation, ChangeUserEmailMutationVariables>;

/**
 * __useChangeUserEmailMutation__
 *
 * To run a mutation, you first call `useChangeUserEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeUserEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeUserEmailMutation, { data, loading, error }] = useChangeUserEmailMutation({
 *   variables: {
 *      id: // value for 'id'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useChangeUserEmailMutation(baseOptions?: Apollo.MutationHookOptions<ChangeUserEmailMutation, ChangeUserEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeUserEmailMutation, ChangeUserEmailMutationVariables>(ChangeUserEmailDocument, options);
      }
export type ChangeUserEmailMutationHookResult = ReturnType<typeof useChangeUserEmailMutation>;
export type ChangeUserEmailMutationResult = Apollo.MutationResult<ChangeUserEmailMutation>;
export type ChangeUserEmailMutationOptions = Apollo.BaseMutationOptions<ChangeUserEmailMutation, ChangeUserEmailMutationVariables>;
export const ChangeAccountPlanDocument = gql`
    mutation changeAccountPlan($id: Int!, $planId: Int!) {
  changeAccountPlan(id: $id, planId: $planId) {
    id
    name
    planId
  }
}
    `;
export type ChangeAccountPlanMutationFn = Apollo.MutationFunction<ChangeAccountPlanMutation, ChangeAccountPlanMutationVariables>;

/**
 * __useChangeAccountPlanMutation__
 *
 * To run a mutation, you first call `useChangeAccountPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeAccountPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeAccountPlanMutation, { data, loading, error }] = useChangeAccountPlanMutation({
 *   variables: {
 *      id: // value for 'id'
 *      planId: // value for 'planId'
 *   },
 * });
 */
export function useChangeAccountPlanMutation(baseOptions?: Apollo.MutationHookOptions<ChangeAccountPlanMutation, ChangeAccountPlanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeAccountPlanMutation, ChangeAccountPlanMutationVariables>(ChangeAccountPlanDocument, options);
      }
export type ChangeAccountPlanMutationHookResult = ReturnType<typeof useChangeAccountPlanMutation>;
export type ChangeAccountPlanMutationResult = Apollo.MutationResult<ChangeAccountPlanMutation>;
export type ChangeAccountPlanMutationOptions = Apollo.BaseMutationOptions<ChangeAccountPlanMutation, ChangeAccountPlanMutationVariables>;
export const GetUsersDocument = gql`
    mutation getUsers($page: Int!, $pageSize: Int!, $filter: String!) {
  getUsers(page: $page, pageSize: $pageSize, filter: $filter) {
    id
    name
    surname
    email
    phone
    image
    status
    stripeId
    planId
    organizationName
  }
}
    `;
export type GetUsersMutationFn = Apollo.MutationFunction<GetUsersMutation, GetUsersMutationVariables>;

/**
 * __useGetUsersMutation__
 *
 * To run a mutation, you first call `useGetUsersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetUsersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getUsersMutation, { data, loading, error }] = useGetUsersMutation({
 *   variables: {
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetUsersMutation(baseOptions?: Apollo.MutationHookOptions<GetUsersMutation, GetUsersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GetUsersMutation, GetUsersMutationVariables>(GetUsersDocument, options);
      }
export type GetUsersMutationHookResult = ReturnType<typeof useGetUsersMutation>;
export type GetUsersMutationResult = Apollo.MutationResult<GetUsersMutation>;
export type GetUsersMutationOptions = Apollo.BaseMutationOptions<GetUsersMutation, GetUsersMutationVariables>;
export const UsedByAnalyticsDocument = gql`
    query usedByAnalytics($filter: AnalyticsFilterInput!) {
  browserAnalytics(filter: $filter) {
    browser
    display
  }
  deviceAnalytics(filter: $filter) {
    device
    display
  }
  resolutionAnalytics(filter: $filter) {
    resolution
    display
  }
}
    `;

/**
 * __useUsedByAnalyticsQuery__
 *
 * To run a query within a React component, call `useUsedByAnalyticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsedByAnalyticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsedByAnalyticsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useUsedByAnalyticsQuery(baseOptions: Apollo.QueryHookOptions<UsedByAnalyticsQuery, UsedByAnalyticsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UsedByAnalyticsQuery, UsedByAnalyticsQueryVariables>(UsedByAnalyticsDocument, options);
      }
export function useUsedByAnalyticsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsedByAnalyticsQuery, UsedByAnalyticsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UsedByAnalyticsQuery, UsedByAnalyticsQueryVariables>(UsedByAnalyticsDocument, options);
        }
export type UsedByAnalyticsQueryHookResult = ReturnType<typeof useUsedByAnalyticsQuery>;
export type UsedByAnalyticsLazyQueryHookResult = ReturnType<typeof useUsedByAnalyticsLazyQuery>;
export type UsedByAnalyticsQueryResult = Apollo.QueryResult<UsedByAnalyticsQuery, UsedByAnalyticsQueryVariables>;
export const LoginDocument = gql`
    mutation login($email: String!, $password: String!) {
  login(email: $email, password: $password) {
    accessToken
    refreshToken
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const RefreshTokenDocument = gql`
    mutation refreshToken($accessToken: String!, $refreshToken: String!) {
  refreshToken(accessToken: $accessToken, refreshToken: $refreshToken) {
    accessToken
    refreshToken
  }
}
    `;
export type RefreshTokenMutationFn = Apollo.MutationFunction<RefreshTokenMutation, RefreshTokenMutationVariables>;

/**
 * __useRefreshTokenMutation__
 *
 * To run a mutation, you first call `useRefreshTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshTokenMutation, { data, loading, error }] = useRefreshTokenMutation({
 *   variables: {
 *      accessToken: // value for 'accessToken'
 *      refreshToken: // value for 'refreshToken'
 *   },
 * });
 */
export function useRefreshTokenMutation(baseOptions?: Apollo.MutationHookOptions<RefreshTokenMutation, RefreshTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RefreshTokenMutation, RefreshTokenMutationVariables>(RefreshTokenDocument, options);
      }
export type RefreshTokenMutationHookResult = ReturnType<typeof useRefreshTokenMutation>;
export type RefreshTokenMutationResult = Apollo.MutationResult<RefreshTokenMutation>;
export type RefreshTokenMutationOptions = Apollo.BaseMutationOptions<RefreshTokenMutation, RefreshTokenMutationVariables>;
export const AdminLoginDocument = gql`
    mutation adminLogin($email: String!, $password: String!) {
  adminLogin(email: $email, password: $password) {
    accessToken
    refreshToken
  }
}
    `;
export type AdminLoginMutationFn = Apollo.MutationFunction<AdminLoginMutation, AdminLoginMutationVariables>;

/**
 * __useAdminLoginMutation__
 *
 * To run a mutation, you first call `useAdminLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminLoginMutation, { data, loading, error }] = useAdminLoginMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useAdminLoginMutation(baseOptions?: Apollo.MutationHookOptions<AdminLoginMutation, AdminLoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminLoginMutation, AdminLoginMutationVariables>(AdminLoginDocument, options);
      }
export type AdminLoginMutationHookResult = ReturnType<typeof useAdminLoginMutation>;
export type AdminLoginMutationResult = Apollo.MutationResult<AdminLoginMutation>;
export type AdminLoginMutationOptions = Apollo.BaseMutationOptions<AdminLoginMutation, AdminLoginMutationVariables>;
export const ForgotPasswordDocument = gql`
    mutation forgotPassword($captcha: String!, $email: String!) {
  forgotPassword(captcha: $captcha, email: $email)
}
    `;
export type ForgotPasswordMutationFn = Apollo.MutationFunction<ForgotPasswordMutation, ForgotPasswordMutationVariables>;

/**
 * __useForgotPasswordMutation__
 *
 * To run a mutation, you first call `useForgotPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForgotPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forgotPasswordMutation, { data, loading, error }] = useForgotPasswordMutation({
 *   variables: {
 *      captcha: // value for 'captcha'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useForgotPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ForgotPasswordMutation, ForgotPasswordMutationVariables>(ForgotPasswordDocument, options);
      }
export type ForgotPasswordMutationHookResult = ReturnType<typeof useForgotPasswordMutation>;
export type ForgotPasswordMutationResult = Apollo.MutationResult<ForgotPasswordMutation>;
export type ForgotPasswordMutationOptions = Apollo.BaseMutationOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation resetPassword($password: String!, $token: String!) {
  resetPassword(password: $password, token: $token)
}
    `;
export type ResetPasswordMutationFn = Apollo.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      password: // value for 'password'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, options);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const RegisterDocument = gql`
    mutation Register($data: RegisterInput!) {
  register(data: $data) {
    token
    refreshToken
    tokenExpireDate
  }
}
    `;
export type RegisterMutationFn = Apollo.MutationFunction<RegisterMutation, RegisterMutationVariables>;

/**
 * __useRegisterMutation__
 *
 * To run a mutation, you first call `useRegisterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerMutation, { data, loading, error }] = useRegisterMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useRegisterMutation(baseOptions?: Apollo.MutationHookOptions<RegisterMutation, RegisterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterMutation, RegisterMutationVariables>(RegisterDocument, options);
      }
export type RegisterMutationHookResult = ReturnType<typeof useRegisterMutation>;
export type RegisterMutationResult = Apollo.MutationResult<RegisterMutation>;
export type RegisterMutationOptions = Apollo.BaseMutationOptions<RegisterMutation, RegisterMutationVariables>;
export const CustomerGoogleLoginDocument = gql`
    mutation customerGoogleLogin($accessToken: String!) {
  customerGoogleLogin(accessToken: $accessToken) {
    isRegisteredUser
    token
    refreshToken
    tokenExpireDate
  }
}
    `;
export type CustomerGoogleLoginMutationFn = Apollo.MutationFunction<CustomerGoogleLoginMutation, CustomerGoogleLoginMutationVariables>;

/**
 * __useCustomerGoogleLoginMutation__
 *
 * To run a mutation, you first call `useCustomerGoogleLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCustomerGoogleLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [customerGoogleLoginMutation, { data, loading, error }] = useCustomerGoogleLoginMutation({
 *   variables: {
 *      accessToken: // value for 'accessToken'
 *   },
 * });
 */
export function useCustomerGoogleLoginMutation(baseOptions?: Apollo.MutationHookOptions<CustomerGoogleLoginMutation, CustomerGoogleLoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CustomerGoogleLoginMutation, CustomerGoogleLoginMutationVariables>(CustomerGoogleLoginDocument, options);
      }
export type CustomerGoogleLoginMutationHookResult = ReturnType<typeof useCustomerGoogleLoginMutation>;
export type CustomerGoogleLoginMutationResult = Apollo.MutationResult<CustomerGoogleLoginMutation>;
export type CustomerGoogleLoginMutationOptions = Apollo.BaseMutationOptions<CustomerGoogleLoginMutation, CustomerGoogleLoginMutationVariables>;
export const AccountTokenDocument = gql`
    query accountToken($requestedAccountId: Float!) {
  accountToken(requestedAccountId: $requestedAccountId) {
    accessToken
    refreshToken
  }
}
    `;

/**
 * __useAccountTokenQuery__
 *
 * To run a query within a React component, call `useAccountTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountTokenQuery({
 *   variables: {
 *      requestedAccountId: // value for 'requestedAccountId'
 *   },
 * });
 */
export function useAccountTokenQuery(baseOptions: Apollo.QueryHookOptions<AccountTokenQuery, AccountTokenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AccountTokenQuery, AccountTokenQueryVariables>(AccountTokenDocument, options);
      }
export function useAccountTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountTokenQuery, AccountTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AccountTokenQuery, AccountTokenQueryVariables>(AccountTokenDocument, options);
        }
export type AccountTokenQueryHookResult = ReturnType<typeof useAccountTokenQuery>;
export type AccountTokenLazyQueryHookResult = ReturnType<typeof useAccountTokenLazyQuery>;
export type AccountTokenQueryResult = Apollo.QueryResult<AccountTokenQuery, AccountTokenQueryVariables>;
export const DeleteCampaignVariantDocument = gql`
    mutation deleteCampaignVariant($campaignId: Int!) {
  deleteCampaignVariant(campaignId: $campaignId)
}
    `;
export type DeleteCampaignVariantMutationFn = Apollo.MutationFunction<DeleteCampaignVariantMutation, DeleteCampaignVariantMutationVariables>;

/**
 * __useDeleteCampaignVariantMutation__
 *
 * To run a mutation, you first call `useDeleteCampaignVariantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCampaignVariantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCampaignVariantMutation, { data, loading, error }] = useDeleteCampaignVariantMutation({
 *   variables: {
 *      campaignId: // value for 'campaignId'
 *   },
 * });
 */
export function useDeleteCampaignVariantMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCampaignVariantMutation, DeleteCampaignVariantMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCampaignVariantMutation, DeleteCampaignVariantMutationVariables>(DeleteCampaignVariantDocument, options);
      }
export type DeleteCampaignVariantMutationHookResult = ReturnType<typeof useDeleteCampaignVariantMutation>;
export type DeleteCampaignVariantMutationResult = Apollo.MutationResult<DeleteCampaignVariantMutation>;
export type DeleteCampaignVariantMutationOptions = Apollo.BaseMutationOptions<DeleteCampaignVariantMutation, DeleteCampaignVariantMutationVariables>;
export const ChangeCampaignVariantNameDocument = gql`
    mutation changeCampaignVariantName($name: String!, $campaignId: Int!) {
  changeCampaignVariantName(name: $name, campaignId: $campaignId) {
    id
    name
  }
}
    `;
export type ChangeCampaignVariantNameMutationFn = Apollo.MutationFunction<ChangeCampaignVariantNameMutation, ChangeCampaignVariantNameMutationVariables>;

/**
 * __useChangeCampaignVariantNameMutation__
 *
 * To run a mutation, you first call `useChangeCampaignVariantNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeCampaignVariantNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeCampaignVariantNameMutation, { data, loading, error }] = useChangeCampaignVariantNameMutation({
 *   variables: {
 *      name: // value for 'name'
 *      campaignId: // value for 'campaignId'
 *   },
 * });
 */
export function useChangeCampaignVariantNameMutation(baseOptions?: Apollo.MutationHookOptions<ChangeCampaignVariantNameMutation, ChangeCampaignVariantNameMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeCampaignVariantNameMutation, ChangeCampaignVariantNameMutationVariables>(ChangeCampaignVariantNameDocument, options);
      }
export type ChangeCampaignVariantNameMutationHookResult = ReturnType<typeof useChangeCampaignVariantNameMutation>;
export type ChangeCampaignVariantNameMutationResult = Apollo.MutationResult<ChangeCampaignVariantNameMutation>;
export type ChangeCampaignVariantNameMutationOptions = Apollo.BaseMutationOptions<ChangeCampaignVariantNameMutation, ChangeCampaignVariantNameMutationVariables>;
export const SaveAsNewCampaignDocument = gql`
    mutation saveAsNewCampaign($variantId: Int!) {
  saveAsNewCampaign(variantId: $variantId) {
    id
  }
}
    `;
export type SaveAsNewCampaignMutationFn = Apollo.MutationFunction<SaveAsNewCampaignMutation, SaveAsNewCampaignMutationVariables>;

/**
 * __useSaveAsNewCampaignMutation__
 *
 * To run a mutation, you first call `useSaveAsNewCampaignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveAsNewCampaignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveAsNewCampaignMutation, { data, loading, error }] = useSaveAsNewCampaignMutation({
 *   variables: {
 *      variantId: // value for 'variantId'
 *   },
 * });
 */
export function useSaveAsNewCampaignMutation(baseOptions?: Apollo.MutationHookOptions<SaveAsNewCampaignMutation, SaveAsNewCampaignMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveAsNewCampaignMutation, SaveAsNewCampaignMutationVariables>(SaveAsNewCampaignDocument, options);
      }
export type SaveAsNewCampaignMutationHookResult = ReturnType<typeof useSaveAsNewCampaignMutation>;
export type SaveAsNewCampaignMutationResult = Apollo.MutationResult<SaveAsNewCampaignMutation>;
export type SaveAsNewCampaignMutationOptions = Apollo.BaseMutationOptions<SaveAsNewCampaignMutation, SaveAsNewCampaignMutationVariables>;
export const DuplicateNewVariantDocument = gql`
    mutation duplicateNewVariant($variantId: Int!) {
  duplicateNewVariant(variantId: $variantId) {
    id
  }
}
    `;
export type DuplicateNewVariantMutationFn = Apollo.MutationFunction<DuplicateNewVariantMutation, DuplicateNewVariantMutationVariables>;

/**
 * __useDuplicateNewVariantMutation__
 *
 * To run a mutation, you first call `useDuplicateNewVariantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicateNewVariantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicateNewVariantMutation, { data, loading, error }] = useDuplicateNewVariantMutation({
 *   variables: {
 *      variantId: // value for 'variantId'
 *   },
 * });
 */
export function useDuplicateNewVariantMutation(baseOptions?: Apollo.MutationHookOptions<DuplicateNewVariantMutation, DuplicateNewVariantMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DuplicateNewVariantMutation, DuplicateNewVariantMutationVariables>(DuplicateNewVariantDocument, options);
      }
export type DuplicateNewVariantMutationHookResult = ReturnType<typeof useDuplicateNewVariantMutation>;
export type DuplicateNewVariantMutationResult = Apollo.MutationResult<DuplicateNewVariantMutation>;
export type DuplicateNewVariantMutationOptions = Apollo.BaseMutationOptions<DuplicateNewVariantMutation, DuplicateNewVariantMutationVariables>;
export const UpdateVariantPercentagesDocument = gql`
    mutation updateVariantPercentages($input: [CampaignVariantInput!]!) {
  updateVariantPercentages(input: $input)
}
    `;
export type UpdateVariantPercentagesMutationFn = Apollo.MutationFunction<UpdateVariantPercentagesMutation, UpdateVariantPercentagesMutationVariables>;

/**
 * __useUpdateVariantPercentagesMutation__
 *
 * To run a mutation, you first call `useUpdateVariantPercentagesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVariantPercentagesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVariantPercentagesMutation, { data, loading, error }] = useUpdateVariantPercentagesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateVariantPercentagesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateVariantPercentagesMutation, UpdateVariantPercentagesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateVariantPercentagesMutation, UpdateVariantPercentagesMutationVariables>(UpdateVariantPercentagesDocument, options);
      }
export type UpdateVariantPercentagesMutationHookResult = ReturnType<typeof useUpdateVariantPercentagesMutation>;
export type UpdateVariantPercentagesMutationResult = Apollo.MutationResult<UpdateVariantPercentagesMutation>;
export type UpdateVariantPercentagesMutationOptions = Apollo.BaseMutationOptions<UpdateVariantPercentagesMutation, UpdateVariantPercentagesMutationVariables>;
export const CustomFontsDocument = gql`
    query CustomFonts($campaignId: Int!) {
  customFonts(campaignId: $campaignId) {
    fonts {
      id
      name
      url
      weightUrls
      campaignId
    }
  }
}
    `;

/**
 * __useCustomFontsQuery__
 *
 * To run a query within a React component, call `useCustomFontsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomFontsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomFontsQuery({
 *   variables: {
 *      campaignId: // value for 'campaignId'
 *   },
 * });
 */
export function useCustomFontsQuery(baseOptions: Apollo.QueryHookOptions<CustomFontsQuery, CustomFontsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CustomFontsQuery, CustomFontsQueryVariables>(CustomFontsDocument, options);
      }
export function useCustomFontsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomFontsQuery, CustomFontsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CustomFontsQuery, CustomFontsQueryVariables>(CustomFontsDocument, options);
        }
export type CustomFontsQueryHookResult = ReturnType<typeof useCustomFontsQuery>;
export type CustomFontsLazyQueryHookResult = ReturnType<typeof useCustomFontsLazyQuery>;
export type CustomFontsQueryResult = Apollo.QueryResult<CustomFontsQuery, CustomFontsQueryVariables>;
export const UploadCustomFontDocument = gql`
    mutation UploadCustomFont($file: String!, $filename: String!, $campaignId: Int!) {
  uploadCustomFont(file: $file, filename: $filename, campaignId: $campaignId) {
    id
    name
    url
    weightUrls
    campaignId
  }
}
    `;
export type UploadCustomFontMutationFn = Apollo.MutationFunction<UploadCustomFontMutation, UploadCustomFontMutationVariables>;

/**
 * __useUploadCustomFontMutation__
 *
 * To run a mutation, you first call `useUploadCustomFontMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadCustomFontMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadCustomFontMutation, { data, loading, error }] = useUploadCustomFontMutation({
 *   variables: {
 *      file: // value for 'file'
 *      filename: // value for 'filename'
 *      campaignId: // value for 'campaignId'
 *   },
 * });
 */
export function useUploadCustomFontMutation(baseOptions?: Apollo.MutationHookOptions<UploadCustomFontMutation, UploadCustomFontMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadCustomFontMutation, UploadCustomFontMutationVariables>(UploadCustomFontDocument, options);
      }
export type UploadCustomFontMutationHookResult = ReturnType<typeof useUploadCustomFontMutation>;
export type UploadCustomFontMutationResult = Apollo.MutationResult<UploadCustomFontMutation>;
export type UploadCustomFontMutationOptions = Apollo.BaseMutationOptions<UploadCustomFontMutation, UploadCustomFontMutationVariables>;
export const DeleteCustomFontDocument = gql`
    mutation DeleteCustomFont($id: Int!) {
  deleteCustomFont(id: $id)
}
    `;
export type DeleteCustomFontMutationFn = Apollo.MutationFunction<DeleteCustomFontMutation, DeleteCustomFontMutationVariables>;

/**
 * __useDeleteCustomFontMutation__
 *
 * To run a mutation, you first call `useDeleteCustomFontMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCustomFontMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCustomFontMutation, { data, loading, error }] = useDeleteCustomFontMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCustomFontMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCustomFontMutation, DeleteCustomFontMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCustomFontMutation, DeleteCustomFontMutationVariables>(DeleteCustomFontDocument, options);
      }
export type DeleteCustomFontMutationHookResult = ReturnType<typeof useDeleteCustomFontMutation>;
export type DeleteCustomFontMutationResult = Apollo.MutationResult<DeleteCustomFontMutation>;
export type DeleteCustomFontMutationOptions = Apollo.BaseMutationOptions<DeleteCustomFontMutation, DeleteCustomFontMutationVariables>;
export const UploadImageDocument = gql`
    mutation uploadImage($imageBase64: String!) {
  uploadImage(imageBase64: $imageBase64) {
    url
  }
}
    `;
export type UploadImageMutationFn = Apollo.MutationFunction<UploadImageMutation, UploadImageMutationVariables>;

/**
 * __useUploadImageMutation__
 *
 * To run a mutation, you first call `useUploadImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadImageMutation, { data, loading, error }] = useUploadImageMutation({
 *   variables: {
 *      imageBase64: // value for 'imageBase64'
 *   },
 * });
 */
export function useUploadImageMutation(baseOptions?: Apollo.MutationHookOptions<UploadImageMutation, UploadImageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadImageMutation, UploadImageMutationVariables>(UploadImageDocument, options);
      }
export type UploadImageMutationHookResult = ReturnType<typeof useUploadImageMutation>;
export type UploadImageMutationResult = Apollo.MutationResult<UploadImageMutation>;
export type UploadImageMutationOptions = Apollo.BaseMutationOptions<UploadImageMutation, UploadImageMutationVariables>;
export const InteractionAnalyticsDocument = gql`
    query interactionAnalytics($filter: AnalyticsFilterInput!) {
  interactionAnalytics(filter: $filter) {
    type
    text
    count
  }
}
    `;

/**
 * __useInteractionAnalyticsQuery__
 *
 * To run a query within a React component, call `useInteractionAnalyticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInteractionAnalyticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInteractionAnalyticsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useInteractionAnalyticsQuery(baseOptions: Apollo.QueryHookOptions<InteractionAnalyticsQuery, InteractionAnalyticsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InteractionAnalyticsQuery, InteractionAnalyticsQueryVariables>(InteractionAnalyticsDocument, options);
      }
export function useInteractionAnalyticsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InteractionAnalyticsQuery, InteractionAnalyticsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InteractionAnalyticsQuery, InteractionAnalyticsQueryVariables>(InteractionAnalyticsDocument, options);
        }
export type InteractionAnalyticsQueryHookResult = ReturnType<typeof useInteractionAnalyticsQuery>;
export type InteractionAnalyticsLazyQueryHookResult = ReturnType<typeof useInteractionAnalyticsLazyQuery>;
export type InteractionAnalyticsQueryResult = Apollo.QueryResult<InteractionAnalyticsQuery, InteractionAnalyticsQueryVariables>;
export const InvitationAddRoleDocument = gql`
    mutation InvitationAddRole($campaignIds: [String!]!, $roleId: Int!, $invitationId: Int!, $canCreateCampaign: Boolean!) {
  invitationAddRole(
    campaignIds: $campaignIds
    roleId: $roleId
    invitationId: $invitationId
    canCreateCampaign: $canCreateCampaign
  )
}
    `;
export type InvitationAddRoleMutationFn = Apollo.MutationFunction<InvitationAddRoleMutation, InvitationAddRoleMutationVariables>;

/**
 * __useInvitationAddRoleMutation__
 *
 * To run a mutation, you first call `useInvitationAddRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInvitationAddRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [invitationAddRoleMutation, { data, loading, error }] = useInvitationAddRoleMutation({
 *   variables: {
 *      campaignIds: // value for 'campaignIds'
 *      roleId: // value for 'roleId'
 *      invitationId: // value for 'invitationId'
 *      canCreateCampaign: // value for 'canCreateCampaign'
 *   },
 * });
 */
export function useInvitationAddRoleMutation(baseOptions?: Apollo.MutationHookOptions<InvitationAddRoleMutation, InvitationAddRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InvitationAddRoleMutation, InvitationAddRoleMutationVariables>(InvitationAddRoleDocument, options);
      }
export type InvitationAddRoleMutationHookResult = ReturnType<typeof useInvitationAddRoleMutation>;
export type InvitationAddRoleMutationResult = Apollo.MutationResult<InvitationAddRoleMutation>;
export type InvitationAddRoleMutationOptions = Apollo.BaseMutationOptions<InvitationAddRoleMutation, InvitationAddRoleMutationVariables>;
export const InvitationsDocument = gql`
    query Invitations {
  invitations {
    id
    email
    status
    role {
      roleTypeId
      campaigns
    }
    canCreateCampaign
  }
}
    `;

/**
 * __useInvitationsQuery__
 *
 * To run a query within a React component, call `useInvitationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvitationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvitationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useInvitationsQuery(baseOptions?: Apollo.QueryHookOptions<InvitationsQuery, InvitationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InvitationsQuery, InvitationsQueryVariables>(InvitationsDocument, options);
      }
export function useInvitationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InvitationsQuery, InvitationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InvitationsQuery, InvitationsQueryVariables>(InvitationsDocument, options);
        }
export type InvitationsQueryHookResult = ReturnType<typeof useInvitationsQuery>;
export type InvitationsLazyQueryHookResult = ReturnType<typeof useInvitationsLazyQuery>;
export type InvitationsQueryResult = Apollo.QueryResult<InvitationsQuery, InvitationsQueryVariables>;
export const DeleteInvitationDocument = gql`
    mutation DeleteInvitation($invitationId: Int!) {
  deleteInvitation(invitationId: $invitationId)
}
    `;
export type DeleteInvitationMutationFn = Apollo.MutationFunction<DeleteInvitationMutation, DeleteInvitationMutationVariables>;

/**
 * __useDeleteInvitationMutation__
 *
 * To run a mutation, you first call `useDeleteInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteInvitationMutation, { data, loading, error }] = useDeleteInvitationMutation({
 *   variables: {
 *      invitationId: // value for 'invitationId'
 *   },
 * });
 */
export function useDeleteInvitationMutation(baseOptions?: Apollo.MutationHookOptions<DeleteInvitationMutation, DeleteInvitationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteInvitationMutation, DeleteInvitationMutationVariables>(DeleteInvitationDocument, options);
      }
export type DeleteInvitationMutationHookResult = ReturnType<typeof useDeleteInvitationMutation>;
export type DeleteInvitationMutationResult = Apollo.MutationResult<DeleteInvitationMutation>;
export type DeleteInvitationMutationOptions = Apollo.BaseMutationOptions<DeleteInvitationMutation, DeleteInvitationMutationVariables>;
export const InviteUserDocument = gql`
    mutation InviteUser($campaignIds: [Int!]!, $roleId: Int!, $email: String!, $canCreateCampaign: Boolean!) {
  inviteUser(
    campaignIds: $campaignIds
    roleId: $roleId
    email: $email
    canCreateCampaign: $canCreateCampaign
  ) {
    id
    email
    status
    role {
      roleTypeId
      campaigns
    }
    canCreateCampaign
  }
}
    `;
export type InviteUserMutationFn = Apollo.MutationFunction<InviteUserMutation, InviteUserMutationVariables>;

/**
 * __useInviteUserMutation__
 *
 * To run a mutation, you first call `useInviteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteUserMutation, { data, loading, error }] = useInviteUserMutation({
 *   variables: {
 *      campaignIds: // value for 'campaignIds'
 *      roleId: // value for 'roleId'
 *      email: // value for 'email'
 *      canCreateCampaign: // value for 'canCreateCampaign'
 *   },
 * });
 */
export function useInviteUserMutation(baseOptions?: Apollo.MutationHookOptions<InviteUserMutation, InviteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InviteUserMutation, InviteUserMutationVariables>(InviteUserDocument, options);
      }
export type InviteUserMutationHookResult = ReturnType<typeof useInviteUserMutation>;
export type InviteUserMutationResult = Apollo.MutationResult<InviteUserMutation>;
export type InviteUserMutationOptions = Apollo.BaseMutationOptions<InviteUserMutation, InviteUserMutationVariables>;
export const AddLeadEmailAddressDocument = gql`
    mutation addLeadEmailAddress($campaignId: Int!, $email: String!) {
  addLeadEmailAddress(campaignId: $campaignId, email: $email) {
    id
    email
    campaignId
    status
    code
  }
}
    `;
export type AddLeadEmailAddressMutationFn = Apollo.MutationFunction<AddLeadEmailAddressMutation, AddLeadEmailAddressMutationVariables>;

/**
 * __useAddLeadEmailAddressMutation__
 *
 * To run a mutation, you first call `useAddLeadEmailAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddLeadEmailAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addLeadEmailAddressMutation, { data, loading, error }] = useAddLeadEmailAddressMutation({
 *   variables: {
 *      campaignId: // value for 'campaignId'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useAddLeadEmailAddressMutation(baseOptions?: Apollo.MutationHookOptions<AddLeadEmailAddressMutation, AddLeadEmailAddressMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddLeadEmailAddressMutation, AddLeadEmailAddressMutationVariables>(AddLeadEmailAddressDocument, options);
      }
export type AddLeadEmailAddressMutationHookResult = ReturnType<typeof useAddLeadEmailAddressMutation>;
export type AddLeadEmailAddressMutationResult = Apollo.MutationResult<AddLeadEmailAddressMutation>;
export type AddLeadEmailAddressMutationOptions = Apollo.BaseMutationOptions<AddLeadEmailAddressMutation, AddLeadEmailAddressMutationVariables>;
export const GetLeadEmailAddressesDocument = gql`
    query getLeadEmailAddresses($campaignId: Int!) {
  getLeadEmailAddresses(campaignId: $campaignId) {
    id
    email
    campaignId
    status
    code
  }
}
    `;

/**
 * __useGetLeadEmailAddressesQuery__
 *
 * To run a query within a React component, call `useGetLeadEmailAddressesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLeadEmailAddressesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLeadEmailAddressesQuery({
 *   variables: {
 *      campaignId: // value for 'campaignId'
 *   },
 * });
 */
export function useGetLeadEmailAddressesQuery(baseOptions: Apollo.QueryHookOptions<GetLeadEmailAddressesQuery, GetLeadEmailAddressesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLeadEmailAddressesQuery, GetLeadEmailAddressesQueryVariables>(GetLeadEmailAddressesDocument, options);
      }
export function useGetLeadEmailAddressesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLeadEmailAddressesQuery, GetLeadEmailAddressesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLeadEmailAddressesQuery, GetLeadEmailAddressesQueryVariables>(GetLeadEmailAddressesDocument, options);
        }
export type GetLeadEmailAddressesQueryHookResult = ReturnType<typeof useGetLeadEmailAddressesQuery>;
export type GetLeadEmailAddressesLazyQueryHookResult = ReturnType<typeof useGetLeadEmailAddressesLazyQuery>;
export type GetLeadEmailAddressesQueryResult = Apollo.QueryResult<GetLeadEmailAddressesQuery, GetLeadEmailAddressesQueryVariables>;
export const DeleteLeadEmailAddressDocument = gql`
    mutation deleteLeadEmailAddress($campaignId: Int!, $emailId: Int!) {
  deleteLeadEmailAddress(campaignId: $campaignId, emailId: $emailId)
}
    `;
export type DeleteLeadEmailAddressMutationFn = Apollo.MutationFunction<DeleteLeadEmailAddressMutation, DeleteLeadEmailAddressMutationVariables>;

/**
 * __useDeleteLeadEmailAddressMutation__
 *
 * To run a mutation, you first call `useDeleteLeadEmailAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLeadEmailAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLeadEmailAddressMutation, { data, loading, error }] = useDeleteLeadEmailAddressMutation({
 *   variables: {
 *      campaignId: // value for 'campaignId'
 *      emailId: // value for 'emailId'
 *   },
 * });
 */
export function useDeleteLeadEmailAddressMutation(baseOptions?: Apollo.MutationHookOptions<DeleteLeadEmailAddressMutation, DeleteLeadEmailAddressMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteLeadEmailAddressMutation, DeleteLeadEmailAddressMutationVariables>(DeleteLeadEmailAddressDocument, options);
      }
export type DeleteLeadEmailAddressMutationHookResult = ReturnType<typeof useDeleteLeadEmailAddressMutation>;
export type DeleteLeadEmailAddressMutationResult = Apollo.MutationResult<DeleteLeadEmailAddressMutation>;
export type DeleteLeadEmailAddressMutationOptions = Apollo.BaseMutationOptions<DeleteLeadEmailAddressMutation, DeleteLeadEmailAddressMutationVariables>;
export const VerifyLeadEmailAddressDocument = gql`
    mutation verifyLeadEmailAddress($code: String!) {
  verifyLeadEmailAddress(code: $code)
}
    `;
export type VerifyLeadEmailAddressMutationFn = Apollo.MutationFunction<VerifyLeadEmailAddressMutation, VerifyLeadEmailAddressMutationVariables>;

/**
 * __useVerifyLeadEmailAddressMutation__
 *
 * To run a mutation, you first call `useVerifyLeadEmailAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyLeadEmailAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyLeadEmailAddressMutation, { data, loading, error }] = useVerifyLeadEmailAddressMutation({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useVerifyLeadEmailAddressMutation(baseOptions?: Apollo.MutationHookOptions<VerifyLeadEmailAddressMutation, VerifyLeadEmailAddressMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VerifyLeadEmailAddressMutation, VerifyLeadEmailAddressMutationVariables>(VerifyLeadEmailAddressDocument, options);
      }
export type VerifyLeadEmailAddressMutationHookResult = ReturnType<typeof useVerifyLeadEmailAddressMutation>;
export type VerifyLeadEmailAddressMutationResult = Apollo.MutationResult<VerifyLeadEmailAddressMutation>;
export type VerifyLeadEmailAddressMutationOptions = Apollo.BaseMutationOptions<VerifyLeadEmailAddressMutation, VerifyLeadEmailAddressMutationVariables>;
export const LeadsDocument = gql`
    query leads($filter: LeadsInput!, $take: Int, $skip: Int) {
  leads(filter: $filter, take: $take, skip: $skip) {
    items {
      id
      campaignId
      campaignName
      date
      country
      countryCode
      region
      regionCode
      city
      ip
      latitude
      longitude
      timezone
      postalCode
      url
      page
      host
      userAgent
      device
      resolution
      os
      browser
      language
      referrer
      meta
      formData
      unsubscribed
    }
    total
    columns
  }
}
    `;

/**
 * __useLeadsQuery__
 *
 * To run a query within a React component, call `useLeadsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLeadsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLeadsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useLeadsQuery(baseOptions: Apollo.QueryHookOptions<LeadsQuery, LeadsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LeadsQuery, LeadsQueryVariables>(LeadsDocument, options);
      }
export function useLeadsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LeadsQuery, LeadsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LeadsQuery, LeadsQueryVariables>(LeadsDocument, options);
        }
export type LeadsQueryHookResult = ReturnType<typeof useLeadsQuery>;
export type LeadsLazyQueryHookResult = ReturnType<typeof useLeadsLazyQuery>;
export type LeadsQueryResult = Apollo.QueryResult<LeadsQuery, LeadsQueryVariables>;
export const DeleteLeadDocument = gql`
    mutation deleteLead($ids: [String!]!) {
  deleteLead(ids: $ids) {
    status
  }
}
    `;
export type DeleteLeadMutationFn = Apollo.MutationFunction<DeleteLeadMutation, DeleteLeadMutationVariables>;

/**
 * __useDeleteLeadMutation__
 *
 * To run a mutation, you first call `useDeleteLeadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLeadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLeadMutation, { data, loading, error }] = useDeleteLeadMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDeleteLeadMutation(baseOptions?: Apollo.MutationHookOptions<DeleteLeadMutation, DeleteLeadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteLeadMutation, DeleteLeadMutationVariables>(DeleteLeadDocument, options);
      }
export type DeleteLeadMutationHookResult = ReturnType<typeof useDeleteLeadMutation>;
export type DeleteLeadMutationResult = Apollo.MutationResult<DeleteLeadMutation>;
export type DeleteLeadMutationOptions = Apollo.BaseMutationOptions<DeleteLeadMutation, DeleteLeadMutationVariables>;
export const ExportLeadsDocument = gql`
    mutation exportLeads($filter: LeadsInput!) {
  exportLeads(filter: $filter)
}
    `;
export type ExportLeadsMutationFn = Apollo.MutationFunction<ExportLeadsMutation, ExportLeadsMutationVariables>;

/**
 * __useExportLeadsMutation__
 *
 * To run a mutation, you first call `useExportLeadsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportLeadsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportLeadsMutation, { data, loading, error }] = useExportLeadsMutation({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useExportLeadsMutation(baseOptions?: Apollo.MutationHookOptions<ExportLeadsMutation, ExportLeadsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ExportLeadsMutation, ExportLeadsMutationVariables>(ExportLeadsDocument, options);
      }
export type ExportLeadsMutationHookResult = ReturnType<typeof useExportLeadsMutation>;
export type ExportLeadsMutationResult = Apollo.MutationResult<ExportLeadsMutation>;
export type ExportLeadsMutationOptions = Apollo.BaseMutationOptions<ExportLeadsMutation, ExportLeadsMutationVariables>;
export const LeadsPageDocument = gql`
    query LeadsPage {
  campaigns {
    items {
      id
      name
      variantInfo {
        abTestingId
        variant {
          name
        }
      }
    }
  }
}
    `;

/**
 * __useLeadsPageQuery__
 *
 * To run a query within a React component, call `useLeadsPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useLeadsPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLeadsPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useLeadsPageQuery(baseOptions?: Apollo.QueryHookOptions<LeadsPageQuery, LeadsPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LeadsPageQuery, LeadsPageQueryVariables>(LeadsPageDocument, options);
      }
export function useLeadsPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LeadsPageQuery, LeadsPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LeadsPageQuery, LeadsPageQueryVariables>(LeadsPageDocument, options);
        }
export type LeadsPageQueryHookResult = ReturnType<typeof useLeadsPageQuery>;
export type LeadsPageLazyQueryHookResult = ReturnType<typeof useLeadsPageLazyQuery>;
export type LeadsPageQueryResult = Apollo.QueryResult<LeadsPageQuery, LeadsPageQueryVariables>;
export const LineChartAnalyticsDocument = gql`
    query LineChartAnalytics($filter: AnalyticsFilterInput!, $range: LineChartRange!) {
  lineChartAnalytics(filter: $filter, range: $range) {
    display {
      date
      value
    }
    lead {
      date
      value
    }
    interaction {
      date
      value
    }
    conversionRate {
      date
      value
    }
    pageView {
      date
      value
    }
  }
}
    `;

/**
 * __useLineChartAnalyticsQuery__
 *
 * To run a query within a React component, call `useLineChartAnalyticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLineChartAnalyticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLineChartAnalyticsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      range: // value for 'range'
 *   },
 * });
 */
export function useLineChartAnalyticsQuery(baseOptions: Apollo.QueryHookOptions<LineChartAnalyticsQuery, LineChartAnalyticsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LineChartAnalyticsQuery, LineChartAnalyticsQueryVariables>(LineChartAnalyticsDocument, options);
      }
export function useLineChartAnalyticsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LineChartAnalyticsQuery, LineChartAnalyticsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LineChartAnalyticsQuery, LineChartAnalyticsQueryVariables>(LineChartAnalyticsDocument, options);
        }
export type LineChartAnalyticsQueryHookResult = ReturnType<typeof useLineChartAnalyticsQuery>;
export type LineChartAnalyticsLazyQueryHookResult = ReturnType<typeof useLineChartAnalyticsLazyQuery>;
export type LineChartAnalyticsQueryResult = Apollo.QueryResult<LineChartAnalyticsQuery, LineChartAnalyticsQueryVariables>;
export const SupportedLanguagesDocument = gql`
    query supportedLanguages {
  supportedLanguages
}
    `;

/**
 * __useSupportedLanguagesQuery__
 *
 * To run a query within a React component, call `useSupportedLanguagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSupportedLanguagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSupportedLanguagesQuery({
 *   variables: {
 *   },
 * });
 */
export function useSupportedLanguagesQuery(baseOptions?: Apollo.QueryHookOptions<SupportedLanguagesQuery, SupportedLanguagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SupportedLanguagesQuery, SupportedLanguagesQueryVariables>(SupportedLanguagesDocument, options);
      }
export function useSupportedLanguagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SupportedLanguagesQuery, SupportedLanguagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SupportedLanguagesQuery, SupportedLanguagesQueryVariables>(SupportedLanguagesDocument, options);
        }
export type SupportedLanguagesQueryHookResult = ReturnType<typeof useSupportedLanguagesQuery>;
export type SupportedLanguagesLazyQueryHookResult = ReturnType<typeof useSupportedLanguagesLazyQuery>;
export type SupportedLanguagesQueryResult = Apollo.QueryResult<SupportedLanguagesQuery, SupportedLanguagesQueryVariables>;
export const GetOverviewPageAnalyticsDataDocument = gql`
    query getOverviewPageAnalyticsData($input: GetOverviewAnalyticsDataInput!) {
  overviewPageAnalyticsData(input: $input) {
    id
    chartData {
      values
      increaseValues
      labels
      dates
    }
    increasePercentage
    total
    startDate
    endDate
  }
}
    `;

/**
 * __useGetOverviewPageAnalyticsDataQuery__
 *
 * To run a query within a React component, call `useGetOverviewPageAnalyticsDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOverviewPageAnalyticsDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOverviewPageAnalyticsDataQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetOverviewPageAnalyticsDataQuery(baseOptions: Apollo.QueryHookOptions<GetOverviewPageAnalyticsDataQuery, GetOverviewPageAnalyticsDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOverviewPageAnalyticsDataQuery, GetOverviewPageAnalyticsDataQueryVariables>(GetOverviewPageAnalyticsDataDocument, options);
      }
export function useGetOverviewPageAnalyticsDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOverviewPageAnalyticsDataQuery, GetOverviewPageAnalyticsDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOverviewPageAnalyticsDataQuery, GetOverviewPageAnalyticsDataQueryVariables>(GetOverviewPageAnalyticsDataDocument, options);
        }
export type GetOverviewPageAnalyticsDataQueryHookResult = ReturnType<typeof useGetOverviewPageAnalyticsDataQuery>;
export type GetOverviewPageAnalyticsDataLazyQueryHookResult = ReturnType<typeof useGetOverviewPageAnalyticsDataLazyQuery>;
export type GetOverviewPageAnalyticsDataQueryResult = Apollo.QueryResult<GetOverviewPageAnalyticsDataQuery, GetOverviewPageAnalyticsDataQueryVariables>;
export const PageAnalyticsDocument = gql`
    query pageAnalytics($filter: AnalyticsFilterInput!) {
  pageAnalytics(filter: $filter) {
    page
    display
    interaction
    lead
  }
}
    `;

/**
 * __usePageAnalyticsQuery__
 *
 * To run a query within a React component, call `usePageAnalyticsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePageAnalyticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePageAnalyticsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function usePageAnalyticsQuery(baseOptions: Apollo.QueryHookOptions<PageAnalyticsQuery, PageAnalyticsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PageAnalyticsQuery, PageAnalyticsQueryVariables>(PageAnalyticsDocument, options);
      }
export function usePageAnalyticsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PageAnalyticsQuery, PageAnalyticsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PageAnalyticsQuery, PageAnalyticsQueryVariables>(PageAnalyticsDocument, options);
        }
export type PageAnalyticsQueryHookResult = ReturnType<typeof usePageAnalyticsQuery>;
export type PageAnalyticsLazyQueryHookResult = ReturnType<typeof usePageAnalyticsLazyQuery>;
export type PageAnalyticsQueryResult = Apollo.QueryResult<PageAnalyticsQuery, PageAnalyticsQueryVariables>;
export const ReferrerAnalyticsDocument = gql`
    query referrerAnalytics($filter: AnalyticsFilterInput!) {
  referrerAnalytics(filter: $filter) {
    referrer
    display
    interaction
    lead
  }
}
    `;

/**
 * __useReferrerAnalyticsQuery__
 *
 * To run a query within a React component, call `useReferrerAnalyticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReferrerAnalyticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReferrerAnalyticsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useReferrerAnalyticsQuery(baseOptions: Apollo.QueryHookOptions<ReferrerAnalyticsQuery, ReferrerAnalyticsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReferrerAnalyticsQuery, ReferrerAnalyticsQueryVariables>(ReferrerAnalyticsDocument, options);
      }
export function useReferrerAnalyticsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReferrerAnalyticsQuery, ReferrerAnalyticsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReferrerAnalyticsQuery, ReferrerAnalyticsQueryVariables>(ReferrerAnalyticsDocument, options);
        }
export type ReferrerAnalyticsQueryHookResult = ReturnType<typeof useReferrerAnalyticsQuery>;
export type ReferrerAnalyticsLazyQueryHookResult = ReturnType<typeof useReferrerAnalyticsLazyQuery>;
export type ReferrerAnalyticsQueryResult = Apollo.QueryResult<ReferrerAnalyticsQuery, ReferrerAnalyticsQueryVariables>;
export const RegionAnalyticsDocument = gql`
    query regionAnalytics($filter: AnalyticsFilterInput!) {
  regionAnalytics(filter: $filter) {
    country
    region
    city
    display
    interaction
    lead
  }
}
    `;

/**
 * __useRegionAnalyticsQuery__
 *
 * To run a query within a React component, call `useRegionAnalyticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegionAnalyticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegionAnalyticsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useRegionAnalyticsQuery(baseOptions: Apollo.QueryHookOptions<RegionAnalyticsQuery, RegionAnalyticsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RegionAnalyticsQuery, RegionAnalyticsQueryVariables>(RegionAnalyticsDocument, options);
      }
export function useRegionAnalyticsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RegionAnalyticsQuery, RegionAnalyticsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RegionAnalyticsQuery, RegionAnalyticsQueryVariables>(RegionAnalyticsDocument, options);
        }
export type RegionAnalyticsQueryHookResult = ReturnType<typeof useRegionAnalyticsQuery>;
export type RegionAnalyticsLazyQueryHookResult = ReturnType<typeof useRegionAnalyticsLazyQuery>;
export type RegionAnalyticsQueryResult = Apollo.QueryResult<RegionAnalyticsQuery, RegionAnalyticsQueryVariables>;
export const GetAccountRevenueAnalyticDataDocument = gql`
    query getAccountRevenueAnalyticData($input: GetAccountRevenueAnalyticDataInput!) {
  getAccountRevenueAnalyticData(input: $input) {
    chartData {
      values
      increaseValues
      labels
      dates
    }
    increasePercentage
    total
    startDate
    endDate
  }
}
    `;

/**
 * __useGetAccountRevenueAnalyticDataQuery__
 *
 * To run a query within a React component, call `useGetAccountRevenueAnalyticDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountRevenueAnalyticDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountRevenueAnalyticDataQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetAccountRevenueAnalyticDataQuery(baseOptions: Apollo.QueryHookOptions<GetAccountRevenueAnalyticDataQuery, GetAccountRevenueAnalyticDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAccountRevenueAnalyticDataQuery, GetAccountRevenueAnalyticDataQueryVariables>(GetAccountRevenueAnalyticDataDocument, options);
      }
export function useGetAccountRevenueAnalyticDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAccountRevenueAnalyticDataQuery, GetAccountRevenueAnalyticDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAccountRevenueAnalyticDataQuery, GetAccountRevenueAnalyticDataQueryVariables>(GetAccountRevenueAnalyticDataDocument, options);
        }
export type GetAccountRevenueAnalyticDataQueryHookResult = ReturnType<typeof useGetAccountRevenueAnalyticDataQuery>;
export type GetAccountRevenueAnalyticDataLazyQueryHookResult = ReturnType<typeof useGetAccountRevenueAnalyticDataLazyQuery>;
export type GetAccountRevenueAnalyticDataQueryResult = Apollo.QueryResult<GetAccountRevenueAnalyticDataQuery, GetAccountRevenueAnalyticDataQueryVariables>;
export const GetSegmentDocument = gql`
    query getSegment($getSegmentId: Int!) {
  getSegment(id: $getSegmentId) {
    id
    name
    description
    icon
    color
    isPredefined
    segmentCategoryId
    segmentCategoryName
    segmentCategoryKey
    createdAt
    targets
    campaigns {
      id
      name
    }
    isDeletable
    key
  }
}
    `;

/**
 * __useGetSegmentQuery__
 *
 * To run a query within a React component, call `useGetSegmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSegmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSegmentQuery({
 *   variables: {
 *      getSegmentId: // value for 'getSegmentId'
 *   },
 * });
 */
export function useGetSegmentQuery(baseOptions: Apollo.QueryHookOptions<GetSegmentQuery, GetSegmentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSegmentQuery, GetSegmentQueryVariables>(GetSegmentDocument, options);
      }
export function useGetSegmentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSegmentQuery, GetSegmentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSegmentQuery, GetSegmentQueryVariables>(GetSegmentDocument, options);
        }
export type GetSegmentQueryHookResult = ReturnType<typeof useGetSegmentQuery>;
export type GetSegmentLazyQueryHookResult = ReturnType<typeof useGetSegmentLazyQuery>;
export type GetSegmentQueryResult = Apollo.QueryResult<GetSegmentQuery, GetSegmentQueryVariables>;
export const GetSegmentsDocument = gql`
    query getSegments($campaignId: Int!) {
  getSegments(campaignId: $campaignId) {
    id
    name
    description
    icon
    color
    isPredefined
    segmentCategoryId
    segmentCategoryName
    segmentCategoryKey
    createdAt
    targets
    campaigns {
      id
      name
    }
    isDeletable
    key
  }
}
    `;

/**
 * __useGetSegmentsQuery__
 *
 * To run a query within a React component, call `useGetSegmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSegmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSegmentsQuery({
 *   variables: {
 *      campaignId: // value for 'campaignId'
 *   },
 * });
 */
export function useGetSegmentsQuery(baseOptions: Apollo.QueryHookOptions<GetSegmentsQuery, GetSegmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSegmentsQuery, GetSegmentsQueryVariables>(GetSegmentsDocument, options);
      }
export function useGetSegmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSegmentsQuery, GetSegmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSegmentsQuery, GetSegmentsQueryVariables>(GetSegmentsDocument, options);
        }
export type GetSegmentsQueryHookResult = ReturnType<typeof useGetSegmentsQuery>;
export type GetSegmentsLazyQueryHookResult = ReturnType<typeof useGetSegmentsLazyQuery>;
export type GetSegmentsQueryResult = Apollo.QueryResult<GetSegmentsQuery, GetSegmentsQueryVariables>;
export const CreateSegmentDocument = gql`
    mutation createSegment($segment: CreateSegment!) {
  createSegment(segment: $segment) {
    id
    name
    description
    icon
    color
    isPredefined
    segmentCategoryId
    segmentCategoryName
    segmentCategoryKey
    createdAt
    targets
    campaigns {
      id
      name
    }
    isDeletable
    key
  }
}
    `;
export type CreateSegmentMutationFn = Apollo.MutationFunction<CreateSegmentMutation, CreateSegmentMutationVariables>;

/**
 * __useCreateSegmentMutation__
 *
 * To run a mutation, you first call `useCreateSegmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSegmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSegmentMutation, { data, loading, error }] = useCreateSegmentMutation({
 *   variables: {
 *      segment: // value for 'segment'
 *   },
 * });
 */
export function useCreateSegmentMutation(baseOptions?: Apollo.MutationHookOptions<CreateSegmentMutation, CreateSegmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSegmentMutation, CreateSegmentMutationVariables>(CreateSegmentDocument, options);
      }
export type CreateSegmentMutationHookResult = ReturnType<typeof useCreateSegmentMutation>;
export type CreateSegmentMutationResult = Apollo.MutationResult<CreateSegmentMutation>;
export type CreateSegmentMutationOptions = Apollo.BaseMutationOptions<CreateSegmentMutation, CreateSegmentMutationVariables>;
export const UpdateSegmentDocument = gql`
    mutation updateSegment($segment: CreateSegment!, $updateSegmentId: Int!) {
  updateSegment(segment: $segment, id: $updateSegmentId) {
    id
    name
    description
    icon
    color
    isPredefined
    segmentCategoryId
    segmentCategoryName
    segmentCategoryKey
    createdAt
    targets
    campaigns {
      id
      name
    }
    isDeletable
    key
  }
}
    `;
export type UpdateSegmentMutationFn = Apollo.MutationFunction<UpdateSegmentMutation, UpdateSegmentMutationVariables>;

/**
 * __useUpdateSegmentMutation__
 *
 * To run a mutation, you first call `useUpdateSegmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSegmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSegmentMutation, { data, loading, error }] = useUpdateSegmentMutation({
 *   variables: {
 *      segment: // value for 'segment'
 *      updateSegmentId: // value for 'updateSegmentId'
 *   },
 * });
 */
export function useUpdateSegmentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSegmentMutation, UpdateSegmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSegmentMutation, UpdateSegmentMutationVariables>(UpdateSegmentDocument, options);
      }
export type UpdateSegmentMutationHookResult = ReturnType<typeof useUpdateSegmentMutation>;
export type UpdateSegmentMutationResult = Apollo.MutationResult<UpdateSegmentMutation>;
export type UpdateSegmentMutationOptions = Apollo.BaseMutationOptions<UpdateSegmentMutation, UpdateSegmentMutationVariables>;
export const ChangeSegmentNameDocument = gql`
    mutation changeSegmentName($description: String!, $name: String!, $changeSegmentNameId: Int!) {
  changeSegmentName(
    description: $description
    name: $name
    id: $changeSegmentNameId
  ) {
    id
    name
    description
    icon
    color
    isPredefined
    segmentCategoryId
    segmentCategoryName
    segmentCategoryKey
    createdAt
    targets
    campaigns {
      id
      name
    }
    isDeletable
    key
  }
}
    `;
export type ChangeSegmentNameMutationFn = Apollo.MutationFunction<ChangeSegmentNameMutation, ChangeSegmentNameMutationVariables>;

/**
 * __useChangeSegmentNameMutation__
 *
 * To run a mutation, you first call `useChangeSegmentNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeSegmentNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeSegmentNameMutation, { data, loading, error }] = useChangeSegmentNameMutation({
 *   variables: {
 *      description: // value for 'description'
 *      name: // value for 'name'
 *      changeSegmentNameId: // value for 'changeSegmentNameId'
 *   },
 * });
 */
export function useChangeSegmentNameMutation(baseOptions?: Apollo.MutationHookOptions<ChangeSegmentNameMutation, ChangeSegmentNameMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeSegmentNameMutation, ChangeSegmentNameMutationVariables>(ChangeSegmentNameDocument, options);
      }
export type ChangeSegmentNameMutationHookResult = ReturnType<typeof useChangeSegmentNameMutation>;
export type ChangeSegmentNameMutationResult = Apollo.MutationResult<ChangeSegmentNameMutation>;
export type ChangeSegmentNameMutationOptions = Apollo.BaseMutationOptions<ChangeSegmentNameMutation, ChangeSegmentNameMutationVariables>;
export const DeleteSegmentDocument = gql`
    mutation deleteSegment($deleteSegmentId: Int!) {
  deleteSegment(id: $deleteSegmentId)
}
    `;
export type DeleteSegmentMutationFn = Apollo.MutationFunction<DeleteSegmentMutation, DeleteSegmentMutationVariables>;

/**
 * __useDeleteSegmentMutation__
 *
 * To run a mutation, you first call `useDeleteSegmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSegmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSegmentMutation, { data, loading, error }] = useDeleteSegmentMutation({
 *   variables: {
 *      deleteSegmentId: // value for 'deleteSegmentId'
 *   },
 * });
 */
export function useDeleteSegmentMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSegmentMutation, DeleteSegmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSegmentMutation, DeleteSegmentMutationVariables>(DeleteSegmentDocument, options);
      }
export type DeleteSegmentMutationHookResult = ReturnType<typeof useDeleteSegmentMutation>;
export type DeleteSegmentMutationResult = Apollo.MutationResult<DeleteSegmentMutation>;
export type DeleteSegmentMutationOptions = Apollo.BaseMutationOptions<DeleteSegmentMutation, DeleteSegmentMutationVariables>;
export const ShopifyLoginOrRegisterDocument = gql`
    mutation shopifyLoginOrRegister($code: String!, $shop: String!, $hmac: String!, $host: String!, $timestamp: String!) {
  shopifyLoginOrRegister(
    code: $code
    shop: $shop
    hmac: $hmac
    host: $host
    timestamp: $timestamp
  ) {
    accessToken
    refreshToken
    isNewAccount
    addedNewDomain
    addedDomain
  }
}
    `;
export type ShopifyLoginOrRegisterMutationFn = Apollo.MutationFunction<ShopifyLoginOrRegisterMutation, ShopifyLoginOrRegisterMutationVariables>;

/**
 * __useShopifyLoginOrRegisterMutation__
 *
 * To run a mutation, you first call `useShopifyLoginOrRegisterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useShopifyLoginOrRegisterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [shopifyLoginOrRegisterMutation, { data, loading, error }] = useShopifyLoginOrRegisterMutation({
 *   variables: {
 *      code: // value for 'code'
 *      shop: // value for 'shop'
 *      hmac: // value for 'hmac'
 *      host: // value for 'host'
 *      timestamp: // value for 'timestamp'
 *   },
 * });
 */
export function useShopifyLoginOrRegisterMutation(baseOptions?: Apollo.MutationHookOptions<ShopifyLoginOrRegisterMutation, ShopifyLoginOrRegisterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ShopifyLoginOrRegisterMutation, ShopifyLoginOrRegisterMutationVariables>(ShopifyLoginOrRegisterDocument, options);
      }
export type ShopifyLoginOrRegisterMutationHookResult = ReturnType<typeof useShopifyLoginOrRegisterMutation>;
export type ShopifyLoginOrRegisterMutationResult = Apollo.MutationResult<ShopifyLoginOrRegisterMutation>;
export type ShopifyLoginOrRegisterMutationOptions = Apollo.BaseMutationOptions<ShopifyLoginOrRegisterMutation, ShopifyLoginOrRegisterMutationVariables>;
export const LoginWithShopifyDocument = gql`
    mutation loginWithShopify($code: String!, $shop: String!, $hmac: String!, $host: String!, $timestamp: String!) {
  loginWithShopify(
    code: $code
    shop: $shop
    hmac: $hmac
    host: $host
    timestamp: $timestamp
  ) {
    accessToken
    refreshToken
  }
}
    `;
export type LoginWithShopifyMutationFn = Apollo.MutationFunction<LoginWithShopifyMutation, LoginWithShopifyMutationVariables>;

/**
 * __useLoginWithShopifyMutation__
 *
 * To run a mutation, you first call `useLoginWithShopifyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginWithShopifyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginWithShopifyMutation, { data, loading, error }] = useLoginWithShopifyMutation({
 *   variables: {
 *      code: // value for 'code'
 *      shop: // value for 'shop'
 *      hmac: // value for 'hmac'
 *      host: // value for 'host'
 *      timestamp: // value for 'timestamp'
 *   },
 * });
 */
export function useLoginWithShopifyMutation(baseOptions?: Apollo.MutationHookOptions<LoginWithShopifyMutation, LoginWithShopifyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginWithShopifyMutation, LoginWithShopifyMutationVariables>(LoginWithShopifyDocument, options);
      }
export type LoginWithShopifyMutationHookResult = ReturnType<typeof useLoginWithShopifyMutation>;
export type LoginWithShopifyMutationResult = Apollo.MutationResult<LoginWithShopifyMutation>;
export type LoginWithShopifyMutationOptions = Apollo.BaseMutationOptions<LoginWithShopifyMutation, LoginWithShopifyMutationVariables>;
export const ShopifyLinkAccountDocument = gql`
    mutation shopifyLinkAccount($code: String!, $shop: String!, $hmac: String!, $host: String!, $timestamp: String!, $isRegisterPage: Boolean) {
  shopifyLinkAccount(
    code: $code
    shop: $shop
    hmac: $hmac
    host: $host
    timestamp: $timestamp
    isRegisterPage: $isRegisterPage
  ) {
    status
    addedNewDomain
    addedDomain
  }
}
    `;
export type ShopifyLinkAccountMutationFn = Apollo.MutationFunction<ShopifyLinkAccountMutation, ShopifyLinkAccountMutationVariables>;

/**
 * __useShopifyLinkAccountMutation__
 *
 * To run a mutation, you first call `useShopifyLinkAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useShopifyLinkAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [shopifyLinkAccountMutation, { data, loading, error }] = useShopifyLinkAccountMutation({
 *   variables: {
 *      code: // value for 'code'
 *      shop: // value for 'shop'
 *      hmac: // value for 'hmac'
 *      host: // value for 'host'
 *      timestamp: // value for 'timestamp'
 *      isRegisterPage: // value for 'isRegisterPage'
 *   },
 * });
 */
export function useShopifyLinkAccountMutation(baseOptions?: Apollo.MutationHookOptions<ShopifyLinkAccountMutation, ShopifyLinkAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ShopifyLinkAccountMutation, ShopifyLinkAccountMutationVariables>(ShopifyLinkAccountDocument, options);
      }
export type ShopifyLinkAccountMutationHookResult = ReturnType<typeof useShopifyLinkAccountMutation>;
export type ShopifyLinkAccountMutationResult = Apollo.MutationResult<ShopifyLinkAccountMutation>;
export type ShopifyLinkAccountMutationOptions = Apollo.BaseMutationOptions<ShopifyLinkAccountMutation, ShopifyLinkAccountMutationVariables>;
export const CreateShopifySubscriptionDocument = gql`
    mutation createShopifySubscription($planId: Int!) {
  createShopifySubscription(planId: $planId)
}
    `;
export type CreateShopifySubscriptionMutationFn = Apollo.MutationFunction<CreateShopifySubscriptionMutation, CreateShopifySubscriptionMutationVariables>;

/**
 * __useCreateShopifySubscriptionMutation__
 *
 * To run a mutation, you first call `useCreateShopifySubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateShopifySubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createShopifySubscriptionMutation, { data, loading, error }] = useCreateShopifySubscriptionMutation({
 *   variables: {
 *      planId: // value for 'planId'
 *   },
 * });
 */
export function useCreateShopifySubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<CreateShopifySubscriptionMutation, CreateShopifySubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateShopifySubscriptionMutation, CreateShopifySubscriptionMutationVariables>(CreateShopifySubscriptionDocument, options);
      }
export type CreateShopifySubscriptionMutationHookResult = ReturnType<typeof useCreateShopifySubscriptionMutation>;
export type CreateShopifySubscriptionMutationResult = Apollo.MutationResult<CreateShopifySubscriptionMutation>;
export type CreateShopifySubscriptionMutationOptions = Apollo.BaseMutationOptions<CreateShopifySubscriptionMutation, CreateShopifySubscriptionMutationVariables>;
export const CreateOneTimePurchaseDocument = gql`
    mutation createOneTimePurchase($planId: Int!) {
  createOneTimePurchase(planId: $planId)
}
    `;
export type CreateOneTimePurchaseMutationFn = Apollo.MutationFunction<CreateOneTimePurchaseMutation, CreateOneTimePurchaseMutationVariables>;

/**
 * __useCreateOneTimePurchaseMutation__
 *
 * To run a mutation, you first call `useCreateOneTimePurchaseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneTimePurchaseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOneTimePurchaseMutation, { data, loading, error }] = useCreateOneTimePurchaseMutation({
 *   variables: {
 *      planId: // value for 'planId'
 *   },
 * });
 */
export function useCreateOneTimePurchaseMutation(baseOptions?: Apollo.MutationHookOptions<CreateOneTimePurchaseMutation, CreateOneTimePurchaseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOneTimePurchaseMutation, CreateOneTimePurchaseMutationVariables>(CreateOneTimePurchaseDocument, options);
      }
export type CreateOneTimePurchaseMutationHookResult = ReturnType<typeof useCreateOneTimePurchaseMutation>;
export type CreateOneTimePurchaseMutationResult = Apollo.MutationResult<CreateOneTimePurchaseMutation>;
export type CreateOneTimePurchaseMutationOptions = Apollo.BaseMutationOptions<CreateOneTimePurchaseMutation, CreateOneTimePurchaseMutationVariables>;
export const ShopifyAppExtensionStatusDocument = gql`
    mutation shopifyAppExtensionStatus($domain: String!) {
  shopifyAppExtensionStatus(domain: $domain) {
    status
    redirectUrl
  }
}
    `;
export type ShopifyAppExtensionStatusMutationFn = Apollo.MutationFunction<ShopifyAppExtensionStatusMutation, ShopifyAppExtensionStatusMutationVariables>;

/**
 * __useShopifyAppExtensionStatusMutation__
 *
 * To run a mutation, you first call `useShopifyAppExtensionStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useShopifyAppExtensionStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [shopifyAppExtensionStatusMutation, { data, loading, error }] = useShopifyAppExtensionStatusMutation({
 *   variables: {
 *      domain: // value for 'domain'
 *   },
 * });
 */
export function useShopifyAppExtensionStatusMutation(baseOptions?: Apollo.MutationHookOptions<ShopifyAppExtensionStatusMutation, ShopifyAppExtensionStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ShopifyAppExtensionStatusMutation, ShopifyAppExtensionStatusMutationVariables>(ShopifyAppExtensionStatusDocument, options);
      }
export type ShopifyAppExtensionStatusMutationHookResult = ReturnType<typeof useShopifyAppExtensionStatusMutation>;
export type ShopifyAppExtensionStatusMutationResult = Apollo.MutationResult<ShopifyAppExtensionStatusMutation>;
export type ShopifyAppExtensionStatusMutationOptions = Apollo.BaseMutationOptions<ShopifyAppExtensionStatusMutation, ShopifyAppExtensionStatusMutationVariables>;
export const CreateCustomerDocument = gql`
    mutation createCustomer($accountId: Int!, $storeDomain: String!, $customTags: String!, $email: String!, $firstName: String!, $lastName: String!, $phone: String!, $tags: String!, $acceptEmailMarketing: Boolean!, $acceptSmsMarketing: Boolean!) {
  createCustomer(
    accountId: $accountId
    storeDomain: $storeDomain
    customTags: $customTags
    email: $email
    firstName: $firstName
    lastName: $lastName
    phone: $phone
    tags: $tags
    acceptEmailMarketing: $acceptEmailMarketing
    acceptSmsMarketing: $acceptSmsMarketing
  ) {
    customer {
      email
      firstName
      lastName
      phone
      tags
    }
    userErrors {
      field
      message
    }
  }
}
    `;
export type CreateCustomerMutationFn = Apollo.MutationFunction<CreateCustomerMutation, CreateCustomerMutationVariables>;

/**
 * __useCreateCustomerMutation__
 *
 * To run a mutation, you first call `useCreateCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomerMutation, { data, loading, error }] = useCreateCustomerMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      storeDomain: // value for 'storeDomain'
 *      customTags: // value for 'customTags'
 *      email: // value for 'email'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      phone: // value for 'phone'
 *      tags: // value for 'tags'
 *      acceptEmailMarketing: // value for 'acceptEmailMarketing'
 *      acceptSmsMarketing: // value for 'acceptSmsMarketing'
 *   },
 * });
 */
export function useCreateCustomerMutation(baseOptions?: Apollo.MutationHookOptions<CreateCustomerMutation, CreateCustomerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCustomerMutation, CreateCustomerMutationVariables>(CreateCustomerDocument, options);
      }
export type CreateCustomerMutationHookResult = ReturnType<typeof useCreateCustomerMutation>;
export type CreateCustomerMutationResult = Apollo.MutationResult<CreateCustomerMutation>;
export type CreateCustomerMutationOptions = Apollo.BaseMutationOptions<CreateCustomerMutation, CreateCustomerMutationVariables>;
export const GetUrlByShopifyDomainDocument = gql`
    query getUrlByShopifyDomain($domain: String!) {
  getUrlByDomain(domain: $domain) {
    url
  }
}
    `;

/**
 * __useGetUrlByShopifyDomainQuery__
 *
 * To run a query within a React component, call `useGetUrlByShopifyDomainQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUrlByShopifyDomainQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUrlByShopifyDomainQuery({
 *   variables: {
 *      domain: // value for 'domain'
 *   },
 * });
 */
export function useGetUrlByShopifyDomainQuery(baseOptions: Apollo.QueryHookOptions<GetUrlByShopifyDomainQuery, GetUrlByShopifyDomainQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUrlByShopifyDomainQuery, GetUrlByShopifyDomainQueryVariables>(GetUrlByShopifyDomainDocument, options);
      }
export function useGetUrlByShopifyDomainLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUrlByShopifyDomainQuery, GetUrlByShopifyDomainQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUrlByShopifyDomainQuery, GetUrlByShopifyDomainQueryVariables>(GetUrlByShopifyDomainDocument, options);
        }
export type GetUrlByShopifyDomainQueryHookResult = ReturnType<typeof useGetUrlByShopifyDomainQuery>;
export type GetUrlByShopifyDomainLazyQueryHookResult = ReturnType<typeof useGetUrlByShopifyDomainLazyQuery>;
export type GetUrlByShopifyDomainQueryResult = Apollo.QueryResult<GetUrlByShopifyDomainQuery, GetUrlByShopifyDomainQueryVariables>;
export const ShortAnalyticsDocument = gql`
    query shortAnalytics($filter: AnalyticsFilterInput!) {
  shortAnalytics(filter: $filter) {
    display
    previousDisplay
    interaction
    previousInteraction
    lead
    previousLead
    pageView
    previousPageView
  }
}
    `;

/**
 * __useShortAnalyticsQuery__
 *
 * To run a query within a React component, call `useShortAnalyticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useShortAnalyticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShortAnalyticsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useShortAnalyticsQuery(baseOptions: Apollo.QueryHookOptions<ShortAnalyticsQuery, ShortAnalyticsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ShortAnalyticsQuery, ShortAnalyticsQueryVariables>(ShortAnalyticsDocument, options);
      }
export function useShortAnalyticsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ShortAnalyticsQuery, ShortAnalyticsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ShortAnalyticsQuery, ShortAnalyticsQueryVariables>(ShortAnalyticsDocument, options);
        }
export type ShortAnalyticsQueryHookResult = ReturnType<typeof useShortAnalyticsQuery>;
export type ShortAnalyticsLazyQueryHookResult = ReturnType<typeof useShortAnalyticsLazyQuery>;
export type ShortAnalyticsQueryResult = Apollo.QueryResult<ShortAnalyticsQuery, ShortAnalyticsQueryVariables>;
export const CreatePortalLinkDocument = gql`
    query createPortalLink($paymentMode: String) {
  createPortalLink(paymentMode: $paymentMode) {
    url
  }
}
    `;

/**
 * __useCreatePortalLinkQuery__
 *
 * To run a query within a React component, call `useCreatePortalLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreatePortalLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreatePortalLinkQuery({
 *   variables: {
 *      paymentMode: // value for 'paymentMode'
 *   },
 * });
 */
export function useCreatePortalLinkQuery(baseOptions?: Apollo.QueryHookOptions<CreatePortalLinkQuery, CreatePortalLinkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CreatePortalLinkQuery, CreatePortalLinkQueryVariables>(CreatePortalLinkDocument, options);
      }
export function useCreatePortalLinkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CreatePortalLinkQuery, CreatePortalLinkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CreatePortalLinkQuery, CreatePortalLinkQueryVariables>(CreatePortalLinkDocument, options);
        }
export type CreatePortalLinkQueryHookResult = ReturnType<typeof useCreatePortalLinkQuery>;
export type CreatePortalLinkLazyQueryHookResult = ReturnType<typeof useCreatePortalLinkLazyQuery>;
export type CreatePortalLinkQueryResult = Apollo.QueryResult<CreatePortalLinkQuery, CreatePortalLinkQueryVariables>;
export const CreateSessionIdDocument = gql`
    query createSessionId($priceId: String!, $paymentMode: String) {
  createSessionId(priceId: $priceId, paymentMode: $paymentMode) {
    url
  }
}
    `;

/**
 * __useCreateSessionIdQuery__
 *
 * To run a query within a React component, call `useCreateSessionIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreateSessionIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreateSessionIdQuery({
 *   variables: {
 *      priceId: // value for 'priceId'
 *      paymentMode: // value for 'paymentMode'
 *   },
 * });
 */
export function useCreateSessionIdQuery(baseOptions: Apollo.QueryHookOptions<CreateSessionIdQuery, CreateSessionIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CreateSessionIdQuery, CreateSessionIdQueryVariables>(CreateSessionIdDocument, options);
      }
export function useCreateSessionIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CreateSessionIdQuery, CreateSessionIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CreateSessionIdQuery, CreateSessionIdQueryVariables>(CreateSessionIdDocument, options);
        }
export type CreateSessionIdQueryHookResult = ReturnType<typeof useCreateSessionIdQuery>;
export type CreateSessionIdLazyQueryHookResult = ReturnType<typeof useCreateSessionIdLazyQuery>;
export type CreateSessionIdQueryResult = Apollo.QueryResult<CreateSessionIdQuery, CreateSessionIdQueryVariables>;
export const TemplatesDocument = gql`
    query templates($templatesInput: TemplatesInput!) {
  templates(templatesInput: $templatesInput) {
    data {
      id
      description
      deviceType
      imageUrl
      isAnimated
      isMultipage
      sort
      layoutType
      popupType
      name
      title
      goals
      tags
      subTags
      layoutElements
    }
    total
    layoutElements
  }
}
    `;

/**
 * __useTemplatesQuery__
 *
 * To run a query within a React component, call `useTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTemplatesQuery({
 *   variables: {
 *      templatesInput: // value for 'templatesInput'
 *   },
 * });
 */
export function useTemplatesQuery(baseOptions: Apollo.QueryHookOptions<TemplatesQuery, TemplatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TemplatesQuery, TemplatesQueryVariables>(TemplatesDocument, options);
      }
export function useTemplatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TemplatesQuery, TemplatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TemplatesQuery, TemplatesQueryVariables>(TemplatesDocument, options);
        }
export type TemplatesQueryHookResult = ReturnType<typeof useTemplatesQuery>;
export type TemplatesLazyQueryHookResult = ReturnType<typeof useTemplatesLazyQuery>;
export type TemplatesQueryResult = Apollo.QueryResult<TemplatesQuery, TemplatesQueryVariables>;
export const TemplateByIdDocument = gql`
    query templateById($id: Float!) {
  templateById(id: $id) {
    id
    name
    imageUrl
    title
    description
    layoutType
    popupType
    goals
    sort
    deviceType
    isMultipage
    isAnimated
    tags
    subTags
    targets
    layout
    layoutElements
  }
}
    `;

/**
 * __useTemplateByIdQuery__
 *
 * To run a query within a React component, call `useTemplateByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useTemplateByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTemplateByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTemplateByIdQuery(baseOptions: Apollo.QueryHookOptions<TemplateByIdQuery, TemplateByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TemplateByIdQuery, TemplateByIdQueryVariables>(TemplateByIdDocument, options);
      }
export function useTemplateByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TemplateByIdQuery, TemplateByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TemplateByIdQuery, TemplateByIdQueryVariables>(TemplateByIdDocument, options);
        }
export type TemplateByIdQueryHookResult = ReturnType<typeof useTemplateByIdQuery>;
export type TemplateByIdLazyQueryHookResult = ReturnType<typeof useTemplateByIdLazyQuery>;
export type TemplateByIdQueryResult = Apollo.QueryResult<TemplateByIdQuery, TemplateByIdQueryVariables>;
export const TemplateTagsDocument = gql`
    query templateTags {
  templateTags {
    id
    tag
    icon
    type
    count
    seasonsCounts {
      count
      name
    }
    subRelations {
      subTags {
        name
        seasonsCounts {
          count
          name
        }
        count
      }
    }
  }
}
    `;

/**
 * __useTemplateTagsQuery__
 *
 * To run a query within a React component, call `useTemplateTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTemplateTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTemplateTagsQuery({
 *   variables: {
 *   },
 * });
 */
export function useTemplateTagsQuery(baseOptions?: Apollo.QueryHookOptions<TemplateTagsQuery, TemplateTagsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TemplateTagsQuery, TemplateTagsQueryVariables>(TemplateTagsDocument, options);
      }
export function useTemplateTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TemplateTagsQuery, TemplateTagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TemplateTagsQuery, TemplateTagsQueryVariables>(TemplateTagsDocument, options);
        }
export type TemplateTagsQueryHookResult = ReturnType<typeof useTemplateTagsQuery>;
export type TemplateTagsLazyQueryHookResult = ReturnType<typeof useTemplateTagsLazyQuery>;
export type TemplateTagsQueryResult = Apollo.QueryResult<TemplateTagsQuery, TemplateTagsQueryVariables>;
export const TemplateSeasonsDocument = gql`
    query templateSeasons {
  templateSeasons {
    id
    name
  }
}
    `;

/**
 * __useTemplateSeasonsQuery__
 *
 * To run a query within a React component, call `useTemplateSeasonsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTemplateSeasonsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTemplateSeasonsQuery({
 *   variables: {
 *   },
 * });
 */
export function useTemplateSeasonsQuery(baseOptions?: Apollo.QueryHookOptions<TemplateSeasonsQuery, TemplateSeasonsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TemplateSeasonsQuery, TemplateSeasonsQueryVariables>(TemplateSeasonsDocument, options);
      }
export function useTemplateSeasonsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TemplateSeasonsQuery, TemplateSeasonsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TemplateSeasonsQuery, TemplateSeasonsQueryVariables>(TemplateSeasonsDocument, options);
        }
export type TemplateSeasonsQueryHookResult = ReturnType<typeof useTemplateSeasonsQuery>;
export type TemplateSeasonsLazyQueryHookResult = ReturnType<typeof useTemplateSeasonsLazyQuery>;
export type TemplateSeasonsQueryResult = Apollo.QueryResult<TemplateSeasonsQuery, TemplateSeasonsQueryVariables>;
export const GetEmailLeadFromRefDocument = gql`
    mutation getEmailLeadFromRef($ref: String!) {
  getEmailLeadFromRef(ref: $ref) {
    status
    message
    data {
      website
      email
      subscribe
      emailAutomationId
    }
  }
}
    `;
export type GetEmailLeadFromRefMutationFn = Apollo.MutationFunction<GetEmailLeadFromRefMutation, GetEmailLeadFromRefMutationVariables>;

/**
 * __useGetEmailLeadFromRefMutation__
 *
 * To run a mutation, you first call `useGetEmailLeadFromRefMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetEmailLeadFromRefMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getEmailLeadFromRefMutation, { data, loading, error }] = useGetEmailLeadFromRefMutation({
 *   variables: {
 *      ref: // value for 'ref'
 *   },
 * });
 */
export function useGetEmailLeadFromRefMutation(baseOptions?: Apollo.MutationHookOptions<GetEmailLeadFromRefMutation, GetEmailLeadFromRefMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GetEmailLeadFromRefMutation, GetEmailLeadFromRefMutationVariables>(GetEmailLeadFromRefDocument, options);
      }
export type GetEmailLeadFromRefMutationHookResult = ReturnType<typeof useGetEmailLeadFromRefMutation>;
export type GetEmailLeadFromRefMutationResult = Apollo.MutationResult<GetEmailLeadFromRefMutation>;
export type GetEmailLeadFromRefMutationOptions = Apollo.BaseMutationOptions<GetEmailLeadFromRefMutation, GetEmailLeadFromRefMutationVariables>;
export const UnsubscribeToWebsiteDocument = gql`
    mutation unsubscribeToWebsite($ref: String!, $email: String!, $emailAutomationId: Float!) {
  unsubscribeToWebsite(
    ref: $ref
    email: $email
    emailAutomationId: $emailAutomationId
  ) {
    status
    message
    data
  }
}
    `;
export type UnsubscribeToWebsiteMutationFn = Apollo.MutationFunction<UnsubscribeToWebsiteMutation, UnsubscribeToWebsiteMutationVariables>;

/**
 * __useUnsubscribeToWebsiteMutation__
 *
 * To run a mutation, you first call `useUnsubscribeToWebsiteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnsubscribeToWebsiteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unsubscribeToWebsiteMutation, { data, loading, error }] = useUnsubscribeToWebsiteMutation({
 *   variables: {
 *      ref: // value for 'ref'
 *      email: // value for 'email'
 *      emailAutomationId: // value for 'emailAutomationId'
 *   },
 * });
 */
export function useUnsubscribeToWebsiteMutation(baseOptions?: Apollo.MutationHookOptions<UnsubscribeToWebsiteMutation, UnsubscribeToWebsiteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnsubscribeToWebsiteMutation, UnsubscribeToWebsiteMutationVariables>(UnsubscribeToWebsiteDocument, options);
      }
export type UnsubscribeToWebsiteMutationHookResult = ReturnType<typeof useUnsubscribeToWebsiteMutation>;
export type UnsubscribeToWebsiteMutationResult = Apollo.MutationResult<UnsubscribeToWebsiteMutation>;
export type UnsubscribeToWebsiteMutationOptions = Apollo.BaseMutationOptions<UnsubscribeToWebsiteMutation, UnsubscribeToWebsiteMutationVariables>;
export const ResubscribeToWebsiteDocument = gql`
    mutation resubscribeToWebsite($ref: String!, $email: String!, $emailAutomationId: Float!) {
  resubscribeToWebsite(
    ref: $ref
    email: $email
    emailAutomationId: $emailAutomationId
  ) {
    status
    message
    data
  }
}
    `;
export type ResubscribeToWebsiteMutationFn = Apollo.MutationFunction<ResubscribeToWebsiteMutation, ResubscribeToWebsiteMutationVariables>;

/**
 * __useResubscribeToWebsiteMutation__
 *
 * To run a mutation, you first call `useResubscribeToWebsiteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResubscribeToWebsiteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resubscribeToWebsiteMutation, { data, loading, error }] = useResubscribeToWebsiteMutation({
 *   variables: {
 *      ref: // value for 'ref'
 *      email: // value for 'email'
 *      emailAutomationId: // value for 'emailAutomationId'
 *   },
 * });
 */
export function useResubscribeToWebsiteMutation(baseOptions?: Apollo.MutationHookOptions<ResubscribeToWebsiteMutation, ResubscribeToWebsiteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResubscribeToWebsiteMutation, ResubscribeToWebsiteMutationVariables>(ResubscribeToWebsiteDocument, options);
      }
export type ResubscribeToWebsiteMutationHookResult = ReturnType<typeof useResubscribeToWebsiteMutation>;
export type ResubscribeToWebsiteMutationResult = Apollo.MutationResult<ResubscribeToWebsiteMutation>;
export type ResubscribeToWebsiteMutationOptions = Apollo.BaseMutationOptions<ResubscribeToWebsiteMutation, ResubscribeToWebsiteMutationVariables>;
export const WeeklyStatsDetailsFromRefDocument = gql`
    mutation weeklyStatsDetailsFromRef($ref: String!) {
  weeklyStatsDetailsFromRef(ref: $ref) {
    status
    email
  }
}
    `;
export type WeeklyStatsDetailsFromRefMutationFn = Apollo.MutationFunction<WeeklyStatsDetailsFromRefMutation, WeeklyStatsDetailsFromRefMutationVariables>;

/**
 * __useWeeklyStatsDetailsFromRefMutation__
 *
 * To run a mutation, you first call `useWeeklyStatsDetailsFromRefMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWeeklyStatsDetailsFromRefMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [weeklyStatsDetailsFromRefMutation, { data, loading, error }] = useWeeklyStatsDetailsFromRefMutation({
 *   variables: {
 *      ref: // value for 'ref'
 *   },
 * });
 */
export function useWeeklyStatsDetailsFromRefMutation(baseOptions?: Apollo.MutationHookOptions<WeeklyStatsDetailsFromRefMutation, WeeklyStatsDetailsFromRefMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<WeeklyStatsDetailsFromRefMutation, WeeklyStatsDetailsFromRefMutationVariables>(WeeklyStatsDetailsFromRefDocument, options);
      }
export type WeeklyStatsDetailsFromRefMutationHookResult = ReturnType<typeof useWeeklyStatsDetailsFromRefMutation>;
export type WeeklyStatsDetailsFromRefMutationResult = Apollo.MutationResult<WeeklyStatsDetailsFromRefMutation>;
export type WeeklyStatsDetailsFromRefMutationOptions = Apollo.BaseMutationOptions<WeeklyStatsDetailsFromRefMutation, WeeklyStatsDetailsFromRefMutationVariables>;
export const ChangeWeeklyStatsSubscriptionStatusDocument = gql`
    mutation changeWeeklyStatsSubscriptionStatus($ref: String!) {
  changeWeeklyStatsSubscriptionStatus(ref: $ref)
}
    `;
export type ChangeWeeklyStatsSubscriptionStatusMutationFn = Apollo.MutationFunction<ChangeWeeklyStatsSubscriptionStatusMutation, ChangeWeeklyStatsSubscriptionStatusMutationVariables>;

/**
 * __useChangeWeeklyStatsSubscriptionStatusMutation__
 *
 * To run a mutation, you first call `useChangeWeeklyStatsSubscriptionStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeWeeklyStatsSubscriptionStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeWeeklyStatsSubscriptionStatusMutation, { data, loading, error }] = useChangeWeeklyStatsSubscriptionStatusMutation({
 *   variables: {
 *      ref: // value for 'ref'
 *   },
 * });
 */
export function useChangeWeeklyStatsSubscriptionStatusMutation(baseOptions?: Apollo.MutationHookOptions<ChangeWeeklyStatsSubscriptionStatusMutation, ChangeWeeklyStatsSubscriptionStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeWeeklyStatsSubscriptionStatusMutation, ChangeWeeklyStatsSubscriptionStatusMutationVariables>(ChangeWeeklyStatsSubscriptionStatusDocument, options);
      }
export type ChangeWeeklyStatsSubscriptionStatusMutationHookResult = ReturnType<typeof useChangeWeeklyStatsSubscriptionStatusMutation>;
export type ChangeWeeklyStatsSubscriptionStatusMutationResult = Apollo.MutationResult<ChangeWeeklyStatsSubscriptionStatusMutation>;
export type ChangeWeeklyStatsSubscriptionStatusMutationOptions = Apollo.BaseMutationOptions<ChangeWeeklyStatsSubscriptionStatusMutation, ChangeWeeklyStatsSubscriptionStatusMutationVariables>;
export const MeDocument = gql`
    query Me {
  me {
    id
    name
    surname
    accountId
    accountIsGettingStartedCompleted
    accountUseProxiedFonts
    apiToken
    email
    emailVerify
    phone_number
    isOwner
    isShopifyUser
    isPageViewBlocked
    accountPlan {
      description
      displayCount
      domainCount
      id
      impressionCount
      interactionCount
      name
      popupCount
      price
      stripeId
      type
      activePopupCount
    }
    isMigrated
    paymentProvider
    baseShopifyStore
    isShopify
    userHasShopifyWebsite
    systemLanguage
    isAgency
    isSubWorkspace
    accountName
    logoUrl
  }
}
    `;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const ChangeEmailDocument = gql`
    mutation changeEmail($email: String!, $captcha: String!) {
  changeEmail(email: $email, captcha: $captcha) {
    accessToken
    refreshToken
  }
}
    `;
export type ChangeEmailMutationFn = Apollo.MutationFunction<ChangeEmailMutation, ChangeEmailMutationVariables>;

/**
 * __useChangeEmailMutation__
 *
 * To run a mutation, you first call `useChangeEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeEmailMutation, { data, loading, error }] = useChangeEmailMutation({
 *   variables: {
 *      email: // value for 'email'
 *      captcha: // value for 'captcha'
 *   },
 * });
 */
export function useChangeEmailMutation(baseOptions?: Apollo.MutationHookOptions<ChangeEmailMutation, ChangeEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeEmailMutation, ChangeEmailMutationVariables>(ChangeEmailDocument, options);
      }
export type ChangeEmailMutationHookResult = ReturnType<typeof useChangeEmailMutation>;
export type ChangeEmailMutationResult = Apollo.MutationResult<ChangeEmailMutation>;
export type ChangeEmailMutationOptions = Apollo.BaseMutationOptions<ChangeEmailMutation, ChangeEmailMutationVariables>;
export const InvitedUserRegisterDocument = gql`
    mutation invitedUserRegister($code: String!, $password: String!, $domain: String, $lastName: String, $firstName: String) {
  invitedUserRegister(
    code: $code
    password: $password
    domain: $domain
    lastName: $lastName
    firstName: $firstName
  ) {
    refreshToken
    token
    tokenExpireDate
  }
}
    `;
export type InvitedUserRegisterMutationFn = Apollo.MutationFunction<InvitedUserRegisterMutation, InvitedUserRegisterMutationVariables>;

/**
 * __useInvitedUserRegisterMutation__
 *
 * To run a mutation, you first call `useInvitedUserRegisterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInvitedUserRegisterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [invitedUserRegisterMutation, { data, loading, error }] = useInvitedUserRegisterMutation({
 *   variables: {
 *      code: // value for 'code'
 *      password: // value for 'password'
 *      domain: // value for 'domain'
 *      lastName: // value for 'lastName'
 *      firstName: // value for 'firstName'
 *   },
 * });
 */
export function useInvitedUserRegisterMutation(baseOptions?: Apollo.MutationHookOptions<InvitedUserRegisterMutation, InvitedUserRegisterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InvitedUserRegisterMutation, InvitedUserRegisterMutationVariables>(InvitedUserRegisterDocument, options);
      }
export type InvitedUserRegisterMutationHookResult = ReturnType<typeof useInvitedUserRegisterMutation>;
export type InvitedUserRegisterMutationResult = Apollo.MutationResult<InvitedUserRegisterMutation>;
export type InvitedUserRegisterMutationOptions = Apollo.BaseMutationOptions<InvitedUserRegisterMutation, InvitedUserRegisterMutationVariables>;
export const UpdateUserProfileDocument = gql`
    mutation updateUserProfile($name: String!, $surname: String!, $phone_number: String!, $systemLanguage: String!) {
  updateUserProfile(
    name: $name
    surname: $surname
    phone_number: $phone_number
    systemLanguage: $systemLanguage
  )
}
    `;
export type UpdateUserProfileMutationFn = Apollo.MutationFunction<UpdateUserProfileMutation, UpdateUserProfileMutationVariables>;

/**
 * __useUpdateUserProfileMutation__
 *
 * To run a mutation, you first call `useUpdateUserProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserProfileMutation, { data, loading, error }] = useUpdateUserProfileMutation({
 *   variables: {
 *      name: // value for 'name'
 *      surname: // value for 'surname'
 *      phone_number: // value for 'phone_number'
 *      systemLanguage: // value for 'systemLanguage'
 *   },
 * });
 */
export function useUpdateUserProfileMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserProfileMutation, UpdateUserProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserProfileMutation, UpdateUserProfileMutationVariables>(UpdateUserProfileDocument, options);
      }
export type UpdateUserProfileMutationHookResult = ReturnType<typeof useUpdateUserProfileMutation>;
export type UpdateUserProfileMutationResult = Apollo.MutationResult<UpdateUserProfileMutation>;
export type UpdateUserProfileMutationOptions = Apollo.BaseMutationOptions<UpdateUserProfileMutation, UpdateUserProfileMutationVariables>;
export const ChangePasswordDocument = gql`
    mutation changePassword($oldPassword: String!, $newPassword: String!) {
  changePassword(oldPassword: $oldPassword, newPassword: $newPassword)
}
    `;
export type ChangePasswordMutationFn = Apollo.MutationFunction<ChangePasswordMutation, ChangePasswordMutationVariables>;

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      oldPassword: // value for 'oldPassword'
 *      newPassword: // value for 'newPassword'
 *   },
 * });
 */
export function useChangePasswordMutation(baseOptions?: Apollo.MutationHookOptions<ChangePasswordMutation, ChangePasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangePasswordMutation, ChangePasswordMutationVariables>(ChangePasswordDocument, options);
      }
export type ChangePasswordMutationHookResult = ReturnType<typeof useChangePasswordMutation>;
export type ChangePasswordMutationResult = Apollo.MutationResult<ChangePasswordMutation>;
export type ChangePasswordMutationOptions = Apollo.BaseMutationOptions<ChangePasswordMutation, ChangePasswordMutationVariables>;
export const AccountStatsDocument = gql`
    query accountStats {
  accountStats {
    pageView
    planPageView
    email
    planEmail
    autoExtendStatus
    autoExtendPageViewStatus
    autoExtendEmailStatus
    usedPageView
    pageViewLimitExtendTotal
    usedEmail
    emailLimitExtendTotal
    pageViewLimitExtendPrice
    emailLimitExtendPrice
    respondentEmailCount
    selfEmailCount
    isBlocked
  }
}
    `;

/**
 * __useAccountStatsQuery__
 *
 * To run a query within a React component, call `useAccountStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountStatsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAccountStatsQuery(baseOptions?: Apollo.QueryHookOptions<AccountStatsQuery, AccountStatsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AccountStatsQuery, AccountStatsQueryVariables>(AccountStatsDocument, options);
      }
export function useAccountStatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountStatsQuery, AccountStatsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AccountStatsQuery, AccountStatsQueryVariables>(AccountStatsDocument, options);
        }
export type AccountStatsQueryHookResult = ReturnType<typeof useAccountStatsQuery>;
export type AccountStatsLazyQueryHookResult = ReturnType<typeof useAccountStatsLazyQuery>;
export type AccountStatsQueryResult = Apollo.QueryResult<AccountStatsQuery, AccountStatsQueryVariables>;
export const ChangeAccountUserRolesDocument = gql`
    mutation changeAccountUserRoles($campaignIds: [Float!]!, $roleId: Float!, $accountUserId: Float!, $canCreateCampaign: Boolean!) {
  changeAccountUserRoles(
    campaignIds: $campaignIds
    roleId: $roleId
    accountUserId: $accountUserId
    canCreateCampaign: $canCreateCampaign
  )
}
    `;
export type ChangeAccountUserRolesMutationFn = Apollo.MutationFunction<ChangeAccountUserRolesMutation, ChangeAccountUserRolesMutationVariables>;

/**
 * __useChangeAccountUserRolesMutation__
 *
 * To run a mutation, you first call `useChangeAccountUserRolesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeAccountUserRolesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeAccountUserRolesMutation, { data, loading, error }] = useChangeAccountUserRolesMutation({
 *   variables: {
 *      campaignIds: // value for 'campaignIds'
 *      roleId: // value for 'roleId'
 *      accountUserId: // value for 'accountUserId'
 *      canCreateCampaign: // value for 'canCreateCampaign'
 *   },
 * });
 */
export function useChangeAccountUserRolesMutation(baseOptions?: Apollo.MutationHookOptions<ChangeAccountUserRolesMutation, ChangeAccountUserRolesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeAccountUserRolesMutation, ChangeAccountUserRolesMutationVariables>(ChangeAccountUserRolesDocument, options);
      }
export type ChangeAccountUserRolesMutationHookResult = ReturnType<typeof useChangeAccountUserRolesMutation>;
export type ChangeAccountUserRolesMutationResult = Apollo.MutationResult<ChangeAccountUserRolesMutation>;
export type ChangeAccountUserRolesMutationOptions = Apollo.BaseMutationOptions<ChangeAccountUserRolesMutation, ChangeAccountUserRolesMutationVariables>;
export const DeleteAccountDocument = gql`
    mutation deleteAccount($email: String!, $survey: String!) {
  deleteAccount(email: $email, survey: $survey)
}
    `;
export type DeleteAccountMutationFn = Apollo.MutationFunction<DeleteAccountMutation, DeleteAccountMutationVariables>;

/**
 * __useDeleteAccountMutation__
 *
 * To run a mutation, you first call `useDeleteAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAccountMutation, { data, loading, error }] = useDeleteAccountMutation({
 *   variables: {
 *      email: // value for 'email'
 *      survey: // value for 'survey'
 *   },
 * });
 */
export function useDeleteAccountMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAccountMutation, DeleteAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteAccountMutation, DeleteAccountMutationVariables>(DeleteAccountDocument, options);
      }
export type DeleteAccountMutationHookResult = ReturnType<typeof useDeleteAccountMutation>;
export type DeleteAccountMutationResult = Apollo.MutationResult<DeleteAccountMutation>;
export type DeleteAccountMutationOptions = Apollo.BaseMutationOptions<DeleteAccountMutation, DeleteAccountMutationVariables>;
export const AccountsDocument = gql`
    query accounts {
  userAccounts {
    id
    isOwner
    name
    parentId
    isAgency
  }
}
    `;

/**
 * __useAccountsQuery__
 *
 * To run a query within a React component, call `useAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAccountsQuery(baseOptions?: Apollo.QueryHookOptions<AccountsQuery, AccountsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AccountsQuery, AccountsQueryVariables>(AccountsDocument, options);
      }
export function useAccountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountsQuery, AccountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AccountsQuery, AccountsQueryVariables>(AccountsDocument, options);
        }
export type AccountsQueryHookResult = ReturnType<typeof useAccountsQuery>;
export type AccountsLazyQueryHookResult = ReturnType<typeof useAccountsLazyQuery>;
export type AccountsQueryResult = Apollo.QueryResult<AccountsQuery, AccountsQueryVariables>;
export const UpdateAutoLimitExtendDocument = gql`
    mutation updateAutoLimitExtend($emailExtend: Boolean!, $pageViewExtend: Boolean!, $limitExtend: Boolean!) {
  updateAutoLimitExtend(
    emailExtend: $emailExtend
    pageViewExtend: $pageViewExtend
    limitExtend: $limitExtend
  ) {
    status
    invoiceUrl
    error
  }
}
    `;
export type UpdateAutoLimitExtendMutationFn = Apollo.MutationFunction<UpdateAutoLimitExtendMutation, UpdateAutoLimitExtendMutationVariables>;

/**
 * __useUpdateAutoLimitExtendMutation__
 *
 * To run a mutation, you first call `useUpdateAutoLimitExtendMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAutoLimitExtendMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAutoLimitExtendMutation, { data, loading, error }] = useUpdateAutoLimitExtendMutation({
 *   variables: {
 *      emailExtend: // value for 'emailExtend'
 *      pageViewExtend: // value for 'pageViewExtend'
 *      limitExtend: // value for 'limitExtend'
 *   },
 * });
 */
export function useUpdateAutoLimitExtendMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAutoLimitExtendMutation, UpdateAutoLimitExtendMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAutoLimitExtendMutation, UpdateAutoLimitExtendMutationVariables>(UpdateAutoLimitExtendDocument, options);
      }
export type UpdateAutoLimitExtendMutationHookResult = ReturnType<typeof useUpdateAutoLimitExtendMutation>;
export type UpdateAutoLimitExtendMutationResult = Apollo.MutationResult<UpdateAutoLimitExtendMutation>;
export type UpdateAutoLimitExtendMutationOptions = Apollo.BaseMutationOptions<UpdateAutoLimitExtendMutation, UpdateAutoLimitExtendMutationVariables>;
export const GetLimitExtendUnpaidInvoiceDocument = gql`
    query getLimitExtendUnpaidInvoice {
  getLimitExtendUnpaidInvoice {
    status
    invoiceUrl
    error
  }
}
    `;

/**
 * __useGetLimitExtendUnpaidInvoiceQuery__
 *
 * To run a query within a React component, call `useGetLimitExtendUnpaidInvoiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLimitExtendUnpaidInvoiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLimitExtendUnpaidInvoiceQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLimitExtendUnpaidInvoiceQuery(baseOptions?: Apollo.QueryHookOptions<GetLimitExtendUnpaidInvoiceQuery, GetLimitExtendUnpaidInvoiceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLimitExtendUnpaidInvoiceQuery, GetLimitExtendUnpaidInvoiceQueryVariables>(GetLimitExtendUnpaidInvoiceDocument, options);
      }
export function useGetLimitExtendUnpaidInvoiceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLimitExtendUnpaidInvoiceQuery, GetLimitExtendUnpaidInvoiceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLimitExtendUnpaidInvoiceQuery, GetLimitExtendUnpaidInvoiceQueryVariables>(GetLimitExtendUnpaidInvoiceDocument, options);
        }
export type GetLimitExtendUnpaidInvoiceQueryHookResult = ReturnType<typeof useGetLimitExtendUnpaidInvoiceQuery>;
export type GetLimitExtendUnpaidInvoiceLazyQueryHookResult = ReturnType<typeof useGetLimitExtendUnpaidInvoiceLazyQuery>;
export type GetLimitExtendUnpaidInvoiceQueryResult = Apollo.QueryResult<GetLimitExtendUnpaidInvoiceQuery, GetLimitExtendUnpaidInvoiceQueryVariables>;
export const GetAccountsAccountUserDocument = gql`
    query getAccountsAccountUser {
  getAccountsAccountUser {
    id
    email
    status
    role {
      roleTypeId
      campaigns
    }
    canCreateCampaign
  }
}
    `;

/**
 * __useGetAccountsAccountUserQuery__
 *
 * To run a query within a React component, call `useGetAccountsAccountUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountsAccountUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountsAccountUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAccountsAccountUserQuery(baseOptions?: Apollo.QueryHookOptions<GetAccountsAccountUserQuery, GetAccountsAccountUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAccountsAccountUserQuery, GetAccountsAccountUserQueryVariables>(GetAccountsAccountUserDocument, options);
      }
export function useGetAccountsAccountUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAccountsAccountUserQuery, GetAccountsAccountUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAccountsAccountUserQuery, GetAccountsAccountUserQueryVariables>(GetAccountsAccountUserDocument, options);
        }
export type GetAccountsAccountUserQueryHookResult = ReturnType<typeof useGetAccountsAccountUserQuery>;
export type GetAccountsAccountUserLazyQueryHookResult = ReturnType<typeof useGetAccountsAccountUserLazyQuery>;
export type GetAccountsAccountUserQueryResult = Apollo.QueryResult<GetAccountsAccountUserQuery, GetAccountsAccountUserQueryVariables>;
export const ChangeAccountUserStatusDocument = gql`
    mutation changeAccountUserStatus($accountUserId: Float!) {
  changeAccountUserStatus(accountUserId: $accountUserId)
}
    `;
export type ChangeAccountUserStatusMutationFn = Apollo.MutationFunction<ChangeAccountUserStatusMutation, ChangeAccountUserStatusMutationVariables>;

/**
 * __useChangeAccountUserStatusMutation__
 *
 * To run a mutation, you first call `useChangeAccountUserStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeAccountUserStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeAccountUserStatusMutation, { data, loading, error }] = useChangeAccountUserStatusMutation({
 *   variables: {
 *      accountUserId: // value for 'accountUserId'
 *   },
 * });
 */
export function useChangeAccountUserStatusMutation(baseOptions?: Apollo.MutationHookOptions<ChangeAccountUserStatusMutation, ChangeAccountUserStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeAccountUserStatusMutation, ChangeAccountUserStatusMutationVariables>(ChangeAccountUserStatusDocument, options);
      }
export type ChangeAccountUserStatusMutationHookResult = ReturnType<typeof useChangeAccountUserStatusMutation>;
export type ChangeAccountUserStatusMutationResult = Apollo.MutationResult<ChangeAccountUserStatusMutation>;
export type ChangeAccountUserStatusMutationOptions = Apollo.BaseMutationOptions<ChangeAccountUserStatusMutation, ChangeAccountUserStatusMutationVariables>;
export const DeleteTeammateDocument = gql`
    mutation deleteTeammate($accountUserId: Float!) {
  deleteTeammate(accountUserId: $accountUserId)
}
    `;
export type DeleteTeammateMutationFn = Apollo.MutationFunction<DeleteTeammateMutation, DeleteTeammateMutationVariables>;

/**
 * __useDeleteTeammateMutation__
 *
 * To run a mutation, you first call `useDeleteTeammateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTeammateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTeammateMutation, { data, loading, error }] = useDeleteTeammateMutation({
 *   variables: {
 *      accountUserId: // value for 'accountUserId'
 *   },
 * });
 */
export function useDeleteTeammateMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTeammateMutation, DeleteTeammateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTeammateMutation, DeleteTeammateMutationVariables>(DeleteTeammateDocument, options);
      }
export type DeleteTeammateMutationHookResult = ReturnType<typeof useDeleteTeammateMutation>;
export type DeleteTeammateMutationResult = Apollo.MutationResult<DeleteTeammateMutation>;
export type DeleteTeammateMutationOptions = Apollo.BaseMutationOptions<DeleteTeammateMutation, DeleteTeammateMutationVariables>;
export const CampaignListAnalyticsDocument = gql`
    query campaignListAnalytics($campaignIds: [Int!]!) {
  campaignDailyAnalytics(campaignIds: $campaignIds) {
    campaignId
    display
    interaction
    lead
    abTestingId
  }
}
    `;

/**
 * __useCampaignListAnalyticsQuery__
 *
 * To run a query within a React component, call `useCampaignListAnalyticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCampaignListAnalyticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCampaignListAnalyticsQuery({
 *   variables: {
 *      campaignIds: // value for 'campaignIds'
 *   },
 * });
 */
export function useCampaignListAnalyticsQuery(baseOptions: Apollo.QueryHookOptions<CampaignListAnalyticsQuery, CampaignListAnalyticsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CampaignListAnalyticsQuery, CampaignListAnalyticsQueryVariables>(CampaignListAnalyticsDocument, options);
      }
export function useCampaignListAnalyticsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CampaignListAnalyticsQuery, CampaignListAnalyticsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CampaignListAnalyticsQuery, CampaignListAnalyticsQueryVariables>(CampaignListAnalyticsDocument, options);
        }
export type CampaignListAnalyticsQueryHookResult = ReturnType<typeof useCampaignListAnalyticsQuery>;
export type CampaignListAnalyticsLazyQueryHookResult = ReturnType<typeof useCampaignListAnalyticsLazyQuery>;
export type CampaignListAnalyticsQueryResult = Apollo.QueryResult<CampaignListAnalyticsQuery, CampaignListAnalyticsQueryVariables>;
export const CampaignNameListDocument = gql`
    query campaignNameList {
  campaigns {
    total
    items {
      id
      name
      isActive
      publishVersion
      isTemplateSelected
      createdAt
      variantInfo {
        abTestingId
      }
      domains {
        id
        url
        verified
        cms
      }
    }
  }
}
    `;

/**
 * __useCampaignNameListQuery__
 *
 * To run a query within a React component, call `useCampaignNameListQuery` and pass it any options that fit your needs.
 * When your component renders, `useCampaignNameListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCampaignNameListQuery({
 *   variables: {
 *   },
 * });
 */
export function useCampaignNameListQuery(baseOptions?: Apollo.QueryHookOptions<CampaignNameListQuery, CampaignNameListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CampaignNameListQuery, CampaignNameListQueryVariables>(CampaignNameListDocument, options);
      }
export function useCampaignNameListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CampaignNameListQuery, CampaignNameListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CampaignNameListQuery, CampaignNameListQueryVariables>(CampaignNameListDocument, options);
        }
export type CampaignNameListQueryHookResult = ReturnType<typeof useCampaignNameListQuery>;
export type CampaignNameListLazyQueryHookResult = ReturnType<typeof useCampaignNameListLazyQuery>;
export type CampaignNameListQueryResult = Apollo.QueryResult<CampaignNameListQuery, CampaignNameListQueryVariables>;
export const CampaignsDocument = gql`
    query Campaigns($filter: CampaignFilterInput) {
  me {
    isPageViewBlocked
  }
  campaigns(filter: $filter) {
    hasFullAccess
    canCreateCampaign
    total
    items {
      id
      name
      isActive
      publishVersion
      isTemplateSelected
      createdAt
      conversionRate
      folderId
      domains {
        id
        url
        verified
        cms
      }
      variantInfo {
        variantId
        abTestingId
        variant {
          name
          percentage
        }
      }
    }
  }
}
    `;

/**
 * __useCampaignsQuery__
 *
 * To run a query within a React component, call `useCampaignsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCampaignsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCampaignsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCampaignsQuery(baseOptions?: Apollo.QueryHookOptions<CampaignsQuery, CampaignsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CampaignsQuery, CampaignsQueryVariables>(CampaignsDocument, options);
      }
export function useCampaignsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CampaignsQuery, CampaignsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CampaignsQuery, CampaignsQueryVariables>(CampaignsDocument, options);
        }
export type CampaignsQueryHookResult = ReturnType<typeof useCampaignsQuery>;
export type CampaignsLazyQueryHookResult = ReturnType<typeof useCampaignsLazyQuery>;
export type CampaignsQueryResult = Apollo.QueryResult<CampaignsQuery, CampaignsQueryVariables>;
export const CampaignCreateWithTemplateIdDocument = gql`
    mutation campaignCreateWithTemplateId($input: CampaignCreateWithTemplateIdRequest!) {
  campaignCreateWithTemplateId(input: $input) {
    id
    name
  }
}
    `;
export type CampaignCreateWithTemplateIdMutationFn = Apollo.MutationFunction<CampaignCreateWithTemplateIdMutation, CampaignCreateWithTemplateIdMutationVariables>;

/**
 * __useCampaignCreateWithTemplateIdMutation__
 *
 * To run a mutation, you first call `useCampaignCreateWithTemplateIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCampaignCreateWithTemplateIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [campaignCreateWithTemplateIdMutation, { data, loading, error }] = useCampaignCreateWithTemplateIdMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCampaignCreateWithTemplateIdMutation(baseOptions?: Apollo.MutationHookOptions<CampaignCreateWithTemplateIdMutation, CampaignCreateWithTemplateIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CampaignCreateWithTemplateIdMutation, CampaignCreateWithTemplateIdMutationVariables>(CampaignCreateWithTemplateIdDocument, options);
      }
export type CampaignCreateWithTemplateIdMutationHookResult = ReturnType<typeof useCampaignCreateWithTemplateIdMutation>;
export type CampaignCreateWithTemplateIdMutationResult = Apollo.MutationResult<CampaignCreateWithTemplateIdMutation>;
export type CampaignCreateWithTemplateIdMutationOptions = Apollo.BaseMutationOptions<CampaignCreateWithTemplateIdMutation, CampaignCreateWithTemplateIdMutationVariables>;
export const CampaignCreateDocument = gql`
    mutation campaignCreate($input: CampaignCreateRequest!) {
  campaignCreate(input: $input) {
    id
    name
  }
}
    `;
export type CampaignCreateMutationFn = Apollo.MutationFunction<CampaignCreateMutation, CampaignCreateMutationVariables>;

/**
 * __useCampaignCreateMutation__
 *
 * To run a mutation, you first call `useCampaignCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCampaignCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [campaignCreateMutation, { data, loading, error }] = useCampaignCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCampaignCreateMutation(baseOptions?: Apollo.MutationHookOptions<CampaignCreateMutation, CampaignCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CampaignCreateMutation, CampaignCreateMutationVariables>(CampaignCreateDocument, options);
      }
export type CampaignCreateMutationHookResult = ReturnType<typeof useCampaignCreateMutation>;
export type CampaignCreateMutationResult = Apollo.MutationResult<CampaignCreateMutation>;
export type CampaignCreateMutationOptions = Apollo.BaseMutationOptions<CampaignCreateMutation, CampaignCreateMutationVariables>;
export const CampaignChangeStatusDocument = gql`
    mutation campaignChangeStatus($campaignId: Int!, $status: Boolean!) {
  campaignChangeStatus(campaignId: $campaignId, status: $status) {
    id
    name
    isActive
    publishVersion
    isTemplateSelected
    createdAt
    variantInfo {
      variantId
      abTestingId
      variant {
        name
        percentage
      }
    }
  }
}
    `;
export type CampaignChangeStatusMutationFn = Apollo.MutationFunction<CampaignChangeStatusMutation, CampaignChangeStatusMutationVariables>;

/**
 * __useCampaignChangeStatusMutation__
 *
 * To run a mutation, you first call `useCampaignChangeStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCampaignChangeStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [campaignChangeStatusMutation, { data, loading, error }] = useCampaignChangeStatusMutation({
 *   variables: {
 *      campaignId: // value for 'campaignId'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useCampaignChangeStatusMutation(baseOptions?: Apollo.MutationHookOptions<CampaignChangeStatusMutation, CampaignChangeStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CampaignChangeStatusMutation, CampaignChangeStatusMutationVariables>(CampaignChangeStatusDocument, options);
      }
export type CampaignChangeStatusMutationHookResult = ReturnType<typeof useCampaignChangeStatusMutation>;
export type CampaignChangeStatusMutationResult = Apollo.MutationResult<CampaignChangeStatusMutation>;
export type CampaignChangeStatusMutationOptions = Apollo.BaseMutationOptions<CampaignChangeStatusMutation, CampaignChangeStatusMutationVariables>;
export const CampaignPublishDocument = gql`
    mutation campaignPublish($campaignId: Int!) {
  campaignPublish(campaignId: $campaignId) {
    success
    message
    code
  }
}
    `;
export type CampaignPublishMutationFn = Apollo.MutationFunction<CampaignPublishMutation, CampaignPublishMutationVariables>;

/**
 * __useCampaignPublishMutation__
 *
 * To run a mutation, you first call `useCampaignPublishMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCampaignPublishMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [campaignPublishMutation, { data, loading, error }] = useCampaignPublishMutation({
 *   variables: {
 *      campaignId: // value for 'campaignId'
 *   },
 * });
 */
export function useCampaignPublishMutation(baseOptions?: Apollo.MutationHookOptions<CampaignPublishMutation, CampaignPublishMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CampaignPublishMutation, CampaignPublishMutationVariables>(CampaignPublishDocument, options);
      }
export type CampaignPublishMutationHookResult = ReturnType<typeof useCampaignPublishMutation>;
export type CampaignPublishMutationResult = Apollo.MutationResult<CampaignPublishMutation>;
export type CampaignPublishMutationOptions = Apollo.BaseMutationOptions<CampaignPublishMutation, CampaignPublishMutationVariables>;
export const AddDomainRelationDocument = gql`
    mutation addDomainRelation($campaignId: Int!, $domainId: Int!) {
  addDomainRelation(campaignId: $campaignId, domainId: $domainId) {
    id
    url
    verified
    cms
    isShopify
  }
}
    `;
export type AddDomainRelationMutationFn = Apollo.MutationFunction<AddDomainRelationMutation, AddDomainRelationMutationVariables>;

/**
 * __useAddDomainRelationMutation__
 *
 * To run a mutation, you first call `useAddDomainRelationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddDomainRelationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addDomainRelationMutation, { data, loading, error }] = useAddDomainRelationMutation({
 *   variables: {
 *      campaignId: // value for 'campaignId'
 *      domainId: // value for 'domainId'
 *   },
 * });
 */
export function useAddDomainRelationMutation(baseOptions?: Apollo.MutationHookOptions<AddDomainRelationMutation, AddDomainRelationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddDomainRelationMutation, AddDomainRelationMutationVariables>(AddDomainRelationDocument, options);
      }
export type AddDomainRelationMutationHookResult = ReturnType<typeof useAddDomainRelationMutation>;
export type AddDomainRelationMutationResult = Apollo.MutationResult<AddDomainRelationMutation>;
export type AddDomainRelationMutationOptions = Apollo.BaseMutationOptions<AddDomainRelationMutation, AddDomainRelationMutationVariables>;
export const DeleteDomainRelationDocument = gql`
    mutation deleteDomainRelation($campaignId: Int!, $domainId: Int!) {
  deleteDomainRelation(campaignId: $campaignId, domainId: $domainId)
}
    `;
export type DeleteDomainRelationMutationFn = Apollo.MutationFunction<DeleteDomainRelationMutation, DeleteDomainRelationMutationVariables>;

/**
 * __useDeleteDomainRelationMutation__
 *
 * To run a mutation, you first call `useDeleteDomainRelationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDomainRelationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDomainRelationMutation, { data, loading, error }] = useDeleteDomainRelationMutation({
 *   variables: {
 *      campaignId: // value for 'campaignId'
 *      domainId: // value for 'domainId'
 *   },
 * });
 */
export function useDeleteDomainRelationMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDomainRelationMutation, DeleteDomainRelationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDomainRelationMutation, DeleteDomainRelationMutationVariables>(DeleteDomainRelationDocument, options);
      }
export type DeleteDomainRelationMutationHookResult = ReturnType<typeof useDeleteDomainRelationMutation>;
export type DeleteDomainRelationMutationResult = Apollo.MutationResult<DeleteDomainRelationMutation>;
export type DeleteDomainRelationMutationOptions = Apollo.BaseMutationOptions<DeleteDomainRelationMutation, DeleteDomainRelationMutationVariables>;
export const GetCampaignCountsDocument = gql`
    query getCampaignCounts {
  getCampaignCounts {
    total
    active
    passive
  }
}
    `;

/**
 * __useGetCampaignCountsQuery__
 *
 * To run a query within a React component, call `useGetCampaignCountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCampaignCountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCampaignCountsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCampaignCountsQuery(baseOptions?: Apollo.QueryHookOptions<GetCampaignCountsQuery, GetCampaignCountsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCampaignCountsQuery, GetCampaignCountsQueryVariables>(GetCampaignCountsDocument, options);
      }
export function useGetCampaignCountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCampaignCountsQuery, GetCampaignCountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCampaignCountsQuery, GetCampaignCountsQueryVariables>(GetCampaignCountsDocument, options);
        }
export type GetCampaignCountsQueryHookResult = ReturnType<typeof useGetCampaignCountsQuery>;
export type GetCampaignCountsLazyQueryHookResult = ReturnType<typeof useGetCampaignCountsLazyQuery>;
export type GetCampaignCountsQueryResult = Apollo.QueryResult<GetCampaignCountsQuery, GetCampaignCountsQueryVariables>;
export const CampaignsWithDomainNamesDocument = gql`
    query campaignsWithDomainNames {
  campaignsWithDomainNames {
    id
    domains
    name
    variantInfo {
      abTestingId
      variant {
        name
        percentage
      }
    }
  }
}
    `;

/**
 * __useCampaignsWithDomainNamesQuery__
 *
 * To run a query within a React component, call `useCampaignsWithDomainNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCampaignsWithDomainNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCampaignsWithDomainNamesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCampaignsWithDomainNamesQuery(baseOptions?: Apollo.QueryHookOptions<CampaignsWithDomainNamesQuery, CampaignsWithDomainNamesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CampaignsWithDomainNamesQuery, CampaignsWithDomainNamesQueryVariables>(CampaignsWithDomainNamesDocument, options);
      }
export function useCampaignsWithDomainNamesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CampaignsWithDomainNamesQuery, CampaignsWithDomainNamesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CampaignsWithDomainNamesQuery, CampaignsWithDomainNamesQueryVariables>(CampaignsWithDomainNamesDocument, options);
        }
export type CampaignsWithDomainNamesQueryHookResult = ReturnType<typeof useCampaignsWithDomainNamesQuery>;
export type CampaignsWithDomainNamesLazyQueryHookResult = ReturnType<typeof useCampaignsWithDomainNamesLazyQuery>;
export type CampaignsWithDomainNamesQueryResult = Apollo.QueryResult<CampaignsWithDomainNamesQuery, CampaignsWithDomainNamesQueryVariables>;
export const CampaignDocument = gql`
    query Campaign($campaignId: Int!) {
  campaign(campaignId: $campaignId) {
    id
    name
    status
    publishVersion
    autoResponderStatus
    autoResponderReplyTo
    autoResponderSenderName
    autoResponderSubject
    autoResponderMailBody
    domains {
      id
      url
      verified
      cMS
      isShopify
    }
    targets
    layout
    settings
    integrationActiveCampaignCampaigns
    integrationCustomerIoCampaigns
    integrationDripCampaigns
    integrationEmailOctopusCampaigns
    integrationHubSpotCampaigns
    integrationKlaviyoCampaigns
    integrationMailchimpCampaigns
    integrationMailerliteCampaigns
    integrationSendLoopCampaigns
    integrationSendgridCampaigns
    integrationSendinBlueCampaigns
    integrationWebhooks
    createdAt
    variantInfo
  }
}
    `;

/**
 * __useCampaignQuery__
 *
 * To run a query within a React component, call `useCampaignQuery` and pass it any options that fit your needs.
 * When your component renders, `useCampaignQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCampaignQuery({
 *   variables: {
 *      campaignId: // value for 'campaignId'
 *   },
 * });
 */
export function useCampaignQuery(baseOptions: Apollo.QueryHookOptions<CampaignQuery, CampaignQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CampaignQuery, CampaignQueryVariables>(CampaignDocument, options);
      }
export function useCampaignLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CampaignQuery, CampaignQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CampaignQuery, CampaignQueryVariables>(CampaignDocument, options);
        }
export type CampaignQueryHookResult = ReturnType<typeof useCampaignQuery>;
export type CampaignLazyQueryHookResult = ReturnType<typeof useCampaignLazyQuery>;
export type CampaignQueryResult = Apollo.QueryResult<CampaignQuery, CampaignQueryVariables>;
export const ChangeCampaignNameDocument = gql`
    mutation changeCampaignName($name: String!, $campaignId: Int!) {
  changeCampaignName(name: $name, campaignId: $campaignId) {
    id
    name
  }
}
    `;
export type ChangeCampaignNameMutationFn = Apollo.MutationFunction<ChangeCampaignNameMutation, ChangeCampaignNameMutationVariables>;

/**
 * __useChangeCampaignNameMutation__
 *
 * To run a mutation, you first call `useChangeCampaignNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeCampaignNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeCampaignNameMutation, { data, loading, error }] = useChangeCampaignNameMutation({
 *   variables: {
 *      name: // value for 'name'
 *      campaignId: // value for 'campaignId'
 *   },
 * });
 */
export function useChangeCampaignNameMutation(baseOptions?: Apollo.MutationHookOptions<ChangeCampaignNameMutation, ChangeCampaignNameMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeCampaignNameMutation, ChangeCampaignNameMutationVariables>(ChangeCampaignNameDocument, options);
      }
export type ChangeCampaignNameMutationHookResult = ReturnType<typeof useChangeCampaignNameMutation>;
export type ChangeCampaignNameMutationResult = Apollo.MutationResult<ChangeCampaignNameMutation>;
export type ChangeCampaignNameMutationOptions = Apollo.BaseMutationOptions<ChangeCampaignNameMutation, ChangeCampaignNameMutationVariables>;
export const DuplicateCampaignDocument = gql`
    mutation duplicateCampaign($campaignId: Int!) {
  duplicateCampaign(campaignId: $campaignId) {
    id
    name
  }
}
    `;
export type DuplicateCampaignMutationFn = Apollo.MutationFunction<DuplicateCampaignMutation, DuplicateCampaignMutationVariables>;

/**
 * __useDuplicateCampaignMutation__
 *
 * To run a mutation, you first call `useDuplicateCampaignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicateCampaignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicateCampaignMutation, { data, loading, error }] = useDuplicateCampaignMutation({
 *   variables: {
 *      campaignId: // value for 'campaignId'
 *   },
 * });
 */
export function useDuplicateCampaignMutation(baseOptions?: Apollo.MutationHookOptions<DuplicateCampaignMutation, DuplicateCampaignMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DuplicateCampaignMutation, DuplicateCampaignMutationVariables>(DuplicateCampaignDocument, options);
      }
export type DuplicateCampaignMutationHookResult = ReturnType<typeof useDuplicateCampaignMutation>;
export type DuplicateCampaignMutationResult = Apollo.MutationResult<DuplicateCampaignMutation>;
export type DuplicateCampaignMutationOptions = Apollo.BaseMutationOptions<DuplicateCampaignMutation, DuplicateCampaignMutationVariables>;
export const DeleteCampaignDocument = gql`
    mutation deleteCampaign($campaignId: Int!) {
  deleteCampaign(campaignId: $campaignId)
}
    `;
export type DeleteCampaignMutationFn = Apollo.MutationFunction<DeleteCampaignMutation, DeleteCampaignMutationVariables>;

/**
 * __useDeleteCampaignMutation__
 *
 * To run a mutation, you first call `useDeleteCampaignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCampaignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCampaignMutation, { data, loading, error }] = useDeleteCampaignMutation({
 *   variables: {
 *      campaignId: // value for 'campaignId'
 *   },
 * });
 */
export function useDeleteCampaignMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCampaignMutation, DeleteCampaignMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCampaignMutation, DeleteCampaignMutationVariables>(DeleteCampaignDocument, options);
      }
export type DeleteCampaignMutationHookResult = ReturnType<typeof useDeleteCampaignMutation>;
export type DeleteCampaignMutationResult = Apollo.MutationResult<DeleteCampaignMutation>;
export type DeleteCampaignMutationOptions = Apollo.BaseMutationOptions<DeleteCampaignMutation, DeleteCampaignMutationVariables>;
export const GenerateAiPopupDocument = gql`
    mutation generateAiPopup($input: GenerateAiPopupInput!) {
  generateAiPopup(input: $input) {
    id
    name
    success
    error
  }
}
    `;
export type GenerateAiPopupMutationFn = Apollo.MutationFunction<GenerateAiPopupMutation, GenerateAiPopupMutationVariables>;

/**
 * __useGenerateAiPopupMutation__
 *
 * To run a mutation, you first call `useGenerateAiPopupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateAiPopupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateAiPopupMutation, { data, loading, error }] = useGenerateAiPopupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGenerateAiPopupMutation(baseOptions?: Apollo.MutationHookOptions<GenerateAiPopupMutation, GenerateAiPopupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GenerateAiPopupMutation, GenerateAiPopupMutationVariables>(GenerateAiPopupDocument, options);
      }
export type GenerateAiPopupMutationHookResult = ReturnType<typeof useGenerateAiPopupMutation>;
export type GenerateAiPopupMutationResult = Apollo.MutationResult<GenerateAiPopupMutation>;
export type GenerateAiPopupMutationOptions = Apollo.BaseMutationOptions<GenerateAiPopupMutation, GenerateAiPopupMutationVariables>;
export const UpdateCampaignTemplateWithAiDocument = gql`
    mutation updateCampaignTemplateWithAi($input: UpdateCampaignWithAiInput!) {
  updateCampaignTemplateWithAi(input: $input) {
    id
    name
    success
    error
    prompt
  }
}
    `;
export type UpdateCampaignTemplateWithAiMutationFn = Apollo.MutationFunction<UpdateCampaignTemplateWithAiMutation, UpdateCampaignTemplateWithAiMutationVariables>;

/**
 * __useUpdateCampaignTemplateWithAiMutation__
 *
 * To run a mutation, you first call `useUpdateCampaignTemplateWithAiMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCampaignTemplateWithAiMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCampaignTemplateWithAiMutation, { data, loading, error }] = useUpdateCampaignTemplateWithAiMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCampaignTemplateWithAiMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCampaignTemplateWithAiMutation, UpdateCampaignTemplateWithAiMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCampaignTemplateWithAiMutation, UpdateCampaignTemplateWithAiMutationVariables>(UpdateCampaignTemplateWithAiDocument, options);
      }
export type UpdateCampaignTemplateWithAiMutationHookResult = ReturnType<typeof useUpdateCampaignTemplateWithAiMutation>;
export type UpdateCampaignTemplateWithAiMutationResult = Apollo.MutationResult<UpdateCampaignTemplateWithAiMutation>;
export type UpdateCampaignTemplateWithAiMutationOptions = Apollo.BaseMutationOptions<UpdateCampaignTemplateWithAiMutation, UpdateCampaignTemplateWithAiMutationVariables>;
export const GenerateAiTextSuggestionsDocument = gql`
    mutation generateAITextSuggestions($input: GenerateAITextSuggestionsInput!) {
  generateAITextSuggestions(input: $input) {
    success
    suggestions {
      text
    }
    error
  }
}
    `;
export type GenerateAiTextSuggestionsMutationFn = Apollo.MutationFunction<GenerateAiTextSuggestionsMutation, GenerateAiTextSuggestionsMutationVariables>;

/**
 * __useGenerateAiTextSuggestionsMutation__
 *
 * To run a mutation, you first call `useGenerateAiTextSuggestionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateAiTextSuggestionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateAiTextSuggestionsMutation, { data, loading, error }] = useGenerateAiTextSuggestionsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGenerateAiTextSuggestionsMutation(baseOptions?: Apollo.MutationHookOptions<GenerateAiTextSuggestionsMutation, GenerateAiTextSuggestionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GenerateAiTextSuggestionsMutation, GenerateAiTextSuggestionsMutationVariables>(GenerateAiTextSuggestionsDocument, options);
      }
export type GenerateAiTextSuggestionsMutationHookResult = ReturnType<typeof useGenerateAiTextSuggestionsMutation>;
export type GenerateAiTextSuggestionsMutationResult = Apollo.MutationResult<GenerateAiTextSuggestionsMutation>;
export type GenerateAiTextSuggestionsMutationOptions = Apollo.BaseMutationOptions<GenerateAiTextSuggestionsMutation, GenerateAiTextSuggestionsMutationVariables>;
export const GetEmailAutomationAnalyticsDocument = gql`
    query getEmailAutomationAnalytics($filter: EmailAutomationAnalyticsInput!) {
  getEmailAutomationAnalytics(filter: $filter) {
    sent {
      count
      changeToLastPeriod
    }
    delivered {
      count
      rate
      changeToLastPeriod
    }
    opened {
      count
      rate
      changeToLastPeriod
    }
    clicked {
      count
      rate
      changeToLastPeriod
    }
    unsubscribed {
      count
      rate
      changeToLastPeriod
    }
    bounced {
      count
      rate
      changeToLastPeriod
      invalid {
        count
        changeToLastPeriod
      }
    }
  }
}
    `;

/**
 * __useGetEmailAutomationAnalyticsQuery__
 *
 * To run a query within a React component, call `useGetEmailAutomationAnalyticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmailAutomationAnalyticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmailAutomationAnalyticsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetEmailAutomationAnalyticsQuery(baseOptions: Apollo.QueryHookOptions<GetEmailAutomationAnalyticsQuery, GetEmailAutomationAnalyticsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEmailAutomationAnalyticsQuery, GetEmailAutomationAnalyticsQueryVariables>(GetEmailAutomationAnalyticsDocument, options);
      }
export function useGetEmailAutomationAnalyticsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEmailAutomationAnalyticsQuery, GetEmailAutomationAnalyticsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEmailAutomationAnalyticsQuery, GetEmailAutomationAnalyticsQueryVariables>(GetEmailAutomationAnalyticsDocument, options);
        }
export type GetEmailAutomationAnalyticsQueryHookResult = ReturnType<typeof useGetEmailAutomationAnalyticsQuery>;
export type GetEmailAutomationAnalyticsLazyQueryHookResult = ReturnType<typeof useGetEmailAutomationAnalyticsLazyQuery>;
export type GetEmailAutomationAnalyticsQueryResult = Apollo.QueryResult<GetEmailAutomationAnalyticsQuery, GetEmailAutomationAnalyticsQueryVariables>;
export const GetEmailAutomationLineChartAnalyticsDocument = gql`
    query getEmailAutomationLineChartAnalytics($filter: EmailAutomationAnalyticsInput!, $range: EmailAutomationLineChartRange!) {
  getEmailAutomationLineChartAnalytics(filter: $filter, range: $range) {
    sent {
      date
      value
    }
    delivered {
      date
      value
    }
    opened {
      date
      value
    }
    clicked {
      date
      value
    }
  }
}
    `;

/**
 * __useGetEmailAutomationLineChartAnalyticsQuery__
 *
 * To run a query within a React component, call `useGetEmailAutomationLineChartAnalyticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmailAutomationLineChartAnalyticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmailAutomationLineChartAnalyticsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      range: // value for 'range'
 *   },
 * });
 */
export function useGetEmailAutomationLineChartAnalyticsQuery(baseOptions: Apollo.QueryHookOptions<GetEmailAutomationLineChartAnalyticsQuery, GetEmailAutomationLineChartAnalyticsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEmailAutomationLineChartAnalyticsQuery, GetEmailAutomationLineChartAnalyticsQueryVariables>(GetEmailAutomationLineChartAnalyticsDocument, options);
      }
export function useGetEmailAutomationLineChartAnalyticsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEmailAutomationLineChartAnalyticsQuery, GetEmailAutomationLineChartAnalyticsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEmailAutomationLineChartAnalyticsQuery, GetEmailAutomationLineChartAnalyticsQueryVariables>(GetEmailAutomationLineChartAnalyticsDocument, options);
        }
export type GetEmailAutomationLineChartAnalyticsQueryHookResult = ReturnType<typeof useGetEmailAutomationLineChartAnalyticsQuery>;
export type GetEmailAutomationLineChartAnalyticsLazyQueryHookResult = ReturnType<typeof useGetEmailAutomationLineChartAnalyticsLazyQuery>;
export type GetEmailAutomationLineChartAnalyticsQueryResult = Apollo.QueryResult<GetEmailAutomationLineChartAnalyticsQuery, GetEmailAutomationLineChartAnalyticsQueryVariables>;
export const GetEmailAutomationAnalyticsByIdsDocument = gql`
    query getEmailAutomationAnalyticsByIds($ids: [Float!]!, $endDate: DateTime, $startDate: DateTime) {
  emailAutomationAnalyticsByIds(
    ids: $ids
    endDate: $endDate
    startDate: $startDate
  ) {
    items {
      id
      details {
        sent
        delivered
        openedRate
        clickedRate
      }
    }
  }
}
    `;

/**
 * __useGetEmailAutomationAnalyticsByIdsQuery__
 *
 * To run a query within a React component, call `useGetEmailAutomationAnalyticsByIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmailAutomationAnalyticsByIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmailAutomationAnalyticsByIdsQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *      endDate: // value for 'endDate'
 *      startDate: // value for 'startDate'
 *   },
 * });
 */
export function useGetEmailAutomationAnalyticsByIdsQuery(baseOptions: Apollo.QueryHookOptions<GetEmailAutomationAnalyticsByIdsQuery, GetEmailAutomationAnalyticsByIdsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEmailAutomationAnalyticsByIdsQuery, GetEmailAutomationAnalyticsByIdsQueryVariables>(GetEmailAutomationAnalyticsByIdsDocument, options);
      }
export function useGetEmailAutomationAnalyticsByIdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEmailAutomationAnalyticsByIdsQuery, GetEmailAutomationAnalyticsByIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEmailAutomationAnalyticsByIdsQuery, GetEmailAutomationAnalyticsByIdsQueryVariables>(GetEmailAutomationAnalyticsByIdsDocument, options);
        }
export type GetEmailAutomationAnalyticsByIdsQueryHookResult = ReturnType<typeof useGetEmailAutomationAnalyticsByIdsQuery>;
export type GetEmailAutomationAnalyticsByIdsLazyQueryHookResult = ReturnType<typeof useGetEmailAutomationAnalyticsByIdsLazyQuery>;
export type GetEmailAutomationAnalyticsByIdsQueryResult = Apollo.QueryResult<GetEmailAutomationAnalyticsByIdsQuery, GetEmailAutomationAnalyticsByIdsQueryVariables>;
export const GetEmailAutomationsDocument = gql`
    query getEmailAutomations($filter: AutomationsFilter!) {
  emailAutomations(filter: $filter) {
    items {
      id
      name
      accountId
      store {
        domain
      }
      flowId
      status
      campaignId
      Flow {
        id
        name
        description
        icon
        accountId
        isTemplate
        triggerId
        type
        Trigger {
          id
          name
          type
          description
          type
          code
        }
      }
    }
    total
  }
}
    `;

/**
 * __useGetEmailAutomationsQuery__
 *
 * To run a query within a React component, call `useGetEmailAutomationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmailAutomationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmailAutomationsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetEmailAutomationsQuery(baseOptions: Apollo.QueryHookOptions<GetEmailAutomationsQuery, GetEmailAutomationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEmailAutomationsQuery, GetEmailAutomationsQueryVariables>(GetEmailAutomationsDocument, options);
      }
export function useGetEmailAutomationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEmailAutomationsQuery, GetEmailAutomationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEmailAutomationsQuery, GetEmailAutomationsQueryVariables>(GetEmailAutomationsDocument, options);
        }
export type GetEmailAutomationsQueryHookResult = ReturnType<typeof useGetEmailAutomationsQuery>;
export type GetEmailAutomationsLazyQueryHookResult = ReturnType<typeof useGetEmailAutomationsLazyQuery>;
export type GetEmailAutomationsQueryResult = Apollo.QueryResult<GetEmailAutomationsQuery, GetEmailAutomationsQueryVariables>;
export const FindEmailAutomationByCampaignIdDocument = gql`
    query findEmailAutomationByCampaignId($campaignId: Float!) {
  findEmailAutomationByCampaignId(campaignId: $campaignId) {
    emailAutomation {
      id
      name
      accountId
      store {
        domain
      }
      flowId
      status
      campaignId
      Campaign {
        Id
        Name
      }
      Flow {
        id
        name
        description
        icon
        accountId
        isTemplate
        triggerId
        type
        steps
        Trigger {
          id
          name
          type
          description
          type
          code
        }
      }
    }
    message
    success
  }
}
    `;

/**
 * __useFindEmailAutomationByCampaignIdQuery__
 *
 * To run a query within a React component, call `useFindEmailAutomationByCampaignIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindEmailAutomationByCampaignIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindEmailAutomationByCampaignIdQuery({
 *   variables: {
 *      campaignId: // value for 'campaignId'
 *   },
 * });
 */
export function useFindEmailAutomationByCampaignIdQuery(baseOptions: Apollo.QueryHookOptions<FindEmailAutomationByCampaignIdQuery, FindEmailAutomationByCampaignIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindEmailAutomationByCampaignIdQuery, FindEmailAutomationByCampaignIdQueryVariables>(FindEmailAutomationByCampaignIdDocument, options);
      }
export function useFindEmailAutomationByCampaignIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindEmailAutomationByCampaignIdQuery, FindEmailAutomationByCampaignIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindEmailAutomationByCampaignIdQuery, FindEmailAutomationByCampaignIdQueryVariables>(FindEmailAutomationByCampaignIdDocument, options);
        }
export type FindEmailAutomationByCampaignIdQueryHookResult = ReturnType<typeof useFindEmailAutomationByCampaignIdQuery>;
export type FindEmailAutomationByCampaignIdLazyQueryHookResult = ReturnType<typeof useFindEmailAutomationByCampaignIdLazyQuery>;
export type FindEmailAutomationByCampaignIdQueryResult = Apollo.QueryResult<FindEmailAutomationByCampaignIdQuery, FindEmailAutomationByCampaignIdQueryVariables>;
export const GetEmailAutomationsWithoutCampaignDocument = gql`
    query getEmailAutomationsWithoutCampaign {
  emailAutomationsWithoutCampaign {
    id
    name
  }
}
    `;

/**
 * __useGetEmailAutomationsWithoutCampaignQuery__
 *
 * To run a query within a React component, call `useGetEmailAutomationsWithoutCampaignQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmailAutomationsWithoutCampaignQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmailAutomationsWithoutCampaignQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetEmailAutomationsWithoutCampaignQuery(baseOptions?: Apollo.QueryHookOptions<GetEmailAutomationsWithoutCampaignQuery, GetEmailAutomationsWithoutCampaignQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEmailAutomationsWithoutCampaignQuery, GetEmailAutomationsWithoutCampaignQueryVariables>(GetEmailAutomationsWithoutCampaignDocument, options);
      }
export function useGetEmailAutomationsWithoutCampaignLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEmailAutomationsWithoutCampaignQuery, GetEmailAutomationsWithoutCampaignQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEmailAutomationsWithoutCampaignQuery, GetEmailAutomationsWithoutCampaignQueryVariables>(GetEmailAutomationsWithoutCampaignDocument, options);
        }
export type GetEmailAutomationsWithoutCampaignQueryHookResult = ReturnType<typeof useGetEmailAutomationsWithoutCampaignQuery>;
export type GetEmailAutomationsWithoutCampaignLazyQueryHookResult = ReturnType<typeof useGetEmailAutomationsWithoutCampaignLazyQuery>;
export type GetEmailAutomationsWithoutCampaignQueryResult = Apollo.QueryResult<GetEmailAutomationsWithoutCampaignQuery, GetEmailAutomationsWithoutCampaignQueryVariables>;
export const GetEmailAutomationDocument = gql`
    query getEmailAutomation($id: Float!) {
  emailAutomation(id: $id) {
    id
    name
    accountId
    store {
      domain
    }
    Account {
      Name
    }
    flowId
    campaignId
    status
    Flow {
      id
      name
      description
      icon
      accountId
      steps
      frequencyStatus
      isTemplate
      type
      triggerId
      Trigger {
        id
        name
        type
        description
        icon
        type
        code
      }
      emailTemplateId
      EmailTemplate {
        id
        name
        url
        imageUrl
      }
      isPlainText
      plainTextBody
    }
  }
}
    `;

/**
 * __useGetEmailAutomationQuery__
 *
 * To run a query within a React component, call `useGetEmailAutomationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmailAutomationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmailAutomationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetEmailAutomationQuery(baseOptions: Apollo.QueryHookOptions<GetEmailAutomationQuery, GetEmailAutomationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEmailAutomationQuery, GetEmailAutomationQueryVariables>(GetEmailAutomationDocument, options);
      }
export function useGetEmailAutomationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEmailAutomationQuery, GetEmailAutomationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEmailAutomationQuery, GetEmailAutomationQueryVariables>(GetEmailAutomationDocument, options);
        }
export type GetEmailAutomationQueryHookResult = ReturnType<typeof useGetEmailAutomationQuery>;
export type GetEmailAutomationLazyQueryHookResult = ReturnType<typeof useGetEmailAutomationLazyQuery>;
export type GetEmailAutomationQueryResult = Apollo.QueryResult<GetEmailAutomationQuery, GetEmailAutomationQueryVariables>;
export const CreateEmailAutomationDocument = gql`
    mutation createEmailAutomation($input: CreateEmailAutomationInput!) {
  createEmailAutomation(input: $input) {
    id
    name
    accountId
    store {
      domain
    }
    flowId
    campaignId
  }
}
    `;
export type CreateEmailAutomationMutationFn = Apollo.MutationFunction<CreateEmailAutomationMutation, CreateEmailAutomationMutationVariables>;

/**
 * __useCreateEmailAutomationMutation__
 *
 * To run a mutation, you first call `useCreateEmailAutomationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEmailAutomationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEmailAutomationMutation, { data, loading, error }] = useCreateEmailAutomationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEmailAutomationMutation(baseOptions?: Apollo.MutationHookOptions<CreateEmailAutomationMutation, CreateEmailAutomationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateEmailAutomationMutation, CreateEmailAutomationMutationVariables>(CreateEmailAutomationDocument, options);
      }
export type CreateEmailAutomationMutationHookResult = ReturnType<typeof useCreateEmailAutomationMutation>;
export type CreateEmailAutomationMutationResult = Apollo.MutationResult<CreateEmailAutomationMutation>;
export type CreateEmailAutomationMutationOptions = Apollo.BaseMutationOptions<CreateEmailAutomationMutation, CreateEmailAutomationMutationVariables>;
export const UpdateEmailAutomationNameDocument = gql`
    mutation updateEmailAutomationName($id: Float!, $name: String!) {
  updateEmailAutomationName(id: $id, name: $name) {
    id
    name
  }
}
    `;
export type UpdateEmailAutomationNameMutationFn = Apollo.MutationFunction<UpdateEmailAutomationNameMutation, UpdateEmailAutomationNameMutationVariables>;

/**
 * __useUpdateEmailAutomationNameMutation__
 *
 * To run a mutation, you first call `useUpdateEmailAutomationNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEmailAutomationNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEmailAutomationNameMutation, { data, loading, error }] = useUpdateEmailAutomationNameMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useUpdateEmailAutomationNameMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEmailAutomationNameMutation, UpdateEmailAutomationNameMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEmailAutomationNameMutation, UpdateEmailAutomationNameMutationVariables>(UpdateEmailAutomationNameDocument, options);
      }
export type UpdateEmailAutomationNameMutationHookResult = ReturnType<typeof useUpdateEmailAutomationNameMutation>;
export type UpdateEmailAutomationNameMutationResult = Apollo.MutationResult<UpdateEmailAutomationNameMutation>;
export type UpdateEmailAutomationNameMutationOptions = Apollo.BaseMutationOptions<UpdateEmailAutomationNameMutation, UpdateEmailAutomationNameMutationVariables>;
export const DeleteEmailAutomationDocument = gql`
    mutation deleteEmailAutomation($id: Float!) {
  deleteEmailAutomation(id: $id) {
    id
  }
}
    `;
export type DeleteEmailAutomationMutationFn = Apollo.MutationFunction<DeleteEmailAutomationMutation, DeleteEmailAutomationMutationVariables>;

/**
 * __useDeleteEmailAutomationMutation__
 *
 * To run a mutation, you first call `useDeleteEmailAutomationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEmailAutomationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEmailAutomationMutation, { data, loading, error }] = useDeleteEmailAutomationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteEmailAutomationMutation(baseOptions?: Apollo.MutationHookOptions<DeleteEmailAutomationMutation, DeleteEmailAutomationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteEmailAutomationMutation, DeleteEmailAutomationMutationVariables>(DeleteEmailAutomationDocument, options);
      }
export type DeleteEmailAutomationMutationHookResult = ReturnType<typeof useDeleteEmailAutomationMutation>;
export type DeleteEmailAutomationMutationResult = Apollo.MutationResult<DeleteEmailAutomationMutation>;
export type DeleteEmailAutomationMutationOptions = Apollo.BaseMutationOptions<DeleteEmailAutomationMutation, DeleteEmailAutomationMutationVariables>;
export const GetEmailFlowTemplatesDocument = gql`
    query getEmailFlowTemplates {
  emailFlowTemplates {
    id
    name
    description
    icon
    color
    frequencyStatus
    accountId
    type
    isTemplate
    triggerId
  }
}
    `;

/**
 * __useGetEmailFlowTemplatesQuery__
 *
 * To run a query within a React component, call `useGetEmailFlowTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmailFlowTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmailFlowTemplatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetEmailFlowTemplatesQuery(baseOptions?: Apollo.QueryHookOptions<GetEmailFlowTemplatesQuery, GetEmailFlowTemplatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEmailFlowTemplatesQuery, GetEmailFlowTemplatesQueryVariables>(GetEmailFlowTemplatesDocument, options);
      }
export function useGetEmailFlowTemplatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEmailFlowTemplatesQuery, GetEmailFlowTemplatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEmailFlowTemplatesQuery, GetEmailFlowTemplatesQueryVariables>(GetEmailFlowTemplatesDocument, options);
        }
export type GetEmailFlowTemplatesQueryHookResult = ReturnType<typeof useGetEmailFlowTemplatesQuery>;
export type GetEmailFlowTemplatesLazyQueryHookResult = ReturnType<typeof useGetEmailFlowTemplatesLazyQuery>;
export type GetEmailFlowTemplatesQueryResult = Apollo.QueryResult<GetEmailFlowTemplatesQuery, GetEmailFlowTemplatesQueryVariables>;
export const GetTriggersDocument = gql`
    query getTriggers {
  triggers {
    total
    items {
      id
      name
      description
      icon
      type
      code
    }
  }
}
    `;

/**
 * __useGetTriggersQuery__
 *
 * To run a query within a React component, call `useGetTriggersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTriggersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTriggersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTriggersQuery(baseOptions?: Apollo.QueryHookOptions<GetTriggersQuery, GetTriggersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTriggersQuery, GetTriggersQueryVariables>(GetTriggersDocument, options);
      }
export function useGetTriggersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTriggersQuery, GetTriggersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTriggersQuery, GetTriggersQueryVariables>(GetTriggersDocument, options);
        }
export type GetTriggersQueryHookResult = ReturnType<typeof useGetTriggersQuery>;
export type GetTriggersLazyQueryHookResult = ReturnType<typeof useGetTriggersLazyQuery>;
export type GetTriggersQueryResult = Apollo.QueryResult<GetTriggersQuery, GetTriggersQueryVariables>;
export const RelatedCampaignDocument = gql`
    query relatedCampaign($automationId: Float!) {
  relatedCampaign(id: $automationId) {
    id
    name
    isActive
    publishVersion
    isTemplateSelected
    haveForm
    formInputs {
      label
      value
      nodeName
    }
    domains {
      verified
      url
      isShopify
      id
      cms
    }
  }
}
    `;

/**
 * __useRelatedCampaignQuery__
 *
 * To run a query within a React component, call `useRelatedCampaignQuery` and pass it any options that fit your needs.
 * When your component renders, `useRelatedCampaignQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRelatedCampaignQuery({
 *   variables: {
 *      automationId: // value for 'automationId'
 *   },
 * });
 */
export function useRelatedCampaignQuery(baseOptions: Apollo.QueryHookOptions<RelatedCampaignQuery, RelatedCampaignQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RelatedCampaignQuery, RelatedCampaignQueryVariables>(RelatedCampaignDocument, options);
      }
export function useRelatedCampaignLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RelatedCampaignQuery, RelatedCampaignQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RelatedCampaignQuery, RelatedCampaignQueryVariables>(RelatedCampaignDocument, options);
        }
export type RelatedCampaignQueryHookResult = ReturnType<typeof useRelatedCampaignQuery>;
export type RelatedCampaignLazyQueryHookResult = ReturnType<typeof useRelatedCampaignLazyQuery>;
export type RelatedCampaignQueryResult = Apollo.QueryResult<RelatedCampaignQuery, RelatedCampaignQueryVariables>;
export const SendTestEmailDocument = gql`
    mutation sendTestEmail($emails: [String!]!, $id: Float!, $subject: String, $sender: String) {
  sendTestEmail(emails: $emails, id: $id, subject: $subject, sender: $sender) {
    message
    success
  }
}
    `;
export type SendTestEmailMutationFn = Apollo.MutationFunction<SendTestEmailMutation, SendTestEmailMutationVariables>;

/**
 * __useSendTestEmailMutation__
 *
 * To run a mutation, you first call `useSendTestEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendTestEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendTestEmailMutation, { data, loading, error }] = useSendTestEmailMutation({
 *   variables: {
 *      emails: // value for 'emails'
 *      id: // value for 'id'
 *      subject: // value for 'subject'
 *      sender: // value for 'sender'
 *   },
 * });
 */
export function useSendTestEmailMutation(baseOptions?: Apollo.MutationHookOptions<SendTestEmailMutation, SendTestEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendTestEmailMutation, SendTestEmailMutationVariables>(SendTestEmailDocument, options);
      }
export type SendTestEmailMutationHookResult = ReturnType<typeof useSendTestEmailMutation>;
export type SendTestEmailMutationResult = Apollo.MutationResult<SendTestEmailMutation>;
export type SendTestEmailMutationOptions = Apollo.BaseMutationOptions<SendTestEmailMutation, SendTestEmailMutationVariables>;
export const CreateFirstEmailAutomationDocument = gql`
    mutation createFirstEmailAutomation($input: CreateFirstEmailAutomationInput!) {
  createFirstEmailAutomation(input: $input) {
    id
    name
    accountId
    store {
      domain
    }
    flowId
    campaignId
  }
}
    `;
export type CreateFirstEmailAutomationMutationFn = Apollo.MutationFunction<CreateFirstEmailAutomationMutation, CreateFirstEmailAutomationMutationVariables>;

/**
 * __useCreateFirstEmailAutomationMutation__
 *
 * To run a mutation, you first call `useCreateFirstEmailAutomationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFirstEmailAutomationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFirstEmailAutomationMutation, { data, loading, error }] = useCreateFirstEmailAutomationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateFirstEmailAutomationMutation(baseOptions?: Apollo.MutationHookOptions<CreateFirstEmailAutomationMutation, CreateFirstEmailAutomationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateFirstEmailAutomationMutation, CreateFirstEmailAutomationMutationVariables>(CreateFirstEmailAutomationDocument, options);
      }
export type CreateFirstEmailAutomationMutationHookResult = ReturnType<typeof useCreateFirstEmailAutomationMutation>;
export type CreateFirstEmailAutomationMutationResult = Apollo.MutationResult<CreateFirstEmailAutomationMutation>;
export type CreateFirstEmailAutomationMutationOptions = Apollo.BaseMutationOptions<CreateFirstEmailAutomationMutation, CreateFirstEmailAutomationMutationVariables>;
export const UpdateFlowTriggerDocument = gql`
    mutation updateFlowTrigger($id: Float!, $triggerId: Float!, $frequencyStatus: Boolean!, $steps: String!, $isPlainText: Boolean!, $plainTextBody: String!) {
  updateFlowTrigger(
    id: $id
    triggerId: $triggerId
    frequencyStatus: $frequencyStatus
    steps: $steps
    isPlainText: $isPlainText
    plainTextBody: $plainTextBody
  ) {
    id
    name
    description
    icon
    accountId
    steps
    isTemplate
    frequencyStatus
    isPlainText
    plainTextBody
    emailTemplateId
    EmailTemplate {
      id
      name
      url
      imageUrl
    }
    type
    triggerId
    Trigger {
      id
      name
      description
      icon
      type
      code
    }
  }
}
    `;
export type UpdateFlowTriggerMutationFn = Apollo.MutationFunction<UpdateFlowTriggerMutation, UpdateFlowTriggerMutationVariables>;

/**
 * __useUpdateFlowTriggerMutation__
 *
 * To run a mutation, you first call `useUpdateFlowTriggerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFlowTriggerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFlowTriggerMutation, { data, loading, error }] = useUpdateFlowTriggerMutation({
 *   variables: {
 *      id: // value for 'id'
 *      triggerId: // value for 'triggerId'
 *      frequencyStatus: // value for 'frequencyStatus'
 *      steps: // value for 'steps'
 *      isPlainText: // value for 'isPlainText'
 *      plainTextBody: // value for 'plainTextBody'
 *   },
 * });
 */
export function useUpdateFlowTriggerMutation(baseOptions?: Apollo.MutationHookOptions<UpdateFlowTriggerMutation, UpdateFlowTriggerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateFlowTriggerMutation, UpdateFlowTriggerMutationVariables>(UpdateFlowTriggerDocument, options);
      }
export type UpdateFlowTriggerMutationHookResult = ReturnType<typeof useUpdateFlowTriggerMutation>;
export type UpdateFlowTriggerMutationResult = Apollo.MutationResult<UpdateFlowTriggerMutation>;
export type UpdateFlowTriggerMutationOptions = Apollo.BaseMutationOptions<UpdateFlowTriggerMutation, UpdateFlowTriggerMutationVariables>;
export const PublishEmailAutomationDocument = gql`
    mutation publishEmailAutomation($id: Float!, $status: Boolean!) {
  publishEmailAutomation(id: $id, status: $status) {
    id
    status
  }
}
    `;
export type PublishEmailAutomationMutationFn = Apollo.MutationFunction<PublishEmailAutomationMutation, PublishEmailAutomationMutationVariables>;

/**
 * __usePublishEmailAutomationMutation__
 *
 * To run a mutation, you first call `usePublishEmailAutomationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishEmailAutomationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishEmailAutomationMutation, { data, loading, error }] = usePublishEmailAutomationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      status: // value for 'status'
 *   },
 * });
 */
export function usePublishEmailAutomationMutation(baseOptions?: Apollo.MutationHookOptions<PublishEmailAutomationMutation, PublishEmailAutomationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PublishEmailAutomationMutation, PublishEmailAutomationMutationVariables>(PublishEmailAutomationDocument, options);
      }
export type PublishEmailAutomationMutationHookResult = ReturnType<typeof usePublishEmailAutomationMutation>;
export type PublishEmailAutomationMutationResult = Apollo.MutationResult<PublishEmailAutomationMutation>;
export type PublishEmailAutomationMutationOptions = Apollo.BaseMutationOptions<PublishEmailAutomationMutation, PublishEmailAutomationMutationVariables>;
export const ConnectCampaignDocument = gql`
    mutation connectCampaign($id: Float!, $campaignId: Int) {
  connectCampaign(id: $id, campaignId: $campaignId) {
    id
    campaignId
  }
}
    `;
export type ConnectCampaignMutationFn = Apollo.MutationFunction<ConnectCampaignMutation, ConnectCampaignMutationVariables>;

/**
 * __useConnectCampaignMutation__
 *
 * To run a mutation, you first call `useConnectCampaignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConnectCampaignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [connectCampaignMutation, { data, loading, error }] = useConnectCampaignMutation({
 *   variables: {
 *      id: // value for 'id'
 *      campaignId: // value for 'campaignId'
 *   },
 * });
 */
export function useConnectCampaignMutation(baseOptions?: Apollo.MutationHookOptions<ConnectCampaignMutation, ConnectCampaignMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ConnectCampaignMutation, ConnectCampaignMutationVariables>(ConnectCampaignDocument, options);
      }
export type ConnectCampaignMutationHookResult = ReturnType<typeof useConnectCampaignMutation>;
export type ConnectCampaignMutationResult = Apollo.MutationResult<ConnectCampaignMutation>;
export type ConnectCampaignMutationOptions = Apollo.BaseMutationOptions<ConnectCampaignMutation, ConnectCampaignMutationVariables>;
export const UpdateDomainDocument = gql`
    mutation updateDomain($id: Float!, $domain: String!) {
  updateDomain(id: $id, domain: $domain) {
    id
    store {
      domain
    }
  }
}
    `;
export type UpdateDomainMutationFn = Apollo.MutationFunction<UpdateDomainMutation, UpdateDomainMutationVariables>;

/**
 * __useUpdateDomainMutation__
 *
 * To run a mutation, you first call `useUpdateDomainMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDomainMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDomainMutation, { data, loading, error }] = useUpdateDomainMutation({
 *   variables: {
 *      id: // value for 'id'
 *      domain: // value for 'domain'
 *   },
 * });
 */
export function useUpdateDomainMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDomainMutation, UpdateDomainMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDomainMutation, UpdateDomainMutationVariables>(UpdateDomainDocument, options);
      }
export type UpdateDomainMutationHookResult = ReturnType<typeof useUpdateDomainMutation>;
export type UpdateDomainMutationResult = Apollo.MutationResult<UpdateDomainMutation>;
export type UpdateDomainMutationOptions = Apollo.BaseMutationOptions<UpdateDomainMutation, UpdateDomainMutationVariables>;
export const CampaignsWithoutEmailAutomationDocument = gql`
    query campaignsWithoutEmailAutomation($filter: AutomationsFilter!) {
  campaignsWithoutEmailAutomation(filter: $filter) {
    name
    id
    domains {
      id
      url
      verified
      isShopify
    }
  }
}
    `;

/**
 * __useCampaignsWithoutEmailAutomationQuery__
 *
 * To run a query within a React component, call `useCampaignsWithoutEmailAutomationQuery` and pass it any options that fit your needs.
 * When your component renders, `useCampaignsWithoutEmailAutomationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCampaignsWithoutEmailAutomationQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCampaignsWithoutEmailAutomationQuery(baseOptions: Apollo.QueryHookOptions<CampaignsWithoutEmailAutomationQuery, CampaignsWithoutEmailAutomationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CampaignsWithoutEmailAutomationQuery, CampaignsWithoutEmailAutomationQueryVariables>(CampaignsWithoutEmailAutomationDocument, options);
      }
export function useCampaignsWithoutEmailAutomationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CampaignsWithoutEmailAutomationQuery, CampaignsWithoutEmailAutomationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CampaignsWithoutEmailAutomationQuery, CampaignsWithoutEmailAutomationQueryVariables>(CampaignsWithoutEmailAutomationDocument, options);
        }
export type CampaignsWithoutEmailAutomationQueryHookResult = ReturnType<typeof useCampaignsWithoutEmailAutomationQuery>;
export type CampaignsWithoutEmailAutomationLazyQueryHookResult = ReturnType<typeof useCampaignsWithoutEmailAutomationLazyQuery>;
export type CampaignsWithoutEmailAutomationQueryResult = Apollo.QueryResult<CampaignsWithoutEmailAutomationQuery, CampaignsWithoutEmailAutomationQueryVariables>;
export const EmailTemplatesDocument = gql`
    query emailTemplates {
  emailTemplates {
    id
    name
    url
    imageUrl
  }
}
    `;

/**
 * __useEmailTemplatesQuery__
 *
 * To run a query within a React component, call `useEmailTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmailTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmailTemplatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useEmailTemplatesQuery(baseOptions?: Apollo.QueryHookOptions<EmailTemplatesQuery, EmailTemplatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EmailTemplatesQuery, EmailTemplatesQueryVariables>(EmailTemplatesDocument, options);
      }
export function useEmailTemplatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EmailTemplatesQuery, EmailTemplatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EmailTemplatesQuery, EmailTemplatesQueryVariables>(EmailTemplatesDocument, options);
        }
export type EmailTemplatesQueryHookResult = ReturnType<typeof useEmailTemplatesQuery>;
export type EmailTemplatesLazyQueryHookResult = ReturnType<typeof useEmailTemplatesLazyQuery>;
export type EmailTemplatesQueryResult = Apollo.QueryResult<EmailTemplatesQuery, EmailTemplatesQueryVariables>;
export const UpdateFlowEmailTemplateDocument = gql`
    mutation updateFlowEmailTemplate($id: Float!, $emailTemplateId: Float!, $isPlainText: Boolean!) {
  updateFlowEmailTemplate(
    id: $id
    emailTemplateId: $emailTemplateId
    isPlainText: $isPlainText
  ) {
    id
    name
    description
    icon
    accountId
    steps
    isTemplate
    frequencyStatus
    isPlainText
    plainTextBody
    emailTemplateId
    EmailTemplate {
      id
      name
      url
      imageUrl
    }
    type
    triggerId
    Trigger {
      id
      name
      description
      icon
      type
      code
    }
  }
}
    `;
export type UpdateFlowEmailTemplateMutationFn = Apollo.MutationFunction<UpdateFlowEmailTemplateMutation, UpdateFlowEmailTemplateMutationVariables>;

/**
 * __useUpdateFlowEmailTemplateMutation__
 *
 * To run a mutation, you first call `useUpdateFlowEmailTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFlowEmailTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFlowEmailTemplateMutation, { data, loading, error }] = useUpdateFlowEmailTemplateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      emailTemplateId: // value for 'emailTemplateId'
 *      isPlainText: // value for 'isPlainText'
 *   },
 * });
 */
export function useUpdateFlowEmailTemplateMutation(baseOptions?: Apollo.MutationHookOptions<UpdateFlowEmailTemplateMutation, UpdateFlowEmailTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateFlowEmailTemplateMutation, UpdateFlowEmailTemplateMutationVariables>(UpdateFlowEmailTemplateDocument, options);
      }
export type UpdateFlowEmailTemplateMutationHookResult = ReturnType<typeof useUpdateFlowEmailTemplateMutation>;
export type UpdateFlowEmailTemplateMutationResult = Apollo.MutationResult<UpdateFlowEmailTemplateMutation>;
export type UpdateFlowEmailTemplateMutationOptions = Apollo.BaseMutationOptions<UpdateFlowEmailTemplateMutation, UpdateFlowEmailTemplateMutationVariables>;
export const DefaultEmailTemplateByTriggerDocument = gql`
    query defaultEmailTemplateByTrigger($id: Float!) {
  defaultEmailTemplateByTrigger(id: $id) {
    id
    name
    url
    imageUrl
  }
}
    `;

/**
 * __useDefaultEmailTemplateByTriggerQuery__
 *
 * To run a query within a React component, call `useDefaultEmailTemplateByTriggerQuery` and pass it any options that fit your needs.
 * When your component renders, `useDefaultEmailTemplateByTriggerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDefaultEmailTemplateByTriggerQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDefaultEmailTemplateByTriggerQuery(baseOptions: Apollo.QueryHookOptions<DefaultEmailTemplateByTriggerQuery, DefaultEmailTemplateByTriggerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DefaultEmailTemplateByTriggerQuery, DefaultEmailTemplateByTriggerQueryVariables>(DefaultEmailTemplateByTriggerDocument, options);
      }
export function useDefaultEmailTemplateByTriggerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DefaultEmailTemplateByTriggerQuery, DefaultEmailTemplateByTriggerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DefaultEmailTemplateByTriggerQuery, DefaultEmailTemplateByTriggerQueryVariables>(DefaultEmailTemplateByTriggerDocument, options);
        }
export type DefaultEmailTemplateByTriggerQueryHookResult = ReturnType<typeof useDefaultEmailTemplateByTriggerQuery>;
export type DefaultEmailTemplateByTriggerLazyQueryHookResult = ReturnType<typeof useDefaultEmailTemplateByTriggerLazyQuery>;
export type DefaultEmailTemplateByTriggerQueryResult = Apollo.QueryResult<DefaultEmailTemplateByTriggerQuery, DefaultEmailTemplateByTriggerQueryVariables>;
export const EmailAutomationLeadsDocument = gql`
    query emailAutomationLeads($filter: EmailAutomationLeadsInput!, $take: Int, $skip: Int) {
  emailAutomationLeads(filter: $filter, take: $take, skip: $skip) {
    items {
      id
      email
      status
      sendingDate
      isOpened
      isClicked
      subscribe
    }
    total
    columns
  }
}
    `;

/**
 * __useEmailAutomationLeadsQuery__
 *
 * To run a query within a React component, call `useEmailAutomationLeadsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmailAutomationLeadsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmailAutomationLeadsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useEmailAutomationLeadsQuery(baseOptions: Apollo.QueryHookOptions<EmailAutomationLeadsQuery, EmailAutomationLeadsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EmailAutomationLeadsQuery, EmailAutomationLeadsQueryVariables>(EmailAutomationLeadsDocument, options);
      }
export function useEmailAutomationLeadsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EmailAutomationLeadsQuery, EmailAutomationLeadsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EmailAutomationLeadsQuery, EmailAutomationLeadsQueryVariables>(EmailAutomationLeadsDocument, options);
        }
export type EmailAutomationLeadsQueryHookResult = ReturnType<typeof useEmailAutomationLeadsQuery>;
export type EmailAutomationLeadsLazyQueryHookResult = ReturnType<typeof useEmailAutomationLeadsLazyQuery>;
export type EmailAutomationLeadsQueryResult = Apollo.QueryResult<EmailAutomationLeadsQuery, EmailAutomationLeadsQueryVariables>;
export const DeleteEmailAutomationLeadsDocument = gql`
    mutation deleteEmailAutomationLeads($ids: [String!]!) {
  deleteEmailAutomationLeads(ids: $ids) {
    status
  }
}
    `;
export type DeleteEmailAutomationLeadsMutationFn = Apollo.MutationFunction<DeleteEmailAutomationLeadsMutation, DeleteEmailAutomationLeadsMutationVariables>;

/**
 * __useDeleteEmailAutomationLeadsMutation__
 *
 * To run a mutation, you first call `useDeleteEmailAutomationLeadsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEmailAutomationLeadsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEmailAutomationLeadsMutation, { data, loading, error }] = useDeleteEmailAutomationLeadsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDeleteEmailAutomationLeadsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteEmailAutomationLeadsMutation, DeleteEmailAutomationLeadsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteEmailAutomationLeadsMutation, DeleteEmailAutomationLeadsMutationVariables>(DeleteEmailAutomationLeadsDocument, options);
      }
export type DeleteEmailAutomationLeadsMutationHookResult = ReturnType<typeof useDeleteEmailAutomationLeadsMutation>;
export type DeleteEmailAutomationLeadsMutationResult = Apollo.MutationResult<DeleteEmailAutomationLeadsMutation>;
export type DeleteEmailAutomationLeadsMutationOptions = Apollo.BaseMutationOptions<DeleteEmailAutomationLeadsMutation, DeleteEmailAutomationLeadsMutationVariables>;
export const EmailAutomationLeadsExportDocument = gql`
    mutation emailAutomationLeadsExport($filter: EmailAutomationLeadsInput!) {
  emailAutomationLeadsExport(filter: $filter)
}
    `;
export type EmailAutomationLeadsExportMutationFn = Apollo.MutationFunction<EmailAutomationLeadsExportMutation, EmailAutomationLeadsExportMutationVariables>;

/**
 * __useEmailAutomationLeadsExportMutation__
 *
 * To run a mutation, you first call `useEmailAutomationLeadsExportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEmailAutomationLeadsExportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [emailAutomationLeadsExportMutation, { data, loading, error }] = useEmailAutomationLeadsExportMutation({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useEmailAutomationLeadsExportMutation(baseOptions?: Apollo.MutationHookOptions<EmailAutomationLeadsExportMutation, EmailAutomationLeadsExportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EmailAutomationLeadsExportMutation, EmailAutomationLeadsExportMutationVariables>(EmailAutomationLeadsExportDocument, options);
      }
export type EmailAutomationLeadsExportMutationHookResult = ReturnType<typeof useEmailAutomationLeadsExportMutation>;
export type EmailAutomationLeadsExportMutationResult = Apollo.MutationResult<EmailAutomationLeadsExportMutation>;
export type EmailAutomationLeadsExportMutationOptions = Apollo.BaseMutationOptions<EmailAutomationLeadsExportMutation, EmailAutomationLeadsExportMutationVariables>;
export const GetFoldersDocument = gql`
    query GetFolders {
  folders {
    id
    name
    color
    campaigns {
      id
      name
    }
    createdAt
  }
}
    `;

/**
 * __useGetFoldersQuery__
 *
 * To run a query within a React component, call `useGetFoldersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFoldersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFoldersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetFoldersQuery(baseOptions?: Apollo.QueryHookOptions<GetFoldersQuery, GetFoldersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFoldersQuery, GetFoldersQueryVariables>(GetFoldersDocument, options);
      }
export function useGetFoldersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFoldersQuery, GetFoldersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFoldersQuery, GetFoldersQueryVariables>(GetFoldersDocument, options);
        }
export type GetFoldersQueryHookResult = ReturnType<typeof useGetFoldersQuery>;
export type GetFoldersLazyQueryHookResult = ReturnType<typeof useGetFoldersLazyQuery>;
export type GetFoldersQueryResult = Apollo.QueryResult<GetFoldersQuery, GetFoldersQueryVariables>;
export const ActiveCampaignChangeStatusDocument = gql`
    mutation activeCampaignChangeStatus($id: Int!, $status: Boolean!) {
  activeCampaignChangeStatus(id: $id, status: $status) {
    id
    status
  }
}
    `;
export type ActiveCampaignChangeStatusMutationFn = Apollo.MutationFunction<ActiveCampaignChangeStatusMutation, ActiveCampaignChangeStatusMutationVariables>;

/**
 * __useActiveCampaignChangeStatusMutation__
 *
 * To run a mutation, you first call `useActiveCampaignChangeStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActiveCampaignChangeStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activeCampaignChangeStatusMutation, { data, loading, error }] = useActiveCampaignChangeStatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useActiveCampaignChangeStatusMutation(baseOptions?: Apollo.MutationHookOptions<ActiveCampaignChangeStatusMutation, ActiveCampaignChangeStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ActiveCampaignChangeStatusMutation, ActiveCampaignChangeStatusMutationVariables>(ActiveCampaignChangeStatusDocument, options);
      }
export type ActiveCampaignChangeStatusMutationHookResult = ReturnType<typeof useActiveCampaignChangeStatusMutation>;
export type ActiveCampaignChangeStatusMutationResult = Apollo.MutationResult<ActiveCampaignChangeStatusMutation>;
export type ActiveCampaignChangeStatusMutationOptions = Apollo.BaseMutationOptions<ActiveCampaignChangeStatusMutation, ActiveCampaignChangeStatusMutationVariables>;
export const ActiveCampaignTestConnectionDocument = gql`
    mutation activeCampaignTestConnection($input: TestConnectionInput!) {
  activeCampaignTestConnection(input: $input) {
    isSent
    sentData {
      integratorFieldName
      integratorFieldId
      integratorTag
      popupsmartFieldName
      value
      nodeName
    }
    messageTitle
    message
    errorLogs
  }
}
    `;
export type ActiveCampaignTestConnectionMutationFn = Apollo.MutationFunction<ActiveCampaignTestConnectionMutation, ActiveCampaignTestConnectionMutationVariables>;

/**
 * __useActiveCampaignTestConnectionMutation__
 *
 * To run a mutation, you first call `useActiveCampaignTestConnectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActiveCampaignTestConnectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activeCampaignTestConnectionMutation, { data, loading, error }] = useActiveCampaignTestConnectionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useActiveCampaignTestConnectionMutation(baseOptions?: Apollo.MutationHookOptions<ActiveCampaignTestConnectionMutation, ActiveCampaignTestConnectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ActiveCampaignTestConnectionMutation, ActiveCampaignTestConnectionMutationVariables>(ActiveCampaignTestConnectionDocument, options);
      }
export type ActiveCampaignTestConnectionMutationHookResult = ReturnType<typeof useActiveCampaignTestConnectionMutation>;
export type ActiveCampaignTestConnectionMutationResult = Apollo.MutationResult<ActiveCampaignTestConnectionMutation>;
export type ActiveCampaignTestConnectionMutationOptions = Apollo.BaseMutationOptions<ActiveCampaignTestConnectionMutation, ActiveCampaignTestConnectionMutationVariables>;
export const CustomerIoChangeStatusDocument = gql`
    mutation customerIoChangeStatus($id: Int!, $status: Boolean!) {
  customerIoChangeStatus(id: $id, status: $status) {
    id
    status
  }
}
    `;
export type CustomerIoChangeStatusMutationFn = Apollo.MutationFunction<CustomerIoChangeStatusMutation, CustomerIoChangeStatusMutationVariables>;

/**
 * __useCustomerIoChangeStatusMutation__
 *
 * To run a mutation, you first call `useCustomerIoChangeStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCustomerIoChangeStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [customerIoChangeStatusMutation, { data, loading, error }] = useCustomerIoChangeStatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useCustomerIoChangeStatusMutation(baseOptions?: Apollo.MutationHookOptions<CustomerIoChangeStatusMutation, CustomerIoChangeStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CustomerIoChangeStatusMutation, CustomerIoChangeStatusMutationVariables>(CustomerIoChangeStatusDocument, options);
      }
export type CustomerIoChangeStatusMutationHookResult = ReturnType<typeof useCustomerIoChangeStatusMutation>;
export type CustomerIoChangeStatusMutationResult = Apollo.MutationResult<CustomerIoChangeStatusMutation>;
export type CustomerIoChangeStatusMutationOptions = Apollo.BaseMutationOptions<CustomerIoChangeStatusMutation, CustomerIoChangeStatusMutationVariables>;
export const CustomerIoTestConnectionDocument = gql`
    mutation customerIoTestConnection($input: TestConnectionInput!) {
  customerIoTestConnection(input: $input) {
    isSent
    sentData {
      integratorFieldName
      integratorFieldId
      integratorTag
      popupsmartFieldName
      value
      nodeName
    }
    messageTitle
    message
    errorLogs
  }
}
    `;
export type CustomerIoTestConnectionMutationFn = Apollo.MutationFunction<CustomerIoTestConnectionMutation, CustomerIoTestConnectionMutationVariables>;

/**
 * __useCustomerIoTestConnectionMutation__
 *
 * To run a mutation, you first call `useCustomerIoTestConnectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCustomerIoTestConnectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [customerIoTestConnectionMutation, { data, loading, error }] = useCustomerIoTestConnectionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCustomerIoTestConnectionMutation(baseOptions?: Apollo.MutationHookOptions<CustomerIoTestConnectionMutation, CustomerIoTestConnectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CustomerIoTestConnectionMutation, CustomerIoTestConnectionMutationVariables>(CustomerIoTestConnectionDocument, options);
      }
export type CustomerIoTestConnectionMutationHookResult = ReturnType<typeof useCustomerIoTestConnectionMutation>;
export type CustomerIoTestConnectionMutationResult = Apollo.MutationResult<CustomerIoTestConnectionMutation>;
export type CustomerIoTestConnectionMutationOptions = Apollo.BaseMutationOptions<CustomerIoTestConnectionMutation, CustomerIoTestConnectionMutationVariables>;
export const DripChangeStatusDocument = gql`
    mutation dripChangeStatus($id: Int!, $status: Boolean!) {
  dripChangeStatus(id: $id, status: $status) {
    id
    status
  }
}
    `;
export type DripChangeStatusMutationFn = Apollo.MutationFunction<DripChangeStatusMutation, DripChangeStatusMutationVariables>;

/**
 * __useDripChangeStatusMutation__
 *
 * To run a mutation, you first call `useDripChangeStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDripChangeStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [dripChangeStatusMutation, { data, loading, error }] = useDripChangeStatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useDripChangeStatusMutation(baseOptions?: Apollo.MutationHookOptions<DripChangeStatusMutation, DripChangeStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DripChangeStatusMutation, DripChangeStatusMutationVariables>(DripChangeStatusDocument, options);
      }
export type DripChangeStatusMutationHookResult = ReturnType<typeof useDripChangeStatusMutation>;
export type DripChangeStatusMutationResult = Apollo.MutationResult<DripChangeStatusMutation>;
export type DripChangeStatusMutationOptions = Apollo.BaseMutationOptions<DripChangeStatusMutation, DripChangeStatusMutationVariables>;
export const DripTestConnectionDocument = gql`
    mutation dripTestConnection($input: TestConnectionInput!) {
  dripTestConnection(input: $input) {
    isSent
    sentData {
      integratorFieldName
      integratorFieldId
      integratorTag
      popupsmartFieldName
      value
      nodeName
    }
    messageTitle
    message
    errorLogs
  }
}
    `;
export type DripTestConnectionMutationFn = Apollo.MutationFunction<DripTestConnectionMutation, DripTestConnectionMutationVariables>;

/**
 * __useDripTestConnectionMutation__
 *
 * To run a mutation, you first call `useDripTestConnectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDripTestConnectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [dripTestConnectionMutation, { data, loading, error }] = useDripTestConnectionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDripTestConnectionMutation(baseOptions?: Apollo.MutationHookOptions<DripTestConnectionMutation, DripTestConnectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DripTestConnectionMutation, DripTestConnectionMutationVariables>(DripTestConnectionDocument, options);
      }
export type DripTestConnectionMutationHookResult = ReturnType<typeof useDripTestConnectionMutation>;
export type DripTestConnectionMutationResult = Apollo.MutationResult<DripTestConnectionMutation>;
export type DripTestConnectionMutationOptions = Apollo.BaseMutationOptions<DripTestConnectionMutation, DripTestConnectionMutationVariables>;
export const EmailOctopusChangeStatusDocument = gql`
    mutation emailOctopusChangeStatus($id: Int!, $status: Boolean!) {
  emailOctopusChangeStatus(id: $id, status: $status) {
    id
    status
  }
}
    `;
export type EmailOctopusChangeStatusMutationFn = Apollo.MutationFunction<EmailOctopusChangeStatusMutation, EmailOctopusChangeStatusMutationVariables>;

/**
 * __useEmailOctopusChangeStatusMutation__
 *
 * To run a mutation, you first call `useEmailOctopusChangeStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEmailOctopusChangeStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [emailOctopusChangeStatusMutation, { data, loading, error }] = useEmailOctopusChangeStatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useEmailOctopusChangeStatusMutation(baseOptions?: Apollo.MutationHookOptions<EmailOctopusChangeStatusMutation, EmailOctopusChangeStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EmailOctopusChangeStatusMutation, EmailOctopusChangeStatusMutationVariables>(EmailOctopusChangeStatusDocument, options);
      }
export type EmailOctopusChangeStatusMutationHookResult = ReturnType<typeof useEmailOctopusChangeStatusMutation>;
export type EmailOctopusChangeStatusMutationResult = Apollo.MutationResult<EmailOctopusChangeStatusMutation>;
export type EmailOctopusChangeStatusMutationOptions = Apollo.BaseMutationOptions<EmailOctopusChangeStatusMutation, EmailOctopusChangeStatusMutationVariables>;
export const EmailOctopusTestConnectionDocument = gql`
    mutation emailOctopusTestConnection($input: TestConnectionInput!) {
  emailOctopusTestConnection(input: $input) {
    isSent
    sentData {
      integratorFieldName
      integratorFieldId
      integratorTag
      popupsmartFieldName
      value
      nodeName
    }
    messageTitle
    message
    errorLogs
  }
}
    `;
export type EmailOctopusTestConnectionMutationFn = Apollo.MutationFunction<EmailOctopusTestConnectionMutation, EmailOctopusTestConnectionMutationVariables>;

/**
 * __useEmailOctopusTestConnectionMutation__
 *
 * To run a mutation, you first call `useEmailOctopusTestConnectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEmailOctopusTestConnectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [emailOctopusTestConnectionMutation, { data, loading, error }] = useEmailOctopusTestConnectionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEmailOctopusTestConnectionMutation(baseOptions?: Apollo.MutationHookOptions<EmailOctopusTestConnectionMutation, EmailOctopusTestConnectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EmailOctopusTestConnectionMutation, EmailOctopusTestConnectionMutationVariables>(EmailOctopusTestConnectionDocument, options);
      }
export type EmailOctopusTestConnectionMutationHookResult = ReturnType<typeof useEmailOctopusTestConnectionMutation>;
export type EmailOctopusTestConnectionMutationResult = Apollo.MutationResult<EmailOctopusTestConnectionMutation>;
export type EmailOctopusTestConnectionMutationOptions = Apollo.BaseMutationOptions<EmailOctopusTestConnectionMutation, EmailOctopusTestConnectionMutationVariables>;
export const HubSpotChangeStatusDocument = gql`
    mutation hubSpotChangeStatus($id: Int!, $status: Boolean!) {
  hubSpotChangeStatus(id: $id, status: $status) {
    id
    status
  }
}
    `;
export type HubSpotChangeStatusMutationFn = Apollo.MutationFunction<HubSpotChangeStatusMutation, HubSpotChangeStatusMutationVariables>;

/**
 * __useHubSpotChangeStatusMutation__
 *
 * To run a mutation, you first call `useHubSpotChangeStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHubSpotChangeStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [hubSpotChangeStatusMutation, { data, loading, error }] = useHubSpotChangeStatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useHubSpotChangeStatusMutation(baseOptions?: Apollo.MutationHookOptions<HubSpotChangeStatusMutation, HubSpotChangeStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<HubSpotChangeStatusMutation, HubSpotChangeStatusMutationVariables>(HubSpotChangeStatusDocument, options);
      }
export type HubSpotChangeStatusMutationHookResult = ReturnType<typeof useHubSpotChangeStatusMutation>;
export type HubSpotChangeStatusMutationResult = Apollo.MutationResult<HubSpotChangeStatusMutation>;
export type HubSpotChangeStatusMutationOptions = Apollo.BaseMutationOptions<HubSpotChangeStatusMutation, HubSpotChangeStatusMutationVariables>;
export const HubSpotTestConnectionDocument = gql`
    mutation hubSpotTestConnection($input: TestConnectionInput!) {
  hubSpotTestConnection(input: $input) {
    isSent
    sentData {
      integratorFieldName
      integratorFieldId
      integratorTag
      popupsmartFieldName
      value
      nodeName
    }
    messageTitle
    message
    errorLogs
  }
}
    `;
export type HubSpotTestConnectionMutationFn = Apollo.MutationFunction<HubSpotTestConnectionMutation, HubSpotTestConnectionMutationVariables>;

/**
 * __useHubSpotTestConnectionMutation__
 *
 * To run a mutation, you first call `useHubSpotTestConnectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHubSpotTestConnectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [hubSpotTestConnectionMutation, { data, loading, error }] = useHubSpotTestConnectionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useHubSpotTestConnectionMutation(baseOptions?: Apollo.MutationHookOptions<HubSpotTestConnectionMutation, HubSpotTestConnectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<HubSpotTestConnectionMutation, HubSpotTestConnectionMutationVariables>(HubSpotTestConnectionDocument, options);
      }
export type HubSpotTestConnectionMutationHookResult = ReturnType<typeof useHubSpotTestConnectionMutation>;
export type HubSpotTestConnectionMutationResult = Apollo.MutationResult<HubSpotTestConnectionMutation>;
export type HubSpotTestConnectionMutationOptions = Apollo.BaseMutationOptions<HubSpotTestConnectionMutation, HubSpotTestConnectionMutationVariables>;
export const DeleteAllIntegrationsInCampaignDocument = gql`
    mutation deleteAllIntegrationsInCampaign($campaignId: Int!) {
  deleteAllIntegrationsInCampaign(id: $campaignId)
}
    `;
export type DeleteAllIntegrationsInCampaignMutationFn = Apollo.MutationFunction<DeleteAllIntegrationsInCampaignMutation, DeleteAllIntegrationsInCampaignMutationVariables>;

/**
 * __useDeleteAllIntegrationsInCampaignMutation__
 *
 * To run a mutation, you first call `useDeleteAllIntegrationsInCampaignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAllIntegrationsInCampaignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAllIntegrationsInCampaignMutation, { data, loading, error }] = useDeleteAllIntegrationsInCampaignMutation({
 *   variables: {
 *      campaignId: // value for 'campaignId'
 *   },
 * });
 */
export function useDeleteAllIntegrationsInCampaignMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAllIntegrationsInCampaignMutation, DeleteAllIntegrationsInCampaignMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteAllIntegrationsInCampaignMutation, DeleteAllIntegrationsInCampaignMutationVariables>(DeleteAllIntegrationsInCampaignDocument, options);
      }
export type DeleteAllIntegrationsInCampaignMutationHookResult = ReturnType<typeof useDeleteAllIntegrationsInCampaignMutation>;
export type DeleteAllIntegrationsInCampaignMutationResult = Apollo.MutationResult<DeleteAllIntegrationsInCampaignMutation>;
export type DeleteAllIntegrationsInCampaignMutationOptions = Apollo.BaseMutationOptions<DeleteAllIntegrationsInCampaignMutation, DeleteAllIntegrationsInCampaignMutationVariables>;
export const DisableAllIntegrationsInCampaignDocument = gql`
    mutation disableAllIntegrationsInCampaign($campaignId: Int!) {
  disableAllIntegrationsInCampaign(id: $campaignId)
}
    `;
export type DisableAllIntegrationsInCampaignMutationFn = Apollo.MutationFunction<DisableAllIntegrationsInCampaignMutation, DisableAllIntegrationsInCampaignMutationVariables>;

/**
 * __useDisableAllIntegrationsInCampaignMutation__
 *
 * To run a mutation, you first call `useDisableAllIntegrationsInCampaignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisableAllIntegrationsInCampaignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disableAllIntegrationsInCampaignMutation, { data, loading, error }] = useDisableAllIntegrationsInCampaignMutation({
 *   variables: {
 *      campaignId: // value for 'campaignId'
 *   },
 * });
 */
export function useDisableAllIntegrationsInCampaignMutation(baseOptions?: Apollo.MutationHookOptions<DisableAllIntegrationsInCampaignMutation, DisableAllIntegrationsInCampaignMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DisableAllIntegrationsInCampaignMutation, DisableAllIntegrationsInCampaignMutationVariables>(DisableAllIntegrationsInCampaignDocument, options);
      }
export type DisableAllIntegrationsInCampaignMutationHookResult = ReturnType<typeof useDisableAllIntegrationsInCampaignMutation>;
export type DisableAllIntegrationsInCampaignMutationResult = Apollo.MutationResult<DisableAllIntegrationsInCampaignMutation>;
export type DisableAllIntegrationsInCampaignMutationOptions = Apollo.BaseMutationOptions<DisableAllIntegrationsInCampaignMutation, DisableAllIntegrationsInCampaignMutationVariables>;
export const KlaviyoChangeStatusDocument = gql`
    mutation klaviyoChangeStatus($id: Int!, $status: Boolean!) {
  klaviyoChangeStatus(id: $id, status: $status) {
    id
    status
  }
}
    `;
export type KlaviyoChangeStatusMutationFn = Apollo.MutationFunction<KlaviyoChangeStatusMutation, KlaviyoChangeStatusMutationVariables>;

/**
 * __useKlaviyoChangeStatusMutation__
 *
 * To run a mutation, you first call `useKlaviyoChangeStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useKlaviyoChangeStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [klaviyoChangeStatusMutation, { data, loading, error }] = useKlaviyoChangeStatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useKlaviyoChangeStatusMutation(baseOptions?: Apollo.MutationHookOptions<KlaviyoChangeStatusMutation, KlaviyoChangeStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<KlaviyoChangeStatusMutation, KlaviyoChangeStatusMutationVariables>(KlaviyoChangeStatusDocument, options);
      }
export type KlaviyoChangeStatusMutationHookResult = ReturnType<typeof useKlaviyoChangeStatusMutation>;
export type KlaviyoChangeStatusMutationResult = Apollo.MutationResult<KlaviyoChangeStatusMutation>;
export type KlaviyoChangeStatusMutationOptions = Apollo.BaseMutationOptions<KlaviyoChangeStatusMutation, KlaviyoChangeStatusMutationVariables>;
export const KlaviyoTestConnectionDocument = gql`
    mutation klaviyoTestConnection($input: TestConnectionInput!) {
  klaviyoTestConnection(input: $input) {
    isSent
    sentData {
      integratorFieldName
      integratorFieldId
      integratorTag
      popupsmartFieldName
      value
      nodeName
    }
    messageTitle
    message
    errorLogs
  }
}
    `;
export type KlaviyoTestConnectionMutationFn = Apollo.MutationFunction<KlaviyoTestConnectionMutation, KlaviyoTestConnectionMutationVariables>;

/**
 * __useKlaviyoTestConnectionMutation__
 *
 * To run a mutation, you first call `useKlaviyoTestConnectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useKlaviyoTestConnectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [klaviyoTestConnectionMutation, { data, loading, error }] = useKlaviyoTestConnectionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useKlaviyoTestConnectionMutation(baseOptions?: Apollo.MutationHookOptions<KlaviyoTestConnectionMutation, KlaviyoTestConnectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<KlaviyoTestConnectionMutation, KlaviyoTestConnectionMutationVariables>(KlaviyoTestConnectionDocument, options);
      }
export type KlaviyoTestConnectionMutationHookResult = ReturnType<typeof useKlaviyoTestConnectionMutation>;
export type KlaviyoTestConnectionMutationResult = Apollo.MutationResult<KlaviyoTestConnectionMutation>;
export type KlaviyoTestConnectionMutationOptions = Apollo.BaseMutationOptions<KlaviyoTestConnectionMutation, KlaviyoTestConnectionMutationVariables>;
export const MailChimpChangeStatusDocument = gql`
    mutation mailChimpChangeStatus($id: Int!, $status: Boolean!) {
  mailChimpChangeStatus(id: $id, status: $status) {
    id
    status
  }
}
    `;
export type MailChimpChangeStatusMutationFn = Apollo.MutationFunction<MailChimpChangeStatusMutation, MailChimpChangeStatusMutationVariables>;

/**
 * __useMailChimpChangeStatusMutation__
 *
 * To run a mutation, you first call `useMailChimpChangeStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMailChimpChangeStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mailChimpChangeStatusMutation, { data, loading, error }] = useMailChimpChangeStatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useMailChimpChangeStatusMutation(baseOptions?: Apollo.MutationHookOptions<MailChimpChangeStatusMutation, MailChimpChangeStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MailChimpChangeStatusMutation, MailChimpChangeStatusMutationVariables>(MailChimpChangeStatusDocument, options);
      }
export type MailChimpChangeStatusMutationHookResult = ReturnType<typeof useMailChimpChangeStatusMutation>;
export type MailChimpChangeStatusMutationResult = Apollo.MutationResult<MailChimpChangeStatusMutation>;
export type MailChimpChangeStatusMutationOptions = Apollo.BaseMutationOptions<MailChimpChangeStatusMutation, MailChimpChangeStatusMutationVariables>;
export const MailChimpTestConnectionDocument = gql`
    mutation mailChimpTestConnection($input: TestConnectionInput!) {
  mailChimpTestConnection(input: $input) {
    isSent
    sentData {
      integratorFieldName
      integratorFieldId
      integratorTag
      popupsmartFieldName
      value
      nodeName
    }
    messageTitle
    message
    errorLogs
  }
}
    `;
export type MailChimpTestConnectionMutationFn = Apollo.MutationFunction<MailChimpTestConnectionMutation, MailChimpTestConnectionMutationVariables>;

/**
 * __useMailChimpTestConnectionMutation__
 *
 * To run a mutation, you first call `useMailChimpTestConnectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMailChimpTestConnectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mailChimpTestConnectionMutation, { data, loading, error }] = useMailChimpTestConnectionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMailChimpTestConnectionMutation(baseOptions?: Apollo.MutationHookOptions<MailChimpTestConnectionMutation, MailChimpTestConnectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MailChimpTestConnectionMutation, MailChimpTestConnectionMutationVariables>(MailChimpTestConnectionDocument, options);
      }
export type MailChimpTestConnectionMutationHookResult = ReturnType<typeof useMailChimpTestConnectionMutation>;
export type MailChimpTestConnectionMutationResult = Apollo.MutationResult<MailChimpTestConnectionMutation>;
export type MailChimpTestConnectionMutationOptions = Apollo.BaseMutationOptions<MailChimpTestConnectionMutation, MailChimpTestConnectionMutationVariables>;
export const MailerLiteChangeStatusDocument = gql`
    mutation mailerLiteChangeStatus($id: Int!, $status: Boolean!) {
  mailerLiteChangeStatus(id: $id, status: $status) {
    id
    status
  }
}
    `;
export type MailerLiteChangeStatusMutationFn = Apollo.MutationFunction<MailerLiteChangeStatusMutation, MailerLiteChangeStatusMutationVariables>;

/**
 * __useMailerLiteChangeStatusMutation__
 *
 * To run a mutation, you first call `useMailerLiteChangeStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMailerLiteChangeStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mailerLiteChangeStatusMutation, { data, loading, error }] = useMailerLiteChangeStatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useMailerLiteChangeStatusMutation(baseOptions?: Apollo.MutationHookOptions<MailerLiteChangeStatusMutation, MailerLiteChangeStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MailerLiteChangeStatusMutation, MailerLiteChangeStatusMutationVariables>(MailerLiteChangeStatusDocument, options);
      }
export type MailerLiteChangeStatusMutationHookResult = ReturnType<typeof useMailerLiteChangeStatusMutation>;
export type MailerLiteChangeStatusMutationResult = Apollo.MutationResult<MailerLiteChangeStatusMutation>;
export type MailerLiteChangeStatusMutationOptions = Apollo.BaseMutationOptions<MailerLiteChangeStatusMutation, MailerLiteChangeStatusMutationVariables>;
export const MailerliteTestConnectionDocument = gql`
    mutation mailerliteTestConnection($input: TestConnectionInput!) {
  mailerliteTestConnection(input: $input) {
    isSent
    sentData {
      integratorFieldName
      integratorFieldId
      integratorTag
      popupsmartFieldName
      value
      nodeName
    }
    messageTitle
    message
    errorLogs
  }
}
    `;
export type MailerliteTestConnectionMutationFn = Apollo.MutationFunction<MailerliteTestConnectionMutation, MailerliteTestConnectionMutationVariables>;

/**
 * __useMailerliteTestConnectionMutation__
 *
 * To run a mutation, you first call `useMailerliteTestConnectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMailerliteTestConnectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mailerliteTestConnectionMutation, { data, loading, error }] = useMailerliteTestConnectionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMailerliteTestConnectionMutation(baseOptions?: Apollo.MutationHookOptions<MailerliteTestConnectionMutation, MailerliteTestConnectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MailerliteTestConnectionMutation, MailerliteTestConnectionMutationVariables>(MailerliteTestConnectionDocument, options);
      }
export type MailerliteTestConnectionMutationHookResult = ReturnType<typeof useMailerliteTestConnectionMutation>;
export type MailerliteTestConnectionMutationResult = Apollo.MutationResult<MailerliteTestConnectionMutation>;
export type MailerliteTestConnectionMutationOptions = Apollo.BaseMutationOptions<MailerliteTestConnectionMutation, MailerliteTestConnectionMutationVariables>;
export const SendGridChangeStatusDocument = gql`
    mutation sendGridChangeStatus($id: Int!, $status: Boolean!) {
  sendGridChangeStatus(id: $id, status: $status) {
    id
    status
  }
}
    `;
export type SendGridChangeStatusMutationFn = Apollo.MutationFunction<SendGridChangeStatusMutation, SendGridChangeStatusMutationVariables>;

/**
 * __useSendGridChangeStatusMutation__
 *
 * To run a mutation, you first call `useSendGridChangeStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendGridChangeStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendGridChangeStatusMutation, { data, loading, error }] = useSendGridChangeStatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useSendGridChangeStatusMutation(baseOptions?: Apollo.MutationHookOptions<SendGridChangeStatusMutation, SendGridChangeStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendGridChangeStatusMutation, SendGridChangeStatusMutationVariables>(SendGridChangeStatusDocument, options);
      }
export type SendGridChangeStatusMutationHookResult = ReturnType<typeof useSendGridChangeStatusMutation>;
export type SendGridChangeStatusMutationResult = Apollo.MutationResult<SendGridChangeStatusMutation>;
export type SendGridChangeStatusMutationOptions = Apollo.BaseMutationOptions<SendGridChangeStatusMutation, SendGridChangeStatusMutationVariables>;
export const SendGridTestConnectionDocument = gql`
    mutation sendGridTestConnection($input: TestConnectionInput!) {
  sendGridTestConnection(input: $input) {
    isSent
    sentData {
      integratorFieldName
      integratorFieldId
      integratorTag
      popupsmartFieldName
      value
      nodeName
    }
    messageTitle
    message
    errorLogs
  }
}
    `;
export type SendGridTestConnectionMutationFn = Apollo.MutationFunction<SendGridTestConnectionMutation, SendGridTestConnectionMutationVariables>;

/**
 * __useSendGridTestConnectionMutation__
 *
 * To run a mutation, you first call `useSendGridTestConnectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendGridTestConnectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendGridTestConnectionMutation, { data, loading, error }] = useSendGridTestConnectionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendGridTestConnectionMutation(baseOptions?: Apollo.MutationHookOptions<SendGridTestConnectionMutation, SendGridTestConnectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendGridTestConnectionMutation, SendGridTestConnectionMutationVariables>(SendGridTestConnectionDocument, options);
      }
export type SendGridTestConnectionMutationHookResult = ReturnType<typeof useSendGridTestConnectionMutation>;
export type SendGridTestConnectionMutationResult = Apollo.MutationResult<SendGridTestConnectionMutation>;
export type SendGridTestConnectionMutationOptions = Apollo.BaseMutationOptions<SendGridTestConnectionMutation, SendGridTestConnectionMutationVariables>;
export const SendinBlueChangeStatusDocument = gql`
    mutation sendinBlueChangeStatus($id: Int!, $status: Boolean!) {
  sendinBlueChangeStatus(id: $id, status: $status) {
    id
    status
  }
}
    `;
export type SendinBlueChangeStatusMutationFn = Apollo.MutationFunction<SendinBlueChangeStatusMutation, SendinBlueChangeStatusMutationVariables>;

/**
 * __useSendinBlueChangeStatusMutation__
 *
 * To run a mutation, you first call `useSendinBlueChangeStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendinBlueChangeStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendinBlueChangeStatusMutation, { data, loading, error }] = useSendinBlueChangeStatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useSendinBlueChangeStatusMutation(baseOptions?: Apollo.MutationHookOptions<SendinBlueChangeStatusMutation, SendinBlueChangeStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendinBlueChangeStatusMutation, SendinBlueChangeStatusMutationVariables>(SendinBlueChangeStatusDocument, options);
      }
export type SendinBlueChangeStatusMutationHookResult = ReturnType<typeof useSendinBlueChangeStatusMutation>;
export type SendinBlueChangeStatusMutationResult = Apollo.MutationResult<SendinBlueChangeStatusMutation>;
export type SendinBlueChangeStatusMutationOptions = Apollo.BaseMutationOptions<SendinBlueChangeStatusMutation, SendinBlueChangeStatusMutationVariables>;
export const BrevoTestConnectionDocument = gql`
    mutation brevoTestConnection($input: TestConnectionInput!) {
  brevoTestConnection(input: $input) {
    isSent
    sentData {
      integratorFieldName
      integratorFieldId
      integratorTag
      popupsmartFieldName
      value
      nodeName
    }
    messageTitle
    message
    errorLogs
  }
}
    `;
export type BrevoTestConnectionMutationFn = Apollo.MutationFunction<BrevoTestConnectionMutation, BrevoTestConnectionMutationVariables>;

/**
 * __useBrevoTestConnectionMutation__
 *
 * To run a mutation, you first call `useBrevoTestConnectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBrevoTestConnectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [brevoTestConnectionMutation, { data, loading, error }] = useBrevoTestConnectionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBrevoTestConnectionMutation(baseOptions?: Apollo.MutationHookOptions<BrevoTestConnectionMutation, BrevoTestConnectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BrevoTestConnectionMutation, BrevoTestConnectionMutationVariables>(BrevoTestConnectionDocument, options);
      }
export type BrevoTestConnectionMutationHookResult = ReturnType<typeof useBrevoTestConnectionMutation>;
export type BrevoTestConnectionMutationResult = Apollo.MutationResult<BrevoTestConnectionMutation>;
export type BrevoTestConnectionMutationOptions = Apollo.BaseMutationOptions<BrevoTestConnectionMutation, BrevoTestConnectionMutationVariables>;
export const CreateShopifyIntegrationDocument = gql`
    mutation createShopifyIntegration($accountId: Int!, $campaignId: Int!, $storeDomain: String!, $customTags: String, $integrationFieldsShopify: [IntegrationShopifyFieldInput!]!, $acceptEmailMarketing: Boolean!, $acceptSmsMarketing: Boolean!) {
  createShopifyIntegration(
    accountId: $accountId
    campaignId: $campaignId
    storeDomain: $storeDomain
    customTags: $customTags
    integrationFieldsShopify: $integrationFieldsShopify
    acceptEmailMarketing: $acceptEmailMarketing
    acceptSmsMarketing: $acceptSmsMarketing
  ) {
    id
    campaignId
    status
    storeDomain
    customTags
    isConnectedTestCompleted
    createdAt
    updatedAt
    integrationFieldsShopify {
      id
      fieldType
      popupsmartFieldName
      integratorFieldName
    }
    acceptEmailMarketing
    acceptSmsMarketing
  }
}
    `;
export type CreateShopifyIntegrationMutationFn = Apollo.MutationFunction<CreateShopifyIntegrationMutation, CreateShopifyIntegrationMutationVariables>;

/**
 * __useCreateShopifyIntegrationMutation__
 *
 * To run a mutation, you first call `useCreateShopifyIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateShopifyIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createShopifyIntegrationMutation, { data, loading, error }] = useCreateShopifyIntegrationMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      campaignId: // value for 'campaignId'
 *      storeDomain: // value for 'storeDomain'
 *      customTags: // value for 'customTags'
 *      integrationFieldsShopify: // value for 'integrationFieldsShopify'
 *      acceptEmailMarketing: // value for 'acceptEmailMarketing'
 *      acceptSmsMarketing: // value for 'acceptSmsMarketing'
 *   },
 * });
 */
export function useCreateShopifyIntegrationMutation(baseOptions?: Apollo.MutationHookOptions<CreateShopifyIntegrationMutation, CreateShopifyIntegrationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateShopifyIntegrationMutation, CreateShopifyIntegrationMutationVariables>(CreateShopifyIntegrationDocument, options);
      }
export type CreateShopifyIntegrationMutationHookResult = ReturnType<typeof useCreateShopifyIntegrationMutation>;
export type CreateShopifyIntegrationMutationResult = Apollo.MutationResult<CreateShopifyIntegrationMutation>;
export type CreateShopifyIntegrationMutationOptions = Apollo.BaseMutationOptions<CreateShopifyIntegrationMutation, CreateShopifyIntegrationMutationVariables>;
export const UpdateShopifyIntegrationInCampaignDocument = gql`
    mutation updateShopifyIntegrationInCampaign($integrationId: Int!, $campaignId: Int!, $storeDomain: String, $customTags: String, $integrationFieldsShopify: [IntegrationShopifyFieldInput!]!, $acceptEmailMarketing: Boolean!, $acceptSmsMarketing: Boolean!) {
  updateShopifyIntegrationInCampaign(
    integrationId: $integrationId
    campaignId: $campaignId
    storeDomain: $storeDomain
    customTags: $customTags
    integrationFieldsShopify: $integrationFieldsShopify
    acceptEmailMarketing: $acceptEmailMarketing
    acceptSmsMarketing: $acceptSmsMarketing
  ) {
    id
    campaignId
    status
    storeDomain
    customTags
    isConnectedTestCompleted
    createdAt
    updatedAt
    integrationFieldsShopify {
      id
      fieldType
      popupsmartFieldName
      integratorFieldName
    }
    acceptEmailMarketing
    acceptSmsMarketing
  }
}
    `;
export type UpdateShopifyIntegrationInCampaignMutationFn = Apollo.MutationFunction<UpdateShopifyIntegrationInCampaignMutation, UpdateShopifyIntegrationInCampaignMutationVariables>;

/**
 * __useUpdateShopifyIntegrationInCampaignMutation__
 *
 * To run a mutation, you first call `useUpdateShopifyIntegrationInCampaignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateShopifyIntegrationInCampaignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateShopifyIntegrationInCampaignMutation, { data, loading, error }] = useUpdateShopifyIntegrationInCampaignMutation({
 *   variables: {
 *      integrationId: // value for 'integrationId'
 *      campaignId: // value for 'campaignId'
 *      storeDomain: // value for 'storeDomain'
 *      customTags: // value for 'customTags'
 *      integrationFieldsShopify: // value for 'integrationFieldsShopify'
 *      acceptEmailMarketing: // value for 'acceptEmailMarketing'
 *      acceptSmsMarketing: // value for 'acceptSmsMarketing'
 *   },
 * });
 */
export function useUpdateShopifyIntegrationInCampaignMutation(baseOptions?: Apollo.MutationHookOptions<UpdateShopifyIntegrationInCampaignMutation, UpdateShopifyIntegrationInCampaignMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateShopifyIntegrationInCampaignMutation, UpdateShopifyIntegrationInCampaignMutationVariables>(UpdateShopifyIntegrationInCampaignDocument, options);
      }
export type UpdateShopifyIntegrationInCampaignMutationHookResult = ReturnType<typeof useUpdateShopifyIntegrationInCampaignMutation>;
export type UpdateShopifyIntegrationInCampaignMutationResult = Apollo.MutationResult<UpdateShopifyIntegrationInCampaignMutation>;
export type UpdateShopifyIntegrationInCampaignMutationOptions = Apollo.BaseMutationOptions<UpdateShopifyIntegrationInCampaignMutation, UpdateShopifyIntegrationInCampaignMutationVariables>;
export const AddShopifyIntegrationInCampaignDocument = gql`
    mutation addShopifyIntegrationInCampaign($integrationId: Int!, $campaignId: Int!, $storeDomain: String!, $customTags: String, $integrationFieldsShopify: [IntegrationShopifyFieldInput!]!, $acceptEmailMarketing: Boolean!, $acceptSmsMarketing: Boolean!) {
  addShopifyIntegrationInCampaign(
    integrationId: $integrationId
    campaignId: $campaignId
    storeDomain: $storeDomain
    customTags: $customTags
    integrationFieldsShopify: $integrationFieldsShopify
    acceptEmailMarketing: $acceptEmailMarketing
    acceptSmsMarketing: $acceptSmsMarketing
  ) {
    id
    campaignId
    status
    storeDomain
    customTags
    isConnectedTestCompleted
    createdAt
    updatedAt
    integrationFieldsShopify {
      id
      fieldType
      popupsmartFieldName
      integratorFieldName
    }
    acceptEmailMarketing
    acceptSmsMarketing
  }
}
    `;
export type AddShopifyIntegrationInCampaignMutationFn = Apollo.MutationFunction<AddShopifyIntegrationInCampaignMutation, AddShopifyIntegrationInCampaignMutationVariables>;

/**
 * __useAddShopifyIntegrationInCampaignMutation__
 *
 * To run a mutation, you first call `useAddShopifyIntegrationInCampaignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddShopifyIntegrationInCampaignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addShopifyIntegrationInCampaignMutation, { data, loading, error }] = useAddShopifyIntegrationInCampaignMutation({
 *   variables: {
 *      integrationId: // value for 'integrationId'
 *      campaignId: // value for 'campaignId'
 *      storeDomain: // value for 'storeDomain'
 *      customTags: // value for 'customTags'
 *      integrationFieldsShopify: // value for 'integrationFieldsShopify'
 *      acceptEmailMarketing: // value for 'acceptEmailMarketing'
 *      acceptSmsMarketing: // value for 'acceptSmsMarketing'
 *   },
 * });
 */
export function useAddShopifyIntegrationInCampaignMutation(baseOptions?: Apollo.MutationHookOptions<AddShopifyIntegrationInCampaignMutation, AddShopifyIntegrationInCampaignMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddShopifyIntegrationInCampaignMutation, AddShopifyIntegrationInCampaignMutationVariables>(AddShopifyIntegrationInCampaignDocument, options);
      }
export type AddShopifyIntegrationInCampaignMutationHookResult = ReturnType<typeof useAddShopifyIntegrationInCampaignMutation>;
export type AddShopifyIntegrationInCampaignMutationResult = Apollo.MutationResult<AddShopifyIntegrationInCampaignMutation>;
export type AddShopifyIntegrationInCampaignMutationOptions = Apollo.BaseMutationOptions<AddShopifyIntegrationInCampaignMutation, AddShopifyIntegrationInCampaignMutationVariables>;
export const ChangeShopifyIntegrationInCampaignStatusDocument = gql`
    mutation changeShopifyIntegrationInCampaignStatus($shopifyIntegrationCampaignId: Int!, $status: Boolean!) {
  changeShopifyIntegrationInCampaignStatus(
    shopifyIntegrationCampaignId: $shopifyIntegrationCampaignId
    status: $status
  ) {
    id
    status
  }
}
    `;
export type ChangeShopifyIntegrationInCampaignStatusMutationFn = Apollo.MutationFunction<ChangeShopifyIntegrationInCampaignStatusMutation, ChangeShopifyIntegrationInCampaignStatusMutationVariables>;

/**
 * __useChangeShopifyIntegrationInCampaignStatusMutation__
 *
 * To run a mutation, you first call `useChangeShopifyIntegrationInCampaignStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeShopifyIntegrationInCampaignStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeShopifyIntegrationInCampaignStatusMutation, { data, loading, error }] = useChangeShopifyIntegrationInCampaignStatusMutation({
 *   variables: {
 *      shopifyIntegrationCampaignId: // value for 'shopifyIntegrationCampaignId'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useChangeShopifyIntegrationInCampaignStatusMutation(baseOptions?: Apollo.MutationHookOptions<ChangeShopifyIntegrationInCampaignStatusMutation, ChangeShopifyIntegrationInCampaignStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeShopifyIntegrationInCampaignStatusMutation, ChangeShopifyIntegrationInCampaignStatusMutationVariables>(ChangeShopifyIntegrationInCampaignStatusDocument, options);
      }
export type ChangeShopifyIntegrationInCampaignStatusMutationHookResult = ReturnType<typeof useChangeShopifyIntegrationInCampaignStatusMutation>;
export type ChangeShopifyIntegrationInCampaignStatusMutationResult = Apollo.MutationResult<ChangeShopifyIntegrationInCampaignStatusMutation>;
export type ChangeShopifyIntegrationInCampaignStatusMutationOptions = Apollo.BaseMutationOptions<ChangeShopifyIntegrationInCampaignStatusMutation, ChangeShopifyIntegrationInCampaignStatusMutationVariables>;
export const ChangeShopifyIntegrationInCampaignIsConnectedTestCompletedDocument = gql`
    mutation changeShopifyIntegrationInCampaignIsConnectedTestCompleted($shopifyIntegrationCampaignId: Int!, $isConnectedTestCompleted: Boolean!) {
  changeShopifyIntegrationInCampaignIsConnectedTestCompleted(
    shopifyIntegrationCampaignId: $shopifyIntegrationCampaignId
    isConnectedTestCompleted: $isConnectedTestCompleted
  )
}
    `;
export type ChangeShopifyIntegrationInCampaignIsConnectedTestCompletedMutationFn = Apollo.MutationFunction<ChangeShopifyIntegrationInCampaignIsConnectedTestCompletedMutation, ChangeShopifyIntegrationInCampaignIsConnectedTestCompletedMutationVariables>;

/**
 * __useChangeShopifyIntegrationInCampaignIsConnectedTestCompletedMutation__
 *
 * To run a mutation, you first call `useChangeShopifyIntegrationInCampaignIsConnectedTestCompletedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeShopifyIntegrationInCampaignIsConnectedTestCompletedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeShopifyIntegrationInCampaignIsConnectedTestCompletedMutation, { data, loading, error }] = useChangeShopifyIntegrationInCampaignIsConnectedTestCompletedMutation({
 *   variables: {
 *      shopifyIntegrationCampaignId: // value for 'shopifyIntegrationCampaignId'
 *      isConnectedTestCompleted: // value for 'isConnectedTestCompleted'
 *   },
 * });
 */
export function useChangeShopifyIntegrationInCampaignIsConnectedTestCompletedMutation(baseOptions?: Apollo.MutationHookOptions<ChangeShopifyIntegrationInCampaignIsConnectedTestCompletedMutation, ChangeShopifyIntegrationInCampaignIsConnectedTestCompletedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeShopifyIntegrationInCampaignIsConnectedTestCompletedMutation, ChangeShopifyIntegrationInCampaignIsConnectedTestCompletedMutationVariables>(ChangeShopifyIntegrationInCampaignIsConnectedTestCompletedDocument, options);
      }
export type ChangeShopifyIntegrationInCampaignIsConnectedTestCompletedMutationHookResult = ReturnType<typeof useChangeShopifyIntegrationInCampaignIsConnectedTestCompletedMutation>;
export type ChangeShopifyIntegrationInCampaignIsConnectedTestCompletedMutationResult = Apollo.MutationResult<ChangeShopifyIntegrationInCampaignIsConnectedTestCompletedMutation>;
export type ChangeShopifyIntegrationInCampaignIsConnectedTestCompletedMutationOptions = Apollo.BaseMutationOptions<ChangeShopifyIntegrationInCampaignIsConnectedTestCompletedMutation, ChangeShopifyIntegrationInCampaignIsConnectedTestCompletedMutationVariables>;
export const DeleteShopifyIntegrationInCampaignDocument = gql`
    mutation deleteShopifyIntegrationInCampaign($shopifyIntegrationCampaignId: Int!) {
  deleteShopifyIntegrationInCampaign(
    shopifyIntegrationCampaignId: $shopifyIntegrationCampaignId
  ) {
    id
  }
}
    `;
export type DeleteShopifyIntegrationInCampaignMutationFn = Apollo.MutationFunction<DeleteShopifyIntegrationInCampaignMutation, DeleteShopifyIntegrationInCampaignMutationVariables>;

/**
 * __useDeleteShopifyIntegrationInCampaignMutation__
 *
 * To run a mutation, you first call `useDeleteShopifyIntegrationInCampaignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteShopifyIntegrationInCampaignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteShopifyIntegrationInCampaignMutation, { data, loading, error }] = useDeleteShopifyIntegrationInCampaignMutation({
 *   variables: {
 *      shopifyIntegrationCampaignId: // value for 'shopifyIntegrationCampaignId'
 *   },
 * });
 */
export function useDeleteShopifyIntegrationInCampaignMutation(baseOptions?: Apollo.MutationHookOptions<DeleteShopifyIntegrationInCampaignMutation, DeleteShopifyIntegrationInCampaignMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteShopifyIntegrationInCampaignMutation, DeleteShopifyIntegrationInCampaignMutationVariables>(DeleteShopifyIntegrationInCampaignDocument, options);
      }
export type DeleteShopifyIntegrationInCampaignMutationHookResult = ReturnType<typeof useDeleteShopifyIntegrationInCampaignMutation>;
export type DeleteShopifyIntegrationInCampaignMutationResult = Apollo.MutationResult<DeleteShopifyIntegrationInCampaignMutation>;
export type DeleteShopifyIntegrationInCampaignMutationOptions = Apollo.BaseMutationOptions<DeleteShopifyIntegrationInCampaignMutation, DeleteShopifyIntegrationInCampaignMutationVariables>;
export const DeleteShopifyIntegrationDocument = gql`
    mutation deleteShopifyIntegration($shopifyIntegrationId: Int!) {
  deleteShopifyIntegration(shopifyIntegrationId: $shopifyIntegrationId)
}
    `;
export type DeleteShopifyIntegrationMutationFn = Apollo.MutationFunction<DeleteShopifyIntegrationMutation, DeleteShopifyIntegrationMutationVariables>;

/**
 * __useDeleteShopifyIntegrationMutation__
 *
 * To run a mutation, you first call `useDeleteShopifyIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteShopifyIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteShopifyIntegrationMutation, { data, loading, error }] = useDeleteShopifyIntegrationMutation({
 *   variables: {
 *      shopifyIntegrationId: // value for 'shopifyIntegrationId'
 *   },
 * });
 */
export function useDeleteShopifyIntegrationMutation(baseOptions?: Apollo.MutationHookOptions<DeleteShopifyIntegrationMutation, DeleteShopifyIntegrationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteShopifyIntegrationMutation, DeleteShopifyIntegrationMutationVariables>(DeleteShopifyIntegrationDocument, options);
      }
export type DeleteShopifyIntegrationMutationHookResult = ReturnType<typeof useDeleteShopifyIntegrationMutation>;
export type DeleteShopifyIntegrationMutationResult = Apollo.MutationResult<DeleteShopifyIntegrationMutation>;
export type DeleteShopifyIntegrationMutationOptions = Apollo.BaseMutationOptions<DeleteShopifyIntegrationMutation, DeleteShopifyIntegrationMutationVariables>;
export const GetAllShopifyIntegrationInCampaignDocument = gql`
    query getAllShopifyIntegrationInCampaign($campaignId: Int!) {
  getAllShopifyIntegrationInCampaign(campaignId: $campaignId) {
    id
    campaignId
    integrationId
    status
    storeDomain
    customTags
    isConnectedTestCompleted
    createdAt
    updatedAt
    integrationFieldsShopify {
      id
      fieldType
      popupsmartFieldName
      integratorFieldName
    }
    acceptEmailMarketing
    acceptSmsMarketing
  }
}
    `;

/**
 * __useGetAllShopifyIntegrationInCampaignQuery__
 *
 * To run a query within a React component, call `useGetAllShopifyIntegrationInCampaignQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllShopifyIntegrationInCampaignQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllShopifyIntegrationInCampaignQuery({
 *   variables: {
 *      campaignId: // value for 'campaignId'
 *   },
 * });
 */
export function useGetAllShopifyIntegrationInCampaignQuery(baseOptions: Apollo.QueryHookOptions<GetAllShopifyIntegrationInCampaignQuery, GetAllShopifyIntegrationInCampaignQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllShopifyIntegrationInCampaignQuery, GetAllShopifyIntegrationInCampaignQueryVariables>(GetAllShopifyIntegrationInCampaignDocument, options);
      }
export function useGetAllShopifyIntegrationInCampaignLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllShopifyIntegrationInCampaignQuery, GetAllShopifyIntegrationInCampaignQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllShopifyIntegrationInCampaignQuery, GetAllShopifyIntegrationInCampaignQueryVariables>(GetAllShopifyIntegrationInCampaignDocument, options);
        }
export type GetAllShopifyIntegrationInCampaignQueryHookResult = ReturnType<typeof useGetAllShopifyIntegrationInCampaignQuery>;
export type GetAllShopifyIntegrationInCampaignLazyQueryHookResult = ReturnType<typeof useGetAllShopifyIntegrationInCampaignLazyQuery>;
export type GetAllShopifyIntegrationInCampaignQueryResult = Apollo.QueryResult<GetAllShopifyIntegrationInCampaignQuery, GetAllShopifyIntegrationInCampaignQueryVariables>;
export const WebsitesDocument = gql`
    query websites {
  websites {
    total
    data {
      id
      url
      verified
      cms
      campaignCount
      isShopify
    }
  }
}
    `;

/**
 * __useWebsitesQuery__
 *
 * To run a query within a React component, call `useWebsitesQuery` and pass it any options that fit your needs.
 * When your component renders, `useWebsitesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWebsitesQuery({
 *   variables: {
 *   },
 * });
 */
export function useWebsitesQuery(baseOptions?: Apollo.QueryHookOptions<WebsitesQuery, WebsitesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WebsitesQuery, WebsitesQueryVariables>(WebsitesDocument, options);
      }
export function useWebsitesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WebsitesQuery, WebsitesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WebsitesQuery, WebsitesQueryVariables>(WebsitesDocument, options);
        }
export type WebsitesQueryHookResult = ReturnType<typeof useWebsitesQuery>;
export type WebsitesLazyQueryHookResult = ReturnType<typeof useWebsitesLazyQuery>;
export type WebsitesQueryResult = Apollo.QueryResult<WebsitesQuery, WebsitesQueryVariables>;
export const DeleteWebsiteDocument = gql`
    mutation deleteWebsite($websiteId: Float!) {
  deleteWebsite(websiteId: $websiteId)
}
    `;
export type DeleteWebsiteMutationFn = Apollo.MutationFunction<DeleteWebsiteMutation, DeleteWebsiteMutationVariables>;

/**
 * __useDeleteWebsiteMutation__
 *
 * To run a mutation, you first call `useDeleteWebsiteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteWebsiteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteWebsiteMutation, { data, loading, error }] = useDeleteWebsiteMutation({
 *   variables: {
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useDeleteWebsiteMutation(baseOptions?: Apollo.MutationHookOptions<DeleteWebsiteMutation, DeleteWebsiteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteWebsiteMutation, DeleteWebsiteMutationVariables>(DeleteWebsiteDocument, options);
      }
export type DeleteWebsiteMutationHookResult = ReturnType<typeof useDeleteWebsiteMutation>;
export type DeleteWebsiteMutationResult = Apollo.MutationResult<DeleteWebsiteMutation>;
export type DeleteWebsiteMutationOptions = Apollo.BaseMutationOptions<DeleteWebsiteMutation, DeleteWebsiteMutationVariables>;
export const CreateWebsiteDocument = gql`
    mutation CreateWebsite($website: String!) {
  createWebsite(website: $website) {
    url
    verified
    isShopify
    id
    cms
    campaignCount
  }
}
    `;
export type CreateWebsiteMutationFn = Apollo.MutationFunction<CreateWebsiteMutation, CreateWebsiteMutationVariables>;

/**
 * __useCreateWebsiteMutation__
 *
 * To run a mutation, you first call `useCreateWebsiteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWebsiteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWebsiteMutation, { data, loading, error }] = useCreateWebsiteMutation({
 *   variables: {
 *      website: // value for 'website'
 *   },
 * });
 */
export function useCreateWebsiteMutation(baseOptions?: Apollo.MutationHookOptions<CreateWebsiteMutation, CreateWebsiteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateWebsiteMutation, CreateWebsiteMutationVariables>(CreateWebsiteDocument, options);
      }
export type CreateWebsiteMutationHookResult = ReturnType<typeof useCreateWebsiteMutation>;
export type CreateWebsiteMutationResult = Apollo.MutationResult<CreateWebsiteMutation>;
export type CreateWebsiteMutationOptions = Apollo.BaseMutationOptions<CreateWebsiteMutation, CreateWebsiteMutationVariables>;
export const UpdateWebsiteDocument = gql`
    mutation UpdateWebsite($website: String!, $websiteId: Float!) {
  updateWebsite(website: $website, websiteId: $websiteId) {
    id
    url
    cms
    verified
    campaignCount
    isShopify
  }
}
    `;
export type UpdateWebsiteMutationFn = Apollo.MutationFunction<UpdateWebsiteMutation, UpdateWebsiteMutationVariables>;

/**
 * __useUpdateWebsiteMutation__
 *
 * To run a mutation, you first call `useUpdateWebsiteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWebsiteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWebsiteMutation, { data, loading, error }] = useUpdateWebsiteMutation({
 *   variables: {
 *      website: // value for 'website'
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useUpdateWebsiteMutation(baseOptions?: Apollo.MutationHookOptions<UpdateWebsiteMutation, UpdateWebsiteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateWebsiteMutation, UpdateWebsiteMutationVariables>(UpdateWebsiteDocument, options);
      }
export type UpdateWebsiteMutationHookResult = ReturnType<typeof useUpdateWebsiteMutation>;
export type UpdateWebsiteMutationResult = Apollo.MutationResult<UpdateWebsiteMutation>;
export type UpdateWebsiteMutationOptions = Apollo.BaseMutationOptions<UpdateWebsiteMutation, UpdateWebsiteMutationVariables>;