import type { DeviceTypeEnum, TemplateThumbnailProps } from './types';
import React, { FC } from 'react';
import styles from './TemplateThumbnail.module.scss';

import cn from 'classnames';

import Tooltip from '../Tooltip';

import MultiPageIcon from '../../assets/icons/MultiPageIcon';
import DesktopIcon from '../../assets/icons/DesktopIcon';
import MobileIcon from '../../assets/icons/MobileIcon';
import AnimatedIcon from '../../assets/icons/AnimatedIcon';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import sendClientSidePosthogEvent from 'api-core/utils/analytics/sendClientSidePosthogEvent';

const deviceTypes: DeviceTypeEnum[] = [
  {
    id: 0,
    name: 'multidevice',
    label: 'Desktop & Mobile',
  },
  {
    id: 1,
    name: 'desktop',
    label: 'Desktop',
  },
  {
    id: 2,
    name: 'mobile',
    label: 'Mobile',
  },
];
const TemplateThumbnail: FC<TemplateThumbnailProps> = ({
  template,
  isSelected = false,
  isIconsHidden = false,
  handleDetailTemplate,
  handleSelectTemplate,
}) => {
  return (
    <div
      data-testid="template-thumbnail"
      className={cn(styles.thumbnail, { [styles.selected]: isSelected })}
    >
      <div className={cn(styles.card, { [styles.selected]: isSelected })}>
        <LazyLoadImage
          className={styles.image}
          alt="popup image"
          src={template.imageUrl || ''} // use normal <img> attributes as props
          data-testid="template-thumbnail-image"
        />
        {isSelected ? (
          <span className={styles.selectedBadge}>Current</span>
        ) : (
          <div className={styles.hover}>
            <div className={styles.buttons}>
              {handleSelectTemplate ? (
                <button
                  className={styles.selectButton}
                  onClick={() => {
                    handleSelectTemplate(template.id);
                    sendClientSidePosthogEvent({
                      key: 'template_select_button_clicked',
                      properties: {
                        from: 'playbook',
                        data: {
                          templateId: String(template.id),
                          templateName: template?.name || '',
                        },
                      },
                    });
                  }}
                  id={`template-select-button-${template.id}`}
                  data-testid="template-thumbnail-select-button"
                >
                  Select
                </button>
              ) : null}
              {handleDetailTemplate ? (
                <button
                  className={styles.detailsButton}
                  onClick={() => {
                    handleDetailTemplate(template.id);
                    sendClientSidePosthogEvent({
                      key: 'template_details_button_clicked',
                      properties: {
                        from: 'playbook',
                        data: {
                          templateId: String(template.id),
                          templateName: template?.name || '',
                        },
                      },
                    });
                  }}
                  id={`template-detail-button-${template.id}`}
                  data-testid="template-thumbnail-button"
                >
                  Details
                </button>
              ) : null}
            </div>

            <div
              className={cn(styles.deviceContainer, {
                [styles.hidden]: isIconsHidden,
              })}
            >
              <div className={styles.deviceWrapper}>
                {template.isAnimated && (
                  <Tooltip
                    contentClassName={styles.isAnimatedTooltip}
                    content={'Animated'}
                  >
                    <span
                      data-testid="template-thumbnail-is-animated"
                      className={cn(styles.typeButtons, styles.default)}
                    >
                      <AnimatedIcon
                        className="rotate-zoom-animation"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                      />
                    </span>
                  </Tooltip>
                )}
                {template.isMultipage && (
                  <Tooltip
                    contentClassName="!top-auto !bottom-[-15px] !transform !translate-y-[51%] !right-[-18px] !left-auto"
                    content={'Multipage'}
                  >
                    <span
                      data-testid="template-thumbnail-is-multipage"
                      className={cn(styles.typeButtons, styles.default)}
                    >
                      <MultiPageIcon
                        width="16"
                        height="16"
                        viewBox="0 0 18 18"
                      />
                    </span>
                  </Tooltip>
                )}
                <Tooltip
                  contentClassName="!top-auto !bottom-[-20px] !transform !translate-y-[50%] !right-[0px] !left-auto"
                  content={
                    deviceTypes.find((l) => l.id === template.deviceType)?.label
                  }
                >
                  <span className={cn(styles.typeButtons, styles.deviceButton)}>
                    {(template.deviceType === 0 ||
                      template.deviceType === 1) && <DesktopIcon />}

                    {(template.deviceType === 0 ||
                      template.deviceType === 2) && <MobileIcon />}
                  </span>
                </Tooltip>
              </div>
            </div>
          </div>
        )}
      </div>
      <h4 className={styles.templateName} data-testid="template-thumbnail-name">
        {template.name}
      </h4>
      {isSelected && (
        <p className={styles.templateSubTag}>{template.subTags[0]}</p>
      )}
    </div>
  );
};

export default TemplateThumbnail;
