import {
  GET_CAMPAIGNS_REQUEST,
  DELETE_CAMPAIGN_REQUEST,
  DUPLICATE_CAMPAIGN_REQUEST,
  RENAME_CAMPAIGN_REQUEST,
  CHANGE_CAMPAIGN_STATUS_REQUEST,
  GET_ALL_CAMPAIGNS_REQUEST,
  CREATE_CAMPAIGN_WITH_TEMPLATE_REQUEST,
  GET_CAMPAIGNS_ANALYTICS_REQUEST,
  CLEAN_CAMPAIGNS_REQUEST,
  GET_CAMPAIGNS_COUNTS,
  CLEAN_CAMPAIGN_WITH_TEMPLATE_SUCCESS,
  ADD_PENDING_CAMPAIGN,
  RESET_PENDING_CAMPAIGN,
  INCREASE_CAMPAIGNS_COUNTS,
  FILTER_CAMPAIGNS,
  TOGGLE_FOLDERS,
} from './constants';
import {
  CreateCampaignWithTemplateIdActionPayload,
  addPendingCampaignActionPayload,
  GetCampaignsAnalyticsPayload,
} from './types';

type CampaignFilter = {
  search?: string;
  sortBy?: string;
  isAsc?: boolean;
  activeOnly?: boolean;
  page?: number;
  pageSize?: number;
  domains?: string;
};

export function getCampaigns(payload?: CampaignFilter) {
  return {
    type: GET_CAMPAIGNS_REQUEST,
    payload,
  };
}

export const filterCampaigns = ({
  skip,
  limit,
  order,
  sortBy,
  searchedName,
  selectedDomainIds,
  folderId,
  isPopupCampaigns,
}: {
  skip: number;
  limit: number;
  order: string;
  sortBy: string;
  searchedName: string;
  selectedDomainIds: string[];
  folderId?: number | null;
  isPopupCampaigns?: boolean;
}) => ({
  type: FILTER_CAMPAIGNS,
  payload: {
    skip,
    limit,
    order,
    sortBy,
    searchedName,
    selectedDomainIds,
    folderId,
    isPopupCampaigns,
  },
});

export function getCampaignsCounts() {
  return {
    type: GET_CAMPAIGNS_COUNTS,
  };
}
export function increaseCampaignsCounts() {
  return {
    type: INCREASE_CAMPAIGNS_COUNTS,
  };
}

export function deleteCampaign(payload: {
  id: number;
  onSuccess?: () => void;
}) {
  return {
    type: DELETE_CAMPAIGN_REQUEST,
    payload,
  };
}
export function duplicateCampaign(payload: any, onSuccess?: () => void) {
  return {
    type: DUPLICATE_CAMPAIGN_REQUEST,
    payload,
    onSuccess,
  };
}
export function renameCampaign(payload: any) {
  return {
    type: RENAME_CAMPAIGN_REQUEST,
    payload,
  };
}
export function changeCampaignStatus(payload: any) {
  return {
    type: CHANGE_CAMPAIGN_STATUS_REQUEST,
    payload,
  };
}

export function getAllCampaigns() {
  return {
    type: GET_ALL_CAMPAIGNS_REQUEST,
  };
}
export function cleanCampaigns() {
  return {
    type: CLEAN_CAMPAIGNS_REQUEST,
  };
}

export function createCampaignWithTemplateId(
  payload: CreateCampaignWithTemplateIdActionPayload,
) {
  return {
    type: CREATE_CAMPAIGN_WITH_TEMPLATE_REQUEST,
    payload,
  };
}

export function addPendingCampaign(payload: addPendingCampaignActionPayload) {
  return {
    type: ADD_PENDING_CAMPAIGN,
    payload,
  };
}

export function resetPendingCampaign() {
  return {
    type: RESET_PENDING_CAMPAIGN,
  };
}

export function clearCampaignWithTemplateSuccess() {
  return {
    type: CLEAN_CAMPAIGN_WITH_TEMPLATE_SUCCESS,
  };
}

export function getCampaignsAnalytics(payload: GetCampaignsAnalyticsPayload) {
  return {
    type: GET_CAMPAIGNS_ANALYTICS_REQUEST,
    payload,
  };
}

export const toggleFolders = () => ({
  type: TOGGLE_FOLDERS,
});
